import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ic_user from "../../../../assets/prepaid_cards/ic_user.svg";
import ic_mobile from "../../../../assets/prepaid_cards/ic_mobile.svg";
import ic_document from "../../../../assets/prepaid_cards/ic_document.svg";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import prepaidCardClientX from "../../../../utils/api/prepaidCardClientX";
import { updateUserData, setSelectedDocumentData } from "../../actions";
import { useEffect } from "react";
// import "react-datepicker/dist/react-datepicker.css";
import { Calendar } from 'react-date-range'
import { OverlayTrigger } from "react-bootstrap";
import format from 'date-fns/format';
import moment from "moment";


const DocumentEdit = (props) => {
  let history = useHistory();
  let dispatch = useDispatch();

  const { selectedCardData, userName, formData } = useSelector((state) => state?.ppi)
  const selectedDocumentData = useSelector((state) => state?.ppi?.selectedDocumentData)
  const { profile } = useSelector((state) => state?.global)

  const [documentsDataList, setDocumentsDataList] = useState();
  const [userSubmissionForm, setUserSubmissionForm] = useState({
    mobile: profile?.mobile,
    name: userName,
  })
  const [selectedDocument, setSelectedDocument] = useState();
  const [showCalender, setShowCalender] = useState(false)
  const [enableContinueButton, setEnableContinueButton] = useState(false)

  const dateRef = useRef(null)
  let ref = useRef(null);

  useEffect(() => {
    getDoucumentTypes()
    if (formData) {
      setSelectedDocument(selectedDocumentData)
      setUserSubmissionForm(formData)
      setEnableContinueButton(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userSelelectedDocument = (id) => {
    // eslint-disable-next-line array-callback-return
    documentsDataList?.map(item => {
      if (parseInt(id) === item.document_id) {
        setSelectedDocument(item)
      }
    })
    updateUserData(null)
    setUserSubmissionForm({
      mobile: profile?.mobile,
      name: userName,
    })
    setEnableContinueButton(false)
  }

  const getDoucumentTypes = () => {
    prepaidCardClientX.get(`/get-card-document-types/${selectedCardData?.cardPlanID}`,
      {
        headers: {
          TempAuthToken: profile?.temp_ppi_token,
        }
      }).then((response) => {
        let data = response.data.data;
        setDocumentsDataList(data)
      });
  }



  const userInputHandler = (value, name) => {
    userSubmissionForm[`${name}`] = value
    setUserSubmissionForm({
      ...userSubmissionForm,
    })
    if (
      selectedDocument.document_types.length === (Object.values(userSubmissionForm).length - 2) &&
      !Object.values(userSubmissionForm).includes("")
    )
      setEnableContinueButton(true)
    else
      setEnableContinueButton(false)
  }


  const formSubmit = () => {
    dispatch(updateUserData(userSubmissionForm));
    dispatch(setSelectedDocumentData(selectedDocument))
    history.push("/prepaid-cards/small-kyc/document/view");
  };

  return (
    <>
      <div className="my-4 mb-5">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-sm-8 col-md-5">
              <p className="cardkyc-title text-center bold m-1 m-sm-2 m-md-2">
                <>{selectedCardData?.cardPlan} KYC</>
              </p>
              <p className="cardkyc-subtitle text-center">
              Fill the below required details
              </p>
              <div className="d-flex flex-row justify-content-center align-items-center mt-2">
                <div className="p-1 col-1 pr-5 pr-lg-5">
                  <img src={ic_mobile} alt=""></img>
                </div>
                <div className="p-1 col-10 col-md-8 my-2">
                  <p className="cardedit-head-text">Mobile Number</p>
                  <p className="cardedit-body-text ">+91 {userSubmissionForm?.mobile || profile?.mobile}</p>
                </div>
              </div>

              <div className="d-flex flex-row justify-content-center align-items-center my-3">
                <div className=" p-1 col-1 pr-5 pr-lg-5">
                  <img src={ic_user} alt=""></img>
                </div>
                <div className="p-1 col-10 col-md-8 mt-2">
                  <p className="cardedit-head-text">Full Name</p>
                  <div className="form__group field w-100 cardedit-body-text">
                    <input
                      readOnly
                      value={userSubmissionForm?.name || userName}
                      type="input"
                      className="form__field cardedit-body-text"
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex flex-row justify-content-center align-items-center my-3">
                <div className=" p-1 col-1 pr-5 pr-lg-5">
                  <img src={ic_document} alt=""></img>
                </div>
                <div className="p-1 col-10 col-md-8 mt-2">
                  <p className="cardedit-head-text">Choose Document Type</p>
                  <div className="form__group field w-100 cardedit-body-text">
                    <select
                      className="form__field "
                      placeholder="Enter your name as per the document"
                      onChange={(e) => {
                        userSelelectedDocument(e.target.value)
                      }}
                    >
                      {selectedDocumentData && selectedDocument ?
                        (
                          <option
                            value={selectedDocumentData?.document_id}
                            selected
                            hidden
                          >
                            {selectedDocumentData['display_document_type'] || "-"}
                          </option>
                        )
                        : (
                          <option value="" selected disabled hidden>
                            Select Document
                          </option>
                        )
                      }
                      {documentsDataList?.map((item) => (
                        <option value={item.document_id}>
                          {item.display_document_type}
                        </option>
                      ))}
                    </select>
                    <div className="resendOtp d-flex"></div>
                  </div>
                </div>
              </div>

              {selectedDocument?.document_types &&
                selectedDocument?.document_types?.map((item, index) => {
                  return item.type === "select" ?
                    (
                      <div className="d-flex flex-row justify-content-center align-items-center my-3">
                        <div className=" p-1 col-1 pr-5 pr-lg-5">
                          <img src={item?.info_icon_url} width="30px" alt=""></img>
                        </div>
                        <div className="p-1 col-10 col-md-8 mt-2">
                          <p className="cardedit-head-text">{item?.display_name}</p>
                          <div className="form__group d-flex flex-row field w-100 cardedit-body-text">
                            <select
                              className="form__field "
                              placeholder={item?.placeholder}
                              onChange={(e) => {
                                userInputHandler(e.target.value, item?.kyc_input_identifier)
                              }}
                            >
                              {
                                userSubmissionForm[item?.kyc_input_identifier] ?
                                  (
                                    <option value={userSubmissionForm[`${item?.kyc_input_identifier}`]} selected disabled hidden>
                                      {userSubmissionForm[`${item?.kyc_input_identifier}`]}
                                    </option>
                                  ) :
                                  (
                                    <option value="" selected disabled hidden>
                                      {item?.placeholder}
                                    </option>
                                  )
                              }
                              {item?.options?.map((item) => (
                                <option value={item.name}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                    ) : item.type === "date" ?
                      (
                        <div className="d-flex flex-row justify-content-center align-items-center my-3">
                          <div className=" p-1 col-1 pr-5 pr-lg-5">
                            <img src={item?.info_icon_url} width="30px" alt=""></img>
                          </div>
                          <div className="p-1 col-10 col-md-8 mt-2">
                            <p className="cardedit-head-text">{item?.display_name}</p>
                            <div className="form__group d-flex flex-row field w-100 cardedit-body-text">
                              <div className='w-100' ref={dateRef} onClick={() => setShowCalender(!showCalender)}>
                                <OverlayTrigger
                                  ref={r => (ref = r)}
                                  container={ref.current}
                                  trigger="click"
                                  placement="bottom"
                                  overlay={<div className="d-flex flex-column">
                                    <Calendar
                                      maxDate={new Date()}
                                      onChange={dateItem => {
                                        userInputHandler(format(dateItem, "yyyy-MM-dd"), item?.kyc_input_identifier)
                                        document.body.click()
                                      }}
                                      // date={new Date()} 
                                      />
                                  </div>}
                                  rootClose
                                >
                                  <div className='' >
                                    <h5 className='m-0 font-weight-bold stored-value-heading cursor-pointer'>{(userSubmissionForm[`${item?.kyc_input_identifier}`] && moment(userSubmissionForm[`${item?.kyc_input_identifier}`]).format("DD MMM yyyy") ) || "Select here"}</h5>
                                  </div>
                                </OverlayTrigger>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) :
                      (<div className="d-flex flex-row justify-content-center align-items-center my-3">
                        <div className=" p-1 col-1 pr-5 pr-lg-5">
                          <img src={item?.info_icon_url} width="30px" alt=""></img>
                        </div>
                        <div className="p-1 col-10 col-md-8 mt-2">
                          <p className="cardedit-head-text">
                            {item?.display_name}
                          </p>
                          <div className="form__group field w-100 cardedit-body-text">
                            <input
                              defaultValue={
                                userSubmissionForm ? userSubmissionForm[item?.kyc_input_identifier] : ""
                              }
                              type="text"
                              className="form__field cardedit-body-text"
                              placeholder={item?.placeholder}
                              required
                              name={item?.display_name}
                              key={item?.display_name}
                              onChange={(e) => userInputHandler(e.target.value, item?.kyc_input_identifier)}
                            />
                          </div>
                        </div>
                      </div>)
                }
                )}

              <button
                className="animated-btn mt-md-4 mt-3"
                onClick={() => formSubmit()}
                id={!enableContinueButton ? "animated-btn-disable" : ""}
                disabled={!enableContinueButton ? true : false}
              >
                CONTINUE
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default DocumentEdit;
