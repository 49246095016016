import HelmetMetaData from "../components/HelmetMetaData";
import { useLocation } from "react-router-dom";

function AboutMetaData() {
  let location = useLocation();
  let currentAppUrl = process.env.REACT_APP_URL + location.pathname;

  return (
    <HelmetMetaData
      title={`About KAMAIE App: Shopping, Online Payments & Win Rewards`}
      description={`Shop and win cash rewards up to crores by using the KAMAIE App for your daily shopping and other expenses. Switch to KAMAIE and get access to online shopping sites and exclusive offline retail vendors around you in over 100 categories.`}
      keywords={` vouchers, gift vouchers, e-vouchers, discount vouchers, online shopping sites, KAMAIE app, shopping, online payments, rewards, cash rewards, about us, offline retail vendors`}
      voiceSchema={<script type="application/ld+json">
        {JSON.stringify(
          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            "name": "ABOUT US",
            "speakable":
            {
              "@type": "SpeakableSpecification",
              "xpath":
                [
                  "/html/head/title",
                  "/html/head/meta[@name='description']/@content"
                ]
            },
            "url": currentAppUrl
          }
        )}
      </script>
      }
      breadcrumbSchema={<script type="application/ld+json">
        {JSON.stringify(
          {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": process.env.REACT_APP_URL
            }, {
              "@type": "ListItem",
              "position": 2,
              "name": "ABOUT US",
              "item": currentAppUrl
            }]
          }
        )}
      </script>
      }
    ></HelmetMetaData>
  );
}

export default AboutMetaData;