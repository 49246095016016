import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ic_check_blue from "../../../assets/prepaid_cards/ic_check_blue.svg";
import ic_check_gold from "../../../assets/prepaid_cards/ic_check_gold.svg";
import ic_checkbox_empty from "../../../assets/prepaid_cards/ic_checkbox_empty.svg";
import ic_checkbox_active from "../../../assets/prepaid_cards/ic_checkbox_active.svg";
import { useState } from "react";
// import SelectCard from "../components/SelectCard";
import { fetchingPrepaidCardPlans, setPrepaidCardPlans, setUserSelectedCardData } from "../actions";
import { useEffect } from "react";
import prepaidCardClientX from "../../../utils/api/prepaidCardClientX";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";


const CardsPlans = (props) => {
  const history = useHistory()
  let dispatch = useDispatch();

  const { isFetchingPrepaidCardPlans, prepaidCardPlans } = useSelector((state) => state?.ppi)
  const { profile } = useSelector((state) => state?.global)


  const [eliteButtonControl, setEliteButtonControl] = useState(false);
  const [premiumButtonControl, setPremiumButtonControl] = useState(false);
  const [selectedPlanValue, setSelectedPlanvalue] = useState();
  const [show, setShow] = useState(false);
  const [physicalButtonControl, setPhysicalButtonControl] = useState(false);
  const [virtualButtonControl, setVirtualButtonControl] = useState(false);
  const [selectedCardTypesData, setSelectedCardTypesData] = useState();
  const [userSelectedData, setUserSelectedData] = useState({
    cardPlanID: "",
    cardTypeID: "",
    cardPlan: ""
  })
  const [cardTypeNote,setCardTypeNote]=useState() 

  useEffect(() => {
    getPPICardsPlans();
    // getPPICardsTypes();
  }, []);

  const getPPICardsPlans = () => {
    const authConfig = {
      headers: {
        TempAuthToken: profile?.temp_ppi_token,
      }
    }
    dispatch(fetchingPrepaidCardPlans());
    prepaidCardClientX.get("/get-card-plans", authConfig).then((response) => {
      let data = response.data;
      setCardTypeNote(data?.card_type_note);
      dispatch(setPrepaidCardPlans(data?.data));
    });
  }

  const handleClose = () => {
    setShow(false);
    setPhysicalButtonControl(false);
    setVirtualButtonControl(false);
    setUserSelectedData({
      ...userSelectedData,
      cardTypeID: ""
    })
  }
  const handleShow = () => {
    setShow(true)
    setVirtualButtonControl(true);
    setPhysicalButtonControl(false);
    setUserSelectedData({ ...userSelectedData, cardTypeID: selectedCardTypesData.virtual_card_type_id, })
  }

  const setUserSelectedCardType = (cardName) => {
    prepaidCardPlans.map(item => {
      if (item.card_type === cardName) {
        setSelectedCardTypesData(item)
      }
    })
  }

  const handleSubmitCardData = () => {
    
    const payload = userSelectedData
    if (userSelectedData.cardTypeID) {
      dispatch(setUserSelectedCardData(payload))
      handlePageRedirection()
    }
  }

  const handlePageRedirection = () => {
    
    const authConfig = {
      headers: {
        TempAuthToken: profile?.temp_ppi_token,
      }
    }
    let apiURL = 'cart-details'
    let requestParams = {
      card_plan_id: userSelectedData?.cardPlanID,
      card_type_id: userSelectedData?.cardTypeID
    }
    prepaidCardClientX
    
      .post(apiURL, requestParams, authConfig)
      .then((response) => {
        // if (!response?.data?.success) {
        //   toast.success(response.data?.message)
        // }
        if (response?.data?.data?.page_redirection_segment === "min_kyc_document")
          history.push("/prepaid-cards/start-kyc")
        if (response?.data?.data?.page_redirection_segment === "full_kyc_document")
          history.push("/prepaid-cards/start-premium-kyc")
        else if (response?.data?.data?.page_redirection_segment === "card_details_page")
          history.push("/prepaid-cards/managecard")
        else if (response?.data?.data?.page_redirection_segment === "shipping_address_details_page")
          history.push("/prepaid-cards/shipping/edit")
        else if (response?.data?.data?.page_redirection_segment === "payment_summary")
          history.push("/prepaid-cards/purchase/cart")
      })
      .catch((error) => {
        // this.props.history.goBack();
      });
    
    // switch (selectedPlanValue) {
    //   case "ELITE":
    //     if ((virtualButtonControl && selectedCardTypesData.virtual_card_fee === "0") ||
    //       (physicalButtonControl && selectedCardTypesData.physical_card_fee === "0")
    //     ) {
    //       handleCartSummaryRedirection()
    //     }
    //     else {
    //       history.push('/prepaid-cards/purchase/cart')
    //     }
    //     break;
    //   case "PREMIUM":
    //     if ((virtualButtonControl && selectedCardTypesData.virtual_card_fee === "0") ||
    //       (physicalButtonControl && selectedCardTypesData.physical_card_fee === "0")
    //     ) {
    //       handleCartSummaryRedirection()
    //     }
    //     else {
    //       history.push('/prepaid-cards/purchase/cart')
    //     }
    //     break;

    //   default:
    //     break;
    // }
  }

  const Shimmer = () => {
    return <div className="box shine w-100" style={{ height: "30px" }}></div>
  };
  const Shimmer2 = () => {
    return <div className="box shine w-75 m-auto" style={{ height: "15px" }}></div>
  };
  const Shimmer3 = () => {
    return <div className="box shine w-75 m-auto" style={{ height: "60px" }}></div>
  };

  const Shimmers = () => {
    return (
      <>
        <div className="col-4 p-0 p-md-1">
          <h4 className="sub-title-elite text-center w-100 p-3">
            <Shimmer2 />
          </h4>
          <div className="card w-100 ">
            <ul className="list-group list-group-flush">
              <h5 className="list-group-item  single-card m-0 d-flex flex-column flex-md-row ">
                <Shimmer />
              </h5>
              <h5 className="list-group-item  single-card m-0 d-flex flex-column flex-md-row ">
                <Shimmer />
              </h5>
              <h5 className="list-group-item  single-card m-0 d-flex flex-column flex-md-row">
                <Shimmer />
              </h5>
              <h5 className="list-group-item  single-card m-0 d-flex flex-column flex-md-row ">
                <Shimmer />
              </h5>
              <h5 className="list-group-item  single-card m-0 d-flex flex-column flex-md-row ">
                <Shimmer />
              </h5>
              <h5 className="list-group-item  single-card m-0 ">
                <Shimmer />
              </h5>
            </ul>
          </div>
        </div>
        <div className="col-4 p-0 p-md-1">
          <h4 className="sub-title-premium text-center w-100 p-3">
            <Shimmer2 />
          </h4>
          <div className="card w-100 ">
            <ul className="list-group list-group-flush">
              <h5 className="list-group-item  single-card m-0 d-flex flex-column flex-md-row ">
                <Shimmer />
              </h5>
              <h5 className="list-group-item  single-card m-0 d-flex flex-column flex-md-row ">
                <Shimmer />
              </h5>
              <h5 className="list-group-item  single-card m-0 d-flex flex-column flex-md-row">
                <Shimmer />
              </h5>
              <h5 className="list-group-item  single-card m-0 d-flex flex-column flex-md-row ">
                <Shimmer />
              </h5>
              <h5 className="list-group-item  single-card m-0 d-flex flex-column flex-md-row ">
                <Shimmer />
              </h5>
              <h5 className="list-group-item  single-card m-0 ">
                <Shimmer />
              </h5>
            </ul>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="cardplans-container pt-5 pb-5 ">
        <div className="container ">
          <h1 className=" cardkyc-title font-proxima-nova-bold text-center pb-3">
          KAMAIE Prepaid Card Plans
          </h1>
          <div className="row justify-content-center box-margin">
            <div className="col-4 p-0 p-md-1">
              <h4 className="sub-title-benefits text-center w-100 p-3">
                BENEFITS
              </h4>
              <div className="card w-100 ">
                <ul className="list-group list-group-flush">
                  <h5 className="list-group-item  single-card m-0">
                    Virtual Card
                  </h5>
                  <h5 className="list-group-item  single-card m-0">
                    Transaction Limit
                  </h5>
                  <h5 className="list-group-item  single-card m-0">
                    Max Topup Limit
                  </h5>
                  <h5 className="list-group-item  single-card m-0">
                    WRP Benefits On Enrollment
                  </h5>
                  <h5 className="list-group-item  single-card m-0">
                    WRP Benefits On Shopping
                  </h5>
                  <h5 className="list-group-item  single-card m-0">
                    KYC Required
                  </h5>
                </ul>
              </div>
            </div>
            {isFetchingPrepaidCardPlans ?
              <Shimmers /> :
              prepaidCardPlans.map(plan => {
                if (plan?.card_type === "ELITE")
                  return (
                    <div className={`p-0 p-md-1 ${window.innerWidth < 767 && prepaidCardPlans?.length === 1 ? 'col-7' : 'col-4'}`}>
                      <h4 className="sub-title-elite text-center w-100 p-3">
                        {plan?.card_type}
                      </h4>
                      <div className="card w-100 ">
                        <ul className="list-group list-group-flush">
                          <h5 className="list-group-item  single-card m-0 d-flex flex-column flex-md-row ">
                            <div>
                              <img src={ic_check_blue} alt="check" />
                              <span className="elite-desc text-uppercase">
                                {plan?.virtual_card}
                              </span>
                            </div>
                            <span className="elite-desc-sub">
                              {plan?.virtual_card_fee_text}
                            </span>
                          </h5>

                          <h5 className="list-group-item  single-card m-0 d-flex flex-column flex-md-row ">
                            <span className="elite-desc ml-0">
                              ₹ {plan?.transaction_limit}
                            </span>
                          </h5>
                          <h5 className="list-group-item  single-card m-0 d-flex flex-column flex-md-row">
                            <span className="elite-desc ml-0">
                              ₹ {plan?.max_topup_limit}
                            </span>
                            <span className="elite-desc-sub">Per Month</span>
                          </h5>
                          <h5 className="list-group-item  single-card m-0 d-flex flex-column flex-md-row ">
                            <div>
                              <img src={ic_check_blue} alt="check" />
                              <span className="elite-desc text-uppercase">
                                {" "}
                                {plan?.WRP_benefits_on_enrollment}
                              </span>
                              <span className="elite-desc-sub">
                               {plan?.WRP_benefits_on_enrollment_text}
                              </span>
                            </div>
                          </h5>
                          <h5 className="list-group-item  single-card m-0 d-flex flex-column flex-md-row ">
                            <div>
                              <img src={ic_check_blue} alt="check" />
                              <span className="elite-desc text-uppercase">
                                {plan?.WRP_benefits_on_shopping}
                              </span>
                            </div>
                          </h5>
                          <h5 className="list-group-item  single-card m-0 ">
                            <span className="desc-kyc ml-0">{plan?.kyc_type}</span>
                          </h5>
                        </ul>
                      </div>
                    </div>
                  )
                if (plan?.card_type === "PREMIUM")
                  return (
                    <div className={`p-0 p-md-1 ${window.innerWidth < 767 && prepaidCardPlans?.length === 1 ? 'col-7' : 'col-4'}`}>
                      <h4 className="sub-title-premium text-center w-100 p-3">
                        {plan?.card_type}
                      </h4>
                      <div className="card w-100">
                        <ul className="list-group list-group-flush">
                          <h5 className="list-group-item  single-card m-0 d-flex flex-column flex-md-row align-content-center">
                            <div>
                              <img src={ic_check_gold} alt="check" />
                              <span className="premium-desc text-capitalize">
                                {plan?.virtual_card}
                              </span>
                            </div>
                            <span className="premium-desc-sub">
                              {plan?.virtual_card_fee_text}
                            </span>
                          </h5>
                          <h5 className="list-group-item  single-card m-0 d-flex flex-column flex-md-row ">
                            <span className="premium-desc ml-0">
                              ₹ {plan?.transaction_limit}
                            </span>
                          </h5>
                          <h5 className="list-group-item  single-card m-0 d-flex flex-column flex-md-row">
                            <span className="premium-desc ml-0">
                              ₹ {plan?.max_topup_limit}
                            </span>
                            <span className="premium-desc-sub">Per Month</span>
                          </h5>
                          <h5 className="list-group-item  single-card m-0 d-flex flex-column flex-md-row ">
                            <div>
                              <img src={ic_check_gold} alt="check" />
                              <span className="premium-desc text-uppercase">
                                {plan?.WRP_benefits_on_enrollment}
                              </span>
                              <span className="premium-desc-sub">
                                {plan?.WRP_benefits_on_enrollment_text}
                              </span>
                            </div>
                          </h5>
                          <h5 className="list-group-item  single-card m-0 d-flex flex-column flex-md-row">
                            <div>
                              <img src={ic_check_gold} alt="check" />
                              <span className="premium-desc text-uppercase">
                                {plan?.WRP_benefits_on_shopping}
                              </span>
                            </div>
                          </h5>
                          <h5 className="list-group-item  single-card m-0 ">
                            <span className="desc-kyc ml-0">{plan?.kyc_type}</span>
                          </h5>
                        </ul>
                      </div>
                    </div>
                  )
              })}
          </div>
          <h2 className="title-choose-plan text-center mt-4">
            Choose your <strong>Preferred Card Plan</strong>
          </h2>
          <div className="row justify-content-center">
            {isFetchingPrepaidCardPlans ?
              (
                <Shimmer3 />
              ) :
              (
                prepaidCardPlans && prepaidCardPlans.map(plan => {
                  if (plan?.card_type === "ELITE")
                    return (
                      <button
                        className="col-8 col-md-4 btn p-3 m-2"
                        id={
                          eliteButtonControl === false
                            ? "elite-btn"
                            : "elite-btn-active"
                        }
                        onClick={() => {
                          setEliteButtonControl(true);
                          setPremiumButtonControl(false);
                          setSelectedPlanvalue(plan?.card_type);
                          setUserSelectedCardType(plan?.card_type)
                          setUserSelectedData({ ...userSelectedData, cardPlanID: plan?.id, cardPlan: plan?.card_type })
                        }}
                      >
                        <img
                          src={
                            eliteButtonControl === false
                              ? ic_checkbox_empty
                              : ic_checkbox_active
                          }
                          alt="check"
                        />{" "}
                        <span>{prepaidCardPlans && plan?.card_type} CARD</span>
                      </button>
                    )
                  if (plan?.card_type === "PREMIUM")
                    return (
                      <button
                        className="col-8 col-md-4 btn premium-btn p-3 m-2"

                        id={
                          premiumButtonControl === false
                            ? "premium-btn"
                            : "premium-btn-active"
                        }
                        onClick={() => {
                          setPremiumButtonControl(true);
                          setEliteButtonControl(false);
                          setSelectedPlanvalue(plan?.card_type);
                          setUserSelectedCardType(plan?.card_type)
                          setUserSelectedData({ ...userSelectedData, cardPlanID: plan?.id, cardPlan: plan?.card_type })
                        }}
                      >
                        <img
                          src={
                            premiumButtonControl === false
                              ? ic_checkbox_empty
                              : ic_checkbox_active
                          }
                          alt="check"
                        />{" "}
                        <span>{prepaidCardPlans && plan?.card_type} CARD</span>
                      </button>
                    )
                })
              )}
          </div>
          <div className="row d-flex justify-content-center">
            <button
              className="animated-btn col-8 col-md-4 mt-4"
              id={selectedPlanValue || "animated-btn-disable"}
              onClick={selectedPlanValue && handleShow}
            >
              CONTINUE
            </button>
            {selectedCardTypesData &&
              <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
              >
                <button className="btn-close-card-type " onClick={handleClose}>
                  <i className="fa fa-close"></i>
                </button>
                <div className="row p-3 p-md-5  d-flex justify-content-center">
                  <h1 className="title-card-type">Choose Card Type</h1>
                  <Modal.Body className="px-4 subtitle-card-type">
                    <div className="d-flex justify-content-center">
                      {
                        selectedCardTypesData?.physical_card_enabled &&
                        <button className="col-12 col-md-6 btn">
                          <div
                            id={selectedCardTypesData.physical_card_type_id}
                            className="d-flex justify-content-end md-justify-content-center align-items-center"
                            onClick={() => {
                              setPhysicalButtonControl(true);
                              setVirtualButtonControl(false);
                              setUserSelectedData({ ...userSelectedData, cardTypeID: selectedCardTypesData.physical_card_type_id, })
                            }}
                          >
                            <img
                              className="btn-img-card-type"
                              src={
                                physicalButtonControl === false
                                  ? ic_checkbox_empty
                                  : ic_check_blue
                              }
                              alt="img"
                            />
                            <span className={`btn-card-type ${physicalButtonControl ? "bold" : " "}`}>Physical Card</span>
                          </div>
                          <p className="btn-card-type-desc d-flex justify-content-end">{selectedCardTypesData.physical_card_fee_text}</p>
                        </button>
                      }
                      {
                        selectedCardTypesData?.virtual_card_enabled &&
                        <button className="col-12 col-md-6 btn">
                          <div
                            id={selectedCardTypesData?.virtual_card_type_id}
                            className="d-flex justify-content-start md-justify-content-center align-items-center "
                            onClick={() => {
                              setVirtualButtonControl(true);
                              setPhysicalButtonControl(false);
                              setUserSelectedData({ ...userSelectedData, cardTypeID: selectedCardTypesData.virtual_card_type_id, })
                            }}
                          >
                            <img
                              className="btn-img-card-type"
                              src={
                                virtualButtonControl === false
                                  ? ic_checkbox_empty
                                  : ic_check_blue
                              }
                              alt="img"
                            />
                            <span className={`btn-card-type ${virtualButtonControl ? "bold" : " "}`}>Virtual Card</span>
                          </div>
                          <p className="btn-card-type-desc">{selectedCardTypesData.virtual_card_fee_text}</p>
                        </button>
                      }

                    </div>

                    Note: {cardTypeNote}
                  </Modal.Body>

                  <Button
                    className="animated-btn btn-continue-card-type col-8 mx-auto P-2"
                    id={userSelectedData.cardTypeID ? "" : "animated-btn-disable"}
                    onClick={() => handleSubmitCardData()}
                  >
                    CONTINUE
                  </Button>
                </div>
              </Modal>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default CardsPlans;
