import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import "@material/react-text-field/index.scss";
import TextField, { Input } from "@material/react-text-field";
import { useHistory } from "react-router-dom";
import { setShippingAddress } from "../actions";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const ShippingFormEdit = () => {
  let history = useHistory();
  let dispatch = useDispatch()

  const { cardDetails, shippingAddress } = useSelector((state) => state?.ppi)
  const { profile } = useSelector((state) => state?.global)

  const [shippingFormUpdate, setShippingFormUpdate] = useState({
    // name: cardDetails?.cardDetailResponse?.customerName,
    name: profile?.name,
    mobile: profile?.mobile,
    referenceNumber: cardDetails?.referenceNumber,
    addressLine1: shippingAddress?.addressLine1 || "",
    addressLine2: shippingAddress?.addressLine2 || "",
    cityDistrict: shippingAddress?.cityDistrict || "",
    state: shippingAddress?.state || "",
    pincode: shippingAddress?.pincode || "",
    landmark: shippingAddress?.landmark || "",
  });
  const [shippingDataValidation, setShippingDataValidation] = useState({
    name: true,
    mobile: true,
    addressLine1: true,
    addressLine2: true,
    cityDistrict: true,
    state: true,
    pincode: true,
    landmark: true,
  });
  const [formValid, setFormValid] = useState("invalid")


  useEffect(() => {
    formValidation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingFormUpdate])

  const validCheck = () => {
    setShippingDataValidation(shippingFormUpdate);
    dispatch(setShippingAddress(shippingFormUpdate))
    history.push("/prepaid-cards/shipping/view");
  };

  const formValidation = () => {
    if (shippingFormUpdate.name === "" ||
      shippingFormUpdate.mobile === "" ||
      shippingFormUpdate.addressLine2 === "" ||
      shippingFormUpdate.addressLine1 === "" ||
      shippingFormUpdate.pincode === "" ||
      shippingFormUpdate.state === "" ||
      shippingFormUpdate.landmark === ""
    ) {
      setFormValid("invalid")
    }
    else {
      setFormValid("valid")
    }
  }

  return (
    <>
      <div className="my-4 mb-5">
        <div className="container">
          <div className="row d-flex flex-column justify-content-center align-items-center">
            <p className="cardkyc-title text-center bold m-1 m-sm-2 m-md-2 pb-4">
              Shipping Details
            </p>
            <div className="col-12 col-sm-8 col-md-4">
              <TextField
                error
                outlined
                className="w-100"
                label="Name*"
              >
                <Input
                  value={shippingFormUpdate.name}
                  onChange={(e) =>
                    setShippingFormUpdate({
                      ...shippingFormUpdate,
                      name: e.currentTarget.value,
                    })
                  }
                />
              </TextField>
              <label
                className={
                  shippingDataValidation.name === ""
                    ? "validation-error-text"
                    : "d-none"
                }
              >
                Please fill the required field
              </label>
              {/* <TextField outlined className="w-100 mt-4" label="Mobile Number*">
                <Input value={profile?.mobile} readOnly />
              </TextField> */}

              <TextField
                error
                outlined
                className="w-100 mt-4"
                label="Mobile Number*"
              >
                <Input
                  type="number"
                  value={shippingFormUpdate.mobile}
                  onChange={(e) =>
                    setShippingFormUpdate({
                      ...shippingFormUpdate,
                      mobile: e.currentTarget.value,
                    })
                  }
                />
              </TextField>
              <label
                className={
                  shippingDataValidation.mobile === ""
                    ? "validation-error-text"
                    : "d-none"
                }
              >
                Please fill the required field
              </label>

              <TextField
                error
                // defaultValue="Hello World"
                outlined
                className="w-100 mt-4"
                label="Flat,House No,Building,Company*"
              >
                <Input
                  value={shippingFormUpdate.addressLine1}
                  onChange={(e) =>
                    setShippingFormUpdate({
                      ...shippingFormUpdate,
                      addressLine1: e.currentTarget.value,
                    })
                  }
                />
              </TextField>
              <label
                className={
                  shippingDataValidation.addressLine1 === ""
                    ? "validation-error-text"
                    : "d-none"
                }
              >
                Please fill the required field
              </label>

              <TextField
                outlined
                className="w-100 mt-4"
                label="Street Name,Area*"
              >
                <Input
                  value={shippingFormUpdate.addressLine2}
                  onChange={(e) =>
                    setShippingFormUpdate({
                      ...shippingFormUpdate,
                      addressLine2: e.currentTarget.value,
                    })
                  }
                />
              </TextField>
              <label
                className={
                  shippingDataValidation.addressLine2 === ""
                    ? "validation-error-text"
                    : "d-none"
                }
              >
                Please fill the required field
              </label>

              <TextField outlined className="w-100 mt-4" label="City/District*">
                <Input
                  value={shippingFormUpdate.cityDistrict}
                  onChange={(e) =>
                    setShippingFormUpdate({
                      ...shippingFormUpdate,
                      cityDistrict: e.currentTarget.value,
                    })
                  }
                />
              </TextField>
              <label
                className={
                  shippingDataValidation.cityDistrict === ""
                    ? "validation-error-text"
                    : "d-none"
                }
              >
                Please fill the required field
              </label>

              <TextField outlined className="w-100 mt-4" label="State*" error>
                <Input
                  className="box-verification-border"
                  value={shippingFormUpdate.state}
                  onChange={(e) =>
                    setShippingFormUpdate({
                      ...shippingFormUpdate,
                      state: e.currentTarget.value,
                    })
                  }
                />
              </TextField>
              <label
                className={
                  shippingDataValidation.state === ""
                    ? "validation-error-text"
                    : "d-none"
                }
              >
                Please fill the required field
              </label>

              <TextField outlined className="w-100 mt-4" label="pincode*">
                <Input
                  value={shippingFormUpdate.pincode}
                  onChange={(e) =>
                    setShippingFormUpdate({
                      ...shippingFormUpdate,
                      pincode: e.currentTarget.value,
                    })
                  }
                  type="number"
                />
              </TextField>
              <label
                className={
                  shippingDataValidation.pincode === ""
                    ? "validation-error-text"
                    : "d-none"
                }
              >
                Please fill the required field
              </label>

              <TextField outlined className="w-100 mt-4" label="landmark*">
                <Input
                  value={shippingFormUpdate.landmark}
                  onChange={(e) =>
                    setShippingFormUpdate({
                      ...shippingFormUpdate,
                      landmark: e.currentTarget.value,
                    })
                  }
                />
              </TextField>
              <label
                className={
                  shippingDataValidation.landmark === ""
                    ? "validation-error-text"
                    : "d-none"
                }
              >
                Please fill the required field
              </label>

              <button
                className="animated-btn mt-4"
                onClick={() => {
                  validCheck();
                }}
                disabled={formValid === "invalid"}
                id={formValid === "invalid" ? "animated-btn-disable" : ""}
              >
                CONTINUE
              </button>
              {/* <div className="d-flex justify-content-center mt-2">
                <Link to="/prepaid-cards/managecard">
                  <span style={{ textDecoration: "underline" }}>Go back</span>
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
function mapDispatchToProps(dispatch) {
  return {};
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ShippingFormEdit);
