import { toast } from "react-toastify";
import moment from "moment";
import _ from "lodash";
import CryptoJS from "crypto-js";
import Cookies from 'universal-cookie';


const cookies = new Cookies();
// import crypto from "crypto"

export const getRandomNumber = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min + 1;
};

export const getQueryParam = (searchedQuery, key) => {
  const query = new URLSearchParams(searchedQuery);
  return query.get(key);
};

export const isDate = (value) => {
  return moment(value).isValid();
};

export const copyToClipboard = (str) => {
  const el = document.createElement("textarea"); // Create a <textarea> element
  el.value = str; // Set its value to the string that you want copied
  el.setAttribute("readonly", ""); // Make it readonly to be tamper-proof
  el.style.position = "absolute";
  el.style.left = "-9999px"; // Move outside the screen to make it invisible
  document.body.appendChild(el); // Append the <textarea> element to the HTML document
  const selected =
    document.getSelection().rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0) // Store selection if found
      : false; // Mark as false to know no selection existed before
  el.select(); // Select the <textarea> content
  document.execCommand("copy"); // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el); // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
    document.getSelection().addRange(selected); // Restore the original selection
  }
  showToastMessage("success", "Code copied successfully.");
};

export const breakString = (str, char) => {
  return str.split(char);
};

export const fetchRemoteConfig = async (key) => {
  let isMaintenance = false;
  const response = await window.remoteConfig
    .fetchAndActivate()
    .then((response) => {
      var maintenance = window.remoteConfig.getValue("web_maintenance");
      isMaintenance = JSON.parse(maintenance._value)[key];
      return isMaintenance;
    })
    .catch((err) => {
      console.error(err);
    });
  return response;
};

export const fetchRemoteConfiguration = async (key) => {
  const response = await window.remoteConfig
    .fetchAndActivate()
    .then((response) => {
      var maintenance = window.remoteConfig.getValue(key);
      // if (
      //   key === "redeem_winds_giftcard_url" ||
      //   key === "phonepe_refund_policy"
      // )
      if (key === "redeem_winds_giftcard_url" || key === "ppi_terms_conditions" || key === "ppi_privacy_policy" || key === "ppi_card_benefits" || key === "phonepe_refund_policy")
        return maintenance._value;
      else return JSON.parse(maintenance._value);
    })
    .catch((err) => {
      console.log(err);
    });
  return response;
};

export const showToastMessage = (type, message) => {
  toast[type](message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

export const reverseDateFormat = (value) => {
  if (value) {
    return value
      .split("-")
      .reverse()
      .join("-");
  }
};

export const replaceAt = (array, index, value) => {
  const ret = array.slice(0);
  ret[index] = value;
  return ret;
};

export const createCookie = (name, value, days) => {
  var expires;
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toGMTString();
  } else expires = "";
  document.cookie = name + "=" + value + expires;
  // document.cookie = name+"="+value+expires+"; path=/";
};

export const readCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const currentlySelectedLanguage = () => {
  const language = readCookie("windsEWorldAppLanguageCode") || "en";
  return language;
};

export const shuffle = (o) => {
  for (
    var j, x, i = o.length;
    i;
    j = parseInt(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x
  );
  return o;
};

// export const filter = (data,rule,identifier,property) => {
// return data.filter(item=>item[property] [rule] identifier )
// }

export const sortDateOrderByDescending = (seriesRawDataArray, timestamp) => {
  const sortedArray = _.orderBy(
    seriesRawDataArray,
    [(data) => data[timestamp]],
    ["desc"]
  );
  return sortedArray;
};

export const OrderStatusLabel = (props) => {
  switch (props.order.status_code) {
    case 3:
    case 7:
      return (
        <p className="badge badge-success text-white">
          {props.order.status_text_translated}
        </p>
      );
    case 1:
    case 4:
    case 8:
    case 11:
    case 24:
      return (
        <p className="badge badge-danger text-white">
          {props.order.status_text_translated}
        </p>
      );
    case 0:
      return (
        <p className="badge badge-warning text-white">
          {props.order.status_text_translated}
        </p>
      );
    case 10:
      return (
        <p className="badge badge-purple text-white">
          {props.order.status_text_translated}
        </p>
      );
    case 6:
    case 9:
      return (
        <p className="badge badge-green text-white">
          {props.order.status_text_translated}
        </p>
      );
    case 2:
      return (
        <p className="badge badge-fulfill text-white">
          {props.order.status_text_translated}
        </p>
      );
    case 5:
      return (
        <p className="badge badge-initiate text-white">
          {props.order.status_text_translated}
        </p>
      );
    case 25:
      return (
        <p className="badge badge-delete text-white">
          {props.order.status_text_translated}
        </p>
      );
    case 26:
      return (
        <p className="badge badge-partial-cancel text-white">
          {props.order.status_text_translated}
        </p>
      );
    default:
      return (
        <p className="badge badge-warning text-white">
          {props.order.status_text_translated}
        </p>
      );
  }
};

export const handleCombinedErrors = (errors) => {
  let error = Object.entries(errors)
    .reduce((result, [key, value]) => result.concat(value), [])
    .map((msg) => {
      return msg;
    });
  return error;
};

export const encryptByAesAndBase64 = (data, key, iv) => {
  try {
    if (typeof data == "object") {
      data = JSON.stringify(data);
    }

    const encrypted = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(key), {
      mode: CryptoJS.mode.CBC,
      iv: CryptoJS.enc.Utf8.parse(iv),
    });
    // return encrypted.toString();

    //convert to base64
    let base64String = btoa(encrypted);
    return base64String;
  } catch (e) {
    return data;
  }
};

export const decryptByAesAndBase64WST = (
  encData,
  encryptionKey,
  encryptionIv
) => {
  try {
    const data = atob(encData); //endata.toString();

    const key = CryptoJS.enc.Utf8.parse(encryptionKey);
    const cipherBytes = CryptoJS.enc.Base64.parse(data);

    const decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherBytes }, key, {
      iv: CryptoJS.enc.Utf8.parse(encryptionIv),
      mode: CryptoJS.mode.CBC,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (e) {
    return e;
  }
};


export const getIsMob = () => {
  try {
    let isMob = cookies.get('isMob');

    if(isMob === null || isMob === undefined){
      isMob = localStorage.getItem('isMob')
    }
    if (!isMob) {
      return 0; // Handle case where the token is not found
    }
    return isMob;

  } catch (error) {
    return null; // Handle invalid token
  }
};

export const getIsKamaie = () => {
  try {
    let isKamaie = cookies.get('is_kamaie');

    if(isKamaie === null || isKamaie === undefined){
      isKamaie = localStorage.getItem('is_kamaie')
    }
    if (!isKamaie) {
      return 0; // Handle case where the token is not found
    }
    return isKamaie;

  } catch (error) {
    return null; // Handle invalid token
  }
};