import React, { Component } from 'react'
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
// import moment from "moment";
import { fetchReferrals, setReferrals } from '../actions';
import earningApi from '../../../utils/api/earningsClient';

class referralList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			profile: null,
			referrals: [],
			showModal: false,
			page: 1,
			user_data: [],
			load: false

		};
	}

	componentDidMount() {
		window.addEventListener("scroll", this.onScroll, false);
		this.call(this.state.page);
	}
	componentWillUnmount() {
		window.removeEventListener("scroll", this.onScroll, false);
	}
	onScroll = () => {
		let height = 0
		window.innerWidth < 767 ? height = 1800 : height = 500
		if (window.innerHeight + window.scrollY >= document.body.offsetHeight - height) {
			let totalPages = (this.state.referrals.count / 20) + 1
			if (this.state.page < totalPages) {
				this.setState({ page: this.state.page + 1 })
				this.call(this.state.page);
			}
		}
	};

	call = (page) => {
		this.setState({ load: true })
		earningApi.get(`/v2/customer-referrals?page=${page}`).then((response) => {
			this.setState({ referrals: response.data.data, load: false })
			let data = response.data.data.user_data;
			let newdata = this.state.user_data
			this.setState({ user_data: newdata.concat(data) })
		}).catch(() => {
			this.setState({ load: false })
		})
	}
	render() {
		const { count } = this.state.referrals;
		return (
			<>
				<div className="container">
					<div className="row mt-3 mb-3 mt-md-4 mb-md-4">
						<div className="col-12">
							<small>
								<Link to="/">
									<FormattedMessage
										id="breadcrumbs.home"
										defaultMessage="Home"
									/>
								</Link>{" "}/{" "}
								<Link to="/earnings">
									<FormattedMessage
										id="navbar.my-earnings"
										defaultMessage="My Earnings"
									/>
								</Link>{" "}/{" "}
								<strong>
									<FormattedMessage
										id="earnings.my-referals"
										defaultMessage="My Referrals"
									/>
								</strong>
							</small>
						</div>
					</div>

					<div className="row mb-5 align-items-start" style={{ minHeight: "300px" }}>
						{
							this.state.user_data && (
								<>
									<div className="col-12 col-md-8 mx-auto">
										<h5 className="font-proxima-nova font-weight-bold mb-4">
											{count} <FormattedMessage
												id="earnings.regsiterd_patrons"
												defaultMessage="Registered Customer Referred By You."
											/>
										</h5>
										<div className="referral-list">
											{
												this.state.user_data.map((item, index) => {
													return (
														<>
															<div key={"refferal-list" + index} className=" d-flex align-items-center border border-grey rounded py-3 px-4 mb-3">
																<div className="ml-0">
																	<h5 className="font-weight-bold text-col-primary mb-2">{item.name}</h5>
																	<small className="text-muted mb-2 d-block">Mobile: {item.mobile} | Patron ID: {item.id}</small>
																	<p className="mb-0 text-col-primary">
																		Registered On {item.created_at}
																	</p>
																</div>
															</div>
														</>
													)
												})
											}
										</div>
									</div>
								</>
							)
						}
					</div>
				</div>
			</>
		)
	}
}

function mapStateToProps(state) {
	return {
		isFetchingReferrals: state.earnings.isFetchingReferrals,
		referrals: state.earnings.referrals,
		profile: state.global.profile,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		initReferrals: (page) => {
			dispatch(fetchReferrals());
			earningApi.get(`/v2/customer-referrals?page=${page}`).then((response) => {
				let data = response.data.data;
				dispatch(setReferrals(data));
			});
			return;
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(referralList);
