import React, { useState, useEffect, useRef, useContext } from "react";
import Ic_Group from "../../../../assets/prepaid_cards/Group 25.png";
import ic_windslogo from "../../../../assets/logo-icon.png";
import Kamaie_logo_symbol_small from "../../../../assets/prepaid_cards/KamaieLogo/Brandmark/Kamaie_logo_symbol_small.png";
import ic_card_illustration from "../../../../assets/prepaid_cards/ic_card_illustration.png";
import ic_rupee from "../../../../assets/prepaid_cards/ic_rupee.png";
import ic_rupee1 from "../../../../assets/prepaid_cards/ic_rupee_3x.png";
import ic_show_password from "../../../../assets/prepaid_cards/ic_show_password.svg";
import ic_rupay_logo from "../../../../assets/prepaid_cards/ic_rupay_logo.svg";
import ic_pine_lags_logo1 from "../../../../assets/prepaid_cards/ic_pine_lags_logo1.svg";
import ic_pine_lags_logo2 from "../../../../assets/prepaid_cards/ic_pine_lags_logo2.svg";
import ic_arrow from "../../../../assets/prepaid_cards/Path.svg";
import ic_hidden from "../../../../assets/prepaid_cards/ic_hidden.svg";
import Ic_Deleteconscent from "../../../../assets/prepaid_cards/ic_block_card_deleteConscent.svg";
import ic_tick_mark from "../../../../assets/prepaid_cards/ic_tick_mark.png";
import ic_block_ppi from "../../../../assets/prepaid_cards/ic_block_card_view.svg";
// import ic_freeze_card from "../../../../assets/prepaid_cards/ic_freeze.svg";
import ic_document from "../../../../assets/prepaid_cards/ic_document.svg"
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import prepaidCardClientX from "../../../../utils/api/prepaidCardClientX";
import { setCardDetails, setCardNumber, setLoadBalance, setShippingAddress, setShippingRedirectionData, setUserSelectedCardData } from "../../actions";
import { Button, Modal } from "react-bootstrap";
import ic_waiting from "../../../../assets/prepaid_cards/ic_loader.json"
import Lottie from "lottie-react";
import PageLoader from "../../../../components/pageLoader";
import ic_swipe_right from "../../../../assets/prepaid_cards/ic_swipe_right.json";
import VerifyOTP from "../../components/VerifyOTP";
import { FirebaseContext } from "../../../../utils/firebase";

const ManageCard = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const context = useContext(FirebaseContext);
  const { cardReferenceNumber, cardDetails, selectedCardData } = useSelector((state) => state?.ppi)
  const { profile } = useSelector((state) => state?.global)

  const [pageLoading, setPageLoading] = useState(false);
  const [cardbalanceLoading, setCardbalanceLoading] = useState(false);
  const [cardBalanceData, setCardBalanceData] = useState(false);
  const [isAccountBalanceHide, setIsAccountBalanceHide] = useState(false);
  const [blockcardActive, setBlockCardActive] = useState(false);
  const [selectedBlockReason, setSelectedBlockReason] = useState("");
  const [deleteConscentActive, setDeleteConscentActive] = useState(true);
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [unblockBtnLoad, setUnlockBtnLoad] = useState(false)
  const [otherReason, setOtherReason] = useState("")
  const [cardStatus, setCardStatus] = useState(null)
  const [cardBlockReasonsData, setCardBlockReasonData] = useState([])
  let textInputValue;


  useEffect(() => {
    getCardDetails()
    setCardBlockReasonData(cardDetails?.card_block_reasons)
    dispatch(setShippingAddress(null)) // discard previously stored shipping address
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTextAreaChange = (e) => {
    textInputValue = e.target.value
  }

  const handleCloseDepositModal = () => setShowDepositModal(false);

  const handleShowDepositModal = () => {
    setShowDepositModal(true);
    getCardBalance()
  }

  const getCardDetails = () => {
    setPageLoading(true)

    prepaidCardClientX.get(`/get-card-details/${cardReferenceNumber}`)
      .then((response) => {
        console.log(response,"response")
        setPageLoading(false)
        dispatch(setCardDetails(response.data.data))
        // handleOnPageLoadRedirection()
      })
      .catch(error => {
      })
  }

  const handleOnPageLoadRedirection = () => {
    if (cardDetails?.page_redirection_segment !== null) {
      history.push(`/`)
    }
  }

  const getCardBalance = () => {
    setCardbalanceLoading(true)

    prepaidCardClientX.get(`/get-card-balance/${cardReferenceNumber}`)
      .then((response) => {
        setCardBalanceData(response.data.data)
        setCardbalanceLoading(false)
        dispatch(setCardNumber(response.data.data))
      })
      .catch(error => {
      })
  }

  const viewCardLink = () => {
    let cardLink = cardDetails?.cardDetailResponse?.cardLink
    window.open(`${cardLink}`, '_blank', 'noopener noreferrer')
  }

  const pinResetLink = () => {
    let resetLink = cardDetails?.cardDetailResponse?.pinSetLink
    window.open(`${resetLink}`, '_blank', 'noopener noreferrer')
  }

  const applyPhysicalCard = () => {
    let payload = {
      redirectType: "manage_card"
    }
    dispatch(setShippingRedirectionData(payload))
    dispatch(setUserSelectedCardData({
      ...selectedCardData,
      cardTypeID: cardDetails?.applyForPhysicalCardId
    }))
    // history.push("/prepaid-cards/purchase/cart")
    handlePageRedirection()
  }

  const handlePageRedirection = () => {

    const authConfig = {
      headers: {
        TempAuthToken: profile?.temp_ppi_token,
      }
    }
    let apiURL = 'cart-details'
    let requestParams = {
      card_plan_id: selectedCardData?.cardPlanID,
      card_type_id: cardDetails?.applyForPhysicalCardId
    }
    prepaidCardClientX

      .post(apiURL, requestParams, authConfig)
      .then((response) => {
        // if (!response?.data?.success) {
        //   toast.success(response.data?.message)
        // }
        if (response?.data?.data?.page_redirection_segment === "min_kyc_document")
          history.push("/prepaid-cards/start-kyc")
        if (response?.data?.data?.page_redirection_segment === "full_kyc_document")
          history.push("/prepaid-cards/start-premium-kyc")
        else if (response?.data?.data?.page_redirection_segment === "card_details_page")
          history.push("/prepaid-cards/managecard")
        else if (response?.data?.data?.page_redirection_segment === "shipping_address_details_page")
          history.push("/prepaid-cards/shipping/edit")
        else if (response?.data?.data?.page_redirection_segment === "payment_summary")
          history.push("/prepaid-cards/purchase/cart")
      })
      .catch((error) => {
        // this.props.history.goBack();
      });

  }

  const handleSubmitDeleteValue = (selectedBlockReason) => {
    if (document.getElementById(selectedBlockReason))
      document.getElementById(selectedBlockReason).checked = true;
    setSelectedBlockReason(selectedBlockReason);
  };

  const openOtpModal = () => {
    setShowOTPModal(true)
  }

  const closeOtpModal = () => {
    setShowOTPModal(false)
  }

  const blockUnblockCard = () => {
    const payload = {
      "cardStatus": cardStatus,
      "cardBlockReason": selectedBlockReason === cardBlockReasonsData?.slice(-1)[0] ? otherReason : selectedBlockReason
    }

    setUnlockBtnLoad(true)
    prepaidCardClientX.post(`/card-status-update`, payload)
      .then((response) => {
        getCardDetails()
        setUnlockBtnLoad(false)
        setDeleteConscentActive(true);
      })
      .catch(error => {
        setUnlockBtnLoad(false)
        setDeleteConscentActive(true);
      })
  }

  const CardBlockReasons = () => {
    return (
      <>
        <div className="p-md-5 p-3">
          <h5 className="text-center bold">
            Please help us understand your reasons for blocking your card
          </h5>
          <div className="my-5">
            {cardBlockReasonsData && cardBlockReasonsData.map((item, index) => {
              return (
                <div className="form-check round p-1" key={index}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id={item}
                    checked={selectedBlockReason === item}
                    onChange={() => handleSubmitDeleteValue(item)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={item}
                  ></label>
                  <p
                    className=" cursor-pointer ml-5"
                    onClick={() => handleSubmitDeleteValue(item)}
                  >
                    {item}
                  </p>
                </div>
              );
            })}

            <div
              className={selectedBlockReason === cardBlockReasonsData?.slice(-1)[0] ? "form-group" : "d-none"}
            >
              <textarea
                className="form-control"
                rows="4"
                id="comment"
                // value={otherReason}
                onChange={(e) => handleTextAreaChange(e)}
                // ref={inputRef}
                placeholder="Please mention your reason here"
              ></textarea>
            </div>
          </div>
          {/* <h6 className="text-center">
            Card balance will be transferd to your WINDS Wallet
          </h6> */}
          <button
            className={
              selectedBlockReason === ""
                ? "animated-btn animated-btn-disabled"
                : "animated-btn"
            }
            disabled={selectedBlockReason === "" ? true : false}
            onClick={() => {
              setOtherReason(textInputValue);
              setDeleteConscentActive(false)
            }}
          >
            Continue
          </button>
        </div>
        ;
      </>
    );
  };

  const CardBlockConsent = () => {
    return (
      <>
        <div className="p-md-5 p-2 mx-3">
          <div className="text-center ">
            <img
              src={Ic_Deleteconscent}
              className="img-fluid p-3"
              alt="deleteconscent-logo"
            />
            <h4 className="text-center cardedit-head-text mb-2">
              Card will be Temporarily Locked!
            </h4>
            <p className="w-75 mx-auto cardkyc-desc-small">
              Here are a few things to keep in mind before blocking the card.
            </p>
          </div>
          <div className="mt-2 mb-4">
            <div className="d-flex ppi-delete-conscent">
              <img src={ic_tick_mark} alt="" className="tick_mark" />
              <p className="mx-1 my-0 cardkyc-desc-small">
                A Temporarily blockage of your card means that you are not able to process any transaction online and offline.
              </p>
            </div>
            <hr />
            <div className="d-flex ppi-delete-conscent">
              <img src={ic_tick_mark} alt="" className="tick_mark" />
              <p className="mx-1 my-0 cardkyc-desc-small">
                You can <strong>“Unblock”</strong> your card any time whenever you want. Just few clicks away.
              </p>
            </div>
            <hr />
            <div className="d-flex ppi-delete-conscent">
              <img src={ic_tick_mark} alt="" className="tick_mark" />
              <p className="mx-1 my-0 cardkyc-desc-small">
                Your cash will be secure even after the card is blocked. It can be utilized when you unlock the card.
              </p>
            </div>
            <hr />
            <div className="d-flex ppi-delete-conscent">
              <img src={ic_tick_mark} alt="" className="tick_mark" />
              <p className="mx-1 my-0 cardkyc-desc-small">
                For security purpose OTP will be sent to your registered mobile number
              </p>
            </div>
          </div>
          <button
            className="animated-btn"
            onClick={() => {
              openOtpModal()
              setBlockCardActive(!blockcardActive);
              setCardStatus(3)
            }}
          >
            CONTINUE
          </button>
        </div>
      </>
    );
  };


  const DepositAmountModal = () => {
    const [depositAmount, setDepositAmount] = useState(cardDetails?.minimum_addable_amount)

    const handleDepositAmount = () => {
      let loadBalance = {
        amount: depositAmount
      }
      dispatch(setLoadBalance(loadBalance))
      history.push('/prepaid-cards/load-balance/cart')
    }

    return (
      <>
        <Modal
          show={showDepositModal}
          onHide={handleCloseDepositModal}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
        >
          <div className="p-3 p-md-4  d-flex flex-column justify-content-center border-bottom" style={{ background: "#EAFAF8" }}>
            <span className="h3 font-weight-bold text-center m-0">Load Amount</span>
            <button className="btn-close-card-type " onClick={handleCloseDepositModal}>
              <i class="fa fa-close"></i>
            </button>
          </div>

          <div className="pt-0 pb-3 pb-md-5  d-flex flex-column justify-content-center">
            {cardbalanceLoading ?
              <div className="p-3 p-md-5 text-center d-flex flex-column justify-content-center align-items-center">
                <div className="p-4" style={{ width: "200px" }}>
                  <Lottie animationData={ic_waiting} loop={true} />
                </div>
              </div>
              :
              <>
                <Modal.Body className="px-4 agreement-text w-100">
                  <span className="title-card-type h6">WINDS Prepaid Card</span>
                  <div className="account-number-text-hide d-flex align-items-center justify-content-center text-black mt-2">
                    <span className="account-number-text-extend">{"•••• •••• ••••"} {cardDetails?.cardDetailResponse?.maskedCardNumber}</span>
                  </div>
                  <div className="mt-3 mb-4">
                    <p className="mb-0">Current Card Balance</p>
                    <span className="mb-0 font-weight-bold h5">Rs. {cardBalanceData?.cardBalance?.toFixed(2)}</span>
                  </div>
                  <div className="d-flex justify-content-center mb-1">
                    <img src={ic_rupee1} alt="img" />
                    <input
                      style={{ width: "150px", border: "2px solid #e4e4e4" }}
                      defaultValue={depositAmount}
                      type="number"
                      className="form__field cardedit-body-text px-2 py-1"
                      placeholder="Enter Amount"
                      required
                      name="deposit"
                      key="deposit"
                      onChange={(e) => setDepositAmount(e.target.value)}
                    />
                  </div>
                  <small>You can add balance up up-to max <b>Rs {cardBalanceData?.maxCardAmountToAdd}</b></small>
                  <p className="mt-5">Your current card limit: {cardBalanceData?.monthlyReloadableAmount}</p>
                  {
                    cardDetails?.card_plan_upgrade_details?.is_upgrade_flag &&
                    <div className="upgrade-strip cursor-pointer" onClick={() => { history.push('/prepaid-cards/plans') }}>
                      <img src={ic_document} alt="img" width="20px" className="mr-2" />
                      <small>{cardDetails?.card_plan_upgrade_details?.upgrade_text_display_deposit_amount_screen}</small>
                    </div>
                  }
                </Modal.Body>
                <Button
                  disabled={depositAmount > cardBalanceData?.monthlyReloadableAmount || depositAmount < cardDetails?.minimum_addable_amount}
                  className="animated-btn col-5 mx-auto  py-3" onClick={() => handleDepositAmount()}>
                  CONTINUE
                </Button>
              </>
            }
          </div>
        </Modal>
      </>
    );
  };

  const ManageCardBlock = () => {
    return (
      <div className="col-12 col-lg-4 mb-4" key="con">
        <h5 className="text-center pb-md-4 pb-2 pt-md-0 pt-4">
          <strong>MANAGE YOUR CARD</strong>
        </h5>
        <div className={cardDetails?.cardDetailResponse?.cardStatus === 3 ? "ppi-selection-innactivate" : ""}>
          {
            // eslint-disable-next-line array-callback-return
            cardDetails?.card_management_menu && cardDetails?.card_management_menu.map(item => {
              if (item?.menu_display_slug === "apply_physical_card")
                return (
                  <div key={item?.menu_display_slug} className="d-flex managecard-manage-link-container justify-content-center align-items-center mb-2 cursor-pointer" onClick={() => applyPhysicalCard()}>
                    <div className="col-2">
                      <img
                        src={item?.info_graphic_icon}
                        className="img-fluid managecard-link-icons my-3"
                        alt="card-icon"
                      />
                    </div>
                    <div className="col-8">
                      <p className="managecard-link-head mt-2 mb-1">
                        <strong>{item?.menu_display_name}</strong>
                      </p>
                      <p className="managecard-link-body mb-2">
                        {item?.menu_description_display_name}
                      </p>
                    </div>
                    <div className="col-2">
                      <img
                        src={ic_arrow}
                        className="img-fluid my-3"
                        alt="arrow-icon"
                      />
                    </div>
                  </div>
                )
              if (item?.menu_display_slug === "set_reset_pin")
                return (
                  <div key={item?.menu_display_slug} className="d-flex managecard-manage-link-container justify-content-center align-items-center mb-2 cursor-pointer" onClick={() => pinResetLink()}>
                    <div className="col-2">
                      <img
                        src={item?.info_graphic_icon}
                        className="img-fluid managecard-link-icons my-3"
                        alt="password-icon"
                      />
                    </div>
                    <div className="col-8">
                      <p className="managecard-link-head mt-2 mb-1">
                        <strong>{item?.menu_display_name}</strong>
                      </p>
                      <p className="managecard-link-body mb-2">
                        {item?.menu_description_display_name}
                      </p>
                    </div>
                    <div className="col-2">
                      <img
                        src={ic_arrow}
                        className="img-fluid my-3"
                        alt="arrow-icon"
                      />
                    </div>
                  </div>
                )
              if (item?.menu_display_slug === "deposit_amount")
                return (
                  <>
                    <div key={item?.menu_display_slug} className="d-flex managecard-manage-link-container justify-content-center align-items-center mb-2 cursor-pointer" onClick={() => handleShowDepositModal()}>
                      <div className="col-2">
                        <img
                          src={item?.info_graphic_icon}
                          className="img-fluid managecard-link-icons my-3"
                          alt="wallet-icon"
                        />
                      </div>
                      <div className="col-8">
                        <p className="managecard-link-head mt-2 mb-1">
                          <strong>{item?.menu_display_name}</strong>
                        </p>
                        <p className="managecard-link-body mb-2">
                          {item?.menu_description_display_name}
                        </p>
                      </div>
                      <div className="col-2">
                        <img
                          src={ic_arrow}
                          className="img-fluid my-3"
                          alt="arrow-icon"
                        />
                      </div>
                    </div>
                    {showDepositModal && <DepositAmountModal />}
                  </>
                )
              if (item?.menu_display_slug === "manage_card_limits")
                return (
                  <div key={item?.menu_display_slug} className="d-flex managecard-manage-link-container justify-content-center align-items-center mb-2 cursor-pointer pe-auto" onClick={() => history.push("/prepaid-cards/managecard/cardlimits")}>
                    <div className="col-2">
                      <img
                        src={item?.info_graphic_icon}
                        className="img-fluid managecard-link-icons my-3"
                        alt="limit-icon"
                      />
                    </div>
                    <div className="col-8">
                      <p className="managecard-link-head mt-2 mb-1">
                        <strong>{item?.menu_display_name}</strong>
                      </p>
                      <p className="managecard-link-body mb-2">
                        {item?.menu_description_display_name}
                      </p>
                    </div>
                    <div className="col-2">
                      <img
                        src={ic_arrow}
                        className="img-fluid my-3"
                        alt="arrow-icon"
                      />
                    </div>
                  </div>
                )

              if (item?.menu_display_slug === "block_your_card")
                return (
                  <>
                    <div className="d-flex managecard-manage-link-container justify-content-center align-items-center mb-2 cursor-pointer"
                      onClick={() => setBlockCardActive(!blockcardActive)}
                    >
                      <div className="col-2">
                        <img
                          src={item?.info_graphic_icon}
                          className="img-fluid managecard-link-icons my-3"
                          alt="blk-img"
                        />
                      </div>
                      <div className="col-8" >
                        <p className="managecard-link-head mt-2 mb-1 text-red">
                          <strong>{item?.menu_display_name}</strong>
                        </p>
                        <p className="managecard-link-body mb-2">
                          {item?.menu_description_display_name}
                        </p>
                      </div>
                      <div className="col-2">
                        <img
                          src={ic_arrow}
                          className="img-fluid my-3"
                          alt="arrow-icon"
                        />
                      </div>
                      {
                        unblockBtnLoad &&
                        <div className="d-flex justify-content-center mt-3">
                          <PageLoader />
                        </div>
                      }
                    </div>
                    {showOTPModal && <VerifyOTP
                      onCloseVerifyOTPModal={() =>
                        closeOtpModal()
                      }
                      onVerifyOTP={(otp) =>
                        blockUnblockCard()
                      }
                    />
                    }
                  </>
                )

              if (item?.menu_display_slug === "faq")
                return (
                  <>
                    <div className="d-flex managecard-manage-link-container justify-content-center align-items-center mb-2 cursor-pointer" onClick={() => history.push("/prepaid-cards/managecard/faq")}>
                      <div className="col-2">
                        <img
                          src={item?.info_graphic_icon}
                          className="img-fluid managecard-link-icons my-3"
                          alt="wallet-icon"
                        />
                      </div>
                      <div className="col-8">
                        <p className="managecard-link-head mt-2 mb-1">
                          <strong>{item?.menu_display_name}</strong>
                        </p>
                        <p className="managecard-link-body mb-2">
                          {item?.menu_description_display_name}
                        </p>
                      </div>
                      <div className="col-2">
                        <img
                          src={ic_arrow}
                          className="img-fluid my-3"
                          alt="arrow-icon"
                        />
                      </div>
                    </div>
                  </>
                )

            })
          }

        </div>
      </div>
    )
  }

  const Shimmer = () => {
    return (
      <>
        <div className="container-fluid">
          <div className="box shine col-12" style={{ maxHeight: "300px" }}></div>
        </div>
        <div className="container py-3 py-md-5">
          <div className="row">
            <div className="col-12 col-md-8 ">
              <div className="d-flex justify-content-center">
                <div className="col-6 box shine fun-game-shimmer-head mb-1 m-1" style={{ maxHeight: "200px" }}></div>
                <div className="col-6 box shine fun-game-shimmer-head mb-1 m-1" style={{ maxHeight: "200px" }}></div>
              </div>
              <div className="col-8 col-md-4 mx-auto">
                <div className="heading shine my-4" ></div>
              </div>
              <div className="col-12 d-flex row row-0 justify-content-center">
                <div className="box shine col-2 m-1" style={{ maxHeight: "120px" }}></div>
                <div className="box shine col-2 m-1" style={{ maxHeight: "120px" }}></div>
                <div className="box shine col-2 m-1" style={{ maxHeight: "120px" }}></div>
                <div className="box shine col-2 m-1" style={{ maxHeight: "120px" }}></div>
                <div className="box shine col-2 m-1" style={{ maxHeight: "120px" }}></div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="col-8 col-md-10 mx-auto">
                <div className="heading shine my-4" ></div>
              </div>
              <div className="box shine m-1" style={{ height: "50px" }}></div>
              <div className="box shine m-1" style={{ height: "50px" }}></div>
              <div className="box shine m-1" style={{ height: "50px" }}></div>
              <div className="box shine m-1" style={{ height: "50px" }}></div>
              <div className="box shine m-1" style={{ height: "50px" }}></div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const PpicardView = () => {
    return (
      <>
        <div className="managecard-cardbody col-12 col-md-6 p-">
          <div className="d-flex flex-row">
            <div className="col-4 p-0 pt-3 justify-content-around">
              <img
                src={Kamaie_logo_symbol_small}
                className="managecard-winds-logo"
                alt="widslogo"
              />
              <h3 className="card-name">{cardDetails?.cardDetailResponse?.customerName}</h3>
              <p className="card-balance-text">CARD BALANCE</p>
            </div>

            <div className="col-8 d-flex align-items-center justify-content-end p-0">
              <img
                src={ic_pine_lags_logo2}
                className="position-absolute align-self-start right-0 pt-3"
                alt="pinelabs-logo"
              />

              <img
                src={ic_card_illustration}
                className="card-illustration"
                alt="card-illustration"
              />
            </div>
          </div>
          <div className="d-flex justify-content-start ">
            <div className="col-1 p-0 justify-content-end">
              <img src={ic_rupee} className="img-rupee" alt="rupees-logo" />
            </div>
            {
              cardbalanceLoading ?
                <div className="col-4 amount-text-hide p-0">
                  <div className="box shine w-75 m-1" style={{ maxHeight: "10px" }}></div>
                </div> :
                isAccountBalanceHide === true ? (
                  <div className="col-4 amount-text p-0">
                    <span>{cardBalanceData?.cardBalance?.toFixed(2)}</span>
                  </div>
                ) : (
                  <div className="col-4 amount-text-hide p-0">
                    <span>&#9898;</span>
                    <span>&#9898;</span>
                    <span>&#9898;</span>
                    <span>&#9898;</span>
                    <span>&#9898;</span>
                    <span>&#9898;</span>
                    <span>
                      <p className="amount-text-extend">.00</p>
                    </span>
                  </div>
                )}
            <div
              className="col-1 p-0"
              onClick={() => {
                setIsAccountBalanceHide(!isAccountBalanceHide)
              }}
            >
              {isAccountBalanceHide === true ? (
                <img className="cursor-pointer" src={ic_hidden} alt="hidden-img" />
              ) : (
                <img className="cursor-pointer" src={ic_show_password} alt="eye-icon" onClick={() => getCardBalance()} />
              )}
            </div>
            <div className="col-6 d-flex justify-content-end pb-3 pt-2">
              <img src={ic_rupay_logo} alt="rupay-icon" />
            </div>
          </div>
        </div>
        <div className="managecard-cardbody col-12 col-md-6 p-3">
          <div className="">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <img
                src={Kamaie_logo_symbol_small}
                className="managecard-winds-logo"
                alt="KAMAIE-logo"
              />
              <span className="text-white cursor-pointer d-flex justify-content-end align-items-center" onClick={() => viewCardLink()}>
                <span>VIEW DETAILS </span>
                <Lottie style={{ width: "10%" }} className="ml-1 swipper-mobile-view" animationData={ic_swipe_right} loop={true} />
              </span>
            </div>

            <div className="account-number d-flex align-items-center justify-content-start pt-3 pb-2">
              <div className="account-number-text-hide d-flex align-items-center justify-content-center">
                <span>&#9898;</span>
                <span>&#9898;</span>
                <span>&#9898;</span>
                <span>&#9898; </span>&nbsp;
                <span>&#9898;</span>
                <span>&#9898;</span>
                <span>&#9898;</span>
                <span>&#9898; </span>&nbsp;
                <span>&#9898;</span>
                <span>&#9898;</span>
                <span>&#9898;</span>
                <span>&#9898; </span>&nbsp;
                <span className="account-number-text-extend">{cardDetails?.cardDetailResponse?.maskedCardNumber}</span>
              </div>
            </div>
            <div className="d-flex justify-content-betweeen card-validity-text">
              <div className="col-4 card-validity-data">
                Valid
                <br />
                From - <strong>{cardDetails?.cardDetailResponse?.issuedOn}</strong>
              </div>
              <div className="col-4 card-validity-data">
                Valid
                <br />
                Thru - <strong>{cardDetails?.cardDetailResponse?.expiryDate}</strong>
              </div>
              <div className="col-3">
                CVV
                <br />
                <div className="cvv-dot-size">
                  <span>&#9898;</span>
                  <span>&#9898;</span>
                  <span>&#9898;</span>
                </div>
              </div>
            </div>
            <div className="col-12 d-flex justify-content-end">
              <img
                src={ic_pine_lags_logo1}
                className="pine-labs-logo"
                alt="pinelabs-logo"
              />
            </div>
          </div>
        </div>
      </>
    );
  };
  const PpicardBolck = () => {
    return (
      <div className="text-center mt-3">
        <img src={ic_block_ppi} alt="" className="img-fluid ppi-block-img" />

        <h4 className="title">
          <strong>Card is Temporarily Blocked</strong>
        </h4>
        <p className="w-75 mx-auto cardkyc-desc-small">
          All card transactions are temporarily locked as per your request. You can <strong>Unblock</strong> your card any time with single tap below
        </p>
        <Button style={{ width: "auto" }} className="animated-btn  mx-auto py-3 px-5 my-2"
          onClick={() => {
            setCardStatus(1)
            openOtpModal()
          }}>
          UNBLOCK CARD
        </Button>
      </div>
    );
  };

  const UpgradeToPremiumBanner = () => {
    return (
      <div className="kyc-cardmanage-banner py-5">
        <div className="container">
          <div className="row d-flex flex-md-row-reverse align-items-center">
            <div className="d-flex justify-content-center col-12 col-md-4">
              <img src={Ic_Group} className="img-fluid" alt="group-icon" />
            </div>
            <div className="col-12 col-md-8">
              <p className="cardmanager-large-head-text">
                <strong>
                  <span>{cardDetails?.card_plan_upgrade_details?.upgrade_text_title}</span>
                </strong>
              </p>
              <div className="w-100 text-center text-md-left">
                <p className="title-slim-small text-white m-0">
                  {cardDetails?.card_plan_upgrade_details?.upgrade_text_description}
                </p>
                <button className="ppi-upgrade-now-button px-4 py-2 mt-3" onClick={() => { history.push('/prepaid-cards/plans') }}>
                  UPGRADE NOW
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const QuickAccessBlock = () => {
    return (
      <>
        <h5 className="styled-line-text">QUICK ACCESS</h5>
        <div className={"d-flex justify-content-center w-100 w-md-75 mx-auto card-actions " + (cardDetails?.cardDetailResponse?.cardStatus === 3 ? "ppi-selection-innactivate" : "")}>
          {
            cardDetails?.quick_access_menu && cardDetails?.quick_access_menu.map(item => {
              if (item?.menu_display_slug === "topup")
                return (
                  <div className="quick-access-box cursor-pointer" onClick={() => handleShowDepositModal()}>
                    <img src={item?.info_graphic_icon_web} className="quick-logo img-fluid" alt="faq-icon" />
                    <p className="text-center"><strong>{item?.menu_display_name}</strong>
                    </p>
                  </div>
                )
              if (item?.menu_display_slug === "statement")
                return (
                  <div className="cursor-pointer" onClick={() => history.push("/prepaid-cards/managecard/statement")}>
                    <img src={item?.info_graphic_icon_web} className="quick-logo img-fluid" alt="statement-icon" />
                    <p className="text-center"><strong>{item?.menu_display_name}</strong>
                    </p>
                  </div>
                )
              if (item?.menu_display_slug === "card_limits")
                return (
                  <div className="cursor-pointer" onClick={() => history.push("/prepaid-cards/managecard/cardlimits")} >
                    <img src={item?.info_graphic_icon_web} className="quick-logo img-fluid" alt="freez-icon" />
                    <p className="text-center"><strong>{item?.menu_display_name}</strong>
                    </p>
                  </div>
                )
              if (item?.menu_display_slug === "benefits")
                return (
                  <div className="cursor-pointer"
                    onClick={() => {
                      window.open(context?.ppi_card_benefits, '_blank');
                    }}
                  >
                    <img src={item?.info_graphic_icon_web} className="quick-logo img-fluid" alt="benefits-icon" />
                    <p className="text-center"> <strong>{item?.menu_display_name}</strong> </p>
                  </div>
                )
              if (item?.menu_display_slug === "block_unblock")
                return (
                  <div className="cursor-pointer" onClick={() => setBlockCardActive(!blockcardActive)}>
                    <img src={item?.info_graphic_icon_web} className="quick-logo img-fluid" alt="blk-icon" />
                    <p className="text-center"><strong>{item?.menu_display_name}</strong>
                    </p>
                  </div>
                )

            })}
        </div>
      </>
    )
  }

  // const PpicardFreeze = () => {
  //   return (
  //     <div className="text-center mt-3">
  //       <img src={ic_freeze_card} alt="" className="img-fluid ppi-block-img" />

  //       <h4 className="title">
  //         <strong>Card is Temporarily Locked</strong>
  //       </h4>
  //       <p className="w-75 mx-auto cardkyc-desc-small">
  //         All card transactions are temporarily locked as per your request. You
  //         can Unfreeze your card any time with single tap below
  //       </p>
  //       <button className="ppi-plane-border-button px-4 py-2">
  //         UNFREEZE CARD
  //       </button>
  //     </div>
  //   );
  // };

  return (
    false ?
      <Shimmer />
      :
      <React.Fragment>
        {cardDetails?.card_plan_upgrade_details?.is_upgrade_flag && window.innerWidth >= 767 &&
          <UpgradeToPremiumBanner />
        }
        <div className="container">
          <div className="row d-flex align-items-top py-4 pt-md-5">
            <div className="col-12 col-lg-8 managecard-card-container p-3 pr-0 pr-lg-5">
              <h1 className="title-card-type mb-3">
                {"Prepaid card"}
                {cardDetails?.kycTypeText === "ELITE" ?
                  <span className="elite-badge">{cardDetails?.kycTypeText}</span> :
                  <span className="premium-badge">{cardDetails?.kycTypeText}</span>
                }
              </h1>
              <div className="row d-flex justify-content-around">
                {cardDetails?.cardDetailResponse?.cardStatus === 3 ?
                  <PpicardBolck /> : <PpicardView />
                }
              </div>

              <QuickAccessBlock />
            </div>
            <ManageCardBlock />
            {cardDetails?.card_plan_upgrade_details?.is_upgrade_flag && window.innerWidth < 767 &&
              <UpgradeToPremiumBanner />
            }
            <div className={`cardBlock-offset-container pt-5 delete-card-area ${blockcardActive ? "mySidenav-active" : ""}`} >
              <button
                className="btn-close-card-type d-flex justify-content-end"
                onClick={() => {
                  setBlockCardActive(!blockcardActive);
                  setDeleteConscentActive(true);
                  setSelectedBlockReason("");
                }}
              >
                <i className="fa fa-close"></i>
              </button>
              {deleteConscentActive ? <CardBlockReasons /> : <CardBlockConsent />}
            </div>
          </div>
        </div>
      </React.Fragment>
  );
};


export default ManageCard;