const INITIAL_STATE = {
  isFetchingPrepaidCardPlans: false,
  prepaidCardPlans: [],
  isFetchingPrepaidCardsPlansFailure: false,
  isFetchingPrepaidCardTypes: false,
  prepaidCardTypes: [],
  isFetchingPrepaidCardsTypesFailure: false,
  isFetchingDocumentTypes: false,
  documentTypes: [],
  isFetchingdocummentTypeFailure: false,
  userName: "",
  formData: [],
  selectedCardData: null,
  kycUniqueID: null,
  selectedDocumentData: null,
  cardReferenceNumber: null,
  cardNumber: null,
  cardDetails: null,
  completeKYCData: null,
  shippingAddress: null,
  loadBalance: null,
  shippingRedirectionData: null
};
function ppiReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "FETCHING_PREPAID_CARD_PLANS":
      return {
        ...state,
        isFetchingPrepaidCardPlans: true,
        prepaidCardPlans: [],
      };
    case "FETCHING_PREPAID_CARD_PLANS_SUCCESS":
      return {
        ...state,
        prepaidCardPlans: action.prepaidCardPlansData,
        isFetchingPrepaidCardPlans: false,
      };
    case "FETCHING_PREPAID_CARD_PLANS_FAILURE":
      return {
        ...state,
        isFetchingPrepaidCardsPlansFailure: true,
      };
    case "FETCHING_PREPAID_CARD_TYPES":
      return {
        ...state,
        isFetchingPrepaidCardTypes: true,
        prepaidCardTypes: [],
      };
    case "FETCHING_PREPAID_CARD_TYPES_SUCCESS":
      return {
        ...state,
        prepaidCardTypes: action.prepaidCardTypesData,
        isFetchingPrepaidCardTypes: false,
      };
    case "FETCHING_PREPAID_CARD_TYPES_FAILURE":
      return {
        ...state,
        isFetchingPrepaidCardsTypesFailure: true,
      };
    case "FETCHING_DOCUMENT_TYPES":
      return {
        ...state,
        isFetchingDocumentTypes: true,
      };
    case "FETCHING_DOCUMENT_TYPES_SUCCESS":
      return {
        ...state,
        documentTypes: action.doctypes,
        isFetchingDocumentTypes: false,
      };
    case "FETCHING_DOCUMENT_TYPES_FAILURE":
      return {
        ...state,
        isFetchingdocummentTypeFailure: true,
      };
    case "SET_USERNAME":
      return {
        ...state,
        userName: action.username,
      };
    case "PPI_USERDATA":
      return {
        ...state,
        formData: action.formData,
      };
    case "SET_USER_SELECTED_CART_DATA":
      return {
        ...state,
        selectedCardData: action.selectedCardData,
      };
    case "SET_KYC_UNIQUE_ID":
      return {
        ...state,
        kycUniqueID: action.kycUniqueID,
      };

    case "SET_SELECTED_DOCUMENT_DATA":
      return {
        ...state,
        selectedDocumentData: action.selectedDocumentData,
      };
    case "SET_CARD_RERFERENCE_NUMBER":
      return {
        ...state,
        cardReferenceNumber: action.cardReferenceNumber,
      };
    case "SET_CARD_NUMBER":
      return {
        ...state,
        cardNumber: action.cardNumber,
      };
    case "SET_CARD_DETAILS":
      return {
        ...state,
        cardDetails: action.cardDetails,
      };
    case "SET_COMPLETE_KYC_DATA":
      return {
        ...state,
        completeKYCData: action.completeKYCData,
      };
    case "SET_SHIPPING_ADDRESS":
      return {
        ...state,
        shippingAddress: action.shippingAddress,
      };
    case "SET_LOAD_BALANCE":
      return {
        ...state,
        loadBalance: action.loadBalance
      }
      case "SET_SHIPPING_REDIRECTION_DATA":
        return {
          ...state,
          shippingRedirectionData: action.shippingRedirectionData
        }
    case "RESET_PREPAID_CARD_DATA":
      return {
        isFetchingPrepaidCardPlans: false,
        prepaidCardPlans: [],
        isFetchingPrepaidCardsPlansFailure: false,
        isFetchingPrepaidCardTypes: false,
        prepaidCardTypes: [],
        isFetchingPrepaidCardsTypesFailure: false,
        isFetchingDocumentTypes: false,
        documentTypes: [],
        isFetchingdocummentTypeFailure: false,
        userName: "",
        formData: [],
        selectedCardData: null,
        kycUniqueID: null,
        selectedDocumentData: null,
        cardReferenceNumber: null,
        cardNumber: null,
        cardDetails: null,
        completeKYCData: null,
        shippingAddress: null,
        loadBalance: null,
        shippingRedirectionData: null
      }
      
    default:
      return state;
  }
}

export default ppiReducer;
