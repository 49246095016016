import { Modal } from "react-bootstrap";
import InlineLoader from "./InlineLoader";

function PageLoader() {
    return (
        <Modal
            show
            size="lg"
            backdrop="static"
            centered
            className="page-loader"
        >
            <Modal.Body className="d-flex justify-content-center">
                <InlineLoader />
            </Modal.Body>
        </Modal>
    );
}

export default PageLoader;