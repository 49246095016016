import React from "react";
// import UploadInstruction from "../assets/sample-mp4-file.mp4";

import { FormattedMessage } from "react-intl";
import Modal from "react-bootstrap/Modal";

const VideoInstructionModal = (props) => {
  return (
    <Modal show onHide={props.onClose} 
    className="font-proxima-nova rewards">
      <Modal.Header closeButton className="border-0">
        <Modal.Title>
          <FormattedMessage
            id="general.upload-video-instruction"
            defaultMessage="Upload video instruction"
          />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {/* <video width="750" height="500" controls>
          <source src={UploadInstruction} type="video/mp4" />
        </video> */}
      </Modal.Body>
    </Modal>
  );
};

export default VideoInstructionModal;
