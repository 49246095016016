import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

export default function HelmetMetaData(props) {
  let location = useLocation();
  let currentAppUrl = process.env.REACT_APP_URL + location.pathname;
  let title = props.title !== undefined ? props.title : "Online Shopping, Recharge, Bill Payments, Brand Vouchers | KAMAIE App";
  let image = "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png";
  let description = props.description || "Shop from Online Shopping Sites, Recharge & Pay Utility Bills, Buy Brand Vouchers or Shop from Nearby Retail Stores. Earn WINDS Coins on every transaction at WINDS App.";
  let keywords = props.keywords || "gift cards, vouchers, brand vouchers, e-gift cards, gift vouchers, online discounts, online deals, e-gift cards, e-vouchers, discount vouchers, buy gift card, gift voucher, winds app gift cards, winds app gift vouchers, winds app vouchers."
  let breadcrumbSchema = props.breadcrumbSchema || null
  let voiceSchema = props.voiceSchema || null
  let faqSchema = props.faqSchema || null
  let videoSchema = props.videoSchema || null
  let reviewSchema = props.reviewSchema || null

  if (location.pathname === "/captain-account-deletion") return null

    return (
      <Helmet>

        {/* Primary Meta Tags */}
        <title>{title}</title>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="csrf_token" content="" />
        <meta property="type" content="website" />
        <meta property="url" content={currentAppUrl} />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="_token" content="" />
        <meta name="robots" content="INDEX,FOLLOW" />
        <meta property="title" content={title} />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <link rel="canonical" href={currentAppUrl} />

        {/*  Open Graph / Facebook  */}
        <meta property="image" content={image} />
        <meta property="og:locale" content="en_IN" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:image" content="https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png" />
        <meta content="image/*" property="og:image:type" />
        <meta property="og:url" content={currentAppUrl} />
        <meta property="og:site_name" content="KAMAIE" />
        <meta property="og:description" content={description} />

        {/*  Open Graph / twitter  */}
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:url" content={currentAppUrl} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content="https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png" />

        {/* Organization Schema */}
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "KAMAIE",
              "url": "https://windsapp.com/",
              "logo": "https://windsapp.com/static/media/logo-icon.1525bf06850008ccab83.png",
              "address": {
                "@type": "PostalAddress",
                "addressCountry": "India",
                "addressLocality": "Karnataka",
                "addressRegion": "Bangalore",
                "postalCode": "560082",
                "streetAddress": "#235, Suraj Centre, 2nd Floor, 27th Cross Rd, 7th Block, Jayanagar"
              },
              "email": "support@Kamaie.com",
              "contactPoint": {
                "@type": "ContactPoint",
                // "telephone": "1800 103 1032",
                "contactType": "Customer Care"
              },
              "sameAs": ["https://www.facebook.com/windsapp", "https://twitter.com/windsapp", "https://www.linkedin.com/company/winds-e-pvt-ltd/", "https://www.youtube.com/channel/UC9oRFRK-5QmokZsS-cBMd2Q", "https://www.instagram.com/winds.app"]
            }
          )}
        </script>

        {/* Voice Schema */}
        {voiceSchema}

        {/* Breadcrubs Schema */}
        {breadcrumbSchema}

        {/* FAQ Schema */}
        {faqSchema}

        {/* Video Schema */}
        {videoSchema}

        {/* Review Schema */}
        {reviewSchema}

      </Helmet>
    );
}
