import React, { Component } from "react";
import logo from "../../assets/logo-icon.png"
import apple_en from "../../assets/apple-en.png"
import google_en from "../../assets/google-en.png"


class DownloadApp extends Component {
    state = {}
    componentDidMount() {
        document.getElementsByClassName('navbar')[0].style.display = "none"
        document.getElementsByClassName('container-fluid--footer')[0].style.display = "none"
        document.getElementById('nh').style.display = "none"
    }
    render() {
        return (
            <section className="download-app" style={{ position: "relative", height: "94vh" }}>
                <div className="div1" style={{ background: "rgb(17 29 69)", minHeight: "250px" }}>

                </div>
                <div className="div2" style={{ boxShadow: "0px 10px 14.1px 0.9px rgb(0 0 0 / 24%), 0px 4px 19.6px 0.4px rgb(0 0 0 / 16%)" }}>
                    <img src={logo} alt="logo" title="logo" style={{ padding: "10px 15px", borderRadius: "5px", boxShadow: "rgb(0 0 0 / 24%) 0px 10px 14.1px 0.9px, rgb(0 0 0 / 16%) 0px 4px 19.6px 0.4px" }} />
                    <br />
                    <br />
                    <h4 style={{ fontWeight: "bold" }}>Download the KAMAIE APP</h4>
                    <br />

                    <div>
                        <a href="https://apps.apple.com/us/app/winds-biggest-reward-program/id1483895863" target="_blank" rel="noopener noreferrer">
                            <img src={apple_en} alt="logo" title="logo" />
                        </a>
                    </div>
                    <br />

                    <div>
                        <a href="https://play.google.com/store/apps/details?id=com.windseworld.customer" target="_blank" rel="noopener noreferrer">
                            <img src={google_en} alt="logo" title="logo" />
                        </a>
                    </div>
                </div>
                <div className="div3" style={{ background: "#fff", minHeight: "250px" }}>

                </div>
            </section>
        );
    }
}

export default DownloadApp;