import React from "react";
import Ic_faq_banner from "../../../../assets/prepaid_cards/ic_faq_banner.png";
import { Accordion, Card } from "react-bootstrap";

const FAQ = () => {
  return (
    <>
      <div className="kyc-cardmanage-faq-banner py-2">
        <div className="container">
          <div className="row d-flex flex-md-row-reverse align-items-center">
            <div className="d-flex justify-content-center col-12 col-md-6">
              <img src={Ic_faq_banner} className="img-fluid" alt="img" />
            </div>
            <div className="col-12 col-md-6">
              <p className="cardmanager-bannerhead col-12 col-md-8 text-left text-sm-center text-md-left p-0 bold">
                Know your Prepaid Card well
              </p>
              <p className="title-choose-plan cardmanager-faq-bannerbody text-left text-sm-center text-md-left">
                Earn 100% WRP on all transactions. Get your statement on single
                tap. Freeze or Block your card instantly
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <p className="title-choose-plan  text-center">
          <strong> Frequenly asked questions</strong>
        </p>
        <Accordion>
          <Card className="faq-accordion-style ">
            <div className="faq-accordian-item">
              <Card.Header style={{ backgroundColor: "#fff" }} className="cursor-pointer">
                <Accordion.Toggle
                  as={Card}
                  eventKey="0"
                  variant="text"
                  className="managecard-accordion-toggle-btn py-1 d-flex  justify-content-between "
                >
                  <p className="p-1"><strong>How do I Block my Prepaid card?</strong></p>
                  <i
                    className="fa fa-angle-right accordion-arrow text-right mt-n5"
                    aria-hidden="true"
                  ></i>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0" variant="text">
                <Card.Body className="accordion-card-body">
              On the Prepaid card screen tab on the 'Block/Unblock'. Select the Valid reason from the list and tab on Continue. Tab on 'Block' in the next screen to confirm the Temporary Block of the Prepaid card.
                </Card.Body>
              </Accordion.Collapse>
            </div>
          </Card>
          <Card className="faq-accordion-style ">
            <div className="faq-accordian-item">
              <Card.Header style={{ backgroundColor: "#fff" }} className="cursor-pointer">
                <Accordion.Toggle
                  as={Card}
                  eventKey="1"
                  variant="text"
                  className="managecard-accordion-toggle-btn py-1 d-flex  justify-content-between "
                >
                  <p className="p-1"><strong>How do I Unblock my Prepaid card?</strong></p>
                  <i
                    className="fa fa-angle-right accordion-arrow text-right mt-n5"
                    aria-hidden="true"
                  ></i>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1" variant="text">
                <Card.Body className="accordion-card-body">
                 On the Prepaid card screen tab on the 'Block/Unblock'. Tab on 'Unblock' in the next screen , Enter the OTP sent to registered number to the confirm Unblock of the Prepaid card.
                </Card.Body>
              </Accordion.Collapse>
            </div>
          </Card>
          <Card className="faq-accordion-style ">
            <div className="faq-accordian-item">
              <Card.Header style={{ backgroundColor: "#fff" }} className="cursor-pointer">
                <Accordion.Toggle
                  as={Card}
                  eventKey="2"
                  variant="text"
                  className="managecard-accordion-toggle-btn py-1 d-flex  justify-content-between "
                >
                  <p className="p-1"><strong>How to Set Card Limits for Prepaid card</strong></p>
                  <i
                    className="fa fa-angle-right accordion-arrow text-right mt-n5"
                    aria-hidden="true"
                  ></i>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2" variant="text">
                <Card.Body className="accordion-card-body">
                On the Prepaid card screen tab on the 'Card Limits'. Enable the button for Online Transaction and Select / Enter the amount to set the Limit for Online Transaction .
                </Card.Body>
              </Accordion.Collapse>
            </div>
          </Card>
          <Card className="faq-accordion-style ">
            <div className="faq-accordian-item">
              <Card.Header style={{ backgroundColor: "#fff" }} className="cursor-pointer">
                <Accordion.Toggle
                  as={Card}
                  eventKey="3"
                  variant="text"
                  className="managecard-accordion-toggle-btn py-1 d-flex  justify-content-between "
                >
                  <p className="p-1"><strong>Can I withdraw money from ATM ?</strong></p>
                  <i
                    className="fa fa-angle-right accordion-arrow text-right mt-n5"
                    aria-hidden="true"
                  ></i>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3" variant="text">
                <Card.Body className="accordion-card-body">
                No, prepaid card doesn't allow to withdraw money from ATM .
                </Card.Body>
              </Accordion.Collapse>
            </div>
          </Card>
        </Accordion>
      </div>
    </>
  );
};

export default FAQ;
