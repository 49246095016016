import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import prepaidCardClientX from "../../../../utils/api/prepaidCardClientX";
import { toast } from "react-toastify";
import InlineLoader from "../../../../components/InlineLoader";
import { setKYCUniqueID } from "../../actions";

const ValidateKYC = () => {
  let history = useHistory();
  let dispatch = useDispatch()

  const { kycUniqueID, userName, selectedCardData } = useSelector((state) => state?.ppi)
  const { profile } = (useSelector((state) => state?.global));

  const [otpVerificationMessage, setOtpVerificationMessage] = useState("");
  const [otp, setOTP] = useState('');
  const [renderTime, setRenderTime] = useState('');
  const [btnLoad, setBtnLoad] = useState(false)
  const [delay, setDelay] = useState(4*60);
  const [enableResendButton,setEnableResentButton]=useState(false)

  const minutes = Math.floor(delay / 60);
  const seconds = Math.floor(delay % 60);

  useEffect(() => {
    const timer = setInterval(() => {
      setDelay(delay - 1);
    }, 1000);
    if (delay === 0) {
      clearInterval(timer);
      setEnableResentButton(true)
    }
    return () => {
      clearInterval(timer);
    };
  }, [delay]);
  
  const verifyOTP = () => {
    const authConfig = {
      headers: {
        TempAuthToken: profile?.temp_ppi_token,
      }
    }

    setBtnLoad(true);
    prepaidCardClientX.post(`/validate-min-kyc`, {
      "minKycUniqueId": kycUniqueID,
      "otp": otp
    }, authConfig)
      .then((response) => {
        let data = response.data.data;
        data?.success && toast.success(data?.responseMessage)
        setBtnLoad(false);
        history.push("/prepaid-cards/small-kyc/document/edit");
      }).catch((error) => {
        setOtpVerificationMessage(error?.data?.message)
        setBtnLoad(false);
      })
  }

  const resendOTP = () =>{
    const authConfig = {
      headers: {
        TempAuthToken: profile?.temp_ppi_token,
      }
    }

    prepaidCardClientX.post(`/generate-min-kyc`, {
      "customerName": userName,
      "mobileNumber": profile?.mobile,
      // "email": profile?.email
      // "kyc_consent": 1,
      // "tnc_consent": 1,
      // "pep_consent": 1
    }, authConfig)
    .then((response) => {
      let data = response.data.data;
      dispatch(setKYCUniqueID(data.minKycUniqueId))
      toast.success(data?.responseMessage)
      setBtnLoad(false)
    }).catch(error => {
      setBtnLoad(false)
    })
  }

  const handleResendOTP = () => {
    setOTP('')
    setOtpVerificationMessage('')
    setRenderTime(Date.now())
    resendOTP()
    setDelay(4*60)
  }

  return (
    <>
      <div className="m-3 mb-5">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-sm-8 col-md-5">
              <p className="cardkyc-title text-center bold m-3 m-sm-4 m-md-5">
              {selectedCardData?.cardPlan} CARD KYC
              </p>
              <p className="cardkyc-subtitle text-center">OTP Verification</p>
              <p className="cardkyc-desc text-center mb-0">
                An 6 digit code has been sent to <strong>{profile?.mobile}</strong>{" "}
              </p>
              <div className="col-12 my-3 d-flex justify-content-center align-items-center otp-box">
                <OtpInput
                  value={otp}
                  onChange={setOTP}
                  numInputs={6}
                  separator={<span></span>}
                  className="otp-input"
                  key={renderTime}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  inputStyle={{
                    width:"auto",
                    margin: "0.3rem",
                    marginTop: "0rem",
                    fontSize: "2rem",
                    backgroundColor: "#f6f6f6",
                    borderWidth: 0,
                    borderRadius: ".2rem",
                    inputType: "number"
                  }}
                />
              </div>

              <div className="resendOtp d-flex justify-content-between mx-4">
                <p>{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}</p>
                <p className={enableResendButton ? "cursor-pointer":"cursor-not-allowed"} onClick={() =>enableResendButton && handleResendOTP()}>Resend OTP</p>
              </div>
              <div className="resendOtp d-flex justify-content-center mx-4 mt-3  mt-md-n1 mt-lg-n4 mb-3">
                {/* <p className="validation-error-text">The otp is invalid</p> */}
              </div>
              <div className="resendOtp d-flex justify-content-center mx-4 mb-md-5 mb-2">
                <p className="validation-error-text">
                  {otpVerificationMessage}
                </p>
              </div>
              {
                btnLoad ?
                  <div className="d-flex justify-content-center my-4">
                    <InlineLoader />
                  </div>
                  :
                  <button
                    className="animated-btn"
                    id={otp.toString().length < 6 ? "animated-btn-disable" : ""}
                    disabled={otp.toString().length < 6}
                    onClick={() => {
                      verifyOTP()
                    }}
                  >
                    VERIFY OTP
                  </button>
              }
            </div>
            <div id="adivaha-wrapper"><script charset="utf-8" type="text/javascript" src="https://www.bengines.com/ui/v2/77A89724/CMBHBTBO5C437514F0404/mt/searchbox/"></script></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ValidateKYC;
