import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import aboutBlue from "../../../assets/about-blue.svg";
import OffersCarousel from "../../home/components/OffersCarousel";
import distanceIcon from "../../../assets/ic_distance.svg";
import ReferNowSection from "../../../components/ReferNowSection";
import mobileIcon from "../../../assets/ic_mobile.svg";
import directionIcon from "../../../assets/ic_direction.svg";
import classNames from "classnames";
import ContentLoader from "react-content-loader";
import { FormattedMessage } from "react-intl";
import bookmarkActive from "../../../assets/bookmark-active.svg";
import bookmarkInactive from "../../../assets/bookmark-inactive.svg";
import Modal from "react-bootstrap/Modal";
import { Range, getTrackBackground } from "react-range";
import WrpBenefitModel from "../../../components/WrpBenefitModel";
import DefaultStoreImage from "../../../assets/default-store-image.svg";
import { onViewedProduct } from "../../../utils/events";
import EmptyState from "../../../components/EmptyState";
import EmptyImage from "../../../assets/empty-state-screen.svg";
import customerPartnerClientX from "../../../utils/api/customerPartnerClientX";

const STEP = 0.1;
const MIN = 1;
const MAX = 5;

class PartnerDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      partner: null,
      index: 0,
      direction: null,
      isLoading: true,
      isRatingModelOpen: false,
      isWrpBenefitModelOpen: false,
      ratingValue: [],
      values: [1],
      isModelTransitionDone: false,
    };
  }

  addRating = () => {
    this.setState({ isRatingModelOpen: true });
  };

  closeWrpModelHandler = () => {
    this.setState({ isWrpBenefitModelOpen: false });
  };

  openWrpBenefitModel = () => {
    this.setState({ isWrpBenefitModelOpen: true });
  };

  closeRatingModelHandler = () => {
    this.setState({ isRatingModelOpen: false, isModelTransitionDone: false });
  };

  submitHandler = () => {
    customerPartnerClientX
      .post(`v1/reviews/${this.state.partner.id}`, {
        rating: this.state.values[0],
      })
      .then((response) => {
        this.closeRatingModelHandler();
        const {
          match: { params },
        } = this.props;

        this.getPartnerData(params.id);
      })
      .catch((error) => { });
  };

  modelEntered = () => {
    this.setState({ isModelTransitionDone: true });
  };

  AddRatingModel = (props) => {
    return (
      <React.Fragment>
        <Modal
          size="lg"
          show={this.state.isRatingModelOpen}
          onHide={this.closeRatingModelHandler}
          onEntered={this.modelEntered}
        >
          <Modal.Header closeButton className="border-0"></Modal.Header>
          <Modal.Body>
            <div className="p-3 p-md-5">
              <div className="row">
                <div className="empty-state">
                  <EmptyState
                    title={
                      <FormattedMessage
                        id="nearby-partners.how_would_you_rate_us"
                        defaultMessage="How would you rate us?"
                      />
                    }
                    imageWidth={50}
                    image={EmptyImage}
                  />
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-1 text-left" style={{ color: "#D2D2D2" }}>
                  1
                </div>
                <div
                  className="col-1 offset-10 text-right"
                  style={{ color: "#D2D2D2" }}
                >
                  5
                </div>
              </div>
              <div className="row mt-3">
                {this.state.isModelTransitionDone && (
                  <div className="col-12">
                    {
                      <Range
                        values={this.state.values}
                        step={STEP}
                        min={MIN}
                        max={MAX}
                        onChange={(values) => this.setState({ values })}
                        renderTrack={({ props, children }) => (
                          <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                              ...props.style,
                              height: "36px",
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <div
                              ref={props.ref}
                              className="winds-blue-bg"
                              style={{
                                height: "5px",
                                width: "100%",
                                borderRadius: "4px",
                                background: getTrackBackground({
                                  values: this.state.values,
                                  colors: ["#0078D7", "#ccc"],
                                  min: MIN,
                                  max: MAX,
                                }),
                                alignSelf: "center",
                              }}
                            >
                              {children}
                            </div>
                          </div>
                        )}
                        renderThumb={({ props, isDragged }) => (
                          <div
                            {...props}
                            style={{
                              ...props.style,
                              height: "42px",
                              width: "42px",
                              borderRadius: "4px",
                              backgroundColor: "#0078D7",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              boxShadow: "0px 2px 6px #AAA",
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: "6px",
                                color: "#fff",
                                fontWeight: "bold",
                                fontSize: "14px",
                                fontFamily:
                                  "Arial,Helvetica Neue,Helvetica,sans-serif",
                                padding: "4px",
                                borderRadius: "4px",
                                backgroundColor: "#0078D7",
                              }}
                            >
                              {this.state.values[0].toFixed(1)}
                            </div>
                            <div
                              style={{
                                height: "16px",
                                width: "5px",
                                backgroundColor: isDragged
                                  ? "#0078D7"
                                  : "#0078D7",
                              }}
                            />
                          </div>
                        )}
                      />
                    }
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer className="border-0">
            <button className="btn btn-primary" onClick={this.submitHandler}>
              <FormattedMessage id="general.submit" defaultMessage="Submit" />
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  };

  componentDidMount() {
    this.initialisePartnerData();
    // this.getPartnerData();
  }

  initialisePartnerData = () => {
    this.setState(
      {
        isLoading: true,
      },
      (params) => {
        this.getPartnerData();
      }
    );
  };

  getPartnerData = (paramId) => {
    const {
      match: { params },
    } = this.props;

    customerPartnerClientX
      .get(
        `v1/partners/${params.id}?latitude=${this.props.latitude}&longitude=${this.props.longitude}`
      )
      .then((response) => {
        let data = response.data.data;
        const payload = {
          Category: "Nearby Partner",
          "Product Name": data.name,
        };

        onViewedProduct(payload);

        this.setState({
          partner: data,
          isLoading: false,
        });
      });
  };

  handleSelect = (selectedIndex, e) => {
    this.setState({
      index: selectedIndex,
    });
  };

  slide = (index, direction) => {
    this.setState({
      direction: direction,
    });
  };

  render() {
    return this.state.isLoading ? <this.Shimmer /> : <this.Partner />;
  }

  Shimmer = (props) => {
    return (
      <div className="container py-3 py-md-5">
        <div className="row">
          <div className="col-md-6">
            <div className="box shine box-partner-detail-logo"></div>
          </div>
          <div className="col-md-6">
            <ContentLoader
              height={160}
              width={400}
              speed={2}
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <rect x="0" y="9" rx="0" ry="0" width="120" height="6" />
              <rect x="0" y="21" rx="0" ry="0" width="320" height="12" />
              <rect x="0" y="39" rx="0" ry="0" width="92" height="6" />
              <rect x="0" y="60" rx="0" ry="0" width="506" height="6" />
              <rect x="0" y="75" rx="0" ry="0" width="136" height="6" />
              <rect x="0" y="90" rx="0" ry="0" width="153" height="6" />
            </ContentLoader>
          </div>
        </div>
      </div>
    );
  };

  toggleBookmark = (event, id, isBookmarked) => {
    event.preventDefault();
    event.stopPropagation();
    this.onToggleBookmark(
      id,
      isBookmarked,
      `/v1/partners/${id}/bookmark-partner`,
      "partner"
    );
  };

  onToggleBookmark = (id, isBookmarked, url, type) =>
    !isBookmarked
      ? this.addBookmark(id, url, this.props.partners)
      : this.deleteBookmark(id, url, this.props.partners);

  addBookmark = (id, url, partner) => {
    customerPartnerClientX
      .post(url, {})
      .then((response) => {
        this.setState((prevState) => ({
          partner: {
            ...prevState.partner,
            bookmarked: !prevState.partner.bookmarked,
          },
        }));
      })
      .catch((error) => { });
  };

  deleteBookmark = (id, url, partner) => {
    customerPartnerClientX
      .delete(url)
      .then((response) => {
        this.setState((prevState) => ({
          partner: {
            ...prevState.partner,
            bookmarked: !prevState.partner.bookmarked,
          },
        }));
      })
      .catch((error) => { });
  };

  inputChangeHandler = (values) => {
    this.setState({ values });
  };

  Partner = (props) => {
    const { wrp_ratio } = this.state.partner;
    const { isWrpBenefitModelOpen } = this.state;
    return (
      <div className="container font-proxima-nova">
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            <div className="row align-items-center">
              <div className="col-12">
                <Link to="/">
                  <small>
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </small>
                </Link>{" "}
                /{" "}
                <Link to="/nearby-partners/categories">
                  <small>
                    <FormattedMessage
                      id="nearby-partners.nearby-categories"
                      defaultMessage="Nearby Categories"
                    />
                  </small>
                </Link>{" "}
                /{" "}
                <small>
                  <strong>{this.state.partner.name}</strong>
                </small>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="row mb-md-4">
              {/* <div
                className={classNames("col-md-6", {
                  "d-none": 0 == this.state.partner.store_images.length
                })}
              >
                <this.ImageCarousel />
              </div> */}
              {this.state.partner.store_images.length > 0 ? (
                <div className="col-md-6">
                  {/* <this.ImageCarousel /> */}
                  {this.ImageCarousel()}
                </div>
              ) : (
                <div className="col-md-6">
                  <div
                    className="card"
                    style={{
                      borderRadius: "8px",
                      border: "none",
                    }}
                  >
                    <div
                      className="card-body"
                      style={{
                        backgroundColor: "#F8F9FA",
                        backgroundImage: `url(${DefaultStoreImage})`,
                        backgroundSize: "contain",
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat",
                        height: "400px",
                      }}
                    >
                      {
                        <img
                          src={
                            this.state.partner.bookmarked
                              ? bookmarkActive
                              : bookmarkInactive
                          }
                          className="bookmark"
                          style={{ zIndex: "2" }}
                          alt="bookmark"
                          title="bookmark"
                          onClick={(event) =>
                            this.toggleBookmark(
                              event,
                              this.state.partner.id,
                              this.state.partner.bookmarked
                            )
                          }
                        />
                      }
                    </div>
                  </div>
                </div>
              )}
              <div className="col-md-6 mt-3 mt-md-0 mb-3 mb-md-0 font-proxima-nova">
                {/* <this.PartnerDetails /> */}
                {this.PartnerDetails()}
              </div>
            </div>
          </div>
        </div>

        {/* <this.Offers /> */}
        {this.Offers()}

        <hr />
        <div className="row font-proxima-nova m-md-auto">
          <div className="col-12 col-md-7 mt-2 mt-md-5">
            <h3 className="font-proxima-nova-bold d-inline-block">
              {this.state.partner.reviews.length > 0 && (
                <>
                  {this.state.partner.reviews.length}{" "}
                  <FormattedMessage
                    id="profile.ratings"
                    defaultMessage="Ratings"
                  />
                </>
              )}
            </h3>
            <div className="float-right d-inline-block align-middle">
              <button
                className="btn btn-outline"
                onClick={this.addRating}
                style={{ color: "#087CD8" }}
              >
                <FormattedMessage
                  id="profile.add-rating"
                  defaultMessage="Add Rating"
                />
              </button>
            </div>
            {this.state.partner.reviews.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="row mt-4 pb-3" key={index}>
                    <div className="col-md-2 col-3 text-center">
                      {item.customer_image ? (
                        <div
                          className="d-inline-block"
                          style={{
                            height: "70px",
                            width: "70px",
                            borderRadius: "50%",
                            backgroundImage: `url(${item.customer_image})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }}
                        ></div>
                      ) : (
                        <div
                          className="avatar"
                          style={{ width: "70px", height: "70px" }}
                        >
                          <p>
                            {item.customer_name.substring(0, 1).toUpperCase()}
                          </p>
                        </div>
                      )}
                    </div>

                    <div
                      className="col-9 col-md-10"
                      style={{ marginLeft: "-1rem" }}
                    >
                      <div>
                        <h5 className="font-proxima-nova-bold winds-grey-color">
                          {item.customer_name}
                        </h5>
                      </div>
                      <div className="d-flex align-items-center">
                        <span
                          style={{
                            padding: "0rem 0.5rem",
                            borderRadius: "2px",
                            fontSize: "12px",
                          }}
                          className={classNames("text-white", {
                            "bg-rating-green": item.rating >= 4,
                            "bg-rating-orange":
                              item.rating >= 3 && item.rating < 4,
                            "bg-rating-red": item.rating < 3,
                          })}
                        >
                          {item.rating}
                        </span>{" "}
                        &nbsp;&nbsp;
                        <span className="text-secondary">
                          {item.updated_at}
                        </span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
            <div className="row mt-5 ml-2"></div>

            {this.state.isWrpBenefitModelOpen && (
              <WrpBenefitModel
                isWrpBenefitModelOpen={isWrpBenefitModelOpen}
                wrp_ratio={wrp_ratio}
                closeWrpModelHandler={this.closeWrpModelHandler}
                description={`Earn KAMAIE Coins on every transaction and Redeem those KAMAIE Coins in Future transactions in KAMAIE App`}
              />
            )}

            {this.state.isRatingModelOpen && (
              // <this.AddRatingModel
              //   {...this.state}
              //   {...this.props}
              //   inputChangeHandler={(values) => this.inputChangeHandler(values)}
              //   closeRatingModelHandler={this.closeRatingModelHandler}
              //   modelEntered={this.modelEntered}
              // />
              this.AddRatingModel(props,)
            )}
          </div>
          <div className="col-md-5 d-none d-md-block mt-5">
            <ReferNowSection />
          </div>
        </div>
      </div>
    );
  };

  ImageCarousel = (props) => {
    const { index, direction } = this.state;

    return (
      <div
        className="card"
        style={{
          boxShadow: "0 4px 14px 0 rgba(0,0,0,0.08)",
          borderRadius: "8px",
          border: "none",
        }}
      >
        <div className="card-body" style={{ padding: "0px" }}>
          {
            <img
              src={
                this.state.partner.bookmarked
                  ? bookmarkActive
                  : bookmarkInactive
              }
              className="bookmark"
              style={{ zIndex: "2" }}
              alt="bookmark"
              title="bookmark"
              onClick={(event) =>
                this.toggleBookmark(
                  event,
                  this.state.partner.id,
                  this.state.partner.bookmarked
                )
              }
            />
          }
          <Carousel
            activeIndex={index}
            direction={direction}
            onSelect={this.handleSelect}
            onSlide={this.slide}
          >
            {this.state.partner.store_images.map((item, index) => {
              return (
                <Carousel.Item key={index}>
                  <div
                    className="partner-carousel-item"
                    style={{ backgroundImage: `url(${item.store_image})` }}
                  ></div>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      </div>
    );
  };

  goToUploadPartnerBill = () => {
    if (this.state.partner.pay_now_enabled) {
      this.props.history.push(
        `/pay-now/nearby-partner/${this.state.partner.code}`
      );
    } else {
      this.props.history.push(
        `/upload-bill/nearby-partner/${this.state.partner.code}`
      );
    }
  };

  PartnerDetails = (props) => {
    return (
      <React.Fragment>
        <p className="mb-0 text-secondary d-inline-block">
          {this.state.partner.category}
        </p>
        <button
          className="btn btn-outline float-right"
          style={{ color: "#087CD8", marginTop: "-0.5rem" }}
          onClick={this.openWrpBenefitModel}
        >
          <img src={aboutBlue} alt="about" title="about" className="mr-1" />{" "}
          <FormattedMessage
            id="general.wrp_benefits"
            defaultMessage="KAMAIE Coins Benefits"
          />
        </button>
        <p className="mb-0">
          <strong style={{ fontSize: "28px", verticalAlign: "middle" }}>
            {this.state.partner.name}
          </strong>
        </p>
        {/* <p className="mb-2 text-secondary mr-2">
          <FormattedMessage
            id="general.wrp_benefits"
            defaultMessage="WRP Benefits"
          />{" "}
          : &nbsp;
          {lodash.range(this.state.partner.winds_rating).map((item, index) => {
            return (
              <img
                className="star"
                style={{ width: "16px", marginRight: "2px" }}
                key={"partner-star-" + index}
                src={wrpIcon}
                alt="star"
              />
            );
          })}
        </p> */}
        <p className="my-3 text-secondary d-flex align-items-center">
          <FormattedMessage
            id="general.customer_rating"
            defaultMessage="Customer Rating"
          />
          :{" "}
          <span
            style={{
              verticalAlign: "middle",
              color: "#fff",
              borderRadius: "2px",
              padding: "1px 8px",
              fontSize: "14px",
              marginLeft: "15px",
            }}
            className={classNames("text-white", {
              "bg-rating-green": this.state.partner.average_rating >= 4,
              "bg-rating-orange":
                this.state.partner.average_rating >= 3 &&
                this.state.partner.average_rating < 4,
              "bg-rating-red": this.state.partner.average_rating < 3,
            })}
          >
            {this.state.partner.average_rating}
          </span>
        </p>
        <p className="mb-3 text-secondary w-75">
          {this.state.partner.communication_address}
        </p>
        <p className="mb-3 text-secondary">
          <img className="align-middle mr-2" src={distanceIcon} alt="distance" title="distance" />
          <span className="align-middle">
            {this.state.partner.distance_from_store} KM
          </span>
        </p>
        <p className="mb-3 text-secondary">
          <img className="align-middle mr-2" src={mobileIcon} alt="mobile" mobile="mobile" />
          <span className="align-middle">{this.state.partner.mobile}</span>
        </p>
        <p className="mb-3 text-secondary">
          <img className="align-middle mr-2" src={directionIcon} alt="map" title="map" />
          <span className="align-middle" style={{ color: "#0070DE" }}>
            <a
              href={`http://www.google.com/maps/place/${this.state.partner.lat},${this.state.partner.long}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="nearby-partners.view-on-map"
                defaultMessage="View on Map"
              />
            </a>
          </span>
        </p>
        {/* <button
          className="w-50 mb-3 mt-4 mt-sm-0 btn btn-outline-primary"
          onClick={this.goToUploadPartnerBill}
        >
          {this.state.partner.pay_now_enabled ? (
            "Pay Now"
          ) : (
            <FormattedMessage
              id="general.upload-bill"
              defaultMessage="Upload Bill"
            />
          )}
        </button> */}
        {this.state.partner.pay_now_enabled ?
          <button
            className="w-50 mb-3 mt-4 mt-sm-0 btn btn-outline-primary"
            onClick={this.goToUploadPartnerBill}>Pay Now</button> :
          <p className="p-2" style={{ backgroundColor: 'rgb(240 210 210)', borderRadius: '5px' }}>Partner has not enabled Pay Now. Please ask the Partner to accept Pay Now feature on their KAMAIE Partner App</p>
        }


      </React.Fragment>
    );
  };

  Offers = (props) => {
    return (
      this.state.partner.offers.length > 0 && (
        <React.Fragment>
          <div className="row mb-md-3 mt-md-3">
            <div className="col-12">
              <h2>
                <strong>
                  <FormattedMessage
                    id="offers.offers"
                    defaultMessage="Offers"
                  />
                </strong>
              </h2>
            </div>
          </div>

          <div className="row" style={{ marginTop: "-1.5rem" }}>
            <div className="col-12">
              <OffersCarousel offers={this.state.partner.offers} />
            </div>
          </div>
        </React.Fragment>
      )
    );
  };
}
function mapStateToProps(state) {
  return {
    latitude: state.location.latitude,
    longitude: state.location.longitude,
  };
}
export default connect(mapStateToProps, null)(PartnerDetail);
