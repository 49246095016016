export const voucherCategoryMetaContent = {
    "beauty-wellness-grooming": {
        keywords: "beauty brand vouchers, wellness brand vouchers, KAMAIE app cosmetics brand vouchers, KAMAIE app grooming brand vouchers, KAMAIE app beauty brand e-gift cards, KAMAIE app wellness brand e-gift cards, KAMAIE app wellness brand e-gift cards, KAMAIE app beauty vouchers,"
    },
    "education": {
        keywords: "education brands e-gift cards, educational  e-gift cards, educational brand vouchers, education gift vouchers, Ed-Tech e-gift cards, Ed-Tech Voucher"
    },
    "fuel-automobile": {
        keywords: "KAMAIE app fuel brand voucher, KAMAIE app oil brand voucher, automobile brands, KAMAIE app fuel brand vouchers, KAMAIE online oil brand vouchers, diesel pump brand vouchers, automobile oil online vouchers, automobile oil brand vouchers, car oil vouchers, HPCL voucher, BPCL voucher"
    },
    "gym-fitness": {
        keywords: "KAMAIE Gym & Fitness, KAMAIE online gym brands, online gym brands vouchers, online fitness brands, online fitness brand vouchers, online gym vouchers, gym  e-gift cards"
    },
    "jewellery": {
        keywords: "online jewellery brand vouchers, KAMAIE app online jewellery brands vouchers, KAMAIE app jewellery vouchers, KAMAIE app jewellery brand vouchers, KAMAIE jewellery vouchers"
    },
    "others": {
        keywords: "KAMAIE, KAMAIE app, shopping, vouchers, buy vouchers online, KAMAIE vouchers, KAMAIE brand vouchers, gift cards, KAMAIE e-gift cards, KAMAIE e-gift vouchers, online vouchers, e-gift cards, e-vouchers, buy gift cards, gift vouchers, KAMAIE app gift cards, KAMAIE app gift vouchers, KAMAIE app vouchers, KAMAIE shopping app, "
    },
    "travel-hotels": {
        keywords: " KAMAIEapp online travel brand vouchers, KAMAIE app hotel booking vouchers, KAMAIE app online flight booking voucher, KAMAIE app bus booking vouchers, KAMAIE app rail booking vouchers, KAMAIE Travel & Hotels voucher, KAMAIE Travel & Hotels  e-gift cards"
    },
    "clothing-accessories": {
        keywords: "KAMAIE Clothing brand vouchers, KAMAIE Clothing & Accessories brand vouchers, new fashion brand vouchers, KAMAIE Fashion brand vouchers, KAMAIE Clothes brand e-gift cards, Clothing accessories brand e-gift cards, KAMAIE Accessories brand e-gift cards, Accessories vouchers, KAMAIE Clothing vouchers, KAMAIE Fashion vouchers, Apparel vouchers"
    },
    "electronics-mobile-accessories": {
        keywords: " KAMAIE Electronics & Mobile Accessories  KAMAIE Electronics vouchers, KAMAIE Mobile Accessories and Electronics brand vouchers, KAMAIE new Electronics brand vouchers, Electronics brand vouchers, KAMAIE Electronics e-gift cards, KAMAIE Mobile Accessories brand e-gift cards, Gadgets brand e-gift cards, Tech vouchers, KAMAIE Mobile vouchers, KAMAIE Mobile E gift  vouchers, New Gadgets vouchers,"
    },
    "gifting": {
        keywords: "KAMAIE Gifting E- Vouchers, KAMAIE Gifting vouchers, Gift cards, E-gift cards, Gift certificates, KAMAIE Voucher codes, KAMAIE Digital gift cards, Online gift vouchers, Gift card codes, Voucher gifts, KAMAIE Gift card offers, Discounted gift cards, KAMAIE Gift card promotions,"
    },
    "healthcare": {
        keywords: "KAMAIE Healthcare E- Vouchers, KAMAIE Healthcare vouchers, KAMAIE Medical Gift cards, KAMAIE Medical voucher, KAMAIE Health voucher, Medical care voucher, Health services voucher, Wellness voucher, Nutrition voucher, KAMAIE fitness voucher, Health Voucher, Fitness certificates, Health cards, Online Fitness vouchers"
    },
    "kids-books-stationery": {
        keywords: "KAMAIE Kids, Books & Stationary brand vouchers, KAMAIE Books & Stationary brand vouchers, KAMAIE Books brand vouchers, KAMAIE Stationary brand vouchers, KAMAIE Stationary and Books brand e-gift cards, Stationary accessories brand e-gift cards, Accessories brand e-gift cards, Kids Accessories vouchers, KAMAIE Kids Clothing vouchers, KAMAIE Kids Fashion vouchers, KAMAIE Apparel vouchers"
    },
    "ott": {
        keywords: "KAMAIE OTT brand vouchers,KAMAIE Movies brand vouchers, KAMAIE TV Series brand vouchers, KAMAIE OTT brand e-gift cards, KAMAIE Kids' streaming brand e-gift cards, KAMAIE Movie Streaming brand e-gift cards, KAMAIE Tv Series vouchers,"
    },
    "travel-accessories": {
        keywords: "KAMAIE Travel Accessories brand vouchers, KAMAIE Travel brand vouchers, KAMAIE Travel E-Gift vouchers, Travel bags brand e-gift cards, KAMAIE Accessories Travel streaming brand e-gift cards, Travel Bags brand e-gift cards,"
    },
    "e-commerce": {
        keywords: "KAMAIE e-commerce brand vouchers, KAMAIE app online shopping vouchers, KAMAIE app e-commerce brand vouchers, KAMAIE e-commerce gift card, KAMAIE e-commerce gift voucher"
    },
    "food-beverages": {
        keywords: "KAMAIE online food delivery brand vouchers, KAMAIE app online food ordering vouchers, KAMAIE app food vouchers, KAMAIE online beverage ordering,"
    },
    "grocery": {
        keywords: "KAMAIE online grocery delivery brand vouchers, KAMAIE app online grocery ordering vouchers, KAMAIE app food vouchers, online grocery ordering, KAMAIE grocery voucher, KAMAIE E-gift card"
    },
    "home-decor": {
        keywords: "KAMAIE online home decor brand vouchers, KAMAIE app online home decor vouchers, KAMAIEHome & Decor E-gift card, home decor brand vouchers, "
    },
    "movies-events": {
        keywords: "KAMAIE online movie brand vouchers, KAMAIE app movie brand vouchers, online movie booking brand vouchers, KAMAIE online event brand vouchers, online event booking brand vouchers, "
    },
    "salon-spa": {
        keywords: "KAMAIE online salon brand vouchers, KAMAIE app salon brand vouchers, KAMAIE online spa brand vouchers, KAMAIE app salon brand vouchers, KAMAIE salon and spa E-Gift card "
    },
}