import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
// import Iframe from "../../../components/iFrame";
import { currentlySelectedLanguage } from "../../../utils/helper";
import FAQMetaData from "../../../seo/FAQMetaData"
import { Accordion, Card } from "react-bootstrap";
const Faq = (props) => {
  let language = currentlySelectedLanguage()

  const EnglishContent = () => {
    return (
      <div class="py-md-0 py-3">
        <h1 className="title h4 pb-3 font-weight-bold"><FormattedMessage id="partner.faq" /></h1>
        <Accordion defaultActiveKey="1">
          <Card className="mb-3">
            <Card.Header className="cursor-pointer p-0">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="1" >
                <h2 className="mb-0 h5 font-weight-bold">What is KAMAIE?</h2>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <p className="desc">KAMAIE is a one of its kind ‘UNIVERSAL SUPER APP’ which brings in the whole convenience factor of discovering Online e-commerce Platforms, Neighborhood Retail Stores, Gift Vouchers of the choicest Brands and Utility Bill Payment options, Flight booking and Hotel booking. On doing regular day-to-day shopping or on any transaction through this App, be it for a product or service. Customer gets KAMAIE Coins on every transaction made on KAMAIE app.</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="mb-3">
            <Card.Header className="cursor-pointer p-0">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="2" >
                <h2 className="mb-0 h5 font-weight-bold">Who are KAMAIE Customers?</h2>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <p className="desc">Any person who does any shopping or uses any service through the KAMAIE ecosystem will be termed a KAMAIE Patron (Customer).</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="mb-3">
            <Card.Header className="cursor-pointer p-0">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="3" >
                <h2 className="mb-0 h5 font-weight-bold">Is it mandatory to use a referral code to become a KAMAIE Customer?</h2>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                <p className="desc">No, it is not mandatory.</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="mb-3">
            <Card.Header className="cursor-pointer p-0">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="4" >
                <h2 className="mb-0 h5 font-weight-bold">Who can refer a Customer?</h2>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
                <p className="desc"> Customers may be referred to KAMAIE by other Customers.</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="mb-3">
            <Card.Header className="cursor-pointer p-0">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="5" >
                <h2 className="mb-0 h5 font-weight-bold">If a KAMAIE  Customer refers another Customer, what benefits will they get as a referring Customer?</h2>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="5">
              <Card.Body>
                <p className="desc">10% of KAMAIE' Earnings through the shopping of a Referred Customer will be given to the Referring Customer. Means if Customer B signs up using Customer A's referral code, Customer A will earn 10% of KAMAIE' Earnings on all transactions done by Customer B through the KAMAIE App (For instance, if Customer B buys a laptop worth Rs. 1 lakh through the KAMAIE Customer, and if the KAMAIE' earning on the transaction is 5% i.e. Rs. 5,000/-, then Customer A will get 10% of KAMAIE' earning, i.e. Rs. 500/-).</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="mb-3">
            <Card.Header className="cursor-pointer p-0">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="6" >
                <h2 className="mb-0 h5 font-weight-bold">What is KAMAIE Coins and how can we redeem in cash?</h2>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="6">
              <Card.Body>
                <p className="desc">Customers can earn KAMAIE Coins by making purchases at Nearby KAMAIE Dukaan (registered KAMAIE Dukaan), Online Shopping sites, by buying Vouchers and using utility bill payment options, booking flight tickets, booking hotel, transaction done by KAMAIE Pre-Paid Card through the KAMAIE App.
                  Though KAMAIE Coins are hugely beneficial and you can redeem it in your next voucher purchase where 1 KAMAIE Coins can be Rs. 0.10 to Rs.10,000, it depends on the luck.</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="mb-3">
            <Card.Header className="cursor-pointer p-0">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="7" >
                <h2 className="mb-0 h5 font-weight-bold">How would a customer know the total KAMAIE Coins earned till date?</h2>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="7">
              <Card.Body>
                <p className="desc">All information related to earned KAMAIE Coins will be available on the KAMAIE App.</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="mb-3">
            <Card.Header className="cursor-pointer p-0">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="8" >
                <h2 className="mb-0 h5 font-weight-bold">Suppose a Customer buys a product to be paid for in instalments then will EMI be also counted as KAMAIE Coins?</h2>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="8">
              <Card.Body>
                <p className="desc">YES - EMI will be considered for KAMAIE Coins. Any amount as and when paid, can be claimed as KAMAIE Coins provided that it is also approved by KAMAIE Dukaan followed by the payment of the service charge that he owes to KAMAIE.</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="mb-3">
            <Card.Header className="cursor-pointer p-0">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="9" >
                <h2 className="mb-0 h5 font-weight-bold">Is there any facility of Money Transfer in KAMAIE App?</h2>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="9">
              <Card.Body>
                <p className="desc">No such facility is currently available.</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="mb-3">
            <Card.Header className="cursor-pointer p-0">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="10" >
                <h2 className="mb-0 h5 font-weight-bold">I am already using online shopping apps available in the market and I am satisfied with them. Why should I switch to KAMAIE?</h2>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="10">
              <Card.Body>
                <p className="desc">KAMAIE is a unique Shopping App which will reward you for your daily shopping transactions like never before. On your regular transactions you collect KAMAIE Coins on every transaction.
                  In addition, you are entitled to referral benefits too as a percentage of KAMAIE’ earnings.</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="mb-3">
            <Card.Header className="cursor-pointer p-0">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="14" >
                <h2 className="mb-0 h5 font-weight-bold">If I update my details on the KAMAIE App, would it be safe? And will the KAMAIE Dukaan be able to see my personal details?</h2>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="14">
              <Card.Body>
                <p className="desc">It is completely safe to update your details on KAMAIE App. Such details won't be shared with the KAMAIE DUkaan. KAMAIE understands and respects the privacy of its customers.</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="mb-3">
            <Card.Header className="cursor-pointer p-0">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="15" >
                <h2 className="mb-0 h5 font-weight-bold">How soon will the KAMAIE Coins earned by me as a  Customer get updated on the KAMAIE app?</h2>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="15">
              <Card.Body>
                <p className="desc">KAMAIE Coins will update immediately. But it is also necessary to clarify here that if you have made purchases through online shopping sites, then once your order is tracked and on the expiry of the refund period, generally within 60 to 90 days you get KAMAIE Coins. Other than on purchases made through online shopping sites, your KAMAIE Coins will be updated instantly.</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="mb-3">
            <Card.Header className="cursor-pointer p-0">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="16" >
                <h2 className="mb-0 h5 font-weight-bold">As a KAMAIE Customer, do I get the same KAMAIE Coins wherever I shop with KAMAIE?</h2>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="16">
              <Card.Body>
                <p className="desc">No, when you shop through online shopping sites, buy vouchers of your favourite brands, shop at nearby KAMAIE Partner outlets or use the Utility Bill Payment option through KAMAIE you get the KAMAIE Coins which may be different also.</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          {/* <Card className="mb-3">
            <Card.Header className="cursor-pointer p-0">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="19" >
                <h2 className="mb-0 h5 font-weight-bold">Do I have to provide any kind of Government ID to validate my profile?</h2>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="19">
              <Card.Body>
                <p className="desc">Yes, you are required to provide your PAN Card copy for verification. Additionally, you can also provide your Aadhaar Card copy. In case you win the cashback, KAMAIE will need these IDs along with your bank account details to transfer the cash directly to your account.</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card> */}
          <Card className="mb-3">
            <Card.Header className="cursor-pointer p-0">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="20" >
                <h2 className="mb-0 h5 font-weight-bold">Is it possible that Customer can make the payments to KAMAIE Executive Partners through another App for e.g. PhonePay, Paytm etc.</h2>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="20">
              <Card.Body>
                <p className="desc">Yes. The customer can make the payments through any mode of payment via Cash, Online, Paytm, GPay, PhonePay etc.</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="mb-3">
            <Card.Header className="cursor-pointer p-0">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="21" >
                <h2 className="mb-0 h5 font-weight-bold">What happens in case a customer decides to return a purchased item?</h2>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="21">
              <Card.Body>
                <p className="desc">The return period of any product will be 14 days from the date of purchase. In case you return the item within this period, no KAMAIE Coins will be alloted.</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="mb-3">
            <Card.Header className="cursor-pointer p-0">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="23" >
                <h2 className="mb-0 h5 font-weight-bold">Does KAMAIE have any customer support centre where all queries related to KAMAIE can be resolved?</h2>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="23">
              <Card.Body>
                <p className="desc">Yes, You can get all your queries resolved in regards to KAMAIE through: sending an email at: support@kamaie.com</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    )
  }

  const HindiContent = () => {
    return (
      <div class="py-md-0 py-3">
        <h1 className="title h4 pb-3 font-weight-bold"><FormattedMessage id="partner.faq" /></h1>
        <Accordion defaultActiveKey="1">
          <Card className="mb-3">
            <Card.Header className="cursor-pointer p-0">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="1" >
                <h2 className="mb-0 h5 font-weight-bold">KAMAIE पेट्रन्स कौन हैं?</h2>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <p className="desc">KAMAIE के सम्मानित ग्राहकों को विंड्स पेट्रन के नाम से जाना जाता है। कोई भी व्यक्ति जो KAMAIE ईकोसिस्टम से किसी भी प्रकार की शॉपिंग करता है अथवा सर्विसेस लेता है, वह KAMAIE पेट्रन के नाम से जाना जायेगा।</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="mb-3">
            <Card.Header className="cursor-pointer p-0">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="2" >
                <h2 className="mb-0 h5 font-weight-bold">KAMAIE पेट्रन बनने के लिए क्या रेफरल कोड का उपयोग अनिवार्य है?</h2>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <p className="desc">जी नहीं, यह अनिवार्य नहीं है।</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="mb-3">
            <Card.Header className="cursor-pointer p-0">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="3" >
                <h2 className="mb-0 h5 font-weight-bold">पेट्रन को कौन-कौन रेफर कर सकता है?</h2>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                <p className="desc">पेट्रन को सीनियर कैप्टन या अन्य पेट्रन रेफर कर सकते हैं।</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="mb-3">
            <Card.Header className="cursor-pointer p-0">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="4" >
                <h2 className="mb-0 h5 font-weight-bold">क्या एक पेट्रन दूसरे पेट्रन को रेफर कर सकता है?</h2>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
                <p className="desc">हाँ। एक पेट्रन के तौर पर आप जितने ज्यादा पेट्रन को विण्ड्स के लिए रेफर करेंगे एवं उन्हें KAMAIE के माध्यम से अपनी खरीदारी करने के लिए प्रेरित करेंगे उतना ही ज्यादा लाभ आप अर्जित करेंगे।</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="mb-3">
            <Card.Header className="cursor-pointer p-0">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="5" >
                <h2 className="mb-0 h5 font-weight-bold">एक KAMAIE पेट्रन यदि किसी अन्य पेट्रन को रेफर करता है तो उसे रेफरल के तौर पर क्या लाभ मिलेगा?</h2>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="5">
              <Card.Body>
                <p className="desc">रेफर्ड पेट्रन्स के द्वारा की गई खरीदारी पर KAMAIE की आय का 10% रेफर करने वाले पेट्रन को मिलेगा। यदि पेट्रन-बी, पेट्रन-ए के रेफरल कोड से साइनअप करता है तो पेट्रन-ए को पेट्रन-बी के द्वारा KAMAIE से किए गये सभी’ ट्राँज़ैक्शन पर विण्ड्स की आय का 10% मिलेगा; उदाहरण के तौर पर यदि पेट्रन-बी KAMAIE पार्टनर के माध्यम से 1 लाख का लैपटॉप खरीदता है तो इस ट्राँज़ैक्शन पर KAMAIE की आय होगी 5% यानी ₹ 5,000/- और पेट्रन-ए को KAMAIE की आय का 10% मिलेगा यानी ₹ 500/-. मिलेंगे।</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="mb-3">
            <Card.Header className="cursor-pointer p-0">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="8" >
                <h2 className="mb-0 h5 font-weight-bold">क्या एक ही व्यक्ति KAMAIE का पेट्रन, पार्टनर और सीनियर कैप्टन बन सकता है?</h2>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="8">
              <Card.Body>
                <p className="desc">जी हाँ। एक ही व्यक्ति इन सारे रोल में कार्य कर सकता है।</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="mb-3">
            <Card.Header className="cursor-pointer p-0">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="14" >
                <h2 className="mb-0 h5 font-weight-bold">लकी ड्रॉ में ईनाम जीतने के पश्चात् इसे प्राप्त करने के लिए एक KAMAIE पेट्रन के तौर पर मुझे क्या करना होगा?</h2>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="14">
              <Card.Body>
                <p className="desc">ईनाम जीतने के पश्चात यह अपने आप आपके कस्टमर ऐप में ही मौजूद वॉलेट ऑप्शन में रिफ्लेक्ट करेगा जिस पर क्लिक करके आपको विथड्रावल रिक्वेस्ट डालनी है और अपने बैंक खाते का विवरण देना है। इस प्रक्रिया को पूरा करने के पश्चात उक्त राशि आपके बैंक खाते में ट्रांसफर कर दी जाएगी।</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="mb-3">
            <Card.Header className="cursor-pointer p-0">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="16" >
                <h2 className="mb-0 h5 font-weight-bold">क्या KAMAIE का कोई ग्राहक सहायता केंद्र है जहाँ विण्ड्स से सम्बंधित सभी समस्याओं का निराकरण हो सके?</h2>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="16">
              <Card.Body>
                <p className="desc">KAMAIE की तीनों ऐप में हमने कस्टमर सपोर्ट का ऑप्शन दिया है एवं साथ ही चैट की सुविधा भी दी है। इसके आलावा आप हमारे टोल फ्री नंबर पर कॉल करके सभी प्रकार की समस्याओं का समाधान ले सकते हैं एवं ई-मेल के माध्यम से भी हमारी सहायता ले सकते हैं। KAMAIE का ईमेल ऐड्रेस है: support@KAMAIE.co.in</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    )
  }

  return (
    <>
      <FAQMetaData />
      <div className="container font-proxima-nova">
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            <small>
              <Link to="/">
                <FormattedMessage id="breadcrumbs.home" defaultMessage="Home" />
              </Link>{" "}
              /{" "}
              <strong>
                <FormattedMessage
                  id="footer.faq"
                  defaultMessage="FAQ"
                  description="FAQ"
                  values={{ what: "react-intl" }}
                />
              </strong>
            </small>
          </div>
        </div>
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            {language === "hi" ? HindiContent() : EnglishContent()}
            {/* <Iframe
            source={`${process.env.REACT_APP_BASE_URL_FAQ
              }/${currentlySelectedLanguage()}`}
          ></Iframe> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default Faq;
