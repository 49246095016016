export function fetchingPrepaidCardPlans() {
  return {
    type: "FETCHING_PREPAID_CARD_PLANS",
  };
}

export function setPrepaidCardPlans(prepaidCardPlansData) {
  return {
    type: "FETCHING_PREPAID_CARD_PLANS_SUCCESS",
    prepaidCardPlansData,
  };
}

export function fetchingPrepaidCardFailure() {
  return {
    type: "FETCHING_PREPAID_CARD_PLANS_FAILURE",
  };
}

export function fetchingPrepaidCardTypes() {
  return {
    type: "FETCHING_PREPAID_CARD_TYPES",
  };
}

export function setPPICardTypes(prepaidCardTypesData) {
  return {
    type: "FETCHING_PREPAID_CARD_TYPES_SUCCESS",
    prepaidCardTypesData,
  };
}

export function fetchingPrepaidCardTypesFailure() {
  return {
    type: "FETCHING_PREPAID_CARD_TYPES_FAILURE",
  };
}

export function fetchingPpiDocumentTypes() {
  return {
    type: "FETCHING_DOCUMENT_TYPES",
  };
}

export function setPpiDocumentTypes(doctypes) {
  return {
    type: "FFETCHING_DOCUMENT_TYPES_SUCCESS",
    doctypes,
  };
}

export function fetchingPpiDocumentTypesFailure() {
  return {
    type: "FETCHING_DOCUMENT_TYPES_FAILURE",
  };
}

export function setUserName(username) {
  return {
    type: "SET_USERNAME",
    username,
  };
}

export function updateUserData(formData) {
  return {
    type: "PPI_USERDATA",
    formData
  };
}

export function setUserSelectedCardData(selectedCardData) {
  return {
    type: "SET_USER_SELECTED_CART_DATA",
    selectedCardData
  };
}

export function setKYCUniqueID(kycUniqueID) {
  return {
    type: "SET_KYC_UNIQUE_ID",
    kycUniqueID
  };
}

export function setSelectedDocumentData(selectedDocumentData) {
  return {
    type: "SET_SELECTED_DOCUMENT_DATA",
    selectedDocumentData
  };
}

export function setCardReferenceNumber(cardReferenceNumber) {
  return {
    type: "SET_CARD_RERFERENCE_NUMBER",
    cardReferenceNumber
  };
}

export function setCardNumber(cardNumber) {
  return {
    type: "SET_CARD_NUMBER",
    cardNumber
  };
}

export function setCardDetails(cardDetails) {
  return {
    type: "SET_CARD_DETAILS",
    cardDetails
  };
}

export function setCompleteKYCData(completeKYCData) {
  return {
    type: "SET_COMPLETE_KYC_DATA",
    completeKYCData
  };
}

export function setShippingAddress(shippingAddress) {
  return {
    type: "SET_SHIPPING_ADDRESS",
    shippingAddress
  };
}

export function setLoadBalance(loadBalance) {
  return {
    type: "SET_LOAD_BALANCE",
    loadBalance
  };
}

export function setShippingRedirectionData(shippingRedirectionData) {
  return {
    type: "SET_SHIPPING_REDIRECTION_DATA",
    shippingRedirectionData
  };
}

export function resetPrepaidCardData(){
  return{
    type: "RESET_PREPAID_CARD_DATA"
  }
}