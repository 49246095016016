import React, { Component } from "react";
import { Link } from "react-router-dom";
import couponImg from "../../../assets/coupon.svg";
import rewardsEmptyImg from "../../../assets/ic_cupon_emptyscreen.png"
import { FormattedMessage } from "react-intl";
import WrpSummary from "../components/WrpSummary";
import RedeemWrp from "../components/RedeemWrp";
import wrpSummaryClientX from '../../../utils/api/wrpSummaryClientX';
import { getIsKamaie, getIsMob, getQueryParam } from '../../../utils/helper';
import { connect } from "react-redux";
import monthlywrp from "../../../assets/ic_monthly_draw.svg"
import monthlycoupons from "../../../assets/ic_mega_draw.svg"

class Coupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active_coupons: null,
      total_points: null,
      pending_points: null,
      coupon_progression_percent: null,
      total_points_per_coupon: null,
      monthly_draw_eligible: null,
      monthly_draw_pending_wrps: null,
      monthly_draw_eligible_coupon: null,
      history: [],
      isWrpSummaryOpen: true,
      isRedeemWrpModelOpen: false,
      orderData: null,
      totalWrpBalance: null,
      load: true
    };
  }

  toggelRedeemWrp = (value) => {
    if (value) {
      wrpSummaryClientX.get(`/v1/confirmed-summary`).then(response => {
        this.setState({
          totalWrpBalance: response.data.total_wrp
        })
      })
    }
    this.setState({
      isRedeemWrpModelOpen: value,
      orderData: null
    });
  }

  toggleWrpSummaryModel = (value) => {
    this.setState({
      isWrpSummaryOpen: value,
    });
  };

  componentDidMount() {
    if (this.props.profile === null) {
      this.props.history.push('/login')
    }
    else {
      if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
        const element = document.querySelector("#top");
        element.scrollIntoView();
      }
      this.getWRPInfo()
    }
  }

  getWRPInfo = () => {
    wrpSummaryClientX.get("v1/info")
      .then((response) => {
        let data = response.data.data;
        wrpSummaryClientX.get("v1/coupon-history").then((response) => {
          this.setState({
            history: response.data.data,
          });
        });

        this.setState({
          active_coupons: data.active_coupons,
          total_points: data.total_points,
          pending_points: data.pending_points,
          coupon_progression_percent: data.coupon_progression_percent,
          total_points_per_coupon: data.total_points_per_coupon,
          monthly_draw_eligible: data.monthly_draw_eligible,
          monthly_draw_pending_wrps: data.monthly_draw_pending_wrps,
          monthly_draw_eligible_coupon: data.monthly_draw_eligible_coupon,
          load: false
        }, () => {
          if (getQueryParam(this.props.location.search, 'isRedeemWrpModelOpen') === 'true') {
            this.toggelRedeemWrp(true);
          }
        });
      });
  }

  claimRedeemOrder = (orderData) => {
    this.setState({
      orderData,
      isWrpSummaryOpen: false,
      isRedeemWrpModelOpen: true
    })
  }

  calculateRedeemableWrp = (props) => {
    return <strong> {this.state.active_coupons * this.state.total_points_per_coupon + this.state.total_points} KAMAIE Coins</strong>
    // return (this.state.active_coupons * this.state.total_points_per_coupon + this.state.total_points);
  }

  Shimmer = (props) => {
    return (
      <div className="container py-3 py-md-5">
        <div className="row">
          <div className="col-md-5">
            <div className="box shine w-100 mb-2" style={{ height: "40px" }}></div>
            <div className="box shine box-voucher-detail-logo"></div>
          </div>
          <div className="col-md-7 pt-4 pt-md-0">
            <div className="box shine w-100" style={{ height: "100px" }}></div>
            <div className="box shine w-100" style={{ height: "150px" }}></div>
            <div className="row">
              <div className="col-md-6 pt-4 pt-md-0">
                <div className="box shine w-100" style={{ height: "100px" }}></div>
              </div>
              <div className="col-md-6 pt-4 pt-md-0">
                <div className="box shine w-100" style={{ height: "100px" }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const isMob=getIsMob("isMob")
    const is_kamaie=getIsKamaie("is_kamaie")
    const {
      active_coupons,
      total_points,
      coupon_progression_percent,
      pending_points,
      isWrpSummaryOpen,
      monthly_draw_eligible,
      monthly_draw_pending_wrps,
      monthly_draw_eligible_coupon,
      isRedeemWrpModelOpen,
      history,
      orderData,
      totalWrpBalance
    } = this.state;
    if (this.state.load)
      return <this.Shimmer />
    else return (
      <div className="container" id="top" >
      <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
        {(isMob != 1 && is_kamaie!=1 &&
            <div className="col-12">
            <div className="row align-items-center">
              <div className="col-12">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>{" "}
                  /{" "}
                  <strong>
                    <FormattedMessage
                      id="home.wrp"
                      defaultMessage="WINDS Coins"
                    />
                  </strong>
                </small>
              </div>
            </div>
          </div>
        )}
      
      </div>
      {/* <span className="couponHeading">Coupons</span> */}
      <div className="row mb-3 mb-md-5">
        {/* <div className="col-12 col-md-5 coupon-wrapper mt-lg-5 mt-md-5 mt-0 flex-column">
          <div>
            <img src={couponImg} alt="coupon" title="coupon"/>
            {
              monthly_draw_eligible_coupon && monthly_draw_eligible_coupon?.toString().length < 4 ?
                <h3>{monthly_draw_eligible_coupon}</h3> :
                <h3 className="coupon-wrapper-small">{monthly_draw_eligible_coupon}</h3>
            }
          </div>
          {monthly_draw_eligible_coupon === 0 || monthly_draw_eligible_coupon === 1 ?
            <h5>
              <FormattedMessage
                id="coupon.current_months_coupon"
                defaultMessage="current month's coupon"
              />
            </h5> :
            <h5>
              <FormattedMessage
                id="coupon.current_months_coupons"
                defaultMessage="current month's coupons"
              />
            </h5>
          }
        </div> */}
        <div className="col-12 ">
        
          <div className="card next-draw-card mt-3">
            <div className="card-body">
            
              {/* <div className="progress">
                <div
                  className="progress-bar wrp-progressbar"
                  role="progressbar"
                  style={{
                    width: `${coupon_progression_percent}%`,
                  }}
                ></div>
              </div> */}
              {/* <p className="mt-2">
                {Math.round(pending_points)}{" "}
                <FormattedMessage
                  id="coupons.provisional-wrps-is-in-process"
                  defaultMessage="Provisional WRP is in process"
                />
              </p> */}
              {/* <span className="font-proxima-nova"
                style={{ color: "#1A94E8", cursor: "pointer", fontWeight: "600", fontSize: "16px" }}
                onClick={() => this.toggleWrpSummaryModel(true)}
              >
                <FormattedMessage id="coupons.view_summary" defaultMessage="View Summary" />
              </span> */}
              <Link to="/winds-coins-details" className="font-proxima-nova"
                style={{ color: "#1A94E8", cursor: "pointer", float: "right" }}>
                <FormattedMessage id="wrp.info" defaultMessage="KAMAIE Coins INFO" /> 
               </Link> 
              {isWrpSummaryOpen && (
                <WrpSummary
                  viewMoreRedeemedOrder={(orderData) => this.claimRedeemOrder(orderData)}
                  onDismiss={() => this.toggleWrpSummaryModel(false)}
                />
              )}
              {isRedeemWrpModelOpen && (
                <RedeemWrp wrpBalance={totalWrpBalance}
                  orderData={orderData}
                  onDismiss={() => this.toggelRedeemWrp(false)}
                />
              )}
            </div>
          </div>
          {/* <div className="d-lg-flex d-block">
            {/* <div className="monthly-wrp d-flex align-items-center px-3 py-1 mr-lg-1 mr-0">
              <img src={monthlywrp} width="80px" alt="wrp" title="wrp" />
              {monthly_draw_eligible === false ?
                <p>{monthly_draw_pending_wrps} <FormattedMessage
                  id="coupon.monthly_draw_eligible_wrp"
                  defaultMessage="more WRP required for entry into Mini Monthly Draw"
                /></p> :
                <p>
                  <FormattedMessage
                    id="coupon.you_have_qualified"
                    defaultMessage="You have qualified for Mini Monthly Draw"
                  />
                </p>
              }
            </div> */}
            {/* <div className="monthly-coupons d-flex align-items-center px-3 py-1 ml-lg-1 ml-0">
              <img src={monthlycoupons} width="115px" alt="coupons" title="coupons" />
              <h4>{active_coupons ? active_coupons : 0}</h4>
              {active_coupons === 0 || active_coupons === 1 ?
                <p>
                  <FormattedMessage
                    id="coupon.reserved_coupon"
                    defaultMessage="coupon reserved for Mega Bumper Draw"
                  />
                </p> :
                <p>
                  <FormattedMessage
                    id="coupon.reserved_coupons"
                    defaultMessage="coupons reserved for Mega Bumper Draw"
                  />
                </p>
              }
            </div> */}
          {/* </div> */} 

          <br />
{/* 
          {history.length === 0 ? (
            <div className="empty-state">
              <img src={rewardsEmptyImg} alt="empty rewards" title="empty rewards" width="40%" />
              <h5 className="mt-3"><FormattedMessage
                id="rewards.no-rewards"
                defaultMessage="
                      No rewards yet. It's only matter of time!"
              />
              </h5>
            </div>
          ) : (
            <div className="card mb-4">
              <div className="card-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" style={{ borderTop: "none" }}>
                        <FormattedMessage
                          id="coupons.month"
                          defaultMessage="Month"
                        />
                      </th>
                      <th scope="col" style={{ borderTop: "none" }}>
                        <FormattedMessage
                          id="coupons.coupon"
                          defaultMessage="Coupon"
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {history.map((item, index) => {
                      return (
                        <tr key={`history${index}`}>
                          <td>{item.month}</td>
                          <th scope="row">{item.coupon_count}</th>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div >
    );
  }
}
function mapStateToProps(state) {
  return {
    profile: state.global.profile,
  };
}

export default connect(mapStateToProps)(Coupon);
