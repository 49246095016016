import React, { Component } from "react";
import { Link } from "react-router-dom";
import profileClientX from "../../../utils/api/profileClientX";
import { FormattedMessage, injectIntl } from "react-intl";
import update from "immutability-helper";
import Modal from "react-bootstrap/Modal";
import classNames from "classnames";
import InlineLoader from "../../../components/InlineLoader";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { updateProfile } from "../../../actions";
import { ErrorToast } from "../../../components/ErrorToast";
import CombineErrorMessages from "../../../components/CombineErrorMessages";
import { onPushProfile } from "../../../utils/events";
import { ProgressBar } from "react-bootstrap";
import ic_edit from "../../../assets/profile/edit copy.png";
import ic_success from "../../../assets/profile/ic_success.svg";
import ic_mail from "../../../assets/profile/ic_mail.svg";
import ic_device from "../../../assets/profile/ic_device.svg";
import ic_name from "../../../assets/profile/ic_name.svg";
import ic_nickname from "../../../assets/profile/ic_nickname.svg";
import ic_city from "../../../assets/profile/ic_city.svg";
import ic_state from "../../../assets/profile/ic_state.svg";
import ic_marriage_status from "../../../assets/profile/ic_marriage_status.svg";
import ic_pincode from "../../../assets/profile/ic_pincode.svg";
import ic_gender from "../../../assets/profile/ic_gender.svg";
import ic_occupation from "../../../assets/profile/ic_occupation.svg";
import ic_calendar from "../../../assets/profile/ic_calendar.svg";
import { subDays } from "date-fns";
import WrpCreditModal from "../components/WrpCreditModal";
import { withRouter } from 'react-router-dom';

const otpRegex = RegExp("^[1-9][0-9]{5}$");
class ProfileDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: null,
      isModelOpen: false,
      isEmailModelOpen: false,
      isMobileModelOpen: false,
      isSuccessModalOpen: false,
      location: this.props.location,
      stateNames: [],
      occupationList: [],
      emailModel: {
        email: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false,
        },
        otp: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false,
        },
        isFormValid: false,
      },
      mobileModel: {
        mobile: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false,
        },
        otp: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false,
        },
        isFormValid: false,
      },
      isLoading: false,
      isMobileLoading: false,
      showOtpField: false,
      showEmailOtpField: false,
      EditEmailControl: false,
      EditMobileControl: false,
      editedProfileData: {
        name: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false,
        },
        nickname: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false,
        },
        city: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false,
        },
        state: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false,
        },
        pincode: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false,
        },
        life_status: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false,
        },

        gender: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false,
          options: [
            { id: "m", name: "Male" },
            { id: "f", name: "Female" },
          ],
        },
        dob: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false,
        },
        occupation: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false,
        },
        isFormValid: false,
      },
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const mailState = location?.state?.mailState;
    mailState &&  
    setTimeout(() => {
      this.setState({
        isEmailModelOpen: true,
      });
    }, 800);
    if (this.props.profile === null) {
      ErrorToast("Unauthenticated", CombineErrorMessages);
      this.props.history.push('/login')
    } else {
      this.getAllStateList();
      this.getAllOccupationList();
    }
  }

  getAllStateList = () => {
    profileClientX
      .get(`v1/states`)
      .then((response) => {
        let responseData = response.data.data;
        this.setState({
          stateNames: responseData,
        });
      })
      .catch((error) => { });
  };
  getAllOccupationList = () => {
    profileClientX
      .get(`v2/occupations`)
      .then((response) => {
        let responseData = response.data.data;
        this.setState({
          occupationList: responseData,
        });
      })
      .catch((error) => { });
  };
  verifyOtpForEmail = (email, otp) => {
    this.setState({ isLoading: true });
    profileClientX
      .post(`v1/email/change`, { email: email, otp: otp })
      .then((response) => {
        if (response.data.success) {
          this.props.setProfile(response.data.data);
          this.setState({
            isLoading: false,
            isEmailModelOpen: false,
            showEmailOtpField: false,
            EditEmailControl: false
          });
          response.data.data?.wrp_credited_status_for_basic_profile ?
            this.setState({ isSuccessModalOpen: true }) :
            toast.success("Your mail ID has been successfully updated!");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };
  verifyOtp = (mobileNumber, otp) => {
    this.setState({ isMpbileLoading: true });
    profileClientX
      .post(`v1/mobile/change`, { mobile: mobileNumber, otp: otp })
      .then((response) => {
        if (response.data.success) {
          this.setState({ isMpbileLoading: false });
          toast.success("Your mobile number has been successfully updated!");
          this.props.setProfile(response.data.data);
          this.setState({
            isMpbileLoading: false,
            isMobileModelOpen: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ isMpbileLoading: false });
      });
  };

  verifyMobile = (mobileNumber) => {
    this.setState({ isMobileLoading: true });
    profileClientX
      .post(`v1/mobile-verification-otp`, { mobile: mobileNumber })
      .then((response) => {
        if (response.data.success) {
          toast.success(
            <FormattedMessage
              id="profile.otp-sent"
              defaultMessage="OTP Sent"
            />,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
        this.setState({
          showOtpField: true,
          isMobileLoading: false,
          EditMobileControl: true,
        });
      })
      .catch((error) => {
        toast.error("Something went wrong!");
        this.setState({ isMobileLoading: false });
      });
  };

  verifyEmail = (email) => {
    this.setState({ isLoading: true });
    profileClientX
      .post(`v1/email-verification-otp`, { email: email })
      .then((response) => {
        if (response.data.success) {
          toast.success(
            <FormattedMessage
              id="profile.otp-sent"
              defaultMessage="OTP Sent"
            />,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
        this.setState({
          showEmailOtpField: true,
          isLoading: false,
          EditEmailControl: true,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  EditProfileFormDialog = (props) => {
    const {
      name,
      nickname,
      city,
      state,
      pincode,
      life_status,
      gender,
      dob,
      occupation,
      isFormValid,
    } = {
      ...props.editedProfileData,
    };

    return (
      <React.Fragment>
        <Modal
          show={props.isModelOpen}
          onHide={props.handleClose}
          className="profile-edit-modal"
          size="lg"
        >
          <Modal.Header closeButton className="profile-info-box-head border-0">
            <Modal.Title>
              {" "}
              <FormattedMessage
                id="profile.edit-profile"
                defaultMessage="Edit Profile Details"
              />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <form noValidate>
              <div className="d-flex justify-content-center">
                <div className="col-2 col-md-1 p-0">
                  <img src={ic_name} className="mt-2" alt="name" title="name" />
                </div>
                <div className="form-group col-10 pl-0">
                  <label className="m-0">
                    <FormattedMessage
                      id="profile.full-name"
                      defaultMessage="Full Name"
                    />
                  </label>
                  <input
                    type="text"
                    name="name"
                    autoComplete="off"
                    onChange={props.inputChangeHandler}
                    value={name.value}
                    className={classNames(
                      "form-control edit-data-input shadow-none font-weight-bold",
                      {
                        "is-valid": name.isValid,
                        "is-invalid": name.isPristine && !name.isValid,
                      }
                    )}
                  />
                  <small className="form-error">{this.state.editedProfileData?.name.error}</small>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <div className="col-2 col-md-1 p-0">
                  <img src={ic_nickname} className="mt-2" alt="nickname" title="nickname" />
                </div>
                <div className="form-group col-10 pl-0">
                  <label className="m-0">Nickname</label>
                  <input
                    type="text"
                    name="nickname"
                    autoComplete="off"
                    onChange={props.inputChangeHandler}
                    value={nickname.value}
                    className={classNames(
                      "form-control edit-data-input shadow-none font-weight-bold",
                      {
                        "is-valid": nickname.isValid,
                        "is-invalid": nickname.isPristine && !nickname.isValid,
                      }
                    )}
                  />
                  {
                    !this.state.editedProfileData.nickname.isValid &&
                    <small className="form-error">{this.state.editedProfileData?.nickname.error}</small>
                  }
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <div className="col-2 col-md-1 p-0">
                  <img src={ic_calendar} className="mt-2" alt="calender" title="calender" />
                </div>
                <div className="form-group col-10 pl-0">
                  <label className="m-0">
                    <FormattedMessage id="profile.dob" defaultMessage="dob" />
                  </label>
                  <input
                    type="date"
                    name="dob"
                    max={subDays(new Date(), 1).toISOString().substring(0, 10)}
                    autoComplete="off"
                    onChange={props.inputChangeHandler}
                    value={dob.value}
                    className={classNames(
                      "form-control edit-data-input shadow-none font-weight-bold",
                      {
                        "is-valid": dob.isValid,
                        "is-invalid": dob.isPristine && !dob.isValid,
                      }
                    )}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <div className="col-2 col-md-1 p-0">
                  <img src={ic_city} className="mt-2" alt="city" title="city" />
                </div>
                <div className="form-group col-10 pl-0">
                  <label>City</label>
                  <input
                    type="text"
                    name="city"
                    autoComplete="off"
                    onChange={props.inputChangeHandler}
                    value={city.value}
                    className={classNames(
                      "form-control edit-data-input shadow-none font-weight-bold",
                      {
                        "is-valid": city.isValid,
                        "is-invalid": city.isPristine && !city.isValid,
                      }
                    )}
                  />
                  <small className="form-error">{this.state.editedProfileData?.city.error}</small>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <div className="col-2 col-md-1 p-0">
                  <img src={ic_state} className="mt-2" alt="state" title="state" />
                </div>
                <div className="form-group col-10 pl-0">
                  <label>State</label>
                  <select
                    className="form-control edit-data-input shadow-none font-weight-bold"
                    name="state"
                    autoComplete="off"
                    onChange={props.inputChangeHandler}
                    value={this.state.editedProfileData.state.value}
                  >
                    {!state.value && (
                      <option value="">
                        {props.intl.formatMessage({ id: "general.choose" })}
                      </option>
                    )}

                    {this.state.stateNames?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>

                </div>
              </div>
              <div className="d-flex justify-content-center">
                <div className="col-2 col-md-1 p-0">
                  <img src={ic_pincode} className="mt-2" alt="pin code" title="pin code" />
                </div>
                <div className="form-group col-10 pl-0">
                  <label>Pin Code</label>
                  <input
                    type="text"
                    name="pincode"
                    autoComplete="off"
                    onChange={props.inputChangeHandler}
                    value={pincode.value}
                    className={classNames(
                      "form-control edit-data-input shadow-none font-weight-bold",
                      {
                        "is-valid": pincode.isValid,
                        "is-invalid": pincode.isPristine && !pincode.isValid,
                      }
                    )}
                  />
                  <small className="form-error">{this.state.editedProfileData?.pincode.error}</small>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <div className="col-2 col-md-1 p-0">
                  <img src={ic_gender} className="mt-2" alt="gender" title="gender" />
                </div>
                <div className="form-group col-10 pl-0">
                  <label>
                    <FormattedMessage
                      id="profile.gender"
                      defaultMessage="Select Gender"
                    />
                  </label>
                  <select
                    className="form-control edit-data-input shadow-none font-weight-bold"
                    name="gender"
                    autoComplete="off"
                    onChange={props.inputChangeHandler}
                    value={gender.value}
                  >
                    {!gender.value && (
                      <option value="">
                        {props.intl.formatMessage({ id: "general.choose" })}
                      </option>
                    )}

                    {gender.options.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <div className="col-2 col-md-1 p-0">
                  <img src={ic_marriage_status} className="mt-2" alt="marriage status" title="marriage status" />
                </div>
                <div className="form-group col-10 pl-0">
                  <label>Marriage Status</label>
                  <select
                    className="form-control edit-data-input shadow-none font-weight-bold"
                    name="life_status"
                    autoComplete="off"
                    onChange={props.inputChangeHandler}
                    value={this.state.editedProfileData.life_status.value}
                  >
                    {!life_status.value && (
                      <option value="">
                        {props.intl.formatMessage({ id: "general.choose" })}
                      </option>
                    )}

                    {this.props.profile?.life_status_possible_value?.map(
                      (item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <div className="col-2 col-md-1 p-0">
                  <img src={ic_occupation} className="mt-2" alt="Occupation" title="Occupation" />
                </div>
                <div className="form-group col-10 pl-0">
                  <label>Occupation</label>
                  <select
                    className="form-control edit-data-input shadow-none font-weight-bold"
                    name="occupation"
                    autoComplete="off"
                    onChange={props.inputChangeHandler}
                    value={this.state.editedProfileData?.occupation?.value}
                  >
                    {!occupation.value && (
                      <option value="">
                        {props.intl.formatMessage({ id: "general.choose" })}
                      </option>
                    )}

                    {this.state.occupationList?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>

                </div>
              </div>
            </form>
          </Modal.Body>
          {props.isLoading && props.isModelOpen && <InlineLoader />}
          <div className="d-flex justify-content-center p-3">
            <button
              className={`animated-btn w-auto px-5 ${!isFormValid || this.state.editedProfileData.dob.error !== "" ? "animated-btn-disabled" : ""}`}
              onClick={props.submitHandler}
              disabled={!isFormValid || this.state.editedProfileData.dob.error !== ""}
            >
              {/* <FormattedMessage
                id="profile.save-profile"
                defaultMessage="Save Profile"
              /> */}
              Update
            </button>
          </div>
        </Modal>
      </React.Fragment>
    );
  };

  EditMobileFormDialog = (props) => {
    const { otp, mobile, isFormValid } = {
      ...props.mobileModel,
    };

    return (
      <React.Fragment>
        <Modal
          show={props.isMobileModelOpen}
          onHide={props.closeMobileModelHandler}
          className="profile-edit-modal"
          size="lg"
        >
          <Modal.Header closeButton className="profile-info-box-head border-0">
            <Modal.Title>
              <p className="m-0">Edit Mobile Number</p>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="profile-info-box ">
            <form noValidate className="p-2">
              <div className="d-flex justify-content-center">
                <div className="col-2 col-md-1 p-0">
                  <img src={ic_device} className="mt-2" alt="Mobile Number" title="Mobile Number"/>
                </div>
                <div className="form-group  m-0 col-10 col-md-11 pl-0">
                  <label className="m-0">
                    <FormattedMessage
                      id="profile.mobile"
                      defaultMessage="Mobile Number"
                    />
                  </label>
                  <input
                    type="text"
                    name="mobile"
                    autoComplete="off"
                    onChange={props.mobileDialoginputChangeHandler}
                    value={mobile.value}
                    className={classNames(
                      "form-control edit-data-input shadow-none",
                      {
                        "is-valid": mobile.isValid,
                        "is-invalid": mobile.isPristine && !mobile.isValid,
                      }
                    )}
                  />
                  <p className="m-0">
                    {props.showOtpField && (
                      <div className="form-group mt-3 pl-0">
                        <label>
                          <FormattedMessage
                            id="profile.otp"
                            defaultMessage="OTP"
                          />
                        </label>
                        <input
                          type="text"
                          name="otp"
                          autoComplete="off"
                          onChange={props.mobileDialoginputChangeHandler}
                          value={otp.value}
                          className={classNames(
                            "form-control edit-data-input shadow-none",
                            {
                              "is-valid": otp.isValid,
                              "is-invalid": otp.isPristine && !otp.isValid,
                            }
                          )}
                        />
                      </div>
                    )}
                    {this.state.isMobileLoading && <InlineLoader />}
                  </p>
                </div>
              </div>
            </form>
            <div className="d-flex justify-content-end p-2">
              {!this.state.EditMobileControl && (
                <button
                  className={
                    mobile.isPristine && mobile.isValid
                      ? "animated-btn w-auto px-5"
                      : "animated-btn-disabled w-auto px-5 disabled mt-2"
                  }
                  onClick={() => props.verifyMobile(mobile.value)}
                >
                  Send OTP
                </button>
              )}

              {this.state.EditMobileControl && (
                <button
                  className={
                    isFormValid
                      ? "animated-btn w-auto px-5"
                      : "animated-btn-disabled w-auto px-5"
                  }
                  onClick={() => props.verifyOtp(mobile.value, otp.value)}
                  disabled={!isFormValid}
                >
                  <FormattedMessage
                    id="rewards.verify-otp"
                    defaultMessage="VERIFY OTP"
                  />
                </button>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  };

  EditEmailFormDialog = (props) => {
    const { otp, email, isFormValid } = {
      ...props.emailModel,
    };
    return (
      <React.Fragment>
        <Modal
          show={props.isEmailModelOpen}
          onHide={props.closeEmailModelHandler}
          className="profile-edit-modal"
          size="lg"
        >
          <Modal.Header closeButton className="profile-info-box-head border-0 ">
            <Modal.Title>
              <p className="m-0">Edit Mailing Info</p>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="profile-info-box ">
            <form noValidate className="p-2">
              <div className="d-flex justify-content-center">
                <div className="col-2 col-md-1 p-0">
                  <img src={ic_mail} className="mt-2" alt="email" title="email" />
                </div>
                <div className="form-group m-0 col-10 col-md-11 pl-0">
                  <label className="m-0">
                    <FormattedMessage
                      id="profile.email"
                      defaultMessage="Email"
                    />
                  </label>
                  <input
                    type="text"
                    name="email"
                    autoComplete="off"
                    onChange={props.emailDialoginputChangeHandler}
                    value={email.value}
                    className={classNames(
                      "form-control edit-data-input shadow-none",
                      {
                        "is-valid": email.isValid,
                        "is-invalid": email.isPristine && !email.isValid,
                      }
                    )}
                  />
                  <p className="m-0">
                    {/* <small className="text-danger">
                      Please enter valid e-mail address.
                    </small> */}
                    {props.showEmailOtpField && (
                      <div className="form-group mt-3 pl-0">
                        <label>
                          <FormattedMessage
                            id="profile.otp"
                            defaultMessage="OTP"
                          />
                        </label>
                        <input
                          type="text"
                          name="otp"
                          autoComplete="off"
                          onChange={props.emailDialoginputChangeHandler}
                          value={otp.value}
                          className={classNames(
                            "form-control edit-data-input shadow-none",
                            {
                              "is-valid": otp.isValid,
                              "is-invalid": otp.isPristine && !otp.isValid,
                            }
                          )}
                        />
                      </div>
                    )}
                    {props.isLoading && props.isEmailModelOpen && (
                      <InlineLoader />
                    )}
                  </p>
                </div>
              </div>
            </form>
            <div className="d-flex justify-content-end p-2">
              {!this.state.EditEmailControl && (
                <button
                  className={
                    email.isPristine && email.isValid
                      ? "animated-btn w-auto px-5"
                      : "animated-btn-disabled w-auto px-5 disabled mt-2"
                  }
                  onClick={() => props.verifyEmail(email.value)}
                >
                  Send OTP
                </button>
              )}

              {this.state.EditEmailControl && (
                <button
                  className={
                    isFormValid
                      ? "animated-btn w-auto px-5"
                      : "animated-btn-disabled w-auto px-5"
                  }
                  onClick={() =>
                    props.verifyOtpForEmail(email.value, otp.value)
                  }
                  disabled={!isFormValid}
                >
                  <FormattedMessage
                    id="rewards.verify-otp"
                    defaultMessage="VERIFY OTP"
                  />
                </button>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  };



  editEmail = () => {
    const { email } = { ...this.props.profile };
    const newData = update(this.state, {
      isEmailModelOpen: { $set: true },
      showOtpField: { $set: false },
      emailModel: {
        isFormValid: { $set: false },
        email: {
          value: { $set: email || "" },
          isValid: { $set: this.props.profile?.email ? true : false },
        },
      },
    });
    this.setState(newData);
  };

  editMobile = () => {
    const { mobile } = { ...this.props.profile };
    const newData = update(this.state, {
      isMobileModelOpen: { $set: true },
      showOtpField: { $set: false },
      mobileModel: {
        isFormValid: { $set: false },
        mobile: {
          value: { $set: mobile || "" },
          isValid: { $set: mobile ? true : false },
          isPristine: { $set: false },
        },
      },
    });
    this.setState(newData);
  };

  editProfile = () => {
    const {
      name,
      nickname,
      city,
      state_id,
      pincode,
      life_status,
      occupation_id,
      gender,
      dob,
    } = { ...this.props.profile };
    const newData = update(this.state, {
      isModelOpen: { $set: true },
      showOtpField: { $set: false },
      editedProfileData: {
        isFormValid: { $set: true },
        name: {
          value: { $set: name || "" },
          isValid: { $set: name ? true : false },
        },
        nickname: {
          value: { $set: nickname || "" },
          isValid: { $set: nickname.length > 2 ? true : false },
        },
        city: {
          value: { $set: city || "" },
          isValid: { $set: city.length > 2 ? true : false },
        },
        state: {
          value: { $set: state_id || "" },
          isValid: { $set: state_id ? true : false },
        },
        gender: {
          value: { $set: gender ? gender.charAt(0).toLowerCase() : "" },
          isValid: { $set: gender ? true : false },
        },
        dob: {
          value: { $set: dob || "" },
          isValid: { $set: dob ? true : false },
        },
        pincode: {
          value: { $set: pincode || "" },
          isValid: { $set: pincode.length > 5 ? true : false },
        },
        life_status: {
          value: { $set: life_status || "" },
          isValid: { $set: life_status ? true : false },
        },
        occupation: {
          value: { $set: occupation_id || "" },
          isValid: { $set: occupation_id ? true : false },
        },
      },
    });
    this.setState(newData);
  };

  handleClose = () => {
    this.setState({ ...this.state, isModelOpen: false });
  };

  closeMobileModelHandler = () => {
    this.setState({
      ...this.state,
      isMobileModelOpen: false,
      EditMobileControl: false,
    });
  };

  closeEmailModelHandler = () => {
    this.setState({
      ...this.state,
      isEmailModelOpen: false,
      EditEmailControl: false,
      showEmailOtpField: false,
    });
  };

  checkMobileDialogValidity(fieldName, value) {
    const { mobileModel } = { ...this.state };
    switch (fieldName) {
      case "mobile":
        mobileModel.mobile.isValid = null !== value.match(/^[6-9]\d{9}$/);
        mobileModel.mobile.error = mobileModel.mobile.isValid
          ? ""
          : "Mobile is invalid";
        break;
      case "otp":
        mobileModel.otp.isValid = null !== value.match(otpRegex);
        mobileModel.otp.error = mobileModel.otp.isValid ? "" : "OTP is invalid";
        break;
      default:
        break;
    }

    const newData = update(this.state, {
      mobileModel: {
        isFormValid: { $set: this.areMobileDilogFieldsValid() },
      },
    });

    this.setState(newData);
  }

  checkEmailDialogValidity(fieldName, value) {
    const { emailModel } = { ...this.state };
    switch (fieldName) {
      case "email":
        emailModel.email.isValid =
          null !== value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        emailModel.email.error = emailModel.email.isValid
          ? ""
          : "email is invalid";
        break;
      case "otp":
        emailModel.otp.isValid = null !== value.match(otpRegex);
        emailModel.otp.error = emailModel.otp.isValid ? "" : "OTP is invalid";
        break;
      default:
        break;
    }

    const newData = update(this.state, {
      emailModel: {
        isFormValid: { $set: this.areEmailDilogFieldsValid() },
      },
    });

    this.setState(newData);
  }

  areMobileDilogFieldsValid() {
    const { mobile, otp } = this.state.mobileModel;
    return mobile.value && mobile.isValid && otp.value && otp.isValid;
  }
  areEmailDilogFieldsValid() {
    const { email, otp } = this.state.emailModel;
    return email.value && email.isValid && otp.value && otp.isValid;
  }
  checkValidity(fieldName, value) {
    const { editedProfileData } = { ...this.state };

    switch (fieldName) {
      case "name":
        editedProfileData.name.isValid = null !== value.match(/^([a-zA-Z0-9 ]){3,40}$/);
        editedProfileData.name.error = editedProfileData.name.isValid
          ? ""
          : "Input valid name";
        break;
      case "nickname":
        editedProfileData.nickname.isValid = null !== value.match(/^([a-zA-Z0-9 ]){3,40}$/);
        editedProfileData.nickname.error = editedProfileData.nickname.isValid
          ? ""
          : "Input valid nickname";
        break;
      case "city":
        editedProfileData.city.isValid = null !== value.match(/^([a-zA-Z0-9 ]){3,40}$/);
        editedProfileData.city.error = editedProfileData.city.isValid
          ? ""
          : "Input valid city";
        break;
      case "state":
        editedProfileData.state.isValid = value.trim().length > 0;
        editedProfileData.state.error = editedProfileData.state.isValid
          ? ""
          : "State is required";
        break;
      case "pincode":
        editedProfileData.pincode.isValid =
          null !== value.match(/^[0-9]\d{5}$/);
        editedProfileData.pincode.error = editedProfileData.pincode.isValid
          ? ""
          : "Input valid pincode";
        break;
      case "life_status":
        editedProfileData.life_status.isValid = value.trim().length > 0;
        editedProfileData.life_status.error = editedProfileData.life_status
          .isValid
          ? ""
          : "Life status is required";
        break;
      case "gender":
        editedProfileData.gender.isValid = value.trim().length > 0;
        editedProfileData.gender.error = editedProfileData.gender.isValid
          ? ""
          : "Gender is invalid";
        break;

      case "occupation":
        editedProfileData.occupation.isValid = value.trim().length > 0;
        editedProfileData.occupation.error = editedProfileData.occupation
          .isValid
          ? ""
          : "Occupation is invalid";
        break;
      case "dob":
        editedProfileData.dob.isValid =
          null !== value.match(/^\d{4}-\d{2}-\d{2}$/);
        editedProfileData.dob.error = editedProfileData.dob.isValid
          ? ""
          : "dob is invalid";
        break;

      default:
        break;
    }

    const newData = update(this.state, {
      editedProfileData: {
        isFormValid: { $set: this.areAllFieldsValid() },
      },
    });

    this.setState(newData);
  }

  areAllFieldsValid() {
    const {
      name,
      state,
    } = this.state.editedProfileData;
    return [
      name,
      state,
    ].every((x) => x.value && x.isValid);
  }

  inputChangeHandler = (e) => {
    const { name, value } = e.target;
    const newData = update(this.state, {
      editedProfileData: {
        [name]: {
          value: { $set: value },
          isPristine: { $set: true },
        },
      },
    });

    // this.setState(newData, () => {
    //   this.checkValidity(name, value);
    // });
    this.setState(newData, () => {
      this.checkValidity(name, value);
    });
  };

  mobileDialoginputChangeHandler = (e) => {
    const { name, value } = e.target;
    const newData = update(this.state, {
      mobileModel: {
        [name]: {
          value: { $set: value },
          isPristine: { $set: true },
        },
      },
    });

    this.setState(newData, () => {
      this.checkMobileDialogValidity(name, value);
    });
  };

  emailDialoginputChangeHandler = (e) => {
    const { name, value } = e.target;
    const newData = update(this.state, {
      emailModel: {
        [name]: {
          value: { $set: value },
          isPristine: { $set: true },
        },
      },
    });

    this.setState(newData, () => {
      this.checkEmailDialogValidity(name, value);
    });
  };

  submitHandler = () => {
    this.setState({ isLoading: true });
    const {
      name,
      nickname,
      city,
      state,
      pincode,
      life_status,
      occupation,
      gender,
      dob,
    } = this.state.editedProfileData;
    const formData = {
      name: name.value,
      state_id: state.value,
    };

    if (pincode.value && pincode.value !== "NA") formData[`pincode`] = pincode.value
    if (occupation.value && occupation.value !== "NA") formData[`occupation_id`] = occupation.value
    if (life_status.value && life_status.value !== "NA") formData[`life_status`] = life_status.value
    if (city.value && city.value !== "NA") formData[`city`] = city.value
    if (nickname.value && nickname.value !== "NA") formData[`nickname`] = nickname.value
    if (dob.value && dob.value !== "NA") formData[`dob`] = dob.value.split("-").reverse().join("-")
    if (gender.value && gender.value !== "NA") formData[`gender`] = gender.value

    profileClientX
      .put(`v1/profile`, formData)
      .then((response) => {
        this.props.setProfile(response.data.data);
        onPushProfile(response.data.data, this.state.location);
        this.setState({
          isLoading: false,
          isModelOpen: false,
        });
        response.data.data?.wrp_credited_status_for_basic_profile ?
          this.setState({ isSuccessModalOpen: true }) :
          toast.success("Personal Info Updated")
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.error("Something went wrong!");
      });
  };

  render() {
    
    return (
      <React.Fragment>
        <div className="container">
          {this.state.isSuccessModalOpen && this.props?.profile?.basic_profile_completion_wrp !== 0 && <WrpCreditModal desc="For completing your Profile" wrp={this.props.profile?.basic_profile_completion_wrp} />}
          <div className="row mb-md-3 mt-4 d-md-none">
            <div className="col-12 m-auto font-proxima-nova">
              <small>
                <Link to="/">
                  <FormattedMessage
                    id="breadcrumbs.home"
                    defaultMessage="Home"
                  />
                </Link>
              </small>{" "}
              /{" "}
              <small>
                <Link to="/profile">
                  <FormattedMessage
                    id="profile.profile"
                    defaultMessage="Profile"
                  />
                </Link>
              </small>{" "}
              /{" "}
              <small>
                <strong>
                  <FormattedMessage
                    id="profile.profile-details"
                    defaultMessage="Profile Details"
                  />
                </strong>
              </small>
            </div>
          </div>
          <div className="px-3 pb-3 pt-0">
            <div>
              <h5 className="font-proxima-nova-bold mb-3">Profile Info</h5>
              {
                this.props.profile?.basic_profile_progress_percent !== 100 ?
                  (
                    <>
                      <p className=" m-0 d-flex justify-content-between profile-text-blue">
                        {
                          this.props.profile?.basic_profile_completion_wrp === 0 ?
                            <span>Complete your profile</span> :
                            <span>Complete your profile and win {this.props.profile?.basic_profile_completion_wrp} KAMAIE Coins</span>
                        }
                        <span className="p-0 m-0">
                          <strong>
                            {this.props.profile?.basic_profile_progress_percent}%
                          </strong>
                        </span>
                      </p>
                      <ProgressBar
                        now={this.props.profile?.basic_profile_progress_percent}
                        className="profile-progress-bar my-2 mb-3"
                        variant="progress-bar-style"
                      />
                    </>) :
                  <p className="completed-profile">Your profile is completed!</p>
              }
              <div className="profile-info-box-head d-flex justify-content-between">
                <span className="font-weight-bold">Personal Info</span>
                <span className="cursor-pointer">
                  <img
                    src={ic_edit}
                    alt="edit-icon"
                    title="edit-icon"
                    onClick={this.editProfile}
                  />
                  <this.EditProfileFormDialog
                    {...this.state}
                    {...this.props}
                    inputChangeHandler={this.inputChangeHandler}
                    handleClose={this.handleClose}
                    submitHandler={this.submitHandler}
                  />
                </span>
              </div>
              <div className="profile-info-box mb-3">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item p-2">
                    <p className="m-0">
                      <small>Full Name</small>
                    </p>
                    <span className="font-weight-bold">
                      {this.props.profile?.name}
                    </span>
                  </li>
                  <li className="list-group-item p-2">
                    <p className="m-0">
                      <small>Nickname</small>
                    </p>

                    {this.props.profile?.nickname !== null && this.props.profile?.nickname !== "NA" ? (
                      <span className="font-weight-bold">
                        {" "}
                        {this.props.profile?.nickname}
                      </span>
                    ) : (
                      <span>Not Provided</span>
                    )}
                  </li>
                  <li className="list-group-item p-2">
                    <p className="m-0">
                      <small>Date of Birth</small>
                    </p>
                    {this.props.profile?.dob !== null && this.props.profile?.dob !== "NA" ? (
                      <span className="font-weight-bold">
                        {" "}
                        {this.props.profile?.dob}
                      </span>
                    ) : (
                      <span>Not Provided</span>
                    )}
                  </li>
                  <li className="list-group-item p-2">
                    <p className="m-0">
                      <small>City</small>
                    </p>
                    {this.props.profile?.city !== null && this.props.profile?.city !== "NA" ? (
                      <span className="font-weight-bold">
                        {" "}
                        {this.props.profile?.city}
                      </span>
                    ) : (
                      <span>Not Provided</span>
                    )}
                  </li>
                  <li className="list-group-item p-2">
                    <p className="m-0">
                      <small>State</small>
                    </p>
                    {this.props.profile?.state_name !== null && this.props.profile?.state_name !== "NA" ? (
                      <span className="font-weight-bold">
                        {" "}
                        {this.props.profile?.state_name}
                      </span>
                    ) : (
                      <span>Not Provided</span>
                    )}
                  </li>
                  <li className="list-group-item p-2">
                    <p className="m-0">
                      <small>Pin Code</small>
                    </p>
                    {this.props.profile?.pincode !== null && this.props.profile?.pincode !== "NA" ? (
                      <span className="font-weight-bold">
                        {" "}
                        {this.props.profile?.pincode}
                      </span>
                    ) : (
                      <span>Not Provided</span>
                    )}
                  </li>
                  <li className="list-group-item p-2">
                    <p className="m-0">
                      <small>Gender</small>
                    </p>
                    {this.props.profile?.gender !== null && this.props.profile?.gender !== "NA" ? (
                      <span className="font-weight-bold">
                        {" "}
                        {this.props.profile?.gender}
                      </span>
                    ) : (
                      <span>Not Provided</span>
                    )}
                  </li>
                  <li className="list-group-item p-2">
                    <p className="m-0">
                      <small>Marriage Status</small>
                    </p>
                    {this.props.profile?.life_status !== null ? (
                      <span className="font-weight-bold">
                        {" "}
                        {this.props.profile?.life_status}
                      </span>
                    ) : (
                      <span>Not Provided</span>
                    )}
                  </li>
                  <li className="list-group-item p-2">
                    <p className="m-0">
                      <small>Occupation</small>
                    </p>
                    {this.props.profile?.occupation_name !== null && this.props.profile?.occupation_name !== "NA" ? (
                      <span className="font-weight-bold">
                        {" "}
                        {this.props.profile?.occupation_name}
                      </span>
                    ) : (
                      <span>Not Provided</span>
                    )}
                  </li>
                </ul>
              </div>
              <div className="profile-info-box-head d-flex justify-content-between">
                <span className="font-weight-bold">Contact Info</span>
                {/* <span className="cursor-pointer">
                  <img
                    src={ic_edit}
                    alt="edit-icon"
                    onClick={this.editMobile}
                  />

                  <this.EditMobileFormDialog
                    {...this.state}
                    {...this.props}
                    mobileDialoginputChangeHandler={
                      this.mobileDialoginputChangeHandler
                    }
                    verifyMobile={(mobileNumber) =>
                      this.verifyMobile(mobileNumber)
                    }
                    verifyOtp={(mobileNumber, otp) =>
                      this.verifyOtp(mobileNumber, otp)
                    }
                    closeMobileModelHandler={this.closeMobileModelHandler}
                  />
                </span> */}
              </div>
              <div className="profile-info-box mb-3">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item p-1 d-flex justify-content-between align-items-center">
                    <span>
                      <p className="m-0">
                        <small>Mobile</small>
                      </p>
                      <span className="font-weight-bold">
                        {this.props.profile?.mobile}
                      </span>
                    </span>

                    {this.props.profile && this.props.profile?.mobile_verified && (
                      <span>
                        <img src={ic_success} alt="success" title="success" />
                      </span>
                    )}
                  </li>
                </ul>
              </div>
              <div className="profile-info-box-head d-flex justify-content-between">
                <span className="font-weight-bold">Mailing Info</span>
                <span className="cursor-pointer">
                  {
                    !this.props.profile?.email_verified &&
                    <img src={ic_edit} alt="edit-icon" title="edit-icon" onClick={this.editEmail} />
                  }
                  <this.EditEmailFormDialog
                    {...this.state}
                    {...this.props}
                    emailDialoginputChangeHandler={
                      this.emailDialoginputChangeHandler
                    }
                    verifyEmail={(email) => this.verifyEmail(email)}
                    verifyOtpForEmail={(email, otp) =>
                      this.verifyOtpForEmail(email, otp)
                    }
                    closeEmailModelHandler={this.closeEmailModelHandler}
                  />
                </span>
              </div>
              <div className="profile-info-box">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item p-1 d-flex justify-content-between align-items-center">
                    <span>
                      <p className="m-0">
                        <small>Email</small>
                      </p>
                      {this.props.profile?.email ? (
                        <span className="font-weight-bold">
                          {" "}
                          {this.props.profile?.email}
                        </span>
                      ) : (
                        <span>Not Provided</span>
                      )}
                    </span>
                    {this.props.profile && this.props.profile?.email_verified && (
                      <span>
                        <img src={ic_success} alt="success" title="success"/>
                      </span>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.global.profile,
    location: state.location.formatted_location,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setProfile: (responseData) => {
      dispatch(updateProfile(responseData));
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(ProfileDetails)));
