import React, { Component } from "react";
import { Link } from "react-router-dom";
import customerAffiliateClientX from "../../../utils/api/customerAffiliateClientX";
import ContentLoader from "react-content-loader";
import { FormattedMessage } from "react-intl";
import bookmarkActive from "../../../assets/bookmark-active.svg";
import bookmarkInactive from "../../../assets/bookmark-inactive.svg";
import affiliateBanner from "../../../assets/affliate-banner-snippet.jpg";
import billUploadIcon from "../../../assets/bill-upload.svg";
import coffeeMugIcon from "../../../assets/coffee-mug.svg";
import ErrorModal from "../../../components/ErrorModal";
import { onViewedProduct, onVisitedAffiliateSite } from "../../../utils/events";
import WrpBenefitModel from "../../../components/WrpBenefitModel";
import aboutBlue from "../../../assets/about-blue.svg";
import LoginDialog from "../../../components/LoginDialog";
import { connect } from "react-redux";
import HelmetMetaData from "../../../components/HelmetMetaData";
import { readCookie } from "../../../utils/helper";
import { getIsMob, getIsKamaie } from "../../../utils/helper";

class ShoppingSiteDetail extends Component {
  landingPageUrl;
  constructor(props) {
    super(props);

    this.state = {
      shoppingSite: null,
      isLoading: true,
      isErrorModalOpen: false,
      isWrpBenefitModelOpen: false,
      isLoginDialogOpen: false,
      // isMob:0,
      // is_kamaie:0
    };
  }

  openWrpBenefitModel = () => {
    this.setState({
      isWrpBenefitModelOpen: true,
    });
  };

  closeWrpModelHandler = () => {
    this.setState({
      isWrpBenefitModelOpen: false,
    });
  };

  openLoginDialog = () => {
    this.setState({ isLoginDialogOpen: true });
  };

  closeLoginDialog = () => {
    this.setState({ isLoginDialogOpen: false });
  };

  componentDidMount() {
    // const queryParameters = new URLSearchParams(window.location.search);
    // this.setState({ isMob: queryParameters.get("isMob") });
    // this.setState({is_kamaie:queryParameters.get("is_kamaie")})  
    const isMob=getIsMob()
    const is_kamaie=getIsKamaie()
    const {
      match: { params },
    } = this.props;
    this.landingPageUrl = this.props.location.search.split('landing_page_url=').pop();
    this.setState(
      {
        isLoading: true,
      },
      () => {
        customerAffiliateClientX
          .get(`/api/v1/affiliates/${params.id}`)
          .then((response) => {
            let data = response.data.data;
            const payload = {
              Category: "Shopping Site",
              "Product Name": data.name,
            };
            let id = parseInt(this.props?.match?.params?.id)
            if (id === data?.id && data?.slug !== null) {
              this.props.history.push(`/shopping-sites/${data.slug}`)
            }

            onViewedProduct(payload);
            this.setState({
              shoppingSite: data,
              isLoading: false,
            });
          })
          .catch((error) => {
            this.props.history.push("/shopping-sites")
          });
      }
    );
  }

  toggleBookmark = (event, id, isBookmarked) => {
    event.preventDefault();
    event.stopPropagation();
    this.onToggleBookmark(
      id,
      isBookmarked,
      `api/v1/affiliates/${id}/bookmark`,
      "shoppingSites"
    );
  };

  onToggleBookmark = (id, isBookmarked, url, type) =>
    !isBookmarked
      ? this.addBookmark(id, url, this.props.shoppingSites)
      : this.deleteBookmark(id, url, this.props.shoppingSites);

  addBookmark = (id, url, shoppingSites) => {
    customerAffiliateClientX
      .post(url, {})
      .then((response) => {
        this.setState((prevState) => ({
          shoppingSite: {
            ...prevState.shoppingSite,
            bookmarked: !prevState.shoppingSite.bookmarked,
          },
        }));
      })
      .catch((error) => { });
  };

  deleteBookmark = (id, url, shoppingSites) => {
    customerAffiliateClientX
      .delete(url)
      .then((response) => {
        this.setState((prevState) => ({
          shoppingSite: {
            ...prevState.shoppingSite,
            bookmarked: !prevState.shoppingSite.bookmarked,
          },
        }));
      })
      .catch((error) => { });
  };

  onAcceptTerms = () => {
    const {
      match: { params },
    } = this.props;
    if (this.props.profile) {
      customerAffiliateClientX
        .post(`api/v1/affiliates/${this.state.shoppingSite.id}/clicked`, {
          deal_id: params.dealId
        })
        .then((response) => {
          const payload = {
            Category: "Shopping Site",
            "Brand Name": this.state.shoppingSite.name,
          };
          onVisitedAffiliateSite(payload);
          const popUp = window.open(response.data.data.link, "_blank");
          if (popUp == null || typeof popUp == "undefined") {
            this.setState({
              isErrorModalOpen: true,
            });
          } else {
            popUp.focus();
          }
        });
      // if (params.dealId) {
      //   customerAffiliateClientX
      //     .post(`api/v1/affiliates/${this.state.shoppingSite.id}/clicked`, {
      //       deal_id: params.dealId
      //     })
      //     .then((response) => {
      //       const payload = {
      //         Category: "Shopping Site",
      //         "Brand Name": this.state.shoppingSite.name,
      //       };
      //       onVisitedAffiliateSite(payload);
      //       const popUp = window.open(response.data.data.link, "_blank");
      //       if (popUp == null || typeof popUp == "undefined") {
      //         this.setState({
      //           isErrorModalOpen: true,
      //         });
      //       } else {
      //         popUp.focus();
      //       }
      //     });
      // } else {
      //     clientX
      //     .post(`v1/shopping-sites/${this.state.shoppingSite.id}/visits`, {
      //       landing_page_url: this.landingPageUrl
      //     })
      //     .then((response) => {
      //       const payload = {
      //         Category: "Shopping Site",
      //         "Brand Name": this.state.shoppingSite.name,
      //       };
      //       onVisitedAffiliateSite(payload);
      //       const popUp = window.open(response.data.data.link, "_blank");
      //       if (popUp == null || typeof popUp == "undefined") {
      //         this.setState({
      //           isErrorModalOpen: true,
      //         });
      //       } else {
      //         popUp.focus();
      //       }
      //     });
      // }
    } else {
      this.openLoginDialog()
    }
  };

  ShoppingSiteDetailMetaData = () => {
    let currentAppUrl = process.env.REACT_APP_URL + window.location.pathname;
    return (
      <HelmetMetaData
        title={`${this.state.shoppingSite?.name} Instant Discount Offers Online | KAMAIE App`}
        description={`Start your shopping from ${this.state.shoppingSite?.name} online and  get a chance to win exciting rewards points on every purchase on KAMAIE App. Get Instant discount deals on ${this.state.shoppingSite?.name} Vouchers, Coupons, and Promo Codes.`}
        keywords={`${this.state.shoppingSite?.name} gift cards, ${this.state.shoppingSite?.name} gift vouchers, ${this.state.shoppingSite?.name} vouchers, ${this.state.shoppingSite?.name} online discounts, ${this.state.shoppingSite?.name} online deals, ${this.state.shoppingSite?.name} e-gift cards, ${this.state.shoppingSite?.name} e-vouchers, ${this.state.shoppingSite?.name} gift online, buy ${this.state.shoppingSite?.name} gift card, ${this.state.shoppingSite?.name} gift voucher, ${this.state.shoppingSite?.name} KAMAIE app vouchers, ${this.state.shoppingSite?.name} KAMAIE app gift cards, ${this.state.shoppingSite?.name} brand vouchers,`}
        voiceSchema={<script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org/",
              "@type": "WebPage",
              "name": `${this.state.shoppingSite?.name}`,
              "speakable":
              {
                "@type": "SpeakableSpecification",
                "xpath":
                  [
                    "/html/head/title",
                    "/html/head/meta[@name='description']/@content"
                  ]
              },
              "url": currentAppUrl
            }
          )}
        </script>
        }
        breadcrumbSchema={<script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org/",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://windsapp.com/"
              }, {
                "@type": "ListItem",
                "position": 2,
                "name": "Shopping sites",
                "item": "https://windsapp.com/shopping-sites"
              }, {
                "@type": "ListItem",
                "position": 3,
                "name": `${this.state.shoppingSite?.name}`,
                "item": currentAppUrl
              }]
            }
          )}
        </script>}
      ></HelmetMetaData>
    )
  }

  render() {
    return this.state.isLoading ? <this.Shimmer /> :
      <>
        <this.ShoppingSiteDetailMetaData />
        <this.ShoppingSite />
      </>
  }

  Shimmer = (props) => {
    return (
      <div className="container-fluid py-3 py-md-5">
        <div className="row">
          <div className="col-md-6">
            <div className="box shine box-shopping-site-detail-logo"></div>
          </div>
          <div className="col-md-6">
            <div className="box shine" style={{ height: "38px" }}></div>
            <ContentLoader
              height={160}
              width={400}
              speed={2}
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <rect x="0" y="19" rx="3" ry="3" width="350" height="6" />
              <rect x="0" y="36" rx="3" ry="3" width="380" height="6" />
              <rect x="0" y="53" rx="3" ry="3" width="201" height="6" />
              <rect x="0" y="81" rx="0" ry="0" width="352" height="6" />
              <rect x="0" y="98" rx="0" ry="0" width="384" height="6" />
              <rect x="-1" y="115" rx="0" ry="0" width="194" height="6" />
            </ContentLoader>
          </div>
        </div>
      </div>
    );
  };

  goToUploadShoppingBill = () => {
    this.props.history.push(
      `/upload-bill/shopping-site/${this.state.shoppingSite.id}`
    );
  };

  onDismissErrorModal = () => {
    this.setState({
      isErrorModalOpen: false,
    });
  };

  ShoppingSite = (props) => {
    const {
      shoppingSite,
      isErrorModalOpen,
      isWrpBenefitModelOpen,
    } = this.state;
    const isMob=getIsMob()
    const is_kamaie=getIsKamaie()
    return (
      <React.Fragment>
        <div className="affiliate-banner" style={{
          backgroundImage: `url(${affiliateBanner})`,
        }}>
          <div className="container">
            {
              (isMob !=1 && is_kamaie!=1) &&(
                <div className="bread-crumbs">
                <small>
                  <Link to="/">
                    <FormattedMessage id="breadcrumbs.home" defaultMessage="Home" />
                  </Link>
                </small>{" "}
                /{" "}
                <small>
                  <Link to="/shopping-sites">
                    <FormattedMessage
                      id="home.shopping-sites"
                      defaultMessage="Shopping Sites"
                    />
                  </Link>
                </small>{" "}
                /{" "}
                <small>
                  <strong>{shoppingSite.name}</strong>
                </small>
              </div>
              )
            }
          
            <div className="logo-container mt-5 mt-sm-5">
              <div className="card mb-md-4 ">
                {
                  <img
                    src={
                      shoppingSite.bookmarked
                        ? bookmarkActive
                        : bookmarkInactive
                    }
                    className="bookmark"
                    alt="bookmark"
                    title="bookmark"
                    onClick={(event) =>
                      this.toggleBookmark(
                        event,
                        shoppingSite.id,
                        shoppingSite.bookmarked
                      )
                    }
                  />
                }
                <div className="card-body">
                  <img
                    className="affliate-logo-img"
                    src={shoppingSite.web_image_url}
                    alt={shoppingSite.name}
                    title={shoppingSite.name}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5 mt-md-4 font-proxima-nova">
          {isWrpBenefitModelOpen && (
            <WrpBenefitModel
              isWrpBenefitModelOpen={isWrpBenefitModelOpen}
              closeWrpModelHandler={this.closeWrpModelHandler}
              wrp_ratio={shoppingSite?.wrp_benefits?.fix_commission_ratio}
              description={`Earn KAMAIE Coins on every transaction and Redeem those KAMAIE Coins in Future transactions in KAMAIE App`}
              // description={shoppingSite.wrp_info}
            />
          )}
          <div className="row">
            <div className="offset-md-0 offset-lg-4 col-lg-4 col-md-6 col-12 w-lg-100 w-sm-100">
              <button
                className="animated-btn mb-3 mt-4 mt-sm-3 home-btn w-100"
                onClick={this.onAcceptTerms}
              >
                <FormattedMessage
                  id="shopping-sites.accept-terms"
                  defaultMessage="Accept terms"
                />
              </button>
            </div>
            {!shoppingSite.auto_tracking && (
              <div className="col-12 col-md-3">
                <button
                  className="w-100 mb-3 mt-0 mt-sm-3 btn btn-outline-primary home-btn"
                  onClick={this.goToUploadShoppingBill}
                >
                  <FormattedMessage
                    id="general.upload-bill"
                    defaultMessage="Upload Bill"
                  />
                </button>
              </div>
            )}{" "}
            <div className="col-12 col-md-3 mt-3">
              <button
                className="btn btn-outline wrp-btn"
                style={{ color: "#087CD8" }}
                onClick={this.openWrpBenefitModel}
              >
                <img src={aboutBlue} alt="about" title="about" className="mr-1" />{" "}
                <FormattedMessage
                  id="general.wrp_benefits"
                  defaultMessage="KAMAIE Coins Benefits"
                />
              </button>
            </div>
          </div>
          <div className="row mt-md-5 mt-2">
            {shoppingSite.auto_tracking ? (
              <div className="col-12">
                <img
                  src={coffeeMugIcon}
                  alt="Bill Upload Icon"
                  title="Bill Upload Icon"
                  className="body-icon mr-2 mb-2"
                />
                {shoppingSite.custom_note_text}
              </div>
            ) : (
              <div className="col-12">
                <img
                  src={billUploadIcon}
                  alt="Bill Upload Icon"
                  title="Bill Upload Icon"
                  className="body-icon mr-2"
                />
                {shoppingSite.custom_note_text}
              </div>
            )}
          </div>
          <div
            className="shopping-site-terms text-left mt-md-5 mt-3"
            dangerouslySetInnerHTML={{
              __html: shoppingSite.terms_and_conditions,
            }}
          />
        </div>
        {isErrorModalOpen && (
          <ErrorModal
            message={
              <>
                <FormattedMessage
                  id="disable.pop-up"
                  defaultMessage="Please disable your pop-up blocker and try again."
                />
              </>
            }
            buttonTxt={
              <>
                <FormattedMessage id="profile.ok" defaultMessage="OK" />
              </>
            }
            onDismiss={this.onDismissErrorModal}
          />
        )}
        {this.state.isLoginDialogOpen && (
          <LoginDialog
            isLoginDialogOpen={this.state.isLoginDialogOpen}
            closeLoginDialog={this.closeLoginDialog}
          />
        )}
      </React.Fragment>
    );
  };
}

function mapStateToProps(state) {
  return {
    profile: state.global.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShoppingSiteDetail);