import React, { Component } from "react";
import customerCommonClientX from "../../../utils/api/customerCommonClientX";
import { Link } from "react-router-dom";
import InlineLoader from "../../../components/InlineLoader";
import lodash from "lodash";
import { FormattedMessage } from "react-intl";
import { OrderStatusLabel, reverseDateFormat } from "../../../utils/helper";
import filterIcon from "../../../assets/filter-icon.svg";
import Modal from "react-bootstrap/Modal";
import ViewVoucher from "../components/ViewVoucher";
import EmptyState from "../../../components/EmptyState";
import EmptyImage from "../../../assets/empty-state-screen.svg";
import { connect } from "react-redux";
import billpayIcon from "../../../assets/orders/ic_billpay.svg";
import voucherIcon from "../../../assets/orders/ic_voucher_purcgase.svg";
import partnerIcon from "../../../assets/orders/ic_partner_purchase.svg";
import flightIcon from "../../../assets/orders/ic_flight_booking.svg";
import hotelIcon from "../../../assets/orders/ic_hotel_booking.svg";
import shoppingsiteIcon from "../../../assets/orders/ic_shpping_site.svg"
import insuranceIcon from "../../../assets/orders/ic_insurance.svg"
import { getIsMob,getIsKamaie } from "../../../utils/helper";

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      isFetchingOrders: false,
      isFetchingNextPage: false,
      nextPage: null,
      segmentFilters: [],
      selectedSegments: [],
      statusFilters: [],
      selectedStatus: [],
      from_dateFilter: "",
      to_dateFilter: "",
      isFilterApplied: false,
      isOrderFilterModelOpen: false,
      currentVoucher: null,
    };
  }

  onScroll = () => {
    let height = 0
    window.innerWidth < 767 ? height = 1800 : height = 500

    if (this.state.isFetchingNextPage) {
      return;
    }

    if (!this.state.nextPage) {
      return;
    }

    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - height
    ) {
      this.fetchPage(this.state.nextPage);
    }
  };

  componentDidMount() {
    if (this.props.profile === null) {
      this.props.history.push('/login')
    }
    this.setState(
      {
        isFetchingOrders: true,
      },
      () => {
        this.initOrders();
        this.getFilters();
      }
    );

    window.addEventListener("scroll", this.onScroll, false);
  }

  initOrders = () => {
    customerCommonClientX.get("v1/orders").then((response) => {
      let data = response.data.data;

      let nextPageURL =
        null !== response.data.links.next && new URL(response.data.links.next);

      let nextPage =
        null !== response.data.links.next &&
        nextPageURL.searchParams.get("page");

      this.setState({
        orders: this.state.orders.concat(data),
        nextPage: nextPage,
        isFetchingOrders: false,
      });
    });
  };

  getFilters = () => {
    customerCommonClientX.get("v1/order-filters").then((res) => {
      const segments = res.data.data.segment;
      const statuses = res.data.data.status;
      const modifiedSegments = segments.map((obj) => ({
        ...obj,
        isChecked: false,
      }));
      const modifiedStatuses = statuses.map((obj) => ({
        ...obj,
        isChecked: false,
      }));
      this.setState({
        segmentFilters: modifiedSegments,
        statusFilters: modifiedStatuses,
      });
    });
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll, false);
  }

  fetchPage = (page) => {
    const {
      selectedStatus,
      selectedSegments,
      from_dateFilter,
      to_dateFilter,
    } = this.state;
    this.setState(
      {
        isFetchingNextPage: true,
      },
      () => {
        customerCommonClientX
          .get("v1/orders", {
            params: {
              page: page,
              ...(selectedStatus.length > 0
                ? { status: selectedStatus.join() }
                : {}),
              ...(selectedSegments.length > 0
                ? { segment: selectedSegments.join() }
                : {}),
              from_date: reverseDateFormat(from_dateFilter),
              to_date: reverseDateFormat(to_dateFilter),
            },
          })
          .then((response) => {
            let data = response.data.data;

            let nextPageURL =
              null !== response.data.links.next &&
              new URL(response.data.links.next);

            let nextPage =
              null !== response.data.links.next &&
              nextPageURL.searchParams.get("page");

            this.setState({
              orders: this.state.orders.concat(data),
              nextPage: nextPage,
              isFetchingNextPage: false,
            });
          });
      }
    );
  };

  getOrderDetailLink = (order) => {
    switch (order.service) {
      case "bill-pay":
        return `/bill-pay/orders/${order.item_order_id}`;
      case "voucher":
        return `/vouchers/orders/${order.item_order_id}`;
      case "shopping-site":
        return `/shopping-sites/orders/${order.item_order_id}`;
      case "partner-purchase":
        return `/nearby-partners/orders/${order.item_order_id}`;
      case "travel":
        return `/flights/orders/${order.item_order_id}`;
      case "hotel":
        return `/hotels/orders/${order.item_order_id}`;
      case "merchandise":
        return `/winds-merchandise/orders/${order.item_order_id}`;
      case "winds_cart":
        return `/winds-kart/orders/${order.item_order_id}`;
      // case "ecommerce":
      //   return window.open(`${process.env.REACT_APP_ECOMMERCE}/customer/account/orders/view/${order.item_order_id}`)
      //   return;
      default:
        return `/orders`;
    }
  };

  getOrderIcon = (order) => {
    switch (order.service) {
      case "bill-pay":
        return <img className="mb-2" src={billpayIcon} width="40px" alt="billpayIcon" title="billpayIcon" />
      case "voucher":
        return <img className="mb-2" src={voucherIcon} width="40px" alt="voucherIcon" title="voucherIcon" />
      case "shopping-site":
        return <img className="mb-2" src={shoppingsiteIcon} width="40px" alt="shoppingsiteIcon" title="shoppingsiteIcon" />
      case "partner-purchase":
        return <img className="mb-2" src={partnerIcon} width="40px" alt="partnerIcon" title="partnerIcon" />
      case "travel":
        return <img className="mb-2" src={flightIcon} width="40px" alt="flightIcon" title="flightIcon" />
      case "hotel":
        return <img className="mb-2" src={hotelIcon} width="40px" alt="hotelIcon" title="hotelIcon" />
      case "insurance":
        return <img className="mb-2" src={insuranceIcon} width="40px" alt="insuranceIcon" title="insuranceIcon" />
      default:
        return <img className="mb-2" src={voucherIcon} width="40px" alt="voucherIcon" title="voucherIcon" />
    }
  }

  resetFilter = () => {
    this.setState((state) => ({
      from_dateFilter: "",
      to_dateFilter: "",
      segmentFilters: state.segmentFilters.map((status) => ({
        ...status,
        checked: false,
      })),
      statusFilters: state.statusFilters.map((status) => ({
        ...status,
        checked: false,
      })),
      isFetchingOrders: true,
      orders: []
    }));

    this.initOrders();
  };

  onChange = (event, key, value, filterType, index) => {
    event.persist();
    this.setState({ isFilterApplied: true }, () => {
      switch (filterType) {
        case "segment":
          this.setState(
            (state) => ({
              ...state,
              segmentFilters: state.segmentFilters.map((status) =>
                key === status.key
                  ? { ...status, checked: !status.checked }
                  : status
              ),
            }),
            () => {
              let uniqueSegment = new Set();
              for (
                let index = 0;
                index < this.state.segmentFilters.length;
                index++
              ) {
                const element = this.state.segmentFilters[index];
                if (element.checked) {
                  uniqueSegment.add(element.key);
                }
              }
              this.setState(
                {
                  selectedSegments: [...Array.from(uniqueSegment)],
                },
                () => {
                  this.getOrdersPerFilter();
                }
              );
            }
          );
          break;
        case "status":
          this.setState(
            (state) => ({
              ...state,
              statusFilters: state.statusFilters.map((status) =>
                key === status.key
                  ? { ...status, checked: !status.checked }
                  : status
              ),
            }),
            () => {
              const result = this.state.statusFilters.filter(
                (item) => item.checked
              );
              let mySet = new Set();
              for (
                let index = 0, resultLength = result.length;
                index < resultLength;
                index++
              ) {
                const element = result[index];
                mySet.add(element.key);
              }
              this.setState(
                {
                  selectedStatus: [...Array.from(mySet)],
                },
                () => {
                  this.getOrdersPerFilter();
                }
              );
            }
          );
          break;
        case "from_date":
          this.setState(
            {
              ...this.state,
              from_dateFilter: key,
            },
            () => {
              this.getOrdersPerFilter();
            }
          );
          break;
        case "to_date":
          this.setState(
            {
              ...this.state,
              to_dateFilter: key,
            },
            () => {
              this.getOrdersPerFilter();
            }
          );
          break;
        default:
          break;
      }
    });
  };

  onOrderFilterClick = () => {
    this.setState((prevState) => ({
      isOrderFilterModelOpen: !prevState.isOrderFilterModelOpen,
    }));
  };

  getOrdersPerFilter = () => {
    const {
      selectedStatus,
      selectedSegments,
      from_dateFilter,
      to_dateFilter,
    } = this.state;
    customerCommonClientX
      .get("v1/orders", {
        params: {
          ...(selectedStatus.length > 0
            ? { status: selectedStatus.join() }
            : {}),
          ...(selectedSegments.length > 0
            ? { segment: selectedSegments.join() }
            : {}),
          from_date: reverseDateFormat(from_dateFilter),
          to_date: reverseDateFormat(to_dateFilter),
        },
      })
      .then((response) => {
        let data = response.data.data;

        let nextPageURL =
          null !== response.data.links.next &&
          new URL(response.data.links.next);

        let nextPage =
          null !== response.data.links.next &&
          nextPageURL.searchParams.get("page");

        this.setState({
          orders: data,
          nextPage: nextPage,
          isFetchingOrders: false,
        });
      });
  };

  viewVoucher = (event, item) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      currentVoucher: item,
    });
  };

  onDismiss = () => {
    this.setState({
      currentVoucher: null,
    });
  };

  render() {
    const isMob = getIsMob();
    const is_kamaie = getIsKamaie();
    return (
      <div className="container font-proxima-nova">
        <div className="row mt-3 mb-3">
      {(isMob !=1 && is_kamaie!=1 &&
        <div className="col-12">
        <small>
        <Link to="/">
          <FormattedMessage id="breadcrumbs.home" defaultMessage="Home" />
        </Link>{" "}
        /{" "}
        <strong>
          <FormattedMessage id="navbar.orders" defaultMessage="Orders" />
        </strong>
        </small>
      </div>
      )}
         
        </div>
        <div className="col-12 text-right d-block d-md-none pr-0">
          <img src={filterIcon} alt="filter" title="filter" className="filter-icon" />
          <button
            className="btn text-primary"
            onClick={this.onOrderFilterClick}
          >
            <FormattedMessage id="general.filters" defaultMessage="Filters" />
          </button>

          <React.Fragment>
            <Modal
              show={this.state.isOrderFilterModelOpen}
              onHide={this.onOrderFilterClick}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <FormattedMessage
                    id="general.filters"
                    defaultMessage="Filters"
                  />
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Filters
                  {...this.state}
                  onChange={(event, key, value, filterType, index) =>
                    this.onChange(event, key, value, filterType, index)
                  }
                  resetFilter={this.resetFilter}
                />
              </Modal.Body>
              <Modal.Footer>
                <div className="row w-100">
                  <div className="col-6">
                    <button
                      onClick={this.onOrderFilterClick}
                      className="w-100 btn btn-outline-primary"
                    >
                      <FormattedMessage
                        id="general.apply"
                        defaultMessage="Apply"
                      />
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      onClick={() => {
                        this.resetFilter();
                        this.onOrderFilterClick();
                      }}
                      className="btn w-100 btn-outline-primary"
                    >
                      <FormattedMessage
                        id="general.reset"
                        defaultMessage="Reset"
                      />
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </Modal>
          </React.Fragment>
        </div>
        <div className="row px-md-3">
          <FilterBlock
            {...this.state}
            onChange={(event, key, value, filterType, index) =>
              this.onChange(event, key, value, filterType, index)
            }
            resetFilter={this.resetFilter}
          />

          <div className="col-md-9 col-12 main-content">
            {this.state.isFetchingOrders ? (
              <React.Fragment>
                {lodash.range(4).map((index) => {
                  return (
                    <div className="row" key={index}>
                      <div className="col-12">
                        <div className="box shine mb-3 mb-md-4"></div>
                      </div>
                    </div>
                  );
                })}
              </React.Fragment>
            ) : this.state.orders.length === 0 ? (
              <NoOrderFoundBlock {...this.state} />
            ) : (
              <React.Fragment>
                {this.state.orders.map((item, index) => {
                  return (
                    <React.Fragment key={`list${index}`}>
                      {
                        item.service === 'ecommerce' ?
                          <a href={`${process.env.REACT_APP_ECOMMERCE}/customer/account/orders/view/${item.item_order_id}`}
                            target="_blank" rel="noopener noreferrer">
                            <div
                              className="card mb-3"
                              style={{
                                boxShadow: "0 4px 14px 0 rgba(0,0,0,0.08)",
                                borderRadius: "8px",
                                border: "none",
                              }}
                            >
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-12 col-md-4">
                                    <p className="mb-1">
                                      <strong>{item.item_name}</strong>
                                    </p>
                                    <p className="mb-0">
                                      {item.service_text} {item.order_number}
                                    </p>
                                    <p className="mb-0">
                                      {item.wrp}&nbsp;
                                      {item.wrp_credited && item.status_code !== 6 && (
                                        <FormattedMessage
                                          id="order.wrp-credited"
                                          defaultMessage="KAMAIE Coins credited"
                                        />
                                      )
                                        //  : (
                                        //   <FormattedMessage
                                        //     id="order.wrp-pending"
                                        //     defaultMessage="WRP Credit is pending"
                                        //   />
                                        // )
                                      }
                                    </p>
                                    <p className="mb-0 d-md-none mt-2 text-secondary">
                                      <FormattedMessage
                                        id="order.status"
                                        defaultMessage="STATUS"
                                      />
                                    </p>
                                    <OrderStatusLabel order={item} />
                                  </div>
                                  <div className="col-12 col-md-4">
                                    <p className="mb-0 mt-md-4 text-secondary">
                                      <FormattedMessage
                                        id="order.placed"
                                        defaultMessage="ORDER PLACED"
                                      />
                                    </p>
                                    <p>{item.created_at}</p>
                                  </div>
                                  <div className="col-12 col-md-4">
                                    <div className="row">
                                      <div className="col-12">
                                        <p className="mb-0 mt-md-4 text-secondary">
                                          <FormattedMessage
                                            id="general.total"
                                            defaultMessage="TOTAL"
                                          />
                                        </p>
                                        <p><b>₹ {item.amount}</b></p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </a> :
                          <Link to={this.getOrderDetailLink(item)}>
                            <div
                              className="card mb-3"
                              style={{
                                boxShadow: "0 4px 14px 0 rgba(0,0,0,0.08)",
                                borderRadius: "8px",
                                border: "none",
                              }}
                            >
                              <div className="card-body">
                                <div className="row align-items-center">
                                  <div className="col-12 col-md-1">
                                    {
                                      item?.web_image_url ?
                                        <img src={item.web_image_url} alt={item.item_name} title={item.item_name} width="40px" /> :
                                        this.getOrderIcon(item)
                                    }
                                  </div>
                                  <div className="col-12 col-md-4">
                                    <p className="mb-1">
                                      <strong>{item.item_name}</strong>
                                    </p>
                                    <p className="mb-0">
                                      {item.service_text} {item.order_number}
                                    </p>
                                    <p className="mb-0">
                                      {item.wrp_credited && item.status_code !== 6 && (
                                        <>
                                          {item.wrp}{" "}
                                          <FormattedMessage
                                            id="order.wrp-credited"
                                            defaultMessage="KAMAIE Coins credited"
                                          />
                                        </>
                                      )}
                                    </p>
                                    <p className="mb-0 d-md-none mt-2 text-secondary">
                                      <FormattedMessage
                                        id="order.status"
                                        defaultMessage="STATUS"
                                      />
                                    </p>
                                    <OrderStatusLabel order={item} />
                                  </div>
                                  <div className="col-6 col-md-4">
                                    <p className="mb-0 mt-md-4 text-secondary">
                                      <FormattedMessage
                                        id="order.placed"
                                        defaultMessage="ORDER PLACED"
                                      />
                                    </p>
                                    <p className="mb-0">{item.created_at}</p>
                                    {item?.expiry_date &&
                                      (item.is_expired ?
                                        <p className="text-red">Expired: {item?.expiry_date}</p> :
                                        <p>Expiry Date: {item?.expiry_date}</p>)
                                    }
                                  </div>
                                  <div className="col-6 col-md-3">
                                    <div className="row">
                                      <div className="col-12">
                                        <p className="mb-0 mt-md-4 text-secondary">
                                          <FormattedMessage
                                            id="general.total"
                                            defaultMessage="TOTAL"
                                          />
                                        </p>
                                        <p><b>₹ {item.amount}</b></p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </Link>
                      }
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            )}
          </div>
          {this.state.currentVoucher && (
            <ViewVoucher
              onDismiss={this.onDismiss}
              voucher={this.state.currentVoucher}
            />
          )}
        </div>

        <div className="row mt-3 mb-3">
          <div className="col-12 text-center">
            {this.state.isFetchingNextPage && <InlineLoader />}
            {!this.state.nextPage && 0 !== this.state.orders.length && (
              <p className="font-weight-bold">
                <FormattedMessage
                  id="order.no-more"
                  defaultMessage="No more orders"
                />
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    profile: state.global.profile,
  };
}

export default connect(mapStateToProps)(Orders);

export const NoOrderFoundBlock = (props) => {
  return (
    <div className="empty-state">
      {props.isFilterApplied ? (
        <EmptyState
          title={
            <FormattedMessage
              id="order.no-order-filter"
              defaultMessage="No order for this filter."
            />
          }
          imageWidth={50}
          image={EmptyImage}
        />
      ) : (
        <EmptyState
          title={
            <FormattedMessage
              id="order.no-order"
              defaultMessage="You don't have any orders."
            />
          }
          imageWidth={50}
          image={EmptyImage}
        />
      )}
    </div>
  );
};

export const FilterBlock = (props) => {
  return (
    <div className="col-3 filter-wrapper border d-none d-md-block" style={{ height: "fit-content" }}>
      <div className="row border-bottom align-items-center status py-3">
        <div className="col-md-6 pl-0">
          <h5>
            <strong>
              <FormattedMessage id="general.filters" defaultMessage="Filters" />
            </strong>
          </h5>
        </div>
        <div className="col-md-6 pb-2">
          <button
            className="btn btn-outline float-right winds-link-color"
            onClick={props.resetFilter}
          >
            <span>
              <FormattedMessage id="general.reset" defaultMessage="Reset" />
            </span>
          </button>
        </div>
      </div>
      <Filters
        {...props}
        resetFilter={props.resetFilter}
        onChange={(event, key, value, filterType, index) =>
          props.onChange(event, key, value, filterType, index)
        }
      />
    </div>
  );
};

export const Filters = (props) => {
  return (
    <React.Fragment>
      <div className="row border-bottom align-items-center mt-4 pb-5 status">
        <div className="col-md-12 pl-0">
          <p className="text-uppercase">
            <strong>
              <FormattedMessage id="order.status" defaultMessage="STATUS" />
            </strong>
          </p>
          {props.statusFilters.map((item, index) => (
            <div className="row mt-3" key={index}>
              <div className="col-9 winds-grey-color">{item.value}</div>
              <div className="col-3 mb-1 text-center">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={item.key}
                    checked={item.checked || false}
                    onChange={(event) => {
                      props.onChange(
                        event,
                        item.key,
                        item.value,
                        "status",
                        index
                      );
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={item.key}
                  ></label>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="row border-bottom align-items-center mt-4 pb-5 status">
        <div className="col-md-12 pl-0">
          <p className="text-uppercase">
            <strong>
              <FormattedMessage id="order.segment" defaultMessage="SEGMENT" />
            </strong>
          </p>
          {props.segmentFilters.map((item, index) => (
            <div className="row mt-3" key={index}>
              <div className="col-9 winds-grey-color">{item.value}</div>
              <div className="col-3 mb-1 text-center">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={item.key}
                    checked={item.checked || false}
                    onChange={(event) => {
                      props.onChange(
                        event,
                        item.key,
                        item.value,
                        "segment",
                        index
                      );
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={item.key}
                  ></label>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="row align-items-center mt-4 pb-3">
        <div className="col-md-12">
          <p className="text-uppercase">
            <strong>
              <FormattedMessage id="order.date" defaultMessage="DATE" />
            </strong>
          </p>
          <div className="row mt-3">
            <div className="col-md-12 winds-grey-color">
              {" "}
              <FormattedMessage id="order.from" defaultMessage="From" />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12 winds-grey-color">
              <input
                type="date"
                name="from-date"
                max={new Date().toISOString().substring(0, 10)}
                className="date-input"
                value={props.from_dateFilter}
                onChange={(event) => {
                  props.onChange(
                    event,
                    event.target.value,
                    event.target.value,
                    "from_date",
                    null
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row align-items-center pb-5">
        <div className="col-md-12">
          <div className="row mt-3">
            <div className="col-md-12 winds-grey-color">
              <FormattedMessage id="order.to" defaultMessage="To" />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12 winds-grey-color">
              <input
                type="date"
                name="to-date"
                max={new Date().toISOString().substring(0, 10)}
                min={
                  props.from_dateFilter === "" ? null : props.from_dateFilter
                }
                className="date-input"
                value={props.to_dateFilter}
                onChange={(event) => {
                  props.onChange(
                    event,
                    event.target.value,
                    event.target.value,
                    "to_date",
                    null
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
