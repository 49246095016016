import React, { Component } from "react";
import { Link } from "react-router-dom";
import DefaultStoreImage from "../../../assets/default-store-image.svg";
import { FormattedMessage, injectIntl } from "react-intl";
import customerPartnerClientX from "../../../utils/api/customerPartnerClientX";
import PreviewDownloadedFiles from "../../shopping_sites/components/previewDownloadedFiles";
import { OrderStatusLabel } from "../../../utils/helper";
import { claimNow, claimed } from "../../../components/RewardsClaim";
import Lottie from "lottie-react";
import SuccessBg from '../../../assets/voucher/ic_confetti.json'

class OrderDetail extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      order: null,
      canDownloadReceipt: false,
      isModelOpen: false,
      amountWithoutTax: 0,
      show: false,
      displayedFirstTime: false
    };
    this.loadRef = React.createRef();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onCustomerAcknowledged = () => {
    const form = {
      refund_acknowledged: true,
    };
    customerPartnerClientX
      .post(`/v1/bills/refund-confirm/${this.state.order.bill_id}`, form)
      .then((response) => {
        this.componentDidMount();
      });
  };

  componentDidMount() {
    this._isMounted = true;
    const {
      match: { params },
    } = this.props;
    customerPartnerClientX.get(`v1/bills/${params.id}`).then((response) => {
      //   clientX.get(`v1/nearby-partners/bills/${params.id}`).then(response => {
      let data = response.data.data;
      let { amount, sgst_amount, cgst_amount, igst_amount } = data;
      let amountWithoutTax;
      if (igst_amount && igst_amount !== 0) {
        amountWithoutTax = amount - igst_amount;
      } else {
        amountWithoutTax = amount - (sgst_amount + cgst_amount)
      }
      if (3 === parseInt(data.status)) {
        this.setState({
          canDownloadReceipt: true,
        });
      }

      if (this._isMounted) {
        this.setState({
          order: data,
          amountWithoutTax: amountWithoutTax
        });
      }

      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            this.setState({ show: true })
            setTimeout(() => {
              this.setState({ displayedFirstTime: true })
            }, 100);
          }
          else {
            this.setState({ show: false })
          }
        });
      });
      this.observer?.observe(this.loadRef.current);

    });
  }
  viewUploadBill = () => {
    this.setState({ isModelOpen: true });
  };
  closeModelHandler = () => {
    this.setState({ isModelOpen: false });
  };
  downloadReceipt = () => {
    customerPartnerClientX
      .get(`/v1/bills/${this.state.order.bill_id}/receipt`)
      .then((response) => {
        window.open(response.data.data.receipt);
      });
  };
  cancelBill = (id) => {
    customerPartnerClientX
      .post(`/v1/bills/${id}/cancel`, {})
      .then((response) => {
        this.componentDidMount();
      });
  };

  Shimmer = (props) => {
    return (
      <div className="container-fluid py-3 py-md-5">
        <div className="row">
          <div className="col-12 col-md-6 offset-md-3">
            <div className="box shine box-voucher-detail-logo"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { order, canDownloadReceipt, isModelOpen, amountWithoutTax } = this.state;
    return (
      order === null ?
        <this.Shimmer />
        :
        null !== order && (
          <div className="container-fluid font-proxima-nova">
            <div className="row mt-3 mb-3 mt-md-4 mb-md-4 align-items-center">
              <div className="col-12 text-md-center">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>
                </small>{" "}
                /{" "}
                <small>
                  <Link to="/orders">
                    <FormattedMessage
                      id="navbar.orders"
                      defaultMessage="Orders"
                    />
                  </Link>
                </small>{" "}
                /{" "}
                <small>
                  <strong><FormattedMessage
                    id="orders.order"
                    defaultMessage="Order"
                  /></strong>
                </small>
              </div>
            </div>
            {isModelOpen && (
              <PreviewDownloadedFiles
                closePreview={() => this.closeModelHandler()}
                bills={
                  order.transaction_image
                    ? order.transaction_image
                    : order.transaction_multiple_images
                }
              />
            )}
            <div className="row mb-3 mb-md-4">
              <div className="col-md-6 offset-md-3">
                <div className="list-group">
                  <div className="list-group-item list-group-item-action">
                    {null !== order.partner_image ? (
                      <img
                        className="mt-2 mt-md-0"
                        src={order.partner_image}
                        style={{
                          background: "#fff",
                          height: "60px",
                          border: "1px solid rgba(0, 0, 0, 0.125)",
                          display: "inline",
                          boxSizing: "content-box",
                          borderRadius: "0.25rem",
                          marginRight: "15px",
                        }}
                        alt={order.name}
                        title={order.name}
                      />
                    ) : (
                      <img
                        className="mt-2 mt-md-0"
                        src={DefaultStoreImage}
                        style={{
                          background: "#fff",
                          height: "60px",
                          border: "1px solid rgba(0, 0, 0, 0.125)",
                          display: "inline",
                          boxSizing: "content-box",
                          borderRadius: "0.25rem",
                          marginRight: "15px",
                        }}
                        alt={order.name}
                        title={order.name}
                      />
                    )}
                    <p className="d-block d-md-inline mt-1 mt-md-0 mb-0">
                      <FormattedMessage
                        id="order.purchase-from"
                        defaultMessage="Purchase from"
                      />    {order.partner_name}{" "}
                      <strong>{order.voucher_name}</strong>
                    </p>
                  </div>
                  <div className="list-group-item list-group-item-action">
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <OrderStatusLabel order={order} />
                      </div>
                      <div className="col-md-6 text-md-right">
                        <p className="mb-0">
                          <FormattedMessage
                            id="orders.your-order-number-is"
                            defaultMessage="Your order number is"
                          /> {" "}
                          <strong>{order.order_number}</strong>
                        </p>
                        <p className="mb-0">{order.created_at}</p>
                      </div>
                    </div>
                  </div>
                  <div className="list-group-item list-group-item-action">
                    {order.status_code === 4 ?
                      <React.Fragment>
                        <p className="mb-0">Bill rejected by the vendor</p>
                      </React.Fragment> :
                      <p className="mb-0">
                        <FormattedMessage
                          id="order.payment-of"
                          defaultMessage="Payment of"
                        />  &#x20B9;
                        {
                          order.refund_amount ?
                            <React.Fragment>
                              <strong >{order.refund_amount}&nbsp;</strong>
                              <FormattedMessage
                                id="order.is-reversed"
                                defaultMessage="is reversed"
                              />
                            </React.Fragment> :
                            order.status_code === 8 ?
                              <React.Fragment>
                                <strong className="mr-1">{order.paid_amount}</strong>
                                <FormattedMessage
                                  id="order.payment-unsuccessful"
                                  defaultMessage="is not successful"
                                  className="pl-2"
                                />
                              </React.Fragment> :
                              order.status_code === 0 || order.status_code === 2 ?
                                <React.Fragment>
                                  <strong className="mr-1">{order.paid_amount}</strong>
                                  <FormattedMessage
                                    id="order.payment-pending"
                                    defaultMessage="is pending"
                                    className="pl-2"
                                  />
                                </React.Fragment> :
                                <React.Fragment>
                                  <strong className="mr-1">{order.paid_amount}</strong>
                                  <FormattedMessage
                                    id="order.payment-successful"
                                    defaultMessage="is successful"
                                    className="pl-2"
                                  />
                                </React.Fragment>
                        }
                      </p>
                    }
                  </div>
                  <div className="list-group-item list-group-item-action">
                    <p className="mb-0">
    
                      {order.reversed_wrp ? `${order.reversed_wrp} ${this.props.intl.formatMessage({
                        id: "refund.wrp-reversed",
                        defaultMessage: "KAMAIE Coins are reversed",
                      })} ` : `
                    ${order.wrp_credited ? `${this.props.intl.formatMessage({
                        id: "general.earned",
                        defaultMessage: "Congrats! You have earned",
                      })} ${order.wrp} ${this.props.intl.formatMessage({
                        id: "general.earned1",
                        defaultMessage: "KAMAIE Coins for this transaction!",
                      })}` :
                        `${this.props.intl.formatMessage({
                          id: "partner.upto",
                          defaultMessage: ""
                        })}
                    ${order.wrp} ${this.props.intl.formatMessage({
                          id: "partner.wrp-pending",
                          defaultMessage: "KAMAIE Coins are pending",
                        })}`}`


                      }
                    </p>
                  </div>
                  {(order.status === 24 ||
                    order.status === 9 ||
                    order.status === 6 ||
                    order.status === 5) &&
                    order.refund_customer_comment && (
                      <div className="list-group-item list-group-item-action">
                        <p className="mb-0">
                          <strong><FormattedMessage
                            id="refund.customer-comment"
                            defaultMessage="Customer Comment"
                          />:</strong>{" "}
                          {order.refund_customer_comment}
                        </p>
                      </div>
                    )}
                  {(order.status === 24 ||
                    order.status === 9 ||
                    order.status === 6 ||
                    order.status === 5) &&
                    order.refund_partner_comment && (
                      <div className="list-group-item list-group-item-action">
                        <h6 className="mb-0">
                          <strong>
                            <FormattedMessage
                              id="refund.partner-comment"
                              defaultMessage="Partner Comment"
                            />
                            :
                          </strong>{" "}
                          {order.refund_partner_comment}
                        </h6>
                      </div>
                    )}

                  {order.status === 24 && (
                    <div className="list-group-item list-group-item-action">
                      <h6 className="mb-0">
                        <strong>
                          <FormattedMessage
                            id="refund.rejected-on"
                            defaultMessage=" The partner has rejected your refund request on"
                          />{" "}
                          {order.refund_rejected_at}.  <FormattedMessage
                            id="refund.reject-reason"
                            defaultMessage="As the request was invalid. For more information
                    please check the Terms & conditions.
                 "
                          />  </strong>
                      </h6>
                    </div>
                  )}
                  {order.can_confirm_refund === 1 && (
                    <div className="list-group-item list-group-item-action">
                      <h6 className="mb-0">
                        <strong>  <FormattedMessage
                          id="refund.confirmation"
                          defaultMessage="Please confirm if the refund is complete."
                        /></strong>
                      </h6>
                    </div>
                  )}
                </div>

                <div className="d-md-flex mt-4 justify-content-between">
                  {canDownloadReceipt && (
                    <button
                      className="btn btn-outline-primary home-btn refund-btn"
                      onClick={this.downloadReceipt}
                    >
                      <FormattedMessage
                        id="general.download-receipt"
                        defaultMessage="Download Receipt"
                      />
                    </button>
                  )}

                  {order.transaction_image ||
                    (order.transaction_multiple_images &&
                      order.transaction_multiple_images.length > 0 && (
                        <button
                          className="btn btn-outline-primary home-btn refund-btn"
                          onClick={this.viewUploadBill}
                        >
                          <FormattedMessage
                            id="order.view-bill"
                            defaultMessage="View Uploaded Bill"
                          />
                        </button>
                      ))}
                  {order.can_refund === 1 && order.bill_type === 2 && (


                    <Link
                      to={`/pay-now/refund-request/${order.bill_id}/${amountWithoutTax}/${order.order_number.substring(1)}/${order.created_at}`}
                      className="btn btn-outline-primary home-btn refund-btn"
                    >
                      <FormattedMessage
                        id="order.refund-request"
                        defaultMessage="Request Refund"
                      />
                    </Link>

                  )}
                </div>

                <div className="d-md-flex justify-content-between">
                  {order.can_cancel === 1 && (
                    <button
                      className="btn btn-outline-primary home-btn refund-btn"
                      onClick={() => this.cancelBill(order.bill_id)}
                    >
                      <FormattedMessage
                        id="order.cancel-bill"
                        defaultMessage="Cancel bill"
                      />
                    </button>
                  )}
                  {order.can_confirm_refund === 1 && (
                    <button
                      className="btn btn-outline-primary home-btn refund-btn"
                      onClick={this.onCustomerAcknowledged}
                    >
                      <FormattedMessage
                        id="profile.confirm"
                        defaultMessage="Confirm"
                      />
                    </button>
                  )}
                </div>
                <div className="mt-3 position-relative" ref={this.loadRef}>
                   {order.won_reward === false ?
                      null :
                      order.rewards_cliamed === false ?
                        <div className="">
                          {claimNow(order)}
                          <Lottie className="celebration-lottie" animationData={SuccessBg} loop={!this.state.displayedFirstTime && this.state.show} />
                        </div>
                        : claimed(order)
                    }
                </div>
              </div>
            </div>

          </div>
        )
    );
  }
}

export default injectIntl(OrderDetail);