import React, { PureComponent } from "react";
import voucherClientX from "../../../utils/api/voucherClientX"
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setQuantity } from "../actions";
import classNames from "classnames";
import update from "immutability-helper";
import { FormattedMessage, injectIntl } from "react-intl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InlineLoader from "../../../components/InlineLoader";
import ic_upi from "../../../assets/billpay/ic_upi.svg"
import ic_debit_card from "../../../assets/billpay/ic_debit_card.svg"
import ic_netbanking from "../../../assets/billpay/ic_netbanking.svg"
import ic_wallet from "../../../assets/billpay/ic_wallet.svg"
import ic_phonepe from "../../../assets/billpay/ic_phonepe.png"
import ic_gpay from "../../../assets/billpay/ic_gpay.png"
import ic_paytm from "../../../assets/billpay/ic_paytm.png"
import ic_bhim from "../../../assets/billpay/ic_bhim.png"
import ic_more from "../../../assets/billpay/ic_more.png"
import ic_airtel from "../../../assets/billpay/ic_airtel.png"
import ic_jiomoney from "../../../assets/billpay/ic_jiomoney.png"
import ic_save from "../../../assets/voucher/ic_save.svg";
import ic_checkbox_active from "../../../assets/ic_check_blue.svg";
import ic_checkbox_empty from "../../../assets/ic_checkbox_empty.svg";
import OtpValidationModal from "../../../components/OtpValidationModal";
import profileClientX from "../../../utils/api/profileClientX"

// import Axios from "axios";

class Cart extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      cart: [],
      payment_methods: [],
      load: false,
      billing_name: {
        value: null,
        isValid: false,
        error: "",
        isPristine: true,
      },
      billing_email: {
        value: null,
        isValid: false,
        error: "",
        isPristine: true,
      },
      billing_mobile: {
        value: null,
        isValid: false,
        error: "",
        isPristine: true,
      },
      shipping_name: {
        value: null,
        isValid: false,
        error: "",
        isPristine: true,
      },
      shipping_email: {
        value: null,
        isValid: false,
        error: "",
        isPristine: true,
      },
      shipping_mobile: {
        value: null,
        isValid: false,
        error: "",
        isPristine: true,
      },
      shipping_same_as_billing: false,
      formValid: false,
      paymentMode: null,
      razorPayMethod: "",
      wallet_balance: null,
      wallet_enabled: 0,
      wallet_info: null,
      winds_coin_balance:null,
      PG: 0,
      proceedPay: null,
      pageLoading: false,
      gc_wallet_balance: null,
      gc_wallet_enabled: 0,
      gc_wallet_info: null,
      isOtpValidationModalOpen: false,
    };
  }

  openOtpValidationModal = () => {
    this.setState({ isOtpValidationModalOpen: true });
  };

  closeOtpValidationModal = () => {
    this.setState({ isOtpValidationModalOpen: false });
  };

  validateProfile = (profile) => {
    let email = "";
    profile?.email !== null ?
      email = profile?.email :
      email = profile?.billing_email

    const { mobile, name } = profile;
    const newData = update(this.state, {
      // isFormValid: { $set: true },
      billing_name: {
        value: { $set: name },
        isValid: { $set: false },
      },
      billing_email: {
        value: { $set: email },
        isValid: { $set: false },
      },
      billing_mobile: {
        value: { $set: mobile },
        isValid: { $set: false },
      },
      shipping_same_as_billing: { $set: true },
      formValid: { $set: true }
    });
    // this.setState(newData);
    this.setState(newData, () => {
      this.validateField('billing_name', name)
    });
    this.setState(newData, () => {
      this.validateField('billing_email', email)
    });
    this.setState(newData, () => {
      this.validateField('billing_mobile', mobile)
    });

  }

  componentDidMount() {
    this.setState({ pageLoading: true })
    profileClientX
      .get("v1/profile?billing_email=true")
      .then((response) => {
        const profileData = response.data.data;
        this.validateProfile(profileData)
        this.setState({ pageLoading: false })
      })
      .catch((error) => {
        this.setState({ pageLoading: false })
      });
    this.getCartSummary()
  }

  getCartSummary = () => {
    voucherClientX
      .post(`v2/cart-details`, {
        denomination_id: this.props.denomination.id,
        denomination_value: this.props.denomination.value,
        quantity: this.props.quantity,
        wallet_enabled: this.state.wallet_enabled,
        payment_mode: this.state.paymentMode,
        gc_wallet_enabled: this.state.gc_wallet_enabled,
        coins_enabled:this.state.coins_enabled

      })
      .then((response) => {
        this.setState({

          cart: response.data.data.cart_data,
          wallet_balance: response.data.data.wallet_info.balance,
          wallet_info: response.data.data.wallet_info,
          cashback_details: response.data.data.cashback_details,
          gc_wallet_balance: response.data.data.gc_wallet_info.balance,
          gc_wallet_info: response.data.data.gc_wallet_info,
          winds_coin_balance:response.data.data.winds_coin_info.balance,
        });
        // eslint-disable-next-line array-callback-return
        response.data.data.cart_data.map(item => {
          if (item.key === "processing_fee") {
            this.setState({ PG: item.value })
          }
          if (item.key === "to_pay") {
            if (item.value === "₹ 0.00")
              this.setState({ proceedPay: null })
            else
              this.setState({ proceedPay: item.value })
          }
        })

        if (response.data.data.payment_methods !== null) {
          this.setState({
            payment_methods: response.data.data.payment_methods
          })
          // eslint-disable-next-line array-callback-return
          response.data.data.payment_methods.map(item => {
            if (item.is_selected) {
              this.setState({ razorPayMethod: item.identifier })
              this.setState({ paymentMode: item.id })
            }
          })
        }
        else {
          this.setState({ payment_methods: [] })
        }

      })
      .catch((error) => {
        this.props.history.goBack();
      });
  }

  handleWallet = () => {
    this.setState({ wallet_enabled: !this.state.wallet_enabled}, () => {
      this.getCartSummary()
    })
  }

  handleGCWallet = (e) => {
    this.setState({ gc_wallet_enabled:!this.state.gc_wallet_enabled}, () => {
      this.getCartSummary()
    })
  }

  handlePayment = (id) => {
    this.setState({ paymentMode: id },
      () => {
        this.getCartSummary()
      })
  }

  onShippingCheckboxChange = (e) => {
    const isChecked = e.target.checked;

    this.setState(
      {
        shipping_same_as_billing: isChecked,
      },
      () => {
        this.validateForm();
      }
    );
  };
  onInitLogin = () => {
    toast.error(
      "Something went wrong! Login and retry",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );
  }

  onInitOtpValidation = () => {
    if(this.state.wallet_enabled || this.state.gc_wallet_enabled)
      this.openOtpValidationModal()
    else
      this.onInitOrder()
  }

  onInitOrder = () => {
    let that = this;
    this.setState({ load: true })

    let requestParams = {
      denomination_id: this.props.denomination.id,
      denomination_value: this.props.denomination.value,
      quantity: this.props.quantity,
      billing_name: this.state.billing_name.value,
      billing_email: this.state.billing_email.value,
      billing_mobile: this.state.billing_mobile.value,
      shipping_name: this.state.shipping_name.value,
      shipping_email: this.state.shipping_email.value,
      shipping_mobile: this.state.shipping_mobile.value,
      shipping_same_as_billing: this.state.shipping_same_as_billing ? 1 : 0,
      wallet_enabled: this.state.wallet_enabled,
      payment_mode: this.state.paymentMode,
      gc_wallet_enabled: this.state.gc_wallet_enabled

    }
    if (this.props.giftDetails.gifting_name) {
      requestParams['gifting_name'] = this.props.giftDetails.gifting_name
      requestParams['gifting_mobile'] = this.props.giftDetails.gifting_mobile
      requestParams['gifting_email'] = this.props.giftDetails.gifting_email
      requestParams['gifting_message'] = this.props.giftDetails.gifting_message
    }
    voucherClientX
      .post(`v1/initiate-order`, requestParams)
      .then((response) => {
        if (response.data.data.proceed_to_gateway === false) {
          this.setState({ load: true })
          voucherClientX
            .post(`v1/orders/${response.data.data.order_id}/fulfill`, {
              payment_gateway_order_id: null,
              razorpay_order_id: null,
            })
            .then((cancelresponse) => {
              this.setState({ load: false })
              that.props.history.push(
                `/vouchers/orders/${response.data.data.order_id}`
              );
            }).catch(error => {
              this.setState({ load: false })
              that.props.history.push(
                `/vouchers/orders/${response.data.data.order_id}`
              );
            })
        }
        else {
          if (response.data.data.razorpay_key === null || response.data.data.razorpay_key === "") {
            this.setState({ load: false })
            toast.error(
              "Something went wrong. Please try again later",
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
          }
          else {
            // console.log("this.state.razorPayMethod : "+this.state.razorPayMethod)
            new window.Razorpay({
              // key: process.env.REACT_APP_RAZOR_PAY_KEY,
              key: response.data.data.razorpay_key,
              order_id: response.data.data.payment_gateway_order_id,
              "theme": {
                "hide_topbar": true
              },
              "prefill": {
                "name": this.state.billing_name.value,
                "email": this.state.billing_email.value,
                "contact": this.state.billing_mobile.value,
                "method": this.state.razorPayMethod,
              },
              handler: function (pgResponse) {
                voucherClientX
                  .post(`v1/orders/${response.data.data.order_id}/fulfill`, {
                    payment_gateway_order_id:
                      response.data.data.payment_gateway_order_id,
                    razorpay_order_id: pgResponse.razorpay_order_id,
                    razorpay_payment_id: pgResponse.razorpay_payment_id,
                    razorpay_signature: pgResponse.razorpay_signature,
                  })
                  .then((response) => {
                    // onCharged(payload);
                    that.props.history.push(
                      `/vouchers/orders/${response.data.data.order_id}`
                    );
                  })
                  .catch((error) => {
                    that.props.history.push(
                      `/vouchers/orders/${response.data.data.order_id}`
                    );
                  });
              },
              "modal": {
                "ondismiss": function () {
                  voucherClientX
                    .post(`v1/orders/${response.data.data.order_id}/fulfill`, {
                      payment_gateway_order_id:
                        response.data.data.payment_gateway_order_id,
                      razorpay_order_id: response.data.data.payment_gateway_order_id,
                      payment_failed: 1
                    })
                    .then((cancelresponse) => {
                    }).catch(error => {
                      that.props.history.push(
                        `/vouchers/orders/${response.data.data.order_id}`
                      );
                    })
                }
              }
            }).open();
          }
        }

      })
      .catch((error) => {
        this.setState({ load: false })
        toast.error(
          "Something went wrong.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      });
  };

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const newData = update(this.state, {
      [name]: {
        value: { $set: value },
        isPristine: { $set: false },
      },
    });

    this.setState(newData, () => {
      this.validateField(name, value);
    });
  };

  validateField(fieldName, value) {
    let state = this.state;

    switch (fieldName) {
      case "billing_name":
        state.billing_name.isValid = value.length >= 3;
        state.billing_name.error = state.billing_name.isValid
          ? ""
          : "Please enter valid name";
        break;
      case "billing_mobile":
        state.billing_mobile.isValid = null !== value.match(/^[6-9]\d{9}$/);
        state.billing_mobile.error = state.billing_mobile.isValid
          ? ""
          : " Please enter valid mobile number";
        break;
      case "billing_email":
        state.billing_email.isValid = ((value === null || value === undefined) ? false : null !== value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i));
        state.billing_email.error = state.billing_email.isValid
          ? ""
          : "Please enter valid email";
        break;
      case "shipping_name":
        state.shipping_name.isValid = value.length >= 3;
        state.shipping_name.error = state.shipping_name.isValid
          ? ""
          : "Please enter valid name";
        break;
      case "shipping_mobile":
        state.shipping_mobile.isValid = null !== value.match(/^[6-9]\d{9}$/);
        state.shipping_mobile.error = state.shipping_mobile.isValid
          ? ""
          : "Please enter valid mobile number";
        break;
      case "shipping_email":
        state.shipping_email.isValid = ((value === null || value === undefined) ? false : null !== value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i));
        state.shipping_email.error = state.shipping_email.isValid
          ? ""
          : "Please enter valid email";
        break;
      default:
        break;
    }
    // this.setState(state, this.validateForm);
    this.setState({ state }, () => {
      this.validateForm()
    })
  }

  validateForm() {
    if (this.state.shipping_same_as_billing) {
      this.setState({
        formValid:
          this.state.billing_name.isValid &&
          this.state.billing_email.isValid &&
          this.state.billing_mobile.isValid,
      });

      return;
    }

    this.setState({
      formValid:
        this.state.billing_name.isValid &&
        this.state.billing_email.isValid &&
        this.state.billing_mobile.isValid &&
        this.state.shipping_name.isValid &&
        this.state.shipping_email.isValid &&
        this.state.shipping_mobile.isValid,
    });
  }
  Shimmer = (props) => {
    return (
      <div className="container py-3 py-md-5">
        <div className="row">
          <div className="col-md-7">
            <div className="box shine box-voucher-detail-logo mb-2"></div>
            <div className="box shine w-100 mb-2" style={{ height: "60px" }}></div>
            <div className="box shine box-voucher-detail-logo"></div>
          </div>
          <div className="col-md-5 pt-4 pt-md-0">
            <div className="box shine w-100" style={{ height: "40px" }}></div>
            <div className="box shine w-100" style={{ height: "300px" }}></div>
            <div className="box shine w-100" style={{ height: "38px" }}></div>
            <div className="box shine w-100" style={{ height: "70px" }}></div>
            <div className="box shine w-100" style={{ height: "70px" }}></div>
            <div className="box shine w-100" style={{ height: "70px" }}></div>
            <div className="box shine w-100" style={{ height: "70px" }}></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      this.state.pageLoading ?
        <this.Shimmer /> :
        <div className="container">
          <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
            <div className="col-12">
              <div className="row align-items-center">
                <div className="col-12">
                  <small>
                    <Link to="/">
                      <FormattedMessage
                        id="breadcrumbs.home"
                        defaultMessage="Home"
                      />
                    </Link>
                  </small>{" "}
                  /{" "}
                  <small>
                    <Link to="/vouchers">
                      <FormattedMessage
                        id="home.vouchers"
                        defaultMessage="Vouchers"
                      />
                    </Link>
                  </small>{" "}
                  /{" "}
                  <small>
                    <strong>
                      <FormattedMessage
                        id="vouchers.buy-vouchers"
                        defaultMessage="Buy Voucher"
                      />
                    </strong>
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-md-7">
                  <this.BillingDetails />
                  <this.ShippingDetails />
                </div>
                <div className="col-12 col-md-5">
                  <this.Controls />
                  {this.state.payment_methods && this.state.payment_methods.length > 0 ?
                    <this.Payments /> :
                    <></>
                  }
                  {this.state.load ?
                    <div className="mb-md-5 mb-3">
                      <InlineLoader />
                    </div> :
                    !this.state.load && this.state.formValid && this.state.proceedPay ? (
                      <button
                        className="animated-btn mt-3 mt-md-2 mb-3 mb-md-5"
                        onClick={this.onInitOtpValidation}
                      >
                        PAY
                        {" "}{this.state.proceedPay}
                      </button>
                    ) : !this.state.load && this.state.formValid && this.state.gc_wallet_enabled ?
                      (
                        <button
                          className="animated-btn mt-3 mt-md-2 mb-3 mb-md-5"
                          onClick={this.onInitOtpValidation}
                        >
                          <FormattedMessage id="pay-via-voucher"
                            defaultMessage="PAY VIA VOUCHER"
                          />
                        </button>
                      ) : !this.state.load && this.state.formValid && this.state.wallet_enabled ?
                        (
                          <button
                            className="animated-btn mt-3 mt-md-2 mb-3 mb-md-5"
                          onClick={this.onInitOtpValidation}
                          >
                            <FormattedMessage id="pay-via-wallet"
                              defaultMessage="PAY VIA WALLET"
                            />
                          </button>
                        ) :
                        (
                          <button
                            className="animated-btn-disabled mt-3 mt-md-2 mb-3 mb-md-5"
                            disabled
                          // onClick={this.onInitLogin}
                          >
                            <FormattedMessage id="billpay.proceed-to-pay"
                              defaultMessage="PROCEED TO PAY"
                            />
                          </button>
                        )
                  }
                </div>
              </div>
            </div>
          </div>
          {this.state.isOtpValidationModalOpen && (
            <OtpValidationModal
            isOtpValidationModalOpen={this.state.isOtpValidationModalOpen}
            closeOtpValidationModal={this.closeOtpValidationModal}
            onVerifyOTP={(otpStatus) =>{
              otpStatus && this.onInitOrder()
            }}
            />
          )}
        </div>
    );
  }

  BillingDetails = (props) => {
    return (
      <div className="card mb-3 mb-md-4">
        <div className="card-header">
          <h6 className="font-proxima-nova-bold mb-0">
            <FormattedMessage id="vouchers.billing-details" />
          </h6>
        </div>
        <div className="card-body">
          <div className="form-group">
            <label>
              <FormattedMessage id="vouchers.billing-name" /><sup className="text-danger">*</sup>
            </label>
            <input
              type="text"
              name="billing_name"
              value={this.state.billing_name.value}
              className={classNames("form-control", {
                "is-valid": this.state.billing_name.isValid,
                "is-invalid":
                  !this.state.billing_name.isPristine &&
                  !this.state.billing_name.isValid,
              })}
              onChange={(event) => {
                this.handleUserInput(event);
              }}
            />
            <small className="text-danger">{this.state.billing_name.error}</small>
          </div>
          <div className="form-group">
            <label>
              <FormattedMessage id="vouchers.billing-mobile" /><sup className="text-danger">*</sup>
            </label>
            <input
              type="text"
              name="billing_mobile"
              value={this.state.billing_mobile.value}
              className={classNames("form-control", {
                "is-valid": this.state.billing_mobile.isValid,
                "is-invalid":
                  !this.state.billing_mobile.isPristine &&
                  !this.state.billing_mobile.isValid,
              })}
              onChange={(event) => {
                this.handleUserInput(event);
              }}
            />
            <small className="text-danger">{this.state.billing_mobile.error}</small>
          </div>
          <div className="form-group">
            <label>
              <FormattedMessage id="vouchers.billing-email" /><sup className="text-danger">*</sup>
            </label>
            <input
              type="text"
              name="billing_email"
              value={this.state.billing_email.value}
              className={classNames("form-control", {
                "is-valid": this.state.billing_email.isValid,
                "is-invalid":
                  !this.state.billing_email.isPristine &&
                  !this.state.billing_email.isValid,
              })}
              onChange={(event) => {
                this.handleUserInput(event);
              }}
              required
            />
            <small className="text-danger">{this.state.billing_email.error}</small>
          </div>
        </div>
      </div>
    );
  };

  ShippingDetails = (props) => {
    return (
      <div className="card mb-3 mb-md-4">
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <h6 className="font-proxima-nova-bold mb-0">
                <FormattedMessage id="vouchers.shipping-details" />
              </h6>
            </div>
            <div className="col-12 text-md-right col-md-6">
              <div className="form-check form-check-inline cursor-pointer">
                <input
                  id="same"
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  checked={this.state.shipping_same_as_billing}
                  onChange={this.onShippingCheckboxChange}
                />
                <label className="form-check-label cursor-pointer" htmlFor="same">
                  <FormattedMessage id="vouchers.same-as-billing-details" />
                </label>
              </div>
            </div>
          </div>
        </div>

        {!this.state.shipping_same_as_billing && (
          <div className="card-body">
            <div className="form-group">
              <label>
                <FormattedMessage id="vouchers.shipping-name" />
              </label>
              <input
                type="text"
                name="shipping_name"
                value={this.state.shipping_name.value}
                className={classNames("form-control", {
                  "is-valid": this.state.shipping_name.isValid,
                  "is-invalid":
                    !this.state.shipping_name.isPristine &&
                    !this.state.shipping_name.isValid,
                })}
                onChange={(event) => {
                  this.handleUserInput(event);
                }}
              />
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id="vouchers.shipping-mobile" />
              </label>
              <input
                type="text"
                name="shipping_mobile"
                value={this.state.shipping_mobile.value}
                className={classNames("form-control", {
                  "is-valid": this.state.shipping_mobile.isValid,
                  "is-invalid":
                    !this.state.shipping_mobile.isPristine &&
                    !this.state.shipping_mobile.isValid,
                })}
                onChange={(event) => {
                  this.handleUserInput(event);
                }}
              />
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id="vouchers.shipping-email" />
              </label>
              <input
                type="text"
                name="shipping_email"
                value={this.state.shipping_email.value}
                className={classNames("form-control", {
                  "is-valid": this.state.shipping_email.isValid,
                  "is-invalid":
                    !this.state.shipping_email.isPristine &&
                    !this.state.shipping_email.isValid,
                })}
                onChange={(event) => {
                  this.handleUserInput(event);
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  };


  Cashback = (props) => {
    const { cashback_details } = this.state;
    return (
      cashback_details?.can_display ?
        <div className="voucher-cashback mt-3">
          <div className="row">
            <div className="col-12">
              <h6 className="d-flex align-items-center m-0">
                <img src={ic_save} width="25px" style={{ marginRight: "10px" }} alt="cashback" />
                {cashback_details?.display_string}
              </h6>
            </div>
          </div>
        </div> :
        <></>
    )
  }

  Controls = (props) => {
    const { image_url, name } = this.props.voucher;
    const { value } = this.props.denomination;
    const { cart } = this.state;
    return (
      <>
        <div
          className="card mb-3 mb-md-4"
          style={{
            backgroundColor: "#F8F9FA",
            borderRadius: "8px",
            border: "none",
          }}
        >
          <div className="card-body" style={{ borderRadius: "8px 8px 0 0" }}>
            <div className="row align-items-center">
              <div className="col-10">
                <img
                  style={{
                    height: "50px",
                    background: "#fff",
                    borderRadius: "8px",
                  }}
                  src={image_url}
                  alt={name}
                />
                <p
                  style={{ fontSize: "15px", display: "inline" }}
                  className="ml-2"
                >
                  <strong>{name}</strong>
                </p>
              </div>
              <div className="col-2">
                <div className="align-middle d-flex justify-content-end">
                  <p className="mb-0">
                    <strong>{value}</strong>
                  </p>
                </div>
              </div>
            </div>
            {this.Cashback()}
          </div>
          <div
            className="card-footer pt-4 pb-4"
            style={{
              border: "none",
              borderRadius: "0 0 8px 8px",
              borderTop: "solid 1px #E8E8E8",
            }}
          >

            {cart.map((item, index) => {
              return (
                <div className="row mb-2">
                  <div className="col-6">
                    <p className="mb-0">{item.status_text_translated}</p>
                  </div>
                  <div className="col-6 text-right">
                    <p className="mb-0">
                      <strong>{item.value}</strong>
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {
          // this.state.wallet_info?.can_show_wallet &&
          <>
            <div className={`card-body payment-card mb-2 cursor-pointer ${this.state?.wallet_info?.can_show_wallet ? "" : "wallet-disable"}`} >
              <div className="row align-items-center" onClick={()=>this.handleWallet()}>
                <div className="col-8 d-flex">
              <img src={this.state.wallet_enabled ?ic_checkbox_active:ic_checkbox_empty} alt="selection-img"/>
                  <div className="payment-card-top d-flex flex-row align-items-center m-0">
                    <h3 className="m-0 ml-4">
                      <FormattedMessage
                        id="wallet.wallet-balance"
                        defaultMessage="Wallet Balance"
                      />
                    </h3>
                  </div>
                </div>
                <div className="col-4">
                  <div className="payment-card-top d-flex flex-row justify-content-end align-items-center">
                    <h3 className="m-0">₹ {this.state.wallet_balance}</h3>
                  </div>
                </div>
              </div>
            </div>
            <p style={{ color: "red" }}>{this.state?.wallet_info?.min_balance_text}</p>
          </>
        }

        {
          this.state.gc_wallet_info?.can_show_wallet &&
          <>
            <div className={`card-body payment-card mt-3 mb-2 cursor-pointer ${this.state?.gc_wallet_info?.can_show_wallet ? "" : "wallet-disable"}`}>
              <div className="row align-items-center" onClick={()=>this.handleGCWallet()}>
                <div className="col-9 d-flex">
                 <img src={this.state.gc_wallet_enabled ?ic_checkbox_active:ic_checkbox_empty} alt="selection-img"/>
                  <div className="payment-card-top d-flex flex-row align-items-center m-0">
                    <h3 className="m-0 ml-4">
                      <FormattedMessage
                        id="wallet.gc_wallet-balance"
                        defaultMessage="KAMAIE E-Gift Voucher"
                      />
                    </h3>
                  </div>
                </div>
                <div className="col-3">
                  <div className="payment-card-top d-flex flex-row justify-content-end align-items-center m-0">
                    <h3 className="m-0">₹ {this.state.gc_wallet_balance}</h3>
                  </div>
                </div>
              </div>
            </div>
            {/* <p style={{ color: "red" }}>{this.state?.gc_wallet_info?.min_balance_text}</p> */}
          </>
        }
      </>
    );
  };

  Payments = (props) => {
    return (
      <div
        className="card mt-3 mt-md-5 mb-3 mb-md-2"
        style={{
          backgroundColor: "#FFF",
          borderRadius: "8px",
          border: "none",
        }}
      >
        <h3 className="choose">
          <FormattedMessage id="payment-options" defaultMessage="Payment Options" />
        </h3>
        {/* eslint-disable-next-line array-callback-return */}
        {this.state.payment_methods.map((item, index) => {
          if (item.identifier === "upi")
            return (
              <div id={"b" + item.id} className={"card-body payment-card mb-2 cursor-pointer " + (item.is_selected ? 'select-border' : '')} >
                <div className="row align-items-center" onClick={()=>this.handlePayment(item.id)}>
                  <div className="col-2">
                    <img src={ic_upi} alt="upi" />
                  </div>
                  <div className="col-8 px-1">
                    <div className="payment-card-top d-flex flex-row align-items-center">
                      <h3 className="m-0">UPI</h3>
                      <div className="line"></div>
                      <p className="m-0">{item.display_string}</p>
                    </div>
                    <div className="payment-card-bottom d-flex flex-row align-items-center">
                      <img src={ic_phonepe} alt="phonepe" />
                      <img src={ic_gpay} alt="gpay" />
                      <img src={ic_paytm} alt="paytm" />
                      <img src={ic_bhim} alt="bhim" />
                      <img src={ic_more} alt="more" />
                      {/* <p className="m-0" >MORE</p> */}
                    </div>
                  </div>
                  <div className="col-2">
                    <img src={item.is_selected ?ic_checkbox_active:ic_checkbox_empty} alt="selection-img" className="cart-selected-tic"/>
                  </div>
                </div>
              </div>
            )

          if (item.identifier === "card")
            return (
              <div id={"b" + item.id} className={"card-body payment-card mb-2 cursor-pointer " + (item.is_selected ? 'select-border' : '')}>
                <div className="row align-items-center" onClick={()=>this.handlePayment(item.id)}>
                  <div className="col-2">
                    <img src={ic_debit_card} alt="upi" />
                  </div>
                  <div className="col-8 px-1">
                    <div className="payment-card-top d-flex flex-row align-items-center">
                      <h3 className="m-0">Cards</h3>
                      <div className="line"></div>
                      <p className="m-0">{item.display_string}</p>
                    </div>
                    <div className="payment-card-bottom d-flex flex-row align-items-center">
                      <p className="m-0" >Master Card, Visa, Rupay and more</p>
                    </div>
                  </div>
                  <div className="col-2">
                  <img src={item.is_selected ?ic_checkbox_active:ic_checkbox_empty} alt="selection-img" className="cart-selected-tic"/>
                  </div>
                </div>
              </div>
            )

          if (item.identifier === "netbanking")
            return (
              <div id={"b" + item.id} className={"card-body payment-card mb-2 cursor-pointer " + (item.is_selected ? 'select-border' : '')}>
                <div className="row align-items-center" onClick={() => this.handlePayment(item.id)}>
                  <div className="col-2">
                    <img src={ic_netbanking} alt="upi" />
                  </div>
                  <div className="col-8 px-1">
                    <div className="payment-card-top d-flex flex-row align-items-center">
                      <h3 className="m-0">Netbanking</h3>
                      <div className="line"></div>
                      <p className="m-0">{item.display_string}</p>
                    </div>
                    <div className="payment-card-bottom d-flex flex-row align-items-center">
                      <p className="m-0" >Pay with all indian banks</p>
                    </div>
                  </div>
                  <div className="col-2">
                  <img src={item.is_selected ?ic_checkbox_active:ic_checkbox_empty} alt="selection-img" className="cart-selected-tic"/>
                  </div>
                </div>
              </div>
            )

          if (item.identifier === "wallet")
            return (
              <div id={"b" + item.id} className={"card-body payment-card mb-2 cursor-pointer " + (item.is_selected ? 'select-border' : '')}>
                <div className="row align-items-center" onClick={() => this.handlePayment(item.id)}>
                  <div className="col-2">
                    <img src={ic_wallet} alt="upi" />
                  </div>
                  <div className="col-8 px-1">
                    <div className="payment-card-top d-flex flex-row align-items-center">
                      <h3 className="m-0">Wallets</h3>
                      <div className="line"></div>
                      <p className="m-0">{item.display_string}</p>
                    </div>
                    <div className="payment-card-bottom d-flex flex-row align-items-center">
                      <img src={ic_phonepe} alt="phonepe" />
                      <img src={ic_paytm} alt="paytm" />
                      <img src={ic_airtel} alt="airtel" />
                      <img src={ic_jiomoney} alt="jio" />
                      <img src={ic_more} alt="more" />
                      {/* <p className="m-0" >MORE</p> */}
                    </div>
                  </div>
                  <div className="col-2">
                  <img src={item.is_selected ?ic_checkbox_active:ic_checkbox_empty} alt="selection-img" className="cart-selected-tic"/>
                  </div>
                </div>
              </div>
            )

        })}

      </div>
    );
  };

}

function mapStateToProps(state) {
  return {
    voucher: state.voucher.voucher,
    denomination: state.voucher.denomination,
    quantity: state.voucher.quantity,
    profile: state.global.profile,
    cashback: state.voucher.cashback,
    giftDetails: state.voucher.giftDetails
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setQuantity: (quantity) => {
      dispatch(setQuantity(quantity));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Cart)));
