import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ic_user from "../../../../assets/prepaid_cards/ic_user.svg";
import ic_mobile from "../../../../assets/prepaid_cards/ic_mobile.svg";
import ic_document from "../../../../assets/prepaid_cards/ic_document.svg";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import Lottie from "lottie-react";
import ic_waiting from "../../../../assets/prepaid_cards/ic_loader.json"
import { Button, Modal } from "react-bootstrap";
import prepaidCardClientX from "../../../../utils/api/prepaidCardClientX";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ic_kyc from "../../../../assets/prepaid_cards/ic_kyc_document.png";
import { setShippingRedirectionData } from "../../actions";


const DocumentView = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { formData, selectedDocumentData, kycUniqueID, selectedCardData } = useSelector((state) => state?.ppi)
  const { profile } = useSelector((state) => state?.global)

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [RedirectionURLData, setRedirectionURLData ] = useState(null)
  const payload = { ...formData }

  const submitDocuments = () => {
    handleShow()
    setSubmitLoading(true)

    const authConfig = {
      headers: {
        TempAuthToken: profile?.temp_ppi_token,
      }
    }

    "state" in payload && getStateCode()
    delete payload['name']
    delete payload['mobile']
    payload['minKycUniqueId'] = kycUniqueID
    payload['documentType'] = selectedDocumentData.document_id
    payload['kycConsent'] = 1
    payload['programTnC'] = 1
    payload['pepDeclaration'] = 1
    payload['pineLabsPrivacyPolicy'] = 1

    prepaidCardClientX.post(`/verify-min-kyc`, payload, authConfig)
    .then((response) => {
      let data = response.data.data
      setRedirectionURLData(data)
      let payload = {
        redirectType : data?.page_redirection_segment,
        name : formData?.name
      }
      dispatch(setShippingRedirectionData(payload))
      setSubmitSuccess(true)
      setSubmitLoading(false)
    })
    .catch(error=>{
      setSubmitLoading(false)
      handleClose()
    })
  }

  const handlePostPaymentRedirection = () => {
    if(RedirectionURLData?.page_redirection_segment === 'shipping_address_details_page')
      history.push('/prepaid-cards/shipping/edit')
    else
      history.push('/')
  }

  const getStateCode = () => {
    // eslint-disable-next-line array-callback-return
    selectedDocumentData && selectedDocumentData.document_types.map(item => {
      if (item.display_name === "State") {
        // eslint-disable-next-line array-callback-return
        item.options.map(state => {
          if (payload['state'] === state.name)
            payload['state'] = state.code
        })
      }
    })
  }

  const WaitingResponseModal = () => {
    return (
      <div className="p-3 p-md-5 text-center d-flex flex-column justify-content-center align-items-center">
        <span className="h4 font-weight-bold"></span>
        <div className="p-4" style={{ width: "200px" }}>
          <Lottie animationData={ic_waiting} loop={true} />
        </div>
        <span className="h4 font-weight-bold">Waiting for Response!</span>
        <p>Kindly avoid closing or forcefully terminating the application</p>
      </div>
    )
  }

  const SuccessResponseModal = () => {
    return (
      <div
        className="p-4 p-md-5 text-center d-flex flex-column justify-content-center align-items-center"
        style={{ background: "linear-gradient(180deg, #009BA3 0%, #5BCDBB 100%)" }}
      >
        <div className="p-4 text-white mb-5" style={{ width: "180px", "background": "#57CABA", "height": "180px", "borderRadius": "50%" }}>
          <img src={ic_kyc} alt="kyc" width="100%" />
        </div>
        <span className="h4 font-weight-bold text-white mb-3">Your KYC documents has been submitted successfully!</span>
        <Button style={{ background: "white", color: "black", width: "auto" }} className="animated-btn  mx-auto py-3 px-5 my-2"
          onClick={() => {
            handleClose()
            handlePostPaymentRedirection()
          }}>
          Continue
        </Button>
        <Link to="/">
        </Link>
      </div>
    )
  }


  return (
    <>
      <div className="my-4 mb-5">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-sm-8 col-md-5">
              <p className="cardkyc-title text-center bold m-1 m-sm-2 m-md-2">
              {selectedCardData?.cardPlan} CARD KYC
              </p>
              <p className="cardkyc-subtitle text-center">
                Provide your personal details
              </p>
              <div className="d-flex flex-row justify-content-center align-items-center mt-2">
                <div className="p-1 col-1 pr-5 pr-lg-5">
                  <img src={ic_mobile} alt="img"></img>
                </div>
                <div className="p-1 col-10 col-md-8 my-2">
                  <p className="cardedit-head-text">Mobile Number</p>
                  <p className="cardedit-body-text ">+91 {formData?.mobile}</p>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-center align-items-center my-3">
                <div className=" p-1 col-1 pr-5 pr-lg-5">
                  <img src={ic_user} alt="img"></img>
                </div>
                <div className="p-1 col-10 col-md-8 mt-2">
                  <p className="cardedit-head-text">Full Name</p>
                  <p className="cardedit-body-text ">{formData?.name}</p>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-center align-items-center my-3">
                <div className=" p-1 col-1 pr-5 pr-lg-5">
                  <img src={ic_document} alt="img"></img>
                </div>
                <div className="p-1 col-10 col-md-8 mt-2">
                  <p className="cardedit-head-text">Choose Document Type</p>
                  <p className="cardedit-body-text ">{selectedDocumentData?.display_document_type}</p>
                </div>
              </div>
              {
                selectedDocumentData &&
                selectedDocumentData?.document_types.map(item => {
                  return (
                    <div className="d-flex flex-row justify-content-center align-items-center my-3">
                      <div className=" p-1 col-1 pr-5 pr-lg-5">
                        <img width="35px" src={item?.info_icon_url} alt="img"></img>
                      </div>
                      <div className="p-1 col-10 col-md-8 mt-2">
                        <p className="cardedit-head-text ">{item?.display_name}</p>
                        <p className="cardedit-body-text" >{formData[item?.kyc_input_identifier]}</p>
                      </div>
                    </div>
                  )
                })
              }



              <button className="animated-btn-border mb-3" onClick={() => { history.push('/prepaid-cards/small-kyc/document/edit') }}>EDIT DETAILS</button>

              <button className="animated-btn " onClick={() => submitDocuments()}>CONTINUE</button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        {submitLoading && WaitingResponseModal()}
        {submitSuccess && SuccessResponseModal()}
      </Modal>
    </>
  );
}

export default DocumentView;