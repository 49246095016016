import React from "react";
import step1 from "../../assets/account_deletion/1.jpg"
import step2 from "../../assets/account_deletion/2.jpg"
import step3 from "../../assets/account_deletion/3.jpg"
import step4 from "../../assets/account_deletion/4.jpg"
import step5 from "../../assets/account_deletion/5.jpg"
import step6 from "../../assets/account_deletion/6.jpg"
import step7 from "../../assets/account_deletion/7.jpg"
import step8 from "../../assets/account_deletion/8.jpg"
import step9 from "../../assets/account_deletion/9.jpg"


function CaptainAccountDeletion() {
    return (
        <div className="home-page pt-0 pb-0 pt-md-5 pb-md-5" >
        <div className="container custom-container ">
            <div>
                <h1 className="title mb-2 text-center">Steps to Delete Captain Account</h1>
            </div>
            <hr className="w-100 mb-4" />


            <div className="row">
                <div className="col-12">
                    <div className="image-list">
                        <p className="desc"><b>Step 1.</b> Launch Captain app and Login with Valid credentails.</p>
                    </div>
                    {/* <hr className="w-100 mb-4" /> */}

                    <div className="image-list">
                        <p className="desc mt-4"><b>Step 2.</b> Go to Profile page</p>
                    </div>
                    <img className="image-width" src={step1} alt="img" title="img" />
                    <hr className="w-100" />

                    <div className="image-list">
                        <p className="desc mt-4"><b>Step 3.</b> Go to Settings page</p>
                    </div>
                    <img className="image-width" src={step2} alt="img" title="img" />
                    <hr className="w-100" />

                    <div className="image-list">
                        <p className="desc mt-4"><b>Step 4.</b> Select Account Management</p>
                    </div>
                    <img className="image-width" src={step3} alt="img" title="img" />
                    <hr className="w-100" />

                    <div className="image-list">
                        <p className="desc mt-4"><b>Step 5.</b> Select Delete Account</p>
                    </div>
                    <img className="image-width" src={step4} alt="img" title="img" />
                    <hr className="w-100" />

                    <div className="image-list">
                        <p className="desc mt-4"><b>Step 6.</b> Select Yes to Give Confirmation to Delete Captain Account</p>
                    </div>
                    <img className="image-width" src={step5} alt="img" title="img" />
                    <hr className="w-100" />

                    <div className="image-list">
                        <p className="desc mt-4"><b>Step 7.</b> Send OTP on Delete Account page</p>
                    </div>
                    <img className="image-width" src={step6} alt="img" title="img" />
                    <hr className="w-100" />

                    <div className="image-list">
                        <p className="desc mt-4"><b>Step 8.</b> Enter the OTP received via SMS in Enter OTP field.</p>
                    </div>
                    <img className="image-width" src={step7} alt="img" title="img" />
                    <hr className="w-100" />

                    <div className="image-list">
                        <p className="desc mt-4"><b>Step 9.</b> Tap on Verify OTP</p>
                    </div>
                    <img className="image-width" src={step8} alt="img" title="img" />
                    <hr className="w-100" />

                    <div className="image-list">
                        <p className="desc mt-4"><b>Step 10.</b> Captain account is successfully deleted.</p>
                    </div>
                    <img className="image-width" src={step9} alt="img" title="img" />
                    {/* <hr className="w-100" /> */}

                    <div className="mt-3 text-center">
                        <span className="desc mt-4 font-weight-bold ">
                            Note: Once the Account is deleted all the details associated with the user will be deleted.
                        </span>
                    </div>
                    <hr className="w-100" />

                    <p className="text-center mb-4">For any issues /queries or concern contact Customer Support on the toll-free
                        number <a className="font-weight-bold" href="tel:18001031032">18001031032</a> or mail us at <a
                            className="font-weight-bold" href="mailto:support@Kamaie.com">support@Kamaie.com</a> </p>
                    <hr className="w-100" />
                </div>
            </div>

            <div className="row my-4">
                <div className="col-12">
                    <div className="text-center">
                        <small>© Copyright 2024 । Winds E Private Limited.</small>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default CaptainAccountDeletion;