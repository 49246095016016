import React, { useState } from 'react'
import ic_spent from '../../../../assets/prepaid_cards/ic_spent.svg'
import prepaidCardClientX from '../../../../utils/api/prepaidCardClientX'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import EmptyState from '../../../../components/EmptyState'
import ic_credited from '../../../../assets/prepaid_cards/ic_credetted.svg'
// import ic_refund from '../../../../assets/prepaid_cards/ic_refund.svg'
// import ic_reversal from '../../../../assets/prepaid_cards/ic_reversal.svg'
import EmptyImage from "../../../../assets/prepaid_cards/ic_empty_state.png";
import ic_pine_labs from "../../../../assets/prepaid_cards/ic_pine_labs.svg";
import { FormattedMessage } from 'react-intl'
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ic_back_arrow from '../../../../assets/ic_back_arrow.svg';




export default function Statements() {
  const { cardDetails } = useSelector((state) => state?.ppi)
  const { profile } = useSelector((state) => state?.global)

  const [statementsLoad, setStatementsLoad] = useState(false)
  const [statementsData, setStatementsData] = useState(null)
  const [filterData, setFilterData] = useState(null)



  useEffect(() => {
    getStatements()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {

  }, [])

  const Shimmer = () => {
    return (
      <div>
        <div className="box shine m-1" style={{ height: "100px" }}></div>
        <div className="box shine m-1" style={{ height: "100px" }}></div>
        <div className="box shine m-1" style={{ height: "100px" }}></div>
        <div className="box shine m-1" style={{ height: "100px" }}></div>
        <div className="box shine m-1" style={{ height: "100px" }}></div>
      </div>
    )
  }

  const [FilterCategory, setFilterCategory] = useState([
    { id: 0, name: 'All', active: true },
    { id: 1, name: 'Credit', active: false },
    { id: 2, name: 'Debit', active: false },
  ]);

  const getStatements = () => {
    const authConfig = {
      headers: {
        TempAuthToken: profile?.temp_ppi_token,
      }
    }

    setStatementsLoad(true)
    prepaidCardClientX.get(`/get-card-trans-history/${cardDetails?.referenceNumber}`, authConfig)
      .then((response) => {
        const data = response.data?.data
        setStatementsLoad(false)
        setStatementsData(data)
        setFilterData(data)
      })
      .catch(error => {
        setStatementsLoad(false)
      })
  }


  const FilterByKey = (key) => {
    const updatedFilterCategory = FilterCategory.map(item => {
      if (item.id === key) {
        return { ...item, active: !item.active };
      } else {
        return { ...item, active: false };
      }
    });

    setFilterCategory(updatedFilterCategory);

    if (key !== 0) {
      const filteredResult = filterData?.transaction_history?.filter(item => item.transactionType === key);
      setStatementsData({
        transaction_history: filteredResult
      });
    }
    else setStatementsData({
      transaction_history: filterData.transaction_history
    });
  }



  const WebFIlter = () => {
    return (
      <div className="col-3 border-right d-none d-md-block">
        <div className="row border-bottom filter-head align-items-center pb-2">
          <h5
            style={{ marginTop: ".5rem" }}
            className="font-proxima-nova-bold"
          >
            <FormattedMessage id="general.filters" defaultMessage="Filters" />
          </h5>
        </div>
        <div className='mt-5'>
          {FilterCategory.map((item, index) => {
            return (
              <div
                className="row mt-3"
                key={"nearby-partner-category-box-" + index}
              >
                <div className="col-9">{item.name}</div>
                <div className="col-3 mb-1 text-center">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={"shopping-sites-category-" + item.id}
                      name={item.name}
                      checked={item.active}
                      onChange={() => {
                        FilterByKey(item.id)
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={"shopping-sites-category-" + item.id}
                    ></label>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    )
  }

  const MobileFilter = () => {
    return (
      <div className='col-12 d-block d-md-none'>
        <ToggleButtonGroup type="checkbox"
          className="d-flex flex-wrap toggle-group">
          {FilterCategory?.map((item, index) =>
            <ToggleButton className="mx-2 rounded-pill" value={item.id} variant={item.active ? "btn statement-active-btn":"outline-light"} onClick={() => FilterByKey(item.id)}>{item.name}</ToggleButton>
          )}
        </ToggleButtonGroup>
      </div>
    )
  }

  return (
    <div className="mt-md-5 mb-md-5 mt-4 mb-4">
      <div className="container ppi-card-limit">
        <div className="row d-flex ">
          <WebFIlter />
          <div className="col-12 col-md-9 text-center">
            <div className='d-flex justify-content-between align-items-center border-bottom mb-md-4 mb-3 pb-2'>

              <div>
                <Link to="/prepaid-cards/managecard">
                  <span style={{ textDecoration: "underline" }} className='d-none d-md-block'>Go back</span>
                  <span className='d-block d-md-none'><img style={{ width: "13px" }} src={ic_back_arrow} alt="arrow" /></span>

                </Link>
              </div>
              <p className="cardkyc-title text-center mb-0">Statement</p>
              <img src={ic_pine_labs} alt="pinelabs icon" />
            </div>
            <MobileFilter />

            {
              statementsLoad ?
                <Shimmer /> :
                <>
                  {
                    statementsData?.transaction_history && statementsData?.transaction_history.length > 0 ?
                      <>
                        {statementsData?.transaction_history?.map(item => {
                          return (
                            <div style={{ borderBottom: "2px solid #d8d8d82e" }} className="w-100 d-flex justify-content-between my-3 pb-3">
                              <div className="d-flex">
                                <span>
                                  {
                                    item?.transactionTypeText === "Credit" ?
                                      <img src={ic_credited} alt='receive' className="statement-icon mr-2 mt-1"></img> :
                                      <img src={ic_spent} alt='receive' className="statement-icon mr-2 mt-1"></img>
                                  }
                                </span>
                                <span className="text-left">
                                  <p className="statement-header m-0 " >{item?.transactionText}</p>
                                  {/* <p className="statement-body m-0">{item?.transactionText}</p> */}
                                  <p className="statement-body m-0">{item?.transactionTypeText}</p>
                                </span>
                              </div>
                              <div className="text-right">
                                <p className="statement-header m-0 bold">₹ {item?.transactionAmount}</p>
                                <p className="statement-body m-0">{item?.transactionDate}</p>
                              </div>
                            </div>
                          )
                        })
                        }
                      </> :
                      <EmptyState
                        title="Nothing to see yet!"
                        description="You don’t have any history to show here"
                        image={EmptyImage} />
                  }
                </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}