import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import voucherbg from "../../../assets/home/ic_voucher.svg"
export default class VoucherCarousel extends Component {
  state = {
    show: false,
    isMultipleVoucherAvailable: false,
    selected: 0,
    redirect: false,
  };

  getVoucherDetail = (voucher, index) => {
    if (voucher.is_internal > 0) {
      this.setState(
        {
          isMultipleVoucherAvailable: true,
          selected: index,
          redirect: false,
        }
      );
    } else {
      this.setState({
        isMultipleVoucherAvailable: false,
        selected: index,
        redirect: true,
      });
    }
  };

  toggleBookmark = (event, id, isBookmarked) => {
    event.preventDefault();
    this.props.onToggleBookmarkVouchers(
      id,
      isBookmarked,
      `vouchers/${id}/bookmark`,
      "voucher"
    );
  };

  render() {
    return (
      <div className="col-12 d-flex flex-wrap justify-content-between">
        {/* eslint-disable-next-line array-callback-return */}
        {this.props.vouchers.map((item, index) => {
          let name;
          if (item.cashback_type === "fixed_value" && item.cashback !== 0) {
            name = `CASHBACK ₹${item.cashback}`
          }
          else if (item.cashback_type === "percentage" && item.cashback !== 0) {
            name = `CASHBACK ${item.cashback}%`
          }
          else if (item.customer_discount > 0) {
            name = `${item.customer_discount}% OFF`
          }
          else {
            name = `KAMAIE Coins`
          }

          if (index < 8)
            return (
              <div className="voucher-card" key={"voucher-" + index}>
                <div className="home-voucher-box d-flex justify-content-center" onClick={() => this.getVoucherDetail(item, index)}>
                  <img className="bg" src={voucherbg} alt={item.name} title={item.name} />
                  <img className="logo" src={item.web_image_url} alt={item.name} title={item.name}/>
                  <span className="name">
                    {name}
                  </span>
                </div>
                {this.state.redirect && this.state.selected === index && (
                  item?.slug ?
                    <Redirect to={`/voucher/${item.slug}`} /> :
                    <Redirect to={`/voucher/${item.id}`} />
                )}
              </div>
            );
        })}
      </div>

    );
  }
}
