import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import Lottie from "lottie-react";
import ic_success from "../../../assets/prepaid_cards/ic_success_green.json"

export default function SuccessModal(props) {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          className="profile-info-box w-100 text-center success-modal p-md-5 p-3"
        >
          <div className=" wrp-success-modal-body mb-3 pb-5">
            <div className="p-4 text-white w-50 mx-auto">
              <Lottie animationData={ic_success} loop={false} />
            </div>
            <h3 className="text-bold mb-3 text-grey">{props?.title}</h3>
            <h6 classNames="text-bold text-grey">
              {props?.desc}
            </h6>
            <button
              onClick={() =>{
                setShow(false)
                props.onCloseModal();
              }}
              className="animated-btn w-75 wrp-success-btn mt-3"
              style={{ zIndex: "9" }}
            >
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
