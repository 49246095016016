import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Iframe from "../../../components/iFrame";
import { currentlySelectedLanguage } from "../../../utils/helper";

const RedeemTermsCondition = () => {
  const url = process.env.REACT_APP_GOA_TERMS_CONDITION + '/' + currentlySelectedLanguage();
  
  return (
    <div className="container-fluid font-proxima-nova">
      <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
        <div className="col-12">
          <small>
            <Link to="/">
              <FormattedMessage id="breadcrumbs.home" defaultMessage="Home" />
            </Link>{" "}
            /{" "}
            <Link to="/winds-coins">
              <FormattedMessage id="breadcrumbs.redeem-erp" defaultMessage="Redeem KAMAIE Coins" />
            </Link>{" "}
            /{" "}
            <strong>
              <FormattedMessage
                id="footer.terms-and-conditions"
                defaultMessage="Terms & Conditions"
                description="Terms & Conditions"
                values={{ what: "react-intl" }}
              />
            </strong>
          </small>
        </div>
        <div className="col-12 termsCondition font-proxima-nova">
          <Iframe
            // source={`${process.env.REACT_APP_GOA_TERMS_CONDITION
            //     }/${currentlySelectedLanguage()}`}
            source={url}
          ></Iframe>
        </div>
        <div className="col-4 mx-auto">
          <Link to="/winds-coins?isRedeemWrpModelOpen=true">
            <button className="btn btn-primary pl-4 pr-4  mt-3 home-btn"><span>AGREE</span></button>
          </Link>
        </div>
      </div>
      <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
        <div className="col-12">
        </div>
      </div>
    </div>
  )
}


export default RedeemTermsCondition;

