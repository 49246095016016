import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import checkmark from "../../../assets/check-blue.png";
import rewardClientX from "../../../utils/api/rewardClientX";
import InlineLoader from "../../../components/InlineLoader";
import AddressFormDialog from "../../../components/AddressFromDialog";
import profileClientX from "../../../utils/api/profileClientX"
import classNames from "classnames";
import { breakString } from "../../../utils/helper";
import InfoDialog from "../../../components/InfoDialog";
import prizeClaimedImg from "../../../assets/handhake-illustration.svg";
import { CoverPageSkeleton } from "../../../components/skeleton";
import EmptyState from "../../../components/EmptyState";
import EmptyImage from "../../../assets/empty-state-screen.svg";
import { fetchAddresses } from '../../../actions/index';
import { connect } from "react-redux";
class PhysicalPrize extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prizeData: null,
      isLoading: false,
      showAddressForm: false,
      addresses: [],
      address_id: null,
      claimed_at: null,
      showClaimedSuccessMessage: false,
      isAddressFetching: false,
      isPrizeFetching: true,
    };
  }
  onDismiss = () => {
    this.props.history.push(`/rewards`);
  };
  handleClose = (isAddressAdded) => {
    isAddressAdded && this.getAddress();
    this.setState({
      showAddressForm: false,
    });
  };

  claimPrize = () => {
    const {
      match: { params },
    } = this.props;
    const id = params.id;
    this.setState(
      {
        isLoading: true,
      },
      () => {
        rewardClientX
          .post(`/v1/customer/winnings/${id}/claim`, {
            address_id: this.state.address_id,
          })
          .then((response) => {
            this.setState({
              isLoading: false,
              showClaimedSuccessMessage: true,
            });
          })
          .catch((error) => {
            this.setState({ isLoading: false });
          });
      }
    );
  };

  selectAddress = (id) => {
    this.state.prizeData.can_claim &&
      this.setState({
        address_id: id,
      });
  };

  componentDidMount() {
    this.setState(
      { isAddressFetching: true, isPrizeFetching: true, isLoading: true },
      () => {
        if (this.props.addresses.length === 0) {
          this.getAddress();
        } else {
          this.setState({
            //    addresses: response.data.data,
            isAddressFetching: false,
          });
        }
        this.getPrizeDetails();
      }
    );
  }
  getAddress = () => {
    profileClientX.get("v1/addresses").then((response) => {
      this.setState({
        //    addresses: response.data.data,
        isAddressFetching: false,
      });
      this.props.getAddresses(response.data.data)
    });
  };

  getParams = () => {
    const {
      match: { params },
    } = this.props;
    const paramArray = breakString(params.id, "&");
    return {
      card_id: paramArray[0],
      claim_id: paramArray[1],
    };
  };
  getSelectedAddressId = (
    area,
    city,
    street,
    full_name,
    house_details,
    landmark,
    mobile,
    state,
    pincode
  ) => {
    const addresses = this.state.addresses;
    for (
      let index = 0, addressesLength = addresses.length;
      index < addressesLength;
      index++
    ) {
      const item = addresses[index];
      if (
        area === item.area &&
        city === item.city &&
        street === item.street &&
        full_name === item.full_name &&
        house_details === item.house_details &&
        landmark === item.landmark &&
        mobile === item.mobile &&
        state === item.state &&
        pincode === item.pincode
      ) {
        return item.id;
      }
    }
  };

  getPrizeDetails = () => {
    const claim_id = this.getParams().claim_id;
    if (claim_id) {
      rewardClientX
        .get(`/v1/customer/claims/${claim_id}`)
        .then((response) => {
          const {
            area,
            city,
            street,
            full_name,
            house_details,
            landmark,
            mobile,
            state,
            pincode,
            prize,
            created_at,
          } = response.data.data;
          const selectAddressID = this.getSelectedAddressId(
            area,
            city,
            street,
            full_name,
            house_details,
            landmark,
            mobile,
            state,
            pincode
          );
          this.setState({
            prizeData: prize,
            address_id: selectAddressID,
            claimed_at: created_at,
            isPrizeFetching: false,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({ isPrizeFetching: false, isLoading: false });
        });
    } else {
      const id = this.getParams().card_id;
      rewardClientX
        .get(`/v1/customer/winnings/${id}`)
        .then((response) => {
          this.setState({
            prizeData: response.data.data,
            isPrizeFetching: false,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({ isPrizeFetching: false, isLoading: false });
        });
    }
  };

  render() {
    const {
      image_url,
      winning_date,
      display_name,
      name,
      can_claim,
      image,
      description,
    } = this.state.prizeData ? this.state.prizeData : {};
    const { showClaimedSuccessMessage } = this.state;
    return (
      <div className="container-fluid font-proxima-nova">
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            <div className="row align-items-center">
              <div className="col-12">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>{" "}
                  /{" "}
                  <Link to="/rewards">
                    <FormattedMessage
                      id="navbar.rewards"
                      defaultMessage="Cahback-Rewards"
                    />
                  </Link>{" "}
                  / <strong>{name}</strong>
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-md-3 mb-5 offer-detail">
          <div className="col-lg-8 col-12 mt-5 mt-md-0 border p-md-5 p-3 rounded">
            {this.state.isAddressFetching ? (
              <CoverPageSkeleton rows={1} columns={2} height={20} width={30} />
            ) : (
              <React.Fragment>
                <h5>
                  <FormattedMessage
                    id="rewards.select-address"
                    defaultMessage="Select Address"
                  />
                </h5>
                <div className="d-flex justify-content-start flex-wrap">
                  {0 === this.props.addresses.length ? (
                    <div className="empty-state">
                      <EmptyState
                        title={
                          <FormattedMessage
                            id="profile.no-saved-addresses"
                            defaultMessage="You don't have any saved addresses."
                          />
                        }
                        imageWidth={25}
                        image={EmptyImage}
                      />
                    </div>
                  ) : (
                    <React.Fragment>
                      {this.props.addresses.map((item, index) => {
                        return (
                          <div
                            key={`address${index}`}
                            className={classNames(
                              "card",
                              "mb-2",
                              "mt-2",
                              "mt-md-4",
                              "col-md-5",
                              "mr-md-5",
                              {
                                "bg-light rounded":
                                  item.id === this.state.address_id,
                              },
                              {
                                "border-0": item.id === this.state.address_id,
                              },
                              {
                                border: item.id !== this.state.address_id,
                              }
                            )}
                            onClick={() => this.selectAddress(item.id)}
                          >
                            <div className="card-body">
                              <div className="row">
                                <div className="col-8">
                                  <h6>
                                    <FormattedMessage
                                      id="rewards.address"
                                      defaultMessage="Address"
                                    />{" "}
                                    {index + 1}
                                  </h6>
                                </div>
                                <div className="col-4 text-right">
                                  {item.id === this.state.address_id ? (
                                    <span>
                                      <img
                                        src={checkmark}
                                        className="align-text-top"
                                        alt="checkmark"
                                        title="checkmark"
                                      />
                                    </span>
                                  ) : (
                                    <span className="empty-checknmark"></span>
                                  )}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <p
                                    className="mb-0 mt-2 text-secondary"
                                    style={{ fontSize: "1rem" }}
                                  >
                                    {item.house_details}, {item.street},{" "}
                                    {item.landmark}, {item.area}, {item.city},{" "}
                                    {item.state} - {item.pincode}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </React.Fragment>
                  )}
                </div>
                {this.state.showAddressForm ? (
                  <AddressFormDialog
                    handleClose={(isAddressAdded) =>
                      this.handleClose(isAddressAdded)
                    }
                  />
                ) : (
                  <button
                    className="btn btn-outline-primary home-btn mt-4 add-address-btn"
                    onClick={() => this.setState({ showAddressForm: true })}
                  >
                    <FormattedMessage
                      id="rewards.add-new-address"
                      defaultMessage="Add new address"
                    />
                  </button>
                )}
              </React.Fragment>
            )}
          </div>
          <div className="col-12 col-lg-4">
            <div className="offer-card d-flex w-100">
              {this.state.isPrizeFetching ? (
                <CoverPageSkeleton
                  rows={1}
                  columns={1}
                  height={60}
                  width={50}
                  padding={0.1}
                />
              ) : (
                this.state.prizeData && (
                  <React.Fragment>
                    <div className="card-icon rewards-card prize-card">
                      <img
                        src={image_url || image}
                        alt="rewards icon."
                        title="rewards icon."
                        className="d-block"
                      />
                    </div>
                    <div className="border mb-3"></div>
                    <h4>{description || `${display_name} ${name}`}</h4>
                    <p className="text-secondary">
                      <FormattedMessage
                        id="rewards.won-on"
                        defaultMessage="
                  Won on"
                      />{" "}
                      {winning_date}
                    </p>
                    {this.state.claimed_at && (
                      <p className="text-secondary">
                        <FormattedMessage
                          id="rewards.claimed-on"
                          defaultMessage="
                  Claimed on"
                        />{" "}
                        {this.state.claimed_at}
                      </p>
                    )}
                  </React.Fragment>
                )
              )}
            </div>
            {this.state.isLoading ? (
              <InlineLoader />
            ) : (
              can_claim && (
                <button
                  className="btn btn-primary home-btn mt-4"
                  disabled={!this.state.address_id}
                  onClick={this.claimPrize}
                >
                  <FormattedMessage
                    id="rewards.claim-prize"
                    defaultMessage="
                          Claim Prize"
                  />
                </button>
              )
            )}
          </div>
        </div>
        {showClaimedSuccessMessage && (
          <InfoDialog
            title={
              <>
                <FormattedMessage
                  id="general.congrats"
                  defaultMessage="Congratulations!"
                />
              </>
            }
            message={
              <>
                <FormattedMessage
                  id="rewards.just-claimed-prize"
                  defaultMessage="You just claimed your amazing prize. Just hang on for 7-10 working days while we deliver it to your address. Keep transacting with the KAMAIE App to win more such cool rewards!"
                />
              </>
            }
            buttonTxt={
              <>
                <FormattedMessage id="profile.ok" defaultMessage="OK" />
              </>
            }
            url={prizeClaimedImg}
            onDismiss={this.onDismiss}
          />
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    addresses: state.global.addresses,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAddresses: (responseData) => {
      dispatch(fetchAddresses(responseData));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhysicalPrize);
