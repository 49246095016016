import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { showToastMessage } from "../../../utils/helper";
import bannerImage from '../../../assets/bannerImage.png';
import copyIcon from '../../../assets/copy-icon.png';
import wrpSummaryClientX from '../../../utils/api/wrpSummaryClientX';
import { copyToClipboard } from '../../../utils/helper';
import InlineLoader from "../../../components/InlineLoader";
import { connect } from "react-redux";
import { setCounterData } from "../../home/actions";

// const RedeemWrp = ({ wrpBalance, onDismiss, profile, orderData }, ...props) => {
const RedeemWrp = ({ wrpBalance, onDismiss, profile, orderData, onFormSubmit }, ...props) => {
  const { name, email, mobile } = profile;
  let voucherInitialState = {};
  let isSubmitted = false;
  if (orderData) {
    voucherInitialState = orderData;
    isSubmitted = true;
  }
  const [redeemForm, setRedeemForm] = useState({ wrpWithDrawalAmount: '', isTermsConditonAccepted: false });
  const [isFormSubmitted, setIsFormSubmitted] = useState(isSubmitted);
  const [voucherData, setVoucherData] = useState(voucherInitialState);
  const [isLoading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    const validValue = name === 'wrpWithDrawalAmount' ? value : checked;
    setRedeemForm({
      ...redeemForm,
      [name]: validValue
    })
  }

  const { wrpWithDrawalAmount, isTermsConditonAccepted } = redeemForm;
  const redeemedWrp = wrpWithDrawalAmount ? wrpWithDrawalAmount : voucherData.wrp;

  const handleSubmit = (event) => {
    event.preventDefault();
    if (wrpWithDrawalAmount > 0) {
      setLoading(true);
      wrpSummaryClientX.post(`v1/goa-redeem`, { wrp: wrpWithDrawalAmount }).then(response => {
        setLoading(false);
        setVoucherData(response.data.data);
        setIsFormSubmitted(true);
        onFormSubmit && onFormSubmit();
      }, error => {
        setLoading(false);
      })
      // onDismiss();
    } else {
      showToastMessage('error', 'please enter amount greater than 0');
    }
  }

  const claimNow = () => {
    window.open(`${process.env.REACT_APP_REDIRECT_GOA}?uname=${name}&uemail=${email}&umobile=${mobile}`)
  }

  return (
    <Modal show onHide={onDismiss} className="font-proxima-nova wrp-summary-modal">
      {
        isFormSubmitted ? (
          <React.Fragment>
            <Modal.Body className=" font-proxima-nova p-0 ">
              <div className="card ">
                <div className="card-img-caption">
                  <div className=" card-text mt-2 mt-lg-4 mt-md-4 ml-5 font-proxima-nova">
                    <h1 className="mb-0"><strong><FormattedMessage id="general.congrats" defaultMessage="CONGRATULATIONS !" /></strong></h1>
                    <h2 className="mb-0"><strong> &#8377;{voucherData.voucher_price} </strong></h2>
                    <h6><p><FormattedMessage id="goa.worth-voucher" defaultMessage="Worth Voucher" /></p></h6>
                  </div>
                  <img className="card-img-top" src={bannerImage} alt="banner" title="banner" style={{ width: '100%' }} />
                </div>
                <div className="card-body card-body-border pl-5 pr-5">
                  <p className="card-text airvoucherText">
                    <FormattedMessage
                      id="goa.you-have-redeemed"
                      defaultMessage="You have redeemed {redeemedWrp} KAMAIE Coins." // Message should be a string literal
                      values={
                        {
                          redeemedWrp: redeemedWrp,
                        } // Values should be an object literal, but not necessarily every value inside
                      }
                    />
                    &nbsp;
                    <FormattedMessage id="goa.redemption" defaultMessage="Your KAMAIE Coins Redemption voucher has been successfully generated." />
                  </p>
                  <div className="row">
                    <div className="col-10">
                      <p >
                        <span className="text-muted"><strong><FormattedMessage id="goa.voucher" defaultMessage="Voucher Code" />:&nbsp;</strong></span>
                        <span className="text-muted">{voucherData.voucher_number} </span>
                      </p>
                      <p >
                        <span className="text-muted"><strong><FormattedMessage id="goa.expire" defaultMessage="Expires" />:&nbsp;</strong></span>
                        <span className="text-muted">{voucherData.expiry_date}</span>
                      </p>
                      {
                        (voucherData.is_partial_used || voucherData.is_used) ?
                          <p >
                            <span className="text-muted"><strong><FormattedMessage id="goa.balance" defaultMessage="Available balance" />:&nbsp;</strong></span>
                            <span className="text-muted">{voucherData.balance}</span>
                          </p> : null
                      }
                    </div>
                    <div className="col-2 " onClick={() => copyToClipboard(voucherData.voucher_number)}><img src={copyIcon} alt="copyIcon" title="copyIcon"/></div>
                  </div>
                  {
                    (!voucherData.is_used | voucherData.is_partial_used) ?
                      <React.Fragment>
                        <div className=" col-12">
                          <button className="btn btn-primary  mt-3 mb-3 home-btn w-100" onClick={claimNow}><span className="claim-now-text"><FormattedMessage id="goa.claim" defaultMessage="CLAIM NOW" /></span></button>
                        </div>
                        <p className="mt-2 text-center" onClick={() => onDismiss()}>
                          <div className="claimLater"><FormattedMessage id="goa.later" defaultMessage="Claim Later" /></div></p>
                      </React.Fragment> : null
                  }
                </div>
              </div>
            </Modal.Body>
          </React.Fragment>
        ) :
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton className="border-0 text-center  ml-auto mr-auto modalHead ">
              <h4 className="modal-title wrpHeading w-100 text-center pt-4 mx-auto"  >
                <strong>
                  <FormattedMessage id="redeem-wrp" defaultMessage="redeem-wrp" />
                </strong>
              </h4>
            </Modal.Header>
            <Modal.Body className="text-center font-proxima-nova wrp-summary">
              <div className="row">
                <div className="col-12  pl-5 text-left">
                  <p className="mt-0 mb-0 confirmedwrpredemption"><FormattedMessage id="goa.confirmed" defaultMessage="Confirmed KAMAIE Coins available for redemption" /></p>
                  <p className="mt-0 wrpNumber" ><b>{wrpBalance} KAMAIE Coins</b></p>
                </div>
                <div className="col-12  pl-5 text-left">
                  {/* <p className="mt-0 mb-3 purchaseGift">Purchase vouchers using your WRP earned. </p> */}
                  <p className="mt-0 mb-3 purchaseGift"><FormattedMessage id="goa.excitingproduct" defaultMessage="See the exciting products your KAMAIE Coins can be redeemed from" /> <a className="termsandCond" rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_REDIRECT_GOA}?uname=${name}&uemail=${email}&umobile=${mobile}`}>here</a></p>
                </div>
                <div className="col-12  pl-5 text-left">
                  <div className="form-group">
                    <label className="lightTextsemiBold" ><FormattedMessage id="goa.enter" defaultMessage="Enter KAMAIE Coins to be redeemed" /></label>
                    <input type="number" className="form-control wrpText mb-3" name="wrpWithDrawalAmount"
                      onChange={handleInputChange} value={wrpWithDrawalAmount}
                    />
                    {
                      wrpWithDrawalAmount &&
                      <React.Fragment>
                        <div className="mb-2 "  ><p><FormattedMessage id="goa.redemptionvalue" defaultMessage="Redemption value:" /> &#8377;{wrpWithDrawalAmount / 10}</p></div>
                      </React.Fragment>
                    }
                    <div className="mb-2 "  ><FormattedMessage id="goa.wrpratio" defaultMessage="(KAMAIE Coins conversion ratio is 10:1)" /></div>
                    <div className="mb-2 lightTextNormal "  ><FormattedMessage id="goa.minimum" defaultMessage="Note: Minimum 100 KAMAIE Coins can be redeemed. Please enter KAMAIE Coins amount in multiples of 10.  These KAMAIE Coins will be deducted from your total redeemable KAMAIE Coins." /></div>
                    <input type="checkbox" className="termsCondition" name="isTermsConditonAccepted" onChange={handleInputChange} id="agree" value={isTermsConditonAccepted} />
                    <span className="pl-2 f-12 ">
                      <FormattedMessage id="general.accept" defaultMessage="I accept the" />&nbsp;
                      <Link to={'/redeem-terms-condition'} className="termsandCond" >  <FormattedMessage id="general.terms-condition" defaultMessage="Terms & Conditions" /> </Link></span>
                    {
                      isLoading ? <InlineLoader /> :
                        <button disabled={!wrpWithDrawalAmount || !isTermsConditonAccepted}
                          className="btn btn-primary mt-3 home-btn w-100"><span>
                            <FormattedMessage id="goa.redeemNow" defaultMessage="Redeem Now" />
                          </span></button>
                    }
                  </div>
                </div>
              </div>
            </Modal.Body>
          </form>
      }
    </Modal >
  );
}


function mapDispatchToProps(dispatch) {
  return {
    getCoupons: () => {
      wrpSummaryClientX.get("v1/info").then((response) => {
        const {
          active_coupons,
          total_points,
          pending_points,
          coupon_progression_percent,
          total_points_per_coupon,
        } = response.data.data;
        dispatch(
          setCounterData(
            active_coupons,
            total_points,
            pending_points,
            coupon_progression_percent,
            total_points_per_coupon
          )
        );
      });
    }
  };
}

function mapStateToProps(state) {
  return {
    profile: state.global.profile,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RedeemWrp);

