import React from "react";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { ProgressBar } from "react-bootstrap";
import ic_password from "../../../assets/profile/ic_password.svg";
import ic_arrow from "../../../assets/profile/ic_arrow.svg";
import ic_address from "../../../assets/profile/ic_address.svg";
import ic_profile from "../../../assets/profile/ic_basic_profile.svg";
import ic_device from "../../../assets/profile/ic_device.svg";
import ic_preference_data from "../../../assets/profile/ic_preference_detail.svg";
import ic_ratings from "../../../assets/profile/ic_ratings.svg";
import { useSelector } from "react-redux";

export default function SideBar() {
  const { profile } = useSelector((state) => state?.global);

  return (
    <>
      <h5 className="font-proxima-nova-bold mb-3">Account Details</h5>
      <NavLink to="/profile" className="d-none d-md-block">
        <div className="col-12 p-3 profile-options mb-2">
          <div className="d-flex justify-content-between align-items-center">
            <img src={ic_profile} alt="profile" title="profile" />
            <div className="col-11">
              <div className="col-12 d-flex justify-content-between p-0 m-0">
                <span className="col-9 p-0 m-0 profile-text-blue-dark "><strong><FormattedMessage id="profile.basic-profile-details" defaultMessage="Basic Profile Details" /></strong></span>
                <span className="col-2 p-0 m-0 profile-text-blue "><strong>{profile?.basic_profile_progress_percent} %</strong></span>
                <span className="col-1 p-0 m-0" style={{ textAlign: "center" }}><img src={ic_arrow} alt="arrow" title="arrow" /></span>
              </div>
              <ProgressBar now={profile?.basic_profile_progress_percent} className="profile-progress-bar my-2" variant="progress-bar-style" />
              {
                profile?.basic_profile_progress_percent !== 100 &&
                (
                  profile?.basic_profile_completion_wrp === 0 ?
                    <p className="profile-text-small m-0">Complete your profile</p> :
                    <p className="profile-text-small m-0">Complete your profile and win {profile?.basic_profile_completion_wrp} WRP</p>
                )
              }
            </div>
          </div>
        </div>
      </NavLink>
      <NavLink to="/profile/details" className="d-block  d-md-none">
        <div className="col-12 p-3 profile-options mb-2">
          <div className="d-flex justify-content-between align-items-center">
            <img src={ic_profile} alt="profile" title="profile" />
            <div className="col-11">
              <div className="col-12 d-flex justify-content-between p-0 m-0">
                <span className="col-9 p-0 m-0 profile-text-blue-dark"><strong><FormattedMessage id="profile.profile" defaultMessage="Basic Profile Details" /></strong></span>
                <span className="col-2 p-0 m-0 profile-text-blue "><strong>{profile?.basic_profile_progress_percent}%</strong></span>
                <span className="col-1 p-0 m-0" style={{ textAlign: "center" }}><img src={ic_arrow} alt="arrow" title="arrow" /></span>
              </div>
              <ProgressBar now={profile?.basic_profile_progress_percent} className="profile-progress-bar my-2" variant="progress-bar-style" />
              {
                profile?.basic_profile_progress_percent !== 100 &&
                (
                  profile?.basic_profile_completion_wrp === 0 ?
                    <p className="profile-text-small m-0">Complete your profile</p> :
                    <p className="profile-text-small m-0">Complete your profile and win {profile?.basic_profile_completion_wrp} WRP</p>
                )
              }
            </div>
          </div>
        </div>
      </NavLink>
      <NavLink to="/PreferenceDetails">
        {/* <NavLink to="/change-password" > */}
        <div className="col-12 p-3 profile-options mb-2">
          <div className="d-flex justify-content-between align-items-center">
            <img src={ic_preference_data} alt="preference" title="preference"/>
            <div className="col-11">
              <div className="col-12 d-flex justify-content-between p-0 m-0">
                <span className="col-9 p-0 m-0 profile-text-blue-dark"><strong>Preference Details</strong></span>
                <span className="col-2 p-0 m-0 profile-text-blue "><strong>{profile?.preference_percentage}%</strong></span>
                <span className="col-1 p-0 m-0" style={{ textAlign: "center" }}><img src={ic_arrow} alt="arrow" title="arrow" /></span>
              </div>
              <ProgressBar now={profile?.preference_percentage} className="profile-progress-bar my-2" variant="progress-bar-style" />
              {
                profile?.preference_percentage !== 100 &&
                (profile?.basic_profile_completion_wrp === 0 ?
                  <p className="profile-text-small m-0">Complete your Preferences</p> :
                  <p className="profile-text-small m-0">Complete your Preferences and win {profile?.preference_alerts_completion_wrp} KAMAIE Coins</p>
                )
              }
            </div>
          </div>
        </div>
      </NavLink>
      <NavLink to="/change-password" >
        <div className="d-flex justify-content-between align-items-center col-12 p-3 profile-options mb-2">
          <img src={ic_password} alt="password" title="password" />
          <div className="col-11">
            <div className="col-12 d-flex justify-content-between p-0 m-0">
              <span className="col-9 p-0 m-0 profile-text-blue-dark"><strong><FormattedMessage id="profile.change-password" defaultMessage="Change Password" /></strong></span>
              <span className="col-1 p-0 m-0" style={{ textAlign: "center" }}><img src={ic_arrow} alt="arrow" title="arrow" /></span>
            </div>
          </div>
        </div>
      </NavLink>
      <NavLink to="/devices">
        <div className="d-flex justify-content-between align-items-center col-12 p-3 profile-options mb-2">
          <img src={ic_device} alt="device" title="device" />
          <div className="col-11">
            <div className="col-12 d-flex justify-content-between p-0 m-0">
              <span className="col-9 p-0 m-0 profile-text-blue-dark"><strong><FormattedMessage id="profile.logged-in-devices" defaultMessage="Manage Logged In Devices" /></strong></span>
              <span className="col-1 p-0 m-0" style={{ textAlign: "center" }}><img src={ic_arrow} alt="arrow" title="arrow" /></span>
            </div>
          </div>
        </div>
      </NavLink>
      <NavLink to="/ratings">
        <div className="d-flex justify-content-between align-items-center col-12 p-3 profile-options mb-2">
          <img src={ic_ratings} alt="rating" title="rating" />
          <div className="col-11">
            <div className="col-12 d-flex justify-content-between p-0 m-0">
              <span className="col-9 p-0 m-0 profile-text-blue-dark"><strong><FormattedMessage id="profile.my-ratings" defaultMessage="My Ratings" /></strong></span>
              <span className="col-1 p-0 m-0" style={{ textAlign: "center" }}><img src={ic_arrow} alt="arrow" title="arrow" /></span>
            </div>
          </div>
        </div>
      </NavLink>
      <NavLink to="/addresses">
        <div className="d-flex justify-content-between align-items-center col-12 p-3 profile-options mb-2">
          <img src={ic_address} alt="address" title="address" />
          <div className="col-11">
            <div className="col-12 d-flex justify-content-between p-0 m-0">
              <span className="col-9 p-0 m-0 profile-text-blue-dark"><strong>Saved Addresses</strong></span>
              <span className="col-1 p-0 m-0" style={{ textAlign: "center" }}><img src={ic_arrow} alt="arrow" title="arrow" /></span>
            </div>
          </div>
        </div>
      </NavLink>
    </>
  );

}

