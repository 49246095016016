import React from 'react'
import icTick from "../../../assets/voucher/ic_tick_mark.png"
import banner from '../../../assets/ppi_banner.png'


export default function PpiBenefits() {
    return (
        <div>
            <div className="voucher-benefits-popup row m-auto">
                <div className="col-12 col-md-8 m-auto">
                    <div className='text-center'>
                        <img className="w-100 mt-2" src={banner} alt="voucher" title="voucher" style={{ maxWidth: "600px" }} />
                    </div>
                    <div className="title mt-3 mt-md-4">
                        The Benefits of having KAMAIE Prepaid Card
                    </div>
                    <div className="my-4">
                        <div className="list d-flex align-items-center border-bottom">
                            <img src={icTick} alt="tick" title="tick" />
                            <span className="h5">KAMAIE Prepaid Card can be used to purchase other Brand Vouchers.</span>
                        </div>
                        <div className="list d-flex align-items-center border-bottom">
                            <img src={icTick} alt="tick" title="tick" />
                            <span className="h5">KAMAIE Prepaid Card can be used for Online purchases in any Online shopping portal.</span>
                        </div>
                        <div className="list d-flex align-items-center border-bottom">
                            <img src={icTick} alt="tick" title="tick" />
                            <span className="h5">KAMAIE Prepaid Card can be used for Offline purchases where ever Prepaid cards are accepted.</span>
                        </div>
                        <div className="list d-flex align-items-center border-bottom">
                            <img src={icTick} alt="tick" title="tick" />
                            <span className="h5">Earn WRP for every purchase made through KAMAIE Prepaid Card whether its Online or Offline Shopping.</span>
                        </div>
                        <div className="list d-flex align-items-center border-bottom">
                            <img src={icTick} alt="tick" title="tick" />
                            <span className="h5">Hassle-free purchase using KAMAIE Prepaid Card anytime anywhere from KAMAIE app.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
