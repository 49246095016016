import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import customerCommonClientX from '../../../utils/api/customerCommonClientX'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setViewTicketUrl } from "../actions";
import { connect } from "react-redux";
import { OrderStatusLabel } from "../../../utils/helper";
import { claimNow, claimed } from "../../../components/RewardsClaim";

class HotelOrderDetail extends Component {

  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      order: null,
      orderStatus: null,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    const {
      match: { params },
    } = this.props;

    customerCommonClientX.get(`v1/hotel/order-detail/${params.id}`)
      .then((response) => {
        let data = response.data.data;
        if (this._isMounted) {
          this.setState({
            order: data,
          });
        }
      }).catch(error => {
        toast.error("Something went wrong. Please try again later")
      })
  }


  viewTicket = (url) => {
    this.props.setViewTicket(url)
    this.props.history.push("/booking/view-ticket");
  };

  Shimmer = (props) => {
    return (
      <div className="container-fluid py-3 py-md-5">
        <div className="row">
          <div className="col-12 col-md-6 offset-md-3">
            <div className="box shine box-voucher-detail-logo"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      order,
    } = this.state;
    return (
      order === null ?
        <this.Shimmer />
        :
        null !== order && (
          <React.Fragment>
            <div className="container">
              <div className="col-md-8 mx-auto">
                <div className="row mt-3 mb-3 mt-md-4 mb-md-4 align-items-center">
                  <div className="col-12 text-md-center">
                    <small>
                      <Link to="/">
                        <FormattedMessage
                          id="breadcrumbs.home"
                          defaultMessage="Home"
                        />
                      </Link>
                    </small>{" "}
                    /{" "}
                    <small>
                      <Link to="/orders">
                        <FormattedMessage id="navbar.orders" />
                      </Link>
                    </small>{" "}
                    /{" "}
                    <small>
                      <strong>
                        <FormattedMessage id="orders.order" />
                      </strong>
                    </small>
                  </div>
                </div>
                <div className="row mb-3 mb-md-4">
                  <div className="col-md-12">
                    <div className="list-group">
                      <div className="list-group-item list-group-item-action">
                        <p className="d-block d-md-inline mt-1 mt-md-0 mb-0">
                          <strong>{order.hotel_name}</strong>
                        </p>
                      </div>
                      <div className="list-group-item list-group-item-action">
                        <div className="row align-items-center">
                          <div className="col-md-6">
                            <OrderStatusLabel order={order} />
                          </div>
                          <div className="col-md-6 text-md-right">
                            <p className="mb-0">
                              <FormattedMessage id="orders.your-order-number-is" />{" "}
                              <strong>{order.order_number}</strong>
                            </p>
                            <p className="mb-0">{order.created_at}</p>
                          </div>
                        </div>
                      </div>
                      <div className="list-group-item list-group-item-action">
                        <p className="mb-0">
                          <PaymentStatusLabel order={order} />
                        </p>
                      </div>
                      <div className="list-group-item list-group-item-action">
                        <p className="mb-0">
                          <strong>{order.wrp}</strong> KAMAIE Coins{" "}
                          {order.wrp_credited ? "credited" : "are pending"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-3 mb-md-4">
                  <div className="col-md-12 text-center">
                    {/* {order?.fulfilled === true && ( */}
                      <button
                        className="btn btn-primary home-btn w-50 mt-3"
                        onClick={() => this.viewTicket(order?.redirect_url)}
                      >
                        View Booking Details
                      </button>
                    {/* )} */}
                  </div>
                </div>

                {order.won_reward === false ?
                  null :
                  order.rewards_cliamed === false ?
                    claimNow(order) : claimed(order)
                }
              </div>
            </div>
          </React.Fragment>
        )
    );
  }
}
function mapStateToProps(state) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setViewTicket: (url) => {
      dispatch(setViewTicketUrl(url));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HotelOrderDetail);


function PaymentStatusLabel(props) {
  const status = props.order.status;
  switch (status) {
    case 0:
      return (
        <React.Fragment>
          <FormattedMessage
            id="order.payment-of-rs"
            defaultMessage="Payment of ₹"
          />{" "}
          <strong>{props.order.amount}</strong>{" "}
          <FormattedMessage
            id="order.payment-pending"
            defaultMessage="is Pending"
          />
        </React.Fragment>
      );
    case 1:
    case 8:
      return (
        <React.Fragment>
          <FormattedMessage
            id="order.payment-of-rs"
            defaultMessage="Payment of ₹"
          />{" "}
          <strong>{props.order.amount}</strong>{" "}
          <FormattedMessage
            id="order.payment-failed"
            defaultMessage="is Failed"
          />
        </React.Fragment>
      );
    default:
      return (
        <React.Fragment>
          <FormattedMessage
            id="order.payment-of-rs"
            defaultMessage="Payment of ₹"
          />{" "}
          <strong>{props.order.amount}</strong>{" "}
          <FormattedMessage
            id="order.payment-success"
            defaultMessage="is Successful"
          />
        </React.Fragment>
      );
  }
}
