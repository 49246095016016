import React, { Component }from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import clientX from "../../../utils/api/deleteAccountX";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import SideBar from "../components/SideBar";
import { createCookie } from "../../../utils/helper"
import { connect } from "react-redux";
import {resetProfile} from "../../../actions/index"

class DeleteAccount extends Component {
    
    state = {
        isOpen: false
      };
    
      openModal = () => this.setState({ isOpen: true });
      closeModal = () => this.setState({ isOpen: false });

      DeleteAccount = () => {
        clientX.delete("v1/delete-account")
        .then((response) => {
            createCookie("windsEWorldAppLanguageCode", "en", "60");
            this.props.reset() //resetting profile data(store)
            this.props.history.push("/");
            })
        .catch((err) =>{})
      }


    render() { 
        return ( 
            <React.Fragment>
        <div className="container-fluid pt-3 pb-3 pt-md-4 pb-md-4 breadCrumbs">
          <div className="row mb-md-3">
            <div className="col-12 m-auto font-proxima-nova text-white">
              <small>
                <Link to="/">
                  <FormattedMessage
                    id="breadcrumbs.home"
                    defaultMessage="Home"
                  />
                </Link>
              </small>{" "}
              /{" "}
              <small>
                <Link to="/profile">
                  <FormattedMessage
                    id="profile.profile"
                    defaultMessage="Profile"
                  />
                </Link>
              </small>{" "}
              /{" "}
              <small>
                <strong>
                  <FormattedMessage
                    id="profile.delete-account"
                    defaultMessage="Delete Account"
                  />
                </strong>
              </small>
            </div>
          </div>
{/* 
          <div className="d-none d-md-block">
            <Profile />
          </div> */}
        </div>

        <div className="container-fluid mt-md-5 mb-4 mb-md-5">
          <div className="row mb-3 mt-md-4 mb-md-4">
            <div className="d-none col-md-2 d-md-block font-proxima-nova border-right">
              <SideBar />
            </div>
            <div className="col-12 col-md-9 d-md-block address-wrapper">
              <div className="row d-md-flex">
                <div className="col-7 col-md-8 font-proxima-nova-bold">
                  <h5 className="d-none d-md-block">
                    <strong>
                    <FormattedMessage
                        id="profile.delete-account"
                        defaultMessage="Account Deletion"
                      />
                    </strong>
                  </h5>
                </div>
              </div>

              <div className="row d-md-flex font-proxima-nova">
                <div className="col-md-10 col-12">
                  <div
                    className="card mt-4"
                    style={{
                      backgroundColor: "#F8F9FA",
                      borderRadius: "10px",
                      border: "none"
                    }}
                  >
                    <div className="card-body">
                      <p>Please note, deleting your account will remove all your account information from KAMAIE. You will no longer be able to access your account or make any transactions through KAMAIE.</p>
                      <p>Are you sure you want to delete your account ?</p>
                
          <Button variant="danger" onClick={this.openModal}>
          Delete Account
          </Button>

          <Modal show={this.state.isOpen} onHide={this.closeModal}  centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete your account?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              No
            </Button>
            <Button variant="danger" onClick={this.DeleteAccount}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
         );
    }
}
 
function mapStateToProps(state) {
    return {
      profile: state.global.profile,
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      reset: () => {
        dispatch(resetProfile());
      }
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccount);