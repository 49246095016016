import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function VerifyFullKYC() {
  const { completeKYCData } = useSelector((state) => state?.ppi)

  const Shimmer = (props) => {
    return (
      <React.Fragment>
        <div className='container my-5' style={{ zIndex: "999" }}>
          <div className='row'>
            <div className='col-12 mx-auto'>
              <div className="box shine w-100" style={{ height: "500px" }}></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    completeKYCData?.kycLink?.webLink === null ?
      <Shimmer /> :
      <React.Fragment>
        <div className="container mt-3 mb-2">
          <Link to={"/"}>
            <span style={{ "padding": "6px 20px", "top": "90px", "border": "1px solid #6372ff", "cursor": "pointer", "fontSize": "small", "fontWeight": "600" }}>
              Back to Home
            </span>
          </Link>
        </div>
        <iframe title="windsIframe" src={completeKYCData?.kycLink?.webLink} className="info-box" name="complete-kyc"></iframe>
      </React.Fragment >
  );
}


export default VerifyFullKYC;