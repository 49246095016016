import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";

const ConsentAgreement = ({ show, handleClose,consentText }) => {

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <button className="btn-close-card-type " onClick={handleClose}>
          <i class="fa fa-close"></i>
        </button>
        <div className="row p-3 p-md-5  d-flex justify-content-center">
          <h1 className="title-card-type">KYC Consent Agreement</h1>
          <Modal.Body className="px-4 agreement-text">
            <span
              dangerouslySetInnerHTML={{
                __html: consentText,
              }}
            ></span>
          </Modal.Body>

          <Button className="animated-btn btn-continue-card-type col-5 mx-auto P-2 " onClick={handleClose}>
            OK
          </Button>
        </div>
      </Modal>
    </>
  );
};
function mapDispatchToProps(dispatch) {
  return {};
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsentAgreement);
