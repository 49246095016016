import React, { useState,useEffect } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Flight from '../components/Flight';
import Hotel from '../components/Hotel';
import flightBG from "../../../assets/travel/ic_flight_bg.png"
import ic_flight from "../../../assets/travel/ic_flight.svg"
import ic_hotel from "../../../assets/travel/ic_hotel.svg"
import hotelBG from "../../../assets/travel/ic_hotel_bg.png"
import { useSelector} from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { setTravelData } from '../actions';
import axios from 'axios';
import { useDispatch } from 'react-redux';
export default function Flights(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const flightData = useSelector((state) => state?.travel?.travelData?.flight);
  const hotelData = useSelector((state) => state?.travel?.travelData?.hotel);
  const { heading_text } = useSelector((state) => state?.home);
  const [services, setServices] = useState([]);

  const [selectedService, setSelectedService] = useState(props.location.pathname === "/travel" ? 'flights' : props.location.pathname.replace("/", ""))

  const flightIcon = <img className='mr-2' src={ic_flight} alt="flightIcon" title="flightIcon" />
  const hotelIcon = <img className='mr-2' src={ic_hotel} alt="hotelIcon" title="hotelIcon" />

  const travelServices = {  
    "flights": {
      tabTitle: "Flight",
      title: "Fly High with Ease",
      subtitle: "Book your next flight from KAMAIE &",
      percentage: "Earn KAMAIE Coins on every transaction",
      bgImage: flightBG
    },
    "hotels": {
      tabTitle: "Hotel",
      title: "Book your hotels now!",
      subtitle: "Book your next hotels from KAMAIE &",
      percentage: "Earn KAMAIE Coins on every transaction",
      bgImage: hotelBG
    }
  }
  useEffect(() => { 
    axios.get('https://customer-common-api.winds.co.in/v1/flight/home') 
      .then(response => {
        if (response.data.success) {
          dispatch(setTravelData(response.data.data));
          setServices(response.data.data.services);
        }
      })
      .catch(error => {
        console.error('Error fetching travel data:', error);
      });
  }, [dispatch]);
  const showFlightTab = services.some(service => service.name === 'Flight');
  const showHotelTab = services.some(service => service.name === 'Hotel');

  return (
    heading_text && heading_text?.travel ?
      <div style={{ backgroundImage: `url(${travelServices[selectedService]?.bgImage})`, backgroundSize: "cover" }}>
        <div className='container'>
          <div className='row pt-5'>
            <div className='col-12 text-center'>
              <h1 className='heading_text'>{travelServices[selectedService]?.title}</h1>
              <p className='sub-text'>{travelServices[selectedService]?.subtitle} <br /> {travelServices[selectedService]?.percentage}</p>
            </div>
          </div>
        </div>
        <div className="travel-page pt-4 pb-4 pt-md-5 pb-md-5">
          <Tabs id="controlled-tab-example" defaultActiveKey={selectedService} onSelect={(k) => setSelectedService(k)}>
          {showFlightTab && (
             <Tab eventKey="flights" onEnter={() => { history.push("/flights") }} title={<span className='d-flex align-items-center'>{flightIcon} Flight</span>}>
             <Flight key="flights" />
           </Tab>
          )}
            {showHotelTab && (
              <Tab eventKey="hotels" onEnter={() => { history.push("/hotels") }} title={<span className='d-flex align-items-center'>{hotelIcon} Hotel</span>}>
              <Hotel key="hotels" />
            </Tab>
            )}
          </Tabs>
        </div>
      </div> :
      <Redirect to="/" />
  )
}

