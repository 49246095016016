import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import userIcon from "../assets/header/ic_profile.png";
import { Link } from "react-router-dom";
import downCaret from "../assets/icon/downBlackArrow.svg";
import classNames from "classnames";
import { onPushProfile, onLogOutCleverTap } from "../utils/events";
import { createCookie } from "../utils/helper";
import { accessTokenName, removeCookie } from "../utils/authorization";
import { connect } from "react-redux";
import { setProfile, resetProfile } from "../actions";
import { resetWrp, setCompleteProfileCount } from "../modules/home/actions";
import { FirebaseContext } from "../utils/firebase";
import profileClientX from "../utils/api/profileClientX";
import { withRouter } from 'react-router-dom';
import { resetPrepaidCardData } from "../modules/prepaid_cards/actions";
import contactIcon from '../assets/icon/contact_2.png'
class Avatar extends Component {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props);

    this.state = {
      profile: null,
      showDropdown: false,
      location: this.props.location
    };
  }

  handleClick = () => {
    this.setState({
      showDropdown: !this.state.showDropdown,
    });
  };

  handleLogout = (event) => {
    profileClientX
      .post("v1/logout")
      .then((response) => {
        this.onLogout()
      })
      .catch((error) => {
      });
  }

  onLogout = () => {
    this.props.setCompleteProfileCount()
    onLogOutCleverTap();
    localStorage.clear();
    removeCookie(accessTokenName)
    createCookie("windsEWorldAppLanguageCode", "en", "60");
    this.props.reset();
    this.props.history.push('/login')
  };

  componentDidMount() {
    if (this.props.profile === null) {
      this.props.setCompleteProfileCount()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile && this.props.latitude !== prevProps.latitude)
      this.props.updateLocation(this.props?.latitude, this.props?.longitude)
  }

  render() {
    if (null != this.props.profile) {
      return (
        <li className="nav-item text-center" onClick={this.handleClick}>
          <div className="account-info">
            <img className="userIcon" src={contactIcon} alt="Login" title="Login" />
            <span className="nav-text">
              <span className=" " style={{fontWeight:'400'}}>
                {this.props.profile.name.substring(0, 10)}
              </span>
            </span>
            <img src={downCaret} style={{ display: "inline", height: "auto" }} alt="down caret" title="down caret" />
          </div>
          <div
            className={classNames("dropdown-menu", "dropdown-menu-right", {
              show: this.state.showDropdown,
            })}
            onMouseLeave={() => {
              this.setState({ showDropdown: false });
            }}
          >
            <Link className="dropdown-item dropdown-icons" to="/profile">
              <FormattedMessage id="navbar.profile" defaultMessage="Profile" />
            </Link>
            <Link className="dropdown-item dropdown-icons d-md-none" to="/orders">
              <FormattedMessage id="navbar.orders" defaultMessage="Orders" />
            </Link>
            {
              this.context.is_winds_giftcard_enabled &&
              <Link className="dropdown-item dropdown-icons" to="/voucher/redeem">
                <FormattedMessage id="navbar.winds-gift-voucher" defaultMessage="KAMAIE E-Gift Voucher" />
              </Link>
            }
            <Link className="dropdown-item dropdown-icons" to="/wallet">
              <FormattedMessage id="navbar.wallet" defaultMessage="Wallet" />
            </Link>
            <Link className="dropdown-item dropdown-icons" to="/earnings">
              <FormattedMessage id="navbar.my-earnings" defaultMessage="My Earnings" />
            </Link>
            <Link className="dropdown-item dropdown-icons" to="/bookmarks">
              <FormattedMessage id="navbar.bookmarks" defaultMessage="Bookmarks" />
            </Link>
            <Link className="dropdown-item dropdown-icons" to="/refer-and-earn">
              <FormattedMessage id="navbar.refer-and-earn" defaultMessage="Refer & Earn" />
            </Link>
            <Link className="dropdown-item dropdown-icons" to="/draw-winners">
              <FormattedMessage id="navbar.winners" defaultMessage="Winners" />
            </Link>
            {
              this.props.profile &&
              <Link className="dropdown-item dropdown-icons" to="/follow-us">
                <FormattedMessage id="navbar.follow-us" defaultMessage="Follow Us" />
              </Link>
            }
            <span className="dropdown-item dropdown-icons" style={{ padding: 0 }} onClick={this.handleLogout}>
              <input
                type="submit"
                value={this.props.intl.formatMessage({
                  id: "navbar.logout",
                  defaultMessage: "Logout",
                })}
                style={{
                  border: "none",
                  background: "transparent",
                  padding: "0.3rem 1.5rem",
                  width: "100%",
                  textAlign: "left"
                }}
              />
            </span>
          </div>
        </li>
      );
    }

    return (
      <li className="nav-item text-center">
        <a className="nav-link" href={`/login`}>
          <div className="account-info">
            <img className="userIcon" src={contactIcon} alt="Login" title="login" />
            <span className="nav-text">
              <FormattedMessage id="navbar.login" defaultMessage="Login" />{" "}
            </span>
            <img src={downCaret} style={{ display: "inline", height: "auto" }} alt="down caret" title="down caret" />
          </div>
        </a>
      </li>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.global.profile,
    location: state.location.formatted_location,
    latitude: state.location.latitude,
    longitude: state.location.longitude
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onInit: (location) => {
      profileClientX
        .get("v1/profile")
        .then((response) => {
          const responseData = response.data.data;
          dispatch(setProfile(responseData));
          onPushProfile(responseData, location);
        })
        .catch((error) => {
          dispatch(resetProfile());
          dispatch(resetWrp())
        });
    },
    reset: () => {
      dispatch(resetProfile())
      dispatch(resetWrp())
      dispatch(resetPrepaidCardData())
    },
    setCompleteProfileCount: () => {
      dispatch(setCompleteProfileCount(0))
    },
    updateLocation: (latitude, longitude) => {
      profileClientX
        .post(`v1/location?latitude=${latitude}&longitude=${longitude}`)
        .then((response) => {
          // console.log(response)
        })
        .catch((error) => {
        });
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(Avatar)));
