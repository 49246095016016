import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import OtpInput from 'react-otp-input';
import { FormattedMessage } from "react-intl";
import { showToastMessage } from "../../../utils/helper";
import prepaidCardClientX from "../../../utils/api/prepaidCardClientX";
import { useSelector } from "react-redux";
import InlineLoader from "../../../components/InlineLoader";

const VerifyOTP = (props) => {
  const { profile } = useSelector((state) => state?.global)

  const [otpVerificationMessage, setOtpVerificationMessage] = useState("");
  const [otp, setOTP] = useState('');
  const [renderTime, setRenderTime] = useState('');
  const [btnLoad, setBtnLoad] = useState(false)
  const [delay, setDelay] = useState(4 * 60);
  const [enableResendButton,setEnableResentButton]=useState(false)

  const minutes = Math.floor(delay / 60);
  const seconds = Math.floor(delay % 60);

  useEffect(() => {
    sendOTP()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      setDelay(delay - 1);
    }, 1000);
    if (delay === 0) {
      clearInterval(timer);
      setEnableResentButton(true)
    }
    return () => {
      clearInterval(timer);
    };
  }, [delay])

  const authConfig = {
    headers: {
      TempAuthToken: profile?.temp_ppi_token,
    }
  }

  const payload = {
    mobile: profile?.mobile
  }

  const sendOTP = () => {
    prepaidCardClientX.post(`send-otp`, payload, authConfig).then((response) => {
      if (response.data.success) {
        showToastMessage("success", "OTP Sent Successfully");
        setOTP("");
      }
      else {
        showToastMessage("error", `Try after ${response.data.data?.try_after_string}`)
      }
    });
  }

  const handleResendOTP = () => {
    setOTP('')
    setOtpVerificationMessage('')
    setRenderTime(Date.now())
    resendOTP()
    setDelay(4 * 60)
  }

  const resendOTP = () => {
    prepaidCardClientX.post(`resend-otp`, payload, authConfig).then((response) => {

      if (response.data.success) {
        showToastMessage("success", "OTP Sent Successfully");
        setOTP("");
      }
      else {
        showToastMessage("error", `Try after ${response.data.data?.try_after_string}`)
      }
    });

    setOTP("");
  }
  const verifyOTP = () => {
    setBtnLoad(true);

    const authConfig = {
      headers: {
        TempAuthToken: profile?.temp_ppi_token,
      }
    }
    let payload = {
      otp: otp,
      mobile: profile?.mobile
    }

    prepaidCardClientX.post(`verify-otp`, payload, authConfig).then((response) => {

      if (response.data.success) {
        props.onCloseVerifyOTPModal();
        props.onVerifyOTP(true);

        showToastMessage("success", "OTP Verified Successfully");
        setOTP("");
        setBtnLoad(false);
      }
    })
      .catch(error => {
        setBtnLoad(false);
        setOtpVerificationMessage("Invalid OTP")
      })
  };


  return (

    <Modal show onHide={props.onCloseVerifyOTPModal} centered>
      <Modal.Header closeButton className="border-0">
        <Modal.Title>
          <strong>
            <FormattedMessage
              id="wallet.otp-verification"
              defaultMessage="OTP Verification"
            />
          </strong>
        </Modal.Title>

      </Modal.Header>
      <Modal.Body className="text-center font-proxima-nova">
        <div className="m-3 mb-md-5 mb-2">
          <div className="container">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-12">

                {/* <p className="cardkyc-subtitle text-center">OTP Verification</p> */}
                <p className="cardkyc-desc text-center mb-0">
                  An 6 digit code has been sent to <strong>{profile?.mobile}</strong>{" "}
                </p>
                <div className="col-12 my-3 d-flex justify-content-center align-items-center otp-box">
                  <OtpInput
                    value={otp}
                    onChange={setOTP}
                    numInputs={6}
                    separator={<span></span>}
                    className="otp-input"
                    key={renderTime}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    inputStyle={{
                      width: "auto",
                      margin: "0.3rem",
                      marginTop: "0rem",
                      fontSize: "2rem",
                      backgroundColor: "#f6f6f6",
                      borderWidth: 0,
                      borderRadius: ".2rem",
                      inputType: "number"
                    }}
                  />
                </div>

                <div className="resendOtp d-flex justify-content-between">
                  <p>{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}</p>
                  <p className={enableResendButton ? "cursor-pointer":"cursor-not-allowed"} onClick={() =>enableResendButton && handleResendOTP()}>Resend OTP</p>
                </div>
                <div className="resendOtp d-flex justify-content-center mx-4 mt-3  mt-md-n1 mt-lg-n4 mb-3">
                  {/* <p className="validation-error-text">The otp is invalid</p> */}
                </div>
                <div className="resendOtp d-flex justify-content-center mx-4 mb-md-5 mb-1">
                  <p className="validation-error-text">
                    {otpVerificationMessage}
                  </p>
                </div>
                {
                  btnLoad ?
                    <div className="d-flex justify-content-center my-4">
                      <InlineLoader />
                    </div>
                    :
                    <button
                      className="animated-btn"
                      id={otp.toString().length < 6 ? "animated-btn-disable" : ""}
                      disabled={otp.toString().length < 6}
                      onClick={() => {
                        verifyOTP()
                      }}
                    >
                      VERIFY OTP
                    </button>
                }
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

    </Modal>

  );


}

export default VerifyOTP;
