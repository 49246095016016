import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import facebookImage from "../assets/footer/ic_fb.svg"
import twitterImage from "../assets/footer/ic_twitter.svg"
import linkedinImage from "../assets/footer/ic_linkedin.svg"
import youtubeImage from "../assets/footer/ic_youtube.svg"
import instagramImage from "../assets/footer/ic_insta.svg"
import playstoreImage from "../assets/img/site-patrons/ic_google_pay.png"
import appstoreImage from "../assets/img/site-patrons/ic_app_store.png"
import ic_simhaaa from "../assets/footer/ic_simhaaa.png"
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import { readCookie } from "../utils/helper";
import { getIsMob, getIsKamaie } from "../utils/helper";
import kamaieIcon from '../assets/icon/kamaiWhiteIcon_2.png'
class Footer extends Component {
  constructor(){
    super();  
    this.state={
      shouldRenderFooter: false,
      isMob: null,
      is_kamaie: null,
    }
  }
  componentDidMount() {
    let isMob = getIsMob();
    let is_kamaie = getIsKamaie();
    this.timeout = setTimeout(() => {
      isMob = getIsMob();
      is_kamaie = getIsKamaie();
      this.setState({ shouldRenderFooter: true, isMob, is_kamaie });
    }, 1000);
    
  }
  handleWindsKartLink = () => {
    const { winds_kart, email, history } = this.props;

    if (email) {
      const urlToOpen = winds_kart?.url || `${process.env.REACT_APP_SSO_URL}/login?continue=${process.env.REACT_APP_URL}`;
      window.open(urlToOpen, '_blank');
    } 
    else {
      if (email === null || email === "") {
        this.props.history.push({
          pathname: '/profile',
          state: { mailState: 'true' }
        });
        toast.error("Complete your mailing info to continue");
      } else {
        history.push("/login");
        toast.error("Login to continue.");
      }
    }
  };
  
  render() {
    const { shouldRenderFooter, isMob, is_kamaie } = this.state;
  //  const isMob=readCookie("isMob");
  //  const is_kamaie=readCookie("is_kamaie");
    return (
      <React.Fragment>
         {isMob != 1 && is_kamaie != 1 && (
          <>
        <div className="site-footer">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-md-3 pb-4">
                    {/* <span className="text-white footer-heading bold mb-3">
                      <FormattedMessage
                        id="WINDS-E-Pvt-Ltd"
                        defaultMessage="WINDS E Pvt Ltd"
                        values={{ what: "react-intl" }}
                      />
                    </span> */}
                    <img src={kamaieIcon} className="my-2"/>
                    <p><FormattedMessage id="WINDS-E-Pvt-Ltd-desc" /></p>

                    <span className="bold text-white mb-3 mb-md-4 footer-heading mt-md-5 mt-4">
                      <FormattedMessage
                        id="follow"
                        defaultMessage="Follow Us On"
                      />
                    </span>
                    <div className="social-links text-center">
                      <Link className="facebook" to={{ pathname: "https://www.facebook.com/windsapp" }} target="_blank">
                        <img src={facebookImage} alt="social-icon" title="page not found" width="20px" />
                      </Link>
                      <Link className="twitter" to={{ pathname: "https://twitter.com/WINDSapp" }} target="_blank">
                        <img src={twitterImage} alt="social-icon" title="page not found" width="20px" />
                      </Link>
                      <Link className="linkedin" to={{ pathname: "https://www.linkedin.com/company/winds-e-pvt-ltd/" }} target="_blank">
                        <img src={linkedinImage} alt="social-icon" title="page not found" width="20px" />
                      </Link>
                      <Link className="youtube" to={{ pathname: "https://www.youtube.com/channel/UC9oRFRK-5QmokZsS-cBMd2Q" }} target="_blank">
                        <img src={youtubeImage} alt="social-icon" title="page not found" width="20px" />
                      </Link>
                      <Link className="instagram" to={{ pathname: "https://www.instagram.com/winds.app" }} target="_blank">
                        <img src={instagramImage} alt="social-icon" title="page not found" width="20px" />
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-2 pb-4">
                    <span className="bold text-white mb-3 footer-heading">
                      <FormattedMessage
                        id="footer.quick-link"
                        defaultMessage="Quick Links"
                        description="Quick Links"
                        values={{ what: "react-intl" }}
                      />
                    </span>
                    <div className="row">
                      <p className="col-12">
                        <Link to="/patrons">
                          <FormattedMessage
                            id="patrons"
                            defaultMessage="Patrons"
                            values={{ what: "react-intl" }}
                          />
                        </Link>
                      </p>
                      <p className="col-12">
                        <Link to="/partner">
                        Kamaie Dukaan
                        </Link>
                      </p>
                      <p className="col-12">
                        <Link to="/digi-world">
                          <FormattedMessage
                            id="winds-digi-world"
                            defaultMessage="KAMAIE Digi-World"
                            values={{ what: "react-intl" }}
                          />
                        </Link>
                      </p>
                      <p className="col-12">
                        <Link to="/about-us">
                          <FormattedMessage
                            id="about-us"
                            defaultMessage="About Us"
                            values={{ what: "react-intl" }}
                          />
                        </Link>
                      </p>
                      {/* <p className="col-12">
                        <Link to="/luckydraw-patrons">
                          <FormattedMessage
                            id="luckydraw-patrons"
                            defaultMessage="Luckydraw-Patrons"
                            values={{ what: "react-intl" }}
                          />
                        </Link>
                      </p>
                      <p className="col-12">
                        <Link to="/luckydraw-partners">
                          <FormattedMessage
                            id="luckydraw-partners"
                            defaultMessage="Luckydraw-Partners"
                            values={{ what: "react-intl" }}
                          />
                        </Link>
                      </p> */}
                      {/* <p className="col-12">
                        <Link to="/testimonials">
                          <FormattedMessage
                            id="testimonials"
                            defaultMessage="Testimonials"
                            values={{ what: "react-intl" }}
                          />
                        </Link>
                      </p> */}

                    </div>
                  </div>
                  <div className="col-md-2 pb-4">
                    <span className="bold text-white mb-3 footer-heading">
                      <FormattedMessage
                        id="help"
                        defaultMessage="Help"
                        values={{ what: "react-intl" }}
                      />
                    </span>
                    <div className="row">
                      <p className="col-12">
                        <Link to="/contact-us">
                          <FormattedMessage
                            id="contact-us"
                            defaultMessage="Contact Us"
                            values={{ what: "react-intl" }}
                          />
                        </Link>
                      </p>
                      <p className="col-12">
                        <Link to="/terms-condition">
                          <FormattedMessage
                            id="footer.terms-and-conditions"
                            defaultMessage="Terms & Conditions"
                            description="Terms & Conditions"
                            values={{ what: "react-intl" }}
                          />
                        </Link>
                      </p>
                      <p className="col-12">
                        <Link to="/privacy-policy">
                          <FormattedMessage
                            id="footer.privacy-policy"
                            defaultMessage="Privacy Policy"
                            description="Privacy Policy"
                            values={{ what: "react-intl" }}
                          />
                        </Link>
                      </p>
                      {/* <p className="col-12"> */}
                        {/* <Link to="/credits"> */}
                        {/* <FormattedMessage
                          id="footer.credits"
                          defaultMessage="Credits"
                          description="Credits"
                          values={{ what: "react-intl" }}
                        /> */}
                        {/* </Link> */}
                      {/* </p> */}
                      <p className="col-12">
                        <Link to="/faq">
                          <FormattedMessage
                            id="footer.faq"
                            defaultMessage="FAQ"
                            description="FAQ"
                            values={{ what: "react-intl" }}
                          />
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-2 pb-4">
                    <span className="bold text-white mb-3 footer-heading">
                      KAMAIE APP
                    </span>
                    <div className="row">
                      <p className="col-12">
                        <a href="https://kamaie.com" target="_blank" rel="noreferrer">
                         KAMAIE Customer
                        </a>
                      </p>
                      <p className="col-12">
                       
                       KAMAIE Dukaan
                        
                      </p>
                      {/* <p className="col-12">
                        <a href="https://p.winds.to/app" target="_blank" rel="noreferrer">
                          <FormattedMessage
                            id="partner"
                            defaultMessage="Customer"
                            values={{ what: "react-intl" }}
                          />
                        </a>
                      </p> */}
                      <p className="col-12">
                        
                        KAMAIE KARO
                      
                      </p>
                    </div>
                    <span className="bold text-white mb-3 footer-heading mt-4">
                      <FormattedMessage
                        id="download-now"
                        defaultMessage="Download Now"
                        values={{ what: "react-intl" }}
                      />
                    </span>
                    <div>
                      <a href="https://winds.to/app" target="blank" rel="noreferrer">
                        <img width="120px" src={playstoreImage} alt="icon" title="icon"/>
                      </a>
                      <a href="https://winds.to/app" target="blank" rel="noreferrer">
                        <img className="mt-md-3 mt-0 ml-md-0 ml-2" width="120px" src={appstoreImage} alt="icon" title="icon"/>
                      </a>
                    </div>
                  </div>

                  <div className="col-md-3 pb-4">
                    <span className="bold text-white mb-3 footer-heading">
                      <FormattedMessage
                        id="contact-us"
                        defaultMessage="Contact Us"
                        values={{ what: "react-intl" }}
                      />
                    </span>
                    <p>
                    <span> support@kamaie.com</span>
                      {/* <FormattedMessage id="address" /> */}
                    </p>
                    {/* <span className="bold text-white mb-3 footer-heading mt-5">
                      <FormattedMessage
                        id="support"
                        defaultMessage="Support"
                        values={{ what: "react-intl" }}
                      />
                    </span> */}
                    {/* <p>
                      <span>
                        <FormattedMessage
                          id="footer.email"
                          defaultMessage="Email:"
                          description="Email:"
                          values={{ what: "react-intl" }}
                        />
                      </span>
                      <span> support@winds.co.in</span>
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="site-footer-heart">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center py-3">
                <img src={ic_simhaaa} alt="heart" title="heart" />
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid container-fluid--sub-footer">
          <div className="row">
            <div className="col-12 text-center py-4">
              <small>
                <FormattedMessage
                  id="footer.terms-and-conditions-before"
                />{" "}
                <Link to="/terms-condition">
                  <FormattedMessage
                    id="footer.terms-and-condition-middle"
                  />
                </Link>{" "}
                <FormattedMessage
                  id="footer.terms-and-conditions-after"
                />
                <div className="marchandize-link" onClick={this.handleWindsKartLink}>
                  {" "}
                </div>
              </small>
            </div>
          </div>
        </div>
         </>
        )}
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    winds_kart: state.home?.winds_kart,
    email:state.global?.profile?.email,
  };
}
export default connect(mapStateToProps)(withRouter(Footer));
