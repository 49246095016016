import React from "react";
import { useState } from "react";
import ic_rupee from "../../../../assets/prepaid_cards/ic_rupee_3x.png";
import Accordion from 'react-bootstrap/Accordion';
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ic_document from "../../../../assets/prepaid_cards/ic_document.svg"
import prepaidCardClientX from "../../../../utils/api/prepaidCardClientX";
import PageLoader from "../../../../components/pageLoader";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setShippingRedirectionData, setUserSelectedCardData } from "../../actions";
import ic_arrow from "../../../../assets/prepaid_cards/Path.svg";
import ic_pine_labs from "../../../../assets/prepaid_cards/ic_pine_labs.svg";
import SuccessModal from "../../components/SuccessModal";
import { toast } from "react-toastify";

export default function CardLimits() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { cardDetails, selectedCardData } = useSelector((state) => state?.ppi)
  const { profile } = useSelector((state) => state?.global)

  const [pageLoading, setPageLoading] = useState(false);
  const [cardLimitsLoad, setCardLimitsLoad] = useState(0);
  const [onlinePaymentActive, setOnlinePaymentActive] = useState(cardDetails?.set_transaction_limit_data?.isEcommTransactionEnabled);
  const [offlinePaymentActive, setOfflinePaymentActive] = useState(cardDetails?.set_transaction_limit_data?.isPOSTransactionEnabled);
  const [contactlessPaymentActive, setContactlessPaymentActive] = useState(cardDetails?.set_transaction_limit_data?.isContactlessTransactionEnabled);
  const [onlineCardLimitValues, setOnlineCardLimitValues] = useState(cardDetails?.set_transaction_limit_data?.ecommRedeemTransactionLimit);
  const [OfflineCardLimitValues, setOfflineCardLimitValues] = useState(cardDetails?.set_transaction_limit_data?.posRedeemTransactionLimit);
  const [contactlessCardLimitValues, setContactlessCardLimitValues] = useState(cardDetails?.set_transaction_limit_data?.contactlessRedeemTransactionLimit);
  const [onlineCardLimitErrorMessage, setOnlineCardLimitErrorMessage] = useState("")
  const [offlineCardLimitErrorMessage, setOfflineCardLimitErrorMessage] = useState("")
  const [contactlessCardLimitErrorMessage, setContactlessCardLimitErrorMessage] = useState("")
  const [successModal, setSuccessModal] = useState(false)


  const getOnlineBackgroundSize = () => {
    return {
      backgroundSize: `${(onlineCardLimitValues * 100) / cardDetails?.set_transaction_limit_data?.ecommRedeemMaxTransactionLimit}% 100%`,
    };
  };

  const getOfflineBackgroundSize = () => {
    return {
      backgroundSize: `${(OfflineCardLimitValues * 100) / cardDetails?.set_transaction_limit_data?.posRedeemMaxTransactionLimit}% 100%`,
    };
  };

  const getContactlessBackgroundSize = () => {
    return {
      backgroundSize: `${(contactlessCardLimitValues * 100) / cardDetails?.set_transaction_limit_data?.contactlessMaxRedeemTransactionLimit}% 100%`,
    };
  };

  const handleUserInput = (type, value) => {
    switch (type) {
      case "online_transaction":
        if (value > cardDetails?.set_transaction_limit_data?.ecommRedeemMaxTransactionLimit || value < 0)
          setOnlineCardLimitErrorMessage("Enter valid amount")
        else
          setOnlineCardLimitErrorMessage("")
        break;
      case "offline_transaction":
        if (value > cardDetails?.set_transaction_limit_data?.posRedeemMaxTransactionLimit || value < 0)
          setOfflineCardLimitErrorMessage("Enter valid amount")
        else
          setOfflineCardLimitErrorMessage("")
        break;
      case "contactless_transaction":
        if (value > cardDetails?.set_transaction_limit_data?.contactlessMaxRedeemTransactionLimit || value < 0)
          setContactlessCardLimitErrorMessage("Enter valid amount")
        else
          setContactlessCardLimitErrorMessage("")
        break;

      default:
        break;
    }
  }

  const updateCardLimits = () => {
    const authConfig = {
      headers: {
        TempAuthToken: profile?.temp_ppi_token,
      }
    }
    const payload = {
      "referenceNumber": cardDetails?.referenceNumber,
      "isEcommTransactionEnabled": onlinePaymentActive ? 1 : 0,
      "ecommTransactionLimit": onlinePaymentActive ? parseInt(onlineCardLimitValues) : 0,
      "isPOSTransactionEnabled": offlinePaymentActive ? 1 : 0,
      "posTransactionLimit": offlinePaymentActive ? parseInt(OfflineCardLimitValues) : 0,
      "isContactlessTransactionEnabled": contactlessPaymentActive ? 1 : 0,
      "contactlessTransactionLimit": contactlessPaymentActive ? parseInt(contactlessCardLimitValues) : 0
    }

    setCardLimitsLoad(true)
    prepaidCardClientX.post(`/card-transaction-limit-update`, payload, authConfig)
      .then((response) => {
        // getCardDetails()
        setSuccessModal(true)
        setCardLimitsLoad(false)
      })
      .catch(error => {
        setCardLimitsLoad(false)
      })
  }


  const applyPhysicalCard = () => {
    let payload = {
      redirectType: "manage_card"
    }
    dispatch(setUserSelectedCardData({
      ...selectedCardData,
      cardTypeID: cardDetails?.applyForPhysicalCardId
    }))
    dispatch(setShippingRedirectionData(payload))
    // history.push('/prepaid-cards/shipping/edit')
    handlePageRedirection()
  }

  const handlePageRedirection = () => {
    
    const authConfig = {
      headers: {
        TempAuthToken: profile?.temp_ppi_token,
      }
    }
    let apiURL = 'cart-details'
    let requestParams = {
      card_plan_id: selectedCardData?.cardPlanID,
      card_type_id: cardDetails?.applyForPhysicalCardId
    }
    prepaidCardClientX
    
      .post(apiURL, requestParams, authConfig)
      .then((response) => {
        // if (!response?.data?.success) {
        //   toast.success(response.data?.message)
        // }
        if (response?.data?.data?.page_redirection_segment === "min_kyc_document")
          history.push("/prepaid-cards/start-kyc")
        if (response?.data?.data?.page_redirection_segment === "full_kyc_document")
          history.push("/prepaid-cards/start-premium-kyc")
        else if (response?.data?.data?.page_redirection_segment === "card_details_page")
          history.push("/prepaid-cards/managecard")
        else if (response?.data?.data?.page_redirection_segment === "shipping_address_details_page")
          history.push("/prepaid-cards/shipping/edit")
        else if (response?.data?.data?.page_redirection_segment === "payment_summary")
          history.push("/prepaid-cards/purchase/cart")
      })
      .catch((error) => {
        // this.props.history.goBack();
      });
    
  }

  const Shimmer = (props) => {
    return (
      <>
        <div className="box shine w-50 d-flex justify-content-start mb-2" style={{ height: "40px" }}></div>
        <div className="box shine w-100" style={{ height: "60px" }}></div>
        <div className="box shine w-100 mb-4" style={{ height: "60px" }}></div>
        <div className="box shine w-50 d-flex justify-content-start mb-2" style={{ height: "40px" }}></div>
        <div className="box shine w-100" style={{ height: "60px" }}></div>
        <div className="box shine w-100 mb-4" style={{ height: "60px" }}></div>
        <div className="box shine w-50 d-flex justify-content-start mb-2" style={{ height: "40px" }}></div>
        <div className="box shine w-100 " style={{ height: "60px" }}></div>
        <div className="box shine w-100" style={{ height: "60px" }}></div>
      </>
    );
  };

  return (
    <div>
      <div className="py-5">
        <div className="container ppi-card-limit">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-sm-10 col-md-6 text-center">
              <div className="d-flex justify-content-between">
              <p className="cardkyc-title text-center bold ">Card Limits</p>
              <img src={ic_pine_labs} alt="pinelabs icon"/>
              </div>
              {pageLoading ?
                <Shimmer /> :
                <>
                  <div>
                    <div className="mb-md-5 mb-4">
                      <p className="cardedit-body-text mt-1 text-left text-bold mb-0">
                        Online Transaction
                      </p>
                      <div className="limit-data w-100 p-3 d-flex justify-content-between mt-2">
                        <p className="m-0 text-align-left cardkyc-limit-text">
                          ECOM Transaction (Online Payments)
                        </p>
                        <label className="switch align-self-center m-0">
                          <input
                            type="checkbox"
                            onChange={() =>
                              setOnlinePaymentActive(!onlinePaymentActive)
                            }
                            defaultChecked={onlinePaymentActive}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <Accordion>
                        <Card className="cardlimit-accordion-style mt-3">
                          <div className="cardlimit-accordian-item" id={onlinePaymentActive ? "" : "limit-data-deactive"}>
                            <Card.Header style={{ backgroundColor: "#f7f7ff" }} className="">
                              <Accordion.Toggle
                                as={Card}
                                eventKey="0"
                                variant="text"
                                className="cardlimit-accordion-toggle-btn d-flex  justify-content-between cursor-pointer"
                              >
                                <p className="p-1 text-left d-flex justify-content-between cardkyc-limit-text" style={{ backgroundColor: "#f7f7ff" }}><span>Set Limit</span><span className="mr-4">Rs. {onlineCardLimitValues}</span></p>
                                <i
                                  className="fa fa-angle-right accordion-arrow text-right mt-n5 "
                                  aria-hidden="true"
                                ></i>
                              </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0" variant="text">
                              <Card.Body className="accordion-card-body p-0">
                                <div className="limit-data w-100 p-3">
                                  <div className="card-limit-input-range-style">
                                    <input
                                      className="form-range w-100 mt-1"
                                      type="range"
                                      min="0"
                                      step={100}
                                      max={cardDetails?.set_transaction_limit_data?.ecommRedeemMaxTransactionLimit}
                                      onChange={(e) => setOnlineCardLimitValues(e.target.value)}
                                      style={getOnlineBackgroundSize()}
                                      value={onlineCardLimitValues || 0}
                                    />
                                  </div>
                                  <label
                                    for="customRange1"
                                    className="form-label d-flex justify-content-between text-bold mt-2"
                                  >
                                    <span><strong>₹ 0</strong>
                                    </span>
                                    <span><strong>₹ {cardDetails?.set_transaction_limit_data?.ecommRedeemMaxTransactionLimit}</strong></span>
                                  </label>
                                  <div className="d-flex justify-content-center align-items-center">
                                    <span className="">
                                      <img src={ic_rupee} alt="rupee-icon w-100" /></span>
                                    <input
                                      style={{ width: "150px", border: "2px solid #e4e4e4", color: "#4e77f7", fontSize: "large" }}
                                      value={onlineCardLimitValues}
                                      type="number"
                                      className="form__field cardedit-body-text p-1 text-center"
                                      name="online"
                                      key="online"
                                      onChange={(e) => {
                                        handleUserInput("online_transaction", e.target.value)
                                        setOnlineCardLimitValues(e.target.value)
                                      }}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>{onlineCardLimitErrorMessage !== "" && onlineCardLimitErrorMessage}</span>
                                  {
                                    cardDetails?.card_plan_upgrade_details?.is_upgrade_flag &&
                                    <div className="upgrade-strip mt-2 text-black" onClick={() => { history.push('/prepaid-cards/plans') }}>
                                      <img src={ic_document} alt="img" width="20px" className="mr-2" />
                                      <small>{cardDetails?.card_plan_upgrade_details?.upgrade_text_display_deposit_amount_screen}</small>
                                    </div>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </div>
                        </Card>
                      </Accordion>
                    </div>
                    <div className="mb-md-5 mb-4">
                      <p className="cardedit-body-text mt-3 text-left text-bold mb-0">
                        Offline Transaction
                      </p>
                      <div className="limit-data w-100 p-3 d-flex justify-content-between mt-2">
                        <p className="m-0 text-align-left cardkyc-limit-text">
                          POS Transactions (Offline Payments)
                        </p>
                        <label className="switch align-self-center m-0">
                          <input
                            type="checkbox"
                            onChange={() =>
                              setOfflinePaymentActive(!offlinePaymentActive)
                            }
                            defaultChecked={offlinePaymentActive}
                            disabled={!cardDetails?.set_transaction_limit_data?.isPhysicalCardEnabled} 
                          />
                          <span className={`slider round ${!cardDetails?.set_transaction_limit_data?.isPhysicalCardEnabled ? 'cursor-not-allowed' : ''}`} ></span>
                        </label>
                      </div>
                      <Accordion>
                        <Card className="cardlimit-accordion-style mt-3">
                          <div className="cardlimit-accordian-item" id={offlinePaymentActive ? "" : "limit-data-deactive"}>
                            <Card.Header style={{ backgroundColor: "#f7f7ff" }} className="">
                              <Accordion.Toggle
                                as={Card}
                                eventKey="0"
                                variant="text"
                                className="cardlimit-accordion-toggle-btn d-flex  justify-content-between cursor-pointer"
                              >
                                <p className="p-1 text-left d-flex justify-content-between cardkyc-limit-text" style={{ backgroundColor: "#f7f7ff" }}><span>Set Limit</span><span className="mr-4">Rs.  {OfflineCardLimitValues}</span></p>
                                <i
                                  className="fa fa-angle-right accordion-arrow text-right mt-n5 "
                                  aria-hidden="true"
                                ></i>
                              </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0" variant="text">
                              <Card.Body className="accordion-card-body p-0">
                                <div className="limit-data w-100 p-3">
                                  <div className="card-limit-input-range-style">
                                    <input
                                      className="form-range w-100 mt-1"
                                      type="range"
                                      min="0"
                                      step={100}
                                      max={cardDetails?.set_transaction_limit_data?.posRedeemMaxTransactionLimit}
                                      onChange={(e) => setOfflineCardLimitValues(e.target.value)}
                                      style={getOfflineBackgroundSize()}
                                      value={OfflineCardLimitValues || 0}
                                    />
                                  </div>
                                  <label
                                    for="customRange1"
                                    className="form-label d-flex justify-content-between text-bold mt-2"
                                  >
                                    <span><strong>₹ 0</strong>
                                    </span>
                                    <span><strong>₹ {cardDetails?.set_transaction_limit_data?.posRedeemMaxTransactionLimit}</strong></span>
                                  </label>
                                  <div className="d-flex justify-content-center align-items-center">
                                    <span className="m-2"><img src={ic_rupee} alt="rupee-icon w-100" /></span>
                                    <input
                                      style={{ width: "150px", border: "2px solid #e4e4e4", color: "#4e77f7", fontSize: "large" }}
                                      value={OfflineCardLimitValues}
                                      type="number"
                                      className="form__field cardedit-body-text p-1 text-center"
                                      name="offline"
                                      key="offline"
                                      max="200"
                                      onChange={(e) => {
                                        handleUserInput("offline_transaction", e.target.value)
                                        setOfflineCardLimitValues(e.target.value)
                                      }}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>{offlineCardLimitErrorMessage !== "" && offlineCardLimitErrorMessage}</span>
                                  {
                                    cardDetails?.card_plan_upgrade_details?.is_upgrade_flag &&
                                    <div className="upgrade-strip mt-2 text-black" onClick={() => { history.push('/prepaid-cards/plans') }}>
                                      <img src={ic_document} alt="img" width="20px" className="mr-2" />
                                      <small>{cardDetails?.card_plan_upgrade_details?.upgrade_text_display_deposit_amount_screen}</small>
                                    </div>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </div>
                        </Card>
                      </Accordion>
                      {
                        !cardDetails?.set_transaction_limit_data?.isPhysicalCardEnabled &&
                        <div className="upgrade-strip mt-2 text-black p-3 w-100 d-flex justify-content-between cursor-pointer" onClick={applyPhysicalCard}>
                          <div>
                            <img src={ic_document} alt="img" width="20px" className="mr-2" />
                            <span>Apply for Physical Card</span>
                          </div>
                          <img src={ic_arrow} alt="img" width="10px" className="mr-2" />
                        </div>
                      }
                    </div>
                    <div className="mb-md-5 mb-4">
                      <p className="cardedit-body-text mt-3 text-left text-bold mb-0">
                        Contactless Payments
                      </p>
                      <div className="limit-data w-100 p-3 d-flex justify-content-between mt-2">
                        <p className="m-0 text-align-left cardkyc-limit-text">
                          Contactless Payments
                        </p>
                        <label className="switch align-self-center m-0">
                          <input
                            type="checkbox"
                            onChange={() =>
                              setContactlessPaymentActive(!contactlessPaymentActive)
                            }
                            defaultChecked={contactlessPaymentActive}
                            disabled={!cardDetails?.set_transaction_limit_data?.isPhysicalCardEnabled}
                          />
                          <span className={`slider round ${!cardDetails?.set_transaction_limit_data?.isPhysicalCardEnabled ? 'cursor-not-allowed' : ''}`}></span>
                        </label>
                      </div>
                      <Accordion>
                        <Card className="cardlimit-accordion-style my-3">
                          <div className="cardlimit-accordian-item" id={contactlessPaymentActive ? "" : "limit-data-deactive"}>
                            <Card.Header style={{ backgroundColor: "#f7f7ff" }} className="">
                              <Accordion.Toggle
                                as={Card}
                                eventKey="0"
                                variant="text"
                                className="cardlimit-accordion-toggle-btn d-flex  justify-content-between cursor-pointer"
                              >
                                <p className="p-1 text-left d-flex justify-content-between cardkyc-limit-text" style={{ backgroundColor: "#f7f7ff" }}><span>Set Limit</span><span className="mr-4">Rs. {contactlessCardLimitValues}</span></p>
                                <i
                                  className="fa fa-angle-right accordion-arrow text-right mt-n5 "
                                  aria-hidden="true"
                                ></i>
                              </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0" variant="text">
                              <Card.Body className="accordion-card-body p-0">
                                <div className="limit-data w-100 p-3">
                                  <div className="card-limit-input-range-style">
                                    <input
                                      className="form-range w-100 mt-1"
                                      type="range"
                                      min="0"
                                      step={100}
                                      max={cardDetails?.set_transaction_limit_data?.contactlessMaxRedeemTransactionLimit}
                                      onChange={(e) => setContactlessCardLimitValues(e.target.value)}
                                      style={getContactlessBackgroundSize()}
                                      value={contactlessCardLimitValues || 0}
                                    />
                                  </div>
                                  <label
                                    for="customRange1"
                                    className="form-label d-flex justify-content-between text-bold mt-2"
                                  >
                                    <span><strong>₹ 0</strong>
                                    </span>
                                    <span><strong>₹ {cardDetails?.set_transaction_limit_data?.contactlessMaxRedeemTransactionLimit}</strong></span>
                                  </label>
                                  <div className="d-flex justify-content-center align-items-center">
                                    <span className="m-2"><img src={ic_rupee} alt="rupee-icon w-100" /></span>
                                    <input
                                      style={{ width: "150px", border: "2px solid #e4e4e4", color: "#4e77f7", fontSize: "large" }}
                                      value={contactlessCardLimitValues}
                                      type="number"
                                      className="form__field cardedit-body-text p-1 text-center"
                                      name="offline"
                                      key="offline"
                                      max="200"
                                      onChange={(e) => {
                                        handleUserInput("contactless_transaction", e.target.value)
                                        setContactlessCardLimitValues(e.target.value)
                                      }}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>{contactlessCardLimitErrorMessage !== "" && contactlessCardLimitErrorMessage}</span>
                                  <p className="mt-2">Contactless Payments, max permissible limit is INR {cardDetails?.set_transaction_limit_data?.contactlessMaxRedeemTransactionLimit} per month (Regulatory Limit)</p>
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </div>
                        </Card>
                      </Accordion>
                    </div>

                  </div>
                  <button className="animated-btn w-50 m-auto mt-3" onClick={() => updateCardLimits()}>Save</button>
                  <div className="mt-2">
                    <Link to="/prepaid-cards/managecard">
                      <span style={{ textDecoration: "underline" }}>Go back</span>
                    </Link>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </div>
      {
        cardLimitsLoad &&
        <div className="d-flex justify-content-center mt-3">
          <PageLoader />
        </div>
      }
      {successModal &&
        <SuccessModal
          title="Card Limit Updated!"
          onCloseModal={() =>
            history.push('/prepaid-cards/managecard')
          }
          desc="Your Prepaid Card Limit has been successfully updated as per your request"
        />
      }
    </div>
  );
}