import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
// import Iframe from "../../../components/iFrame";
import { currentlySelectedLanguage } from "../../../utils/helper";
import PrivacyPolicyMetaData from "../../../seo/PrivacyPolicyMetaData";

const PrivacyPolicy = (props) => {
  let language = currentlySelectedLanguage()

  const EnglishContent = () => {
    return (
      <div className="">
        <div className="row mt-4">
          <div className="col-12 ">
            <div className="heading px-2 py-3" style={{background:"rgba(0, 0, 0, .03)"}}>
              <h1 className="font-weight-bold" style={{color:"rgb(34, 42, 115)"}}>Privacy Policy</h1>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <h2 className="h4 font-weight-bold">ACCEPTANCE OF PRIVACY POLICY</h2>
            <p>By using the KAMAIE mobile applications ( KAMAIE, KAMAIE Dukaan or KAMAIE KARO) (the
              <b>“Platform”</b>), you, the customer ( <b>“you”</b>, <b>“your”</b>, or the <b>“User”</b> in this
              document) unconditionally agree to the terms and conditions that we have provided herein for the use
              of the KAMAIE Platform.
            </p>
            <p>This Privacy Policy ( <b>“Policy”</b> ) describes our privacy practices in plain language, keeping
              legal and technical jargon to a minimum. While you are enjoying the Platform, we collect some
              information about you in accordance with this Policy. </p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <h2 className="h4 font-weight-bold">WHO WE ARE</h2>
            <p>The Platform is operated by M/s Winds E PRIVATE LIMITED is a Private Limited Company registration held business entity registered under The Companies Act, 2013. It’s registered for pre-defined objects or activity Computer and related activities. It is set up directly by registering the company with RoC, Ministry of Corporate Affairs.
            </p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <h2 className="h4 font-weight-bold">WHERE THIS POLICY APPLIES</h2>
            <p>This Policy applies to all Users of the Platform. Our Terms and Conditions and other policies and
              regulations (<b>“Terms”</b>) are incorporated herein by reference. All defined capitalized terms in
              this Policy shall have the same meaning as defined in the Terms unless otherwise provided for. </p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <h2 className="h4 font-weight-bold">INFORMATION WE COLLECT</h2>
            <p>Our services require us to collect certain information from you (which includes personal and
              sensitive information), apart from which we also collect information through third party service
              providers and your regular interactions on our Platform. Further information on what all information
              is collected is given below:</p>
            <h3 className="h5 font-weight-bold">Information You Give Us</h3>
            <ul>
              <li>
                <p>When you create an Account, we would require you to furnish certain information about you:
                </p>
                <ul>
                  <li>Name;</li>
                  <li>Address;</li>
                  <li>Mobile Number;</li>
                  <li>Email ID;</li>
                  <li>Date of Birth;</li>
                  <li>Gender;</li>
                  <li>Location; </li>
                  <li>Aadhar Number;</li>
                  <li>You will also be required to create a password in connection with registration of your
                    Account. Once you register, you will be able to review and change this information at
                    any time by logging in to your Account.</li>
                </ul>
              </li>
              <li>We may also require you to furnish certain other information such as your bank account number,
                name, IFSC Code, Credit Card information and other payment processing information. Such
                information may be shared with the Payment Processor as well. </li>
              <li>We may also require you to furnish information about your corporate entity or business such as -
                Name, Corporate Identification Number, Address, GSTIN, PAN, and other relevant information.
              </li>
              <li>You may provide certain information while giving feedback or comments (if permitted on the
                Platform);</li>
              <li>When you choose to participate in our promotions, events or contests, we collect information
                that you use to register or enter;</li>
              <li>If you contact our customer care team, we collect the information you give us during the
                interaction. Sometimes, we monitor or record these interactions for training purposes and to
                ensure a high quality of service.</li>
              <li>If you ask us to communicate with or otherwise process information of other people (for example,
                if you ask us to send an email on your behalf to one of your referrals), we collect the
                information about others that you give us in order to complete your request.</li>
            </ul>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <h3 className="h5 font-weight-bold">Information We Receive From Others</h3>
            <p>In addition to the information you provide to us directly, we receive information about you from
              other, including:</p>
            <ul>
              <li>Referrals: When another user of the Platform (being a  Customer, or KAMAIE Dukaan or KBP)
                refers you to join our Platform, they may provide us with information about you such as your
                name, mobile number, email address or other similar contact information;
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <h3 className="h5 font-weight-bold">Information Collected When You Use Our Services</h3>
            <ul>
              <li>Usage Information: We collect information about your activity on our services, for example how
                you use them (such as date and time you logged in, your searches, clicks and pages which have
                been shown to you, any advertisements you click on) and how you interact with other users (such
                as the KAMAIE Dukaans/  Customers/ KBP you connect and interact with or refer, time and
                date of your exchanges, payments you send and receive).</li>
              <li>
                <p>Device Information: We collect information from and about the devices you use to access our
                  Services, including:</p>

                <ul>
                  <li>Hardware and software information such as IP address, device ID and type, device
                    specification and app settings and characteristics, app crashed, advertising IDs,
                    browser type, version and language, operating system, time zones, identifiers associated
                    with cookies or other technologies that may uniquely identify your device or browser;
                  </li>
                  <li>Information on you wireless and mobile network connection, like your services provider
                    and signal strength;</li>
                  <li>Information on device sensors such as accelerometers, gyroscopes and compasses</li>
                </ul>
              </li>
              <li>Other Information with your Consent: if you give us permission, we can collect your precise
                geolocation through various means, depending on the service and device you are using, including
                GPS, Bluetooth or Wi-Fi connections. The collection of your geolocation may occur in the
                background even when you are not using the Services if the permission you gave us expressly
                permits such collection. If you decline permission for us to collect your geolocation, we will
                not collect it, however it may be required for the purpose of certain Services on the Platform.
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <h2 className="h4 font-weight-bold">COOKIES AND OTHER SIMILAR DATA COLLECTION TECHNOLOGIES</h2>
            <p>When you visit our Service, we may assign your device one or more cookies or other technologies that
              facilitate personalization to facilitate access to our Service and to personalize your experience.
              Through the use of a cookie, we also may automatically collect information about your activity on
              our Service, such as the pages you visit, the time and date of your visits and the links you click
              and also collect information about how you interacted with the online KAMAIE Dukaans (whether you
              purchased any goods/ services from their portal). If we advertise, we (or third parties) may use
              certain data collected on our Service to show you advertisements on other sites or applications.</p>
            <p>If you deny the use of Cookies or other such technologies to our Platform on your device, we may not
              be able to detect your activities on the online KAMAIE Dukaans portals and resultantly we shall not be
              liable to credit any reward (KAMAIE Coins, as the case may be or commissions) to your Account.
            </p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <h2 className="h4 font-weight-bold">HOW WE USE THE INFORMATION</h2>
            <p>The main reason we use your information is to deliver and improve our Services. Additionally, we use
              your information to help keep you safe and to provide you with advertising that may be of interest
              to you. Therefore, we may use the information collected to:</p>

            <ul>
              <li>Deliver and improve our Services, and manage our business;</li>
              <li>Manage your Account and provide you with customer support;</li>
              <li>Perform research and analysis about your use of, or interest in, our or others’ products,
                services, or content;</li>
              <li>Ensure that the payments for the transactions are handled in a proper fashion;</li>
              <li>Credit the relevant reward points or commission, as applicable, to your Account as exclusive
                benefits of our Platform;</li>
              <li>Communicate with you by email, telephone, WhatsApp or other communication mode for notifications
                with respect to the Services or your Account.</li>
              <li>Communicate with you by email, postal mail, telephone and/or mobile devices about products or
                services that may be of interest to you either from us or other third parties;</li>
              <li>Develop, display, and track content and advertising tailored to your interests on our Service
                and other sites, including providing our advertisements to you when you visit other sites;</li>
              <li>Mobile application analytics;</li>
              <li>To prevent, detect and fight fraud or other illegal or unauthorized activities;</li>
              {/* <li>Verify your eligibility and deliver prizes in connection with the Lucky Draw or other contests
                and sweepstakes</li> */}
              <li>Enforce or exercise any rights in our Terms;</li>
              <li>To ensure legal compliance or assisting law enforcement agencies; and</li>
              <li>Perform functions or services as otherwise described to you at the time of collection.</li>
            </ul>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <h2 className="h4 font-weight-bold">HOW WE SHARE THE INFORMATION</h2>
            <p>Since we operate an online marketplace wherein a customer and vendor interact to trade goods/
              services, we share some of the information provided by you with other members and our service
              providers and also with certain other persons as given below:</p>
            <ul>
              <li>With other users: When using the Platform, you should assume that anything you post or submit on
                the Platform, may be publicly-viewable and accessible by other Patrons (Customers), KAMAIE Dukaans or
                Captains or unregistered users (guests). We may, where applicable, share certain payment/
                transaction information between users to fulfil the Services. </li>

              <li>With our service providers: We use third parties to help us operate and improve our Services.
                These third parties assist us with various tasks, including data hosting and maintenance,
                analytics, customer care, marketing, advertising, payment processing and security operations. We
                may also share information with KAMAIE Dukaans who distribute and assist us in advertising our
                services. For instance, we may share limited information on you in hashed, non-human readable
                form to advertising KAMAIE Dukaans. We follow a strict vetting process prior to engaging any service
                provider or working with any KAMAIE Dukaan. All of our service providers and KAMAIE Dukaans must agree to
                strict confidentiality obligations.</li>

              <li>For corporate transactions: We may transfer your information if we are involved, whether in
                whole or in part, in a merger, sale, acquisition, divestiture, restructuring, reorganization,
                dissolution, bankruptcy or other change of ownership or control.</li>

              <li>When required by law: We may disclose your information if reasonably necessary: (i) to comply
                with a legal process, such as a court order, or warrant, government / law enforcement
                investigation or other legal requirements; (ii) to assist in the prevention or detection of
                crime (subject in each case to applicable law); or (iii) to protect the safety of any person.
              </li>

              <li>To enforce our legal rights: We may also share information: (i) if disclosure would mitigate our
                liability in an actual or threatened lawsuit; (ii) as necessary to protect our legal rights and
                legal rights of our users, business KAMAIE Dukaans or other interested parties; (iii) to enforce our
                agreements with you; and (iv) to investigate, prevent, or take other action regarding illegal
                activity, suspected fraud or other wrongdoing.</li>

              <li>With your consent or at your request: We may ask for your consent to share your information with
                third parties. In any such case, we will make it clear why we want to share the information.
              </li>
            </ul>
            <p>We may use and share non-personal information (meaning information that, by itself, does not identify
              who you are such as device information, general demographics, general behavioural data, geolocation
              in de-identified form), as well as personal information in hashed, non-human readable form, under
              any of the above circumstances. We may also share this information with other group companies and
              third parties (notably advertisers) to develop and deliver targeted advertising on our services and
              on websites or applications of third parties, and to analyse and report on advertising you see. We
              may combine this information with additional non-personal information or personal information in
              hashed, non-human readable form collected from other sources.</p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <h2 className="h4 font-weight-bold">TRANSFER OF DATA</h2>
            <ul>
              <li>Your information may be transferred to and maintained on devices located outside of India, where
                data protection laws may differ other those from India. If you are located outside India, you
                may not use the Services however, if you still use such information, we reserve the right to
                process your information in India.</li>
              <li>KAMAIE will take all steps reasonably necessary to ensure that your data is treated securely and
                in accordance with this Privacy Policy and no transfer of your Personal Data will take place to
                an organization or a country unless there are adequate controls in place including the security
                of your data and other personal information.</li>
            </ul>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <h2 className="h4 font-weight-bold">YOUR RIGHTS</h2>
            <p>In order to control your information, you may use any of the following:</p>
            <ul>
              <li>Access/ update tools in the Service: Account settings that help you access, rectify or delete
                information that you provided to us and that’s associated with your Account directly within the
                Platform.</li>
              <li>Device Permissions: Mobile platforms have permission systems for specific types of device data
                and notifications, such as phone book and location services as well as push notifications. You
                can change your settings on your device to either consent or oppose the collection of the
                corresponding information or the display of the corresponding notifications. Of course, if you
                do that, certain services may lose full functionality.</li>
              <li>Deletion: We may provide you with the ability to delete your account however, we may still
                retain certain information for the purpose of compliance with applicable laws of India.</li>
              <li>Uninstall: You can stop all information collection by an app by uninstalling it using the
                standard uninstall process for your device. If you uninstall the app from your mobile device,
                the unique identifier associated with your device will continue to be stored. If you re-install
                the application on the same mobile device, we will be able to re-associate this identifier to
                your previous transactions and activities.</li>
              <li>Complaint: You may lodge a complaint with our customer service or grievance redressal officer
                for any data related query on your account.</li>
            </ul>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <h2 className="h4 font-weight-bold">HOW WE PROTECT YOUR INFORMATION</h2>
            <ul>
              <li>We work hard to protect you from unauthorized access to or alteration, disclosure or destruction
                of your personal information. As with all technology companies, although we take steps to secure
                your information, we do not promise, and you should not expect, that your personal information
                will always remain secure.</li>

              <li>We regularly monitor our systems for possible vulnerabilities and attacks and regularly review
                our information collection, storage and processing practices to update our physical, technical
                and organizational security measures.</li>

              <li>We may suspend your use of all or part of the Services without notice if we suspect or detect
                any breach of security. If you believe that your account or information is no longer secure,
                please notify us immediately at the customer service or grievance redressal officer. We will not
                be liable for any such breach where you have failed to inform us immediately.</li>

              {/* <li>Particularly with respect to Lucky Draws, in case you as a Participant win any prize in the
                Lucky Draw, our only obligation shall be to remit the prize money to the bank account validated
                on your Account. We shall not be liable in case any such bank account provided on your Account
                does not belong to you or if you are unable to utilize the funds.</li> */}
            </ul>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <h2 className="h4 font-weight-bold">SECURITY</h2>
            <ul>
              <li>The security of your data is important to us but remember that no method of transmission over
                the Internet, or method of electronic storage is 100% secure. While we strive to use
                commercially acceptable means to protect your information, we cannot, in any manner whatsoever,
                guarantee its absolute security. Unfortunately, no internet transmission is ever completely 100%
                secure and even we cannot guarantee that unauthorized access, hacking, data loss or other
                breaches will never occur.</li>
              <li>If you ever think someone has had access to your password or Account, please follow the steps
                set out here. We cannot guarantee the security of your personal data while it is being
                transmitted to our site and any transmission is at your own risk.</li>
            </ul>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <h2 className="h4 font-weight-bold">POLICY CHANGES</h2>
            <p>We take privacy seriously and therefore, from time to time we may amend, change, rescind and/or
              update this Policy to reflect the same. We also amend this Policy if there is any change in our
              business model. Your continued use of the Platform would be deemed acceptance of any change in this
              Policy. It shall be your responsibility to keep yourself updated with any change and regularly
              review this Policy along with the Terms. If you disagree with any provision of this Policy, you may
              stop using the Platform.</p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <h2 className="h4 font-weight-bold">GRIEVANCE REDRESSAL</h2>
            <p>In the event of any violation by any user of this Policy or any inconsistent application of this
              Policy on the Platform or contravention of the laws, you may provide your concerns in writing to us
              at:</p>
            <p>
              Email: grievance@kamaie.com
            </p>

            <p>Additionally, you may also contact us via the address given below. When something gets reported to
              KAMAIE, we review it and act accordingly. We don’t include any information about the person who filed
              the report when we contact the reported persons. However, please note that reporting something to us
              does not guarantee that it will be removed as we are legally bound to retain certain things. </p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <h2 className="h4 font-weight-bold">MISCELLANEOUS</h2>
            <ul>
              <li>Children’s Privacy: Our Services do not cater to anyone below the age of 18 years. KAMAIE cannot
                personally identify if a person is 18 years of age or not, therefore it is the responsibility of
                the parent or guardian of anyone below the age of 18 years to ensure they do not access our
                Services/ Platform. If we are notified of any such person (below the age of 18 years), we shall
                immediately prohibit their access to our Platform. </li>
              <li>Contact Us: If you have any questions or comments, or would like to discuss your Account with
                our Customer Support staff, please contact us at the details below:
                <p><br />
                  Name: KAMAIE  <br />
                  Email: support@kamaie.com
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }

  const HindiContent = () => {
    return (
      <div className="">
        <div className="row mt-4">
          <div className="col-12">
            <div className="heading px-2 py-3" style={{background:"rgba(0, 0, 0, .03)"}}>
              <h1 className="font-weight-bold" style={{color:"rgb(34, 42, 115)"}}>गोपनीयता नीति</h1>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h2 className="my-4 font-weight-bold pt-2 h4">
              गोपनीयता नीति की स्वीकृति
            </h2>

            <p>
            KAMAIE मोबाइल एप्लिकेशन  ग्राहक, कमाई दुकान या KBP ("प्लेटफ़ॉर्म") का उपयोग करके, आप, ग्राहक
              ("आप", "आपका", या इस दस्तावेज़ में "उपयोगकर्ता") बिना किसी शर्त के हमने यहां KAMAIE प्लेटफॉर्म के
              उपयोग के लिए जो शर्तें प्रदान की इन से सहमत हैं।
            </p>

            <p>
              यह गोपनीयता नीति ("नीति") कानूनी और तकनीकी शब्दजाल को न्यूनतम रखते हुए हमारी गोपनीयता प्रथाओं का सरल
              भाषा में वर्णन करती है। जब आप प्लेटफ़ॉर्म का आनंद ले रहे हों, हम इस नीति के अनुसार आपके बारे में कुछ
              जानकारी एकत्र करते हैं।
            </p>

            <h2 className="my-4 font-weight-bold pt-2 h4">
              हम कौन हैं
            </h2>

            <p>
              प्लेटफॉर्म का संचालन मेसर्स KAMAIE ई प्राइवेट लिमिटेड निजी कंपनी की श्रेणी में सूचीबद्ध है और गैरसरकारी कंपनी के रूप में वर्गीकृत है। यह कंपनी रजिस्ट्रार ऑफ कंपनीज (ROC) में रजिस्टर्ड है ("KAMAIE", "हम सब", "हम" या "हमारा"
              इस दस्तावेज़
              में)।
            </p>

            <h2 className="my-4 font-weight-bold pt-2 h4">
              जहां यह नीति लागू होती है
            </h2>

            <p>
              यह नीति प्लेटफ़ॉर्म के सभी उपयोगकर्ताओं पर लागू होती है। हमारे नियम और शर्तें और अन्य नीतियां और
              विनियम ("नियम") यहां संदर्भ द्वारा शामिल किए गए हैं। इस नीति में सभी परिभाषित पूंजीकृत शर्तों का
              अर्थ वही होगा जो शर्तों में परिभाषित किया गया है जब तक कि अन्यथा प्रदान न किया गया हो।
            </p>

            <h2 className="my-4 font-weight-bold pt-2 h4">
              जानकारी हम एकत्र करते हैं
            </h2>

            <p>
              हमारी सेवाओं के लिए हमें आपसे कुछ जानकारी एकत्र करने की आवश्यकता होती है (जिसमें व्यक्तिगत और
              संवेदनशील जानकारी शामिल होती है), इसके अलावा हम तीसरे पक्ष के सेवा प्रदाताओं और हमारे प्लेटफॉर्म पर
              आपकी नियमित बातचीत के माध्यम से भी जानकारी एकत्र करते हैं। सभी जानकारी क्या एकत्र की जाती है, इसके
              बारे में अधिक जानकारी नीचे दी गई है:
            </p>

            <h2 className="my-4 font-weight-bold pt-2 h4">
              जानकारी जो आप हमें देते हैं
            </h2>

            <p>जब आप एक खाता बनाते हैं, तो हम चाहते हैं कि आप अपने बारे में कुछ जानकारी दें:</p>

            <ul className="mb-4 pb-3">
              <li>नाम;</li>
              <li>पता;</li>
              <li>मोबाइल नंबर;</li>
              <li>ईमेल आईडी;</li>
              <li>जन्म की तारीख;</li>
              <li>लिंग;</li>
              <li>स्थान;</li>
              <li>आधार संख्या;</li>
            </ul>

            <p>
              आपको अपने खाते के पंजीकरण के संबंध में एक पासवर्ड भी बनाना होगा। एक बार पंजीकरण करने के बाद, आप अपने
              खाते में लॉग इन करके किसी भी समय इस जानकारी की समीक्षा और परिवर्तन कर सकेंगे।
            </p>

            <ul className="mb-4 pb-3">
              <li>
                हम आपसे कुछ अन्य जानकारी जैसे कि आपका बैंक खाता नंबर, नाम, IFSC कोड, क्रेडिट कार्ड की जानकारी और
                अन्य भुगतान प्रसंस्करण जानकारी भी प्रस्तुत करने को कह सकते हैं। ऐसी जानकारी पेमेंट प्रोसेसर के
                साथ भी साझा की जा सकती है।
              </li>
              <li>
                हम आपको अपनी कॉर्पोरेट इकाई या व्यवसाय के बारे में जानकारी जैसे - नाम, कॉर्पोरेट पहचान संख्या,
                पता, जीएसटीआईएन, पैन और अन्य प्रासंगिक जानकारी प्रस्तुत करने को भी कह सकते हैं।
              </li>
              <li>
                आप प्रतिक्रिया या टिप्पणी देते समय कुछ जानकारी प्रदान कर सकते हैं (यदि प्लेटफ़ॉर्म पर अनुमति
                है);
              </li>
              <li>
                जब आप हमारे प्रचारों, आयोजनों या प्रतियोगिताओं में भाग लेना चुनते हैं, तो हम वह जानकारी एकत्र
                करते हैं जिसका उपयोग आप पंजीकरण या दर्ज करने के लिए करते हैं;
              </li>
              <li>
                यदि आप हमारी ग्राहक सेवा टीम से संपर्क करते हैं, तो हम बातचीत के दौरान आपके द्वारा हमें दी गई
                जानकारी एकत्र करते हैं। कभी-कभी, हम प्रशिक्षण उद्देश्यों के लिए और सेवा की उच्च गुणवत्ता
                सुनिश्चित करने के लिए इन इंटरैक्शन की निगरानी या रिकॉर्ड करते हैं।
              </li>
              <li>
                यदि आप हमें अन्य लोगों के साथ संवाद करने या किसी अन्य प्रकार से सूचनाओं को प्रोसेस करने के लिए
                कहते हैं (उदाहरण के लिए, यदि आप हमें अपनी ओर से अपने किसी रेफरल को ईमेल भेजने के लिए कहते हैं),
                तो आपका अनुरोध को पूरा करने के लिए हम दूसरों के बारे में जानकारी एकत्र करते हैं।
              </li>
            </ul>

            <h2 className="my-4 font-weight-bold pt-2 h4">
              जानकारी हम दूसरों से प्राप्त करते हैं
            </h2>

            <p>
              आपके द्वारा हमें सीधे प्रदान की जाने वाली जानकारी के अलावा, हम आपके बारे में अन्य लोगों से भी
              जानकारी प्राप्त करते हैं, जिनमें शामिल हैं:
            </p>

            <p>
              <b>रेफ़रल:</b> जब प्लेटफ़ॉर्म का कोई अन्य उपयोगकर्ता (एक ग्राहक, या कमाई दुकान या KBP होने
              के नाते) आपको हमारे प्लेटफ़ॉर्म में शामिल होने के लिए रेफर करता है, तो वे हमें आपके बारे में जानकारी
              प्रदान कर सकते हैं जैसे आपका नाम, मोबाइल नंबर, ईमेल पता या अन्य समान संपर्क जानकारी;
            </p>

            <p className="mt-4 pt-3">
              <b>जब आप हमारी सेवाओं का उपयोग करते हैं तो एकत्रित जानकारी</b>
            </p>

            <ul className="my-4 py-3">
              <li>
                <b>उपयोग की जानकारी:</b> हम अपनी सेवाओं पर आपकी गतिविधि के बारे में जानकारी एकत्र करते हैं,
                उदाहरण के
                लिए आप उनका उपयोग कैसे करते हैं (जैसे कि आपके द्वारा लॉग इन करने की तिथि और समय, आपकी खोज, क्लिक
                और पृष्ठ जो आपको दिखाए गए हैं, कोई भी विज्ञापन जिस पर आप क्लिक करते हैं) और कैसे आप अन्य
                उपयोगकर्ताओं के साथ बातचीत करते हैं जैसे कमाई दुकान/ ग्राहक/ KBP जिनसे आप जुड़ते हैं और
                उनके साथ बातचीत करते हैं या रेफर करते हैं, और आपके एक्सचेंज और पेमेंट के प्राप्त और भेजने का समय
                और तारीख।
              </li>

              <li>
                <p>
                  <b>डिवाइस की जानकारी:</b> हम हमारी सेवाओं तक पहुंचने के लिए आपके द्वारा उपयोग किए जाने वाले
                  उपकरणों से और उनके बारे में जानकारी एकत्र करते हैं, जिनमें शामिल हैं:
                </p>

                <ol className="my-4">
                  <li>
                    हार्डवेयर और सॉफ्टवेयर जानकारी जैसे आईपी पता, डिवाइस आईडी और प्रकार, डिवाइस विनिर्देश और
                    ऐप सेटिंग्स और विशेषताएं, ऐप क्रैश, विज्ञापन आईडी, ब्राउज़र प्रकार, संस्करण और भाषा,
                    ऑपरेटिंग सिस्टम, समय क्षेत्र, कुकीज़ या अन्य तकनीकों से जुड़े आईडेन्टिफायर्स अपने डिवाइस
                    या ब्राउज़र को विशिष्ट रूप से पहचान सकते हैं।
                  </li>
                  <li>
                    आपके वायरलेस और मोबाइल नेटवर्क कनेक्शन की जानकारी, जैसे आपके सेवा प्रदाता और सिग्नल की
                    शक्ति;
                  </li>
                  <li>
                    एक्सेलेरोमीटर, जायरोस्कोप और कंपास जैसे डिवाइस सेंसर की जानकारी।
                  </li>
                </ol>
              </li>
              <li>
                <b>आपकी सहमति से अन्य जानकारी:</b> यदि आप हमें अनुमति देते हैं, तो हम जीपीएस, ब्लूटूथ या वाई-फाई
                कनेक्शन सहित आपके द्वारा उपयोग की जा रही सेवा और डिवाइस के आधार पर विभिन्न माध्यमों से आपकी सटीक
                भौगोलिक स्थिति एकत्र कर सकते हैं। आपके भौगोलिक स्थान का संग्रह तब भी हो सकता है जब आप सेवाओं का
                उपयोग नहीं कर रहे हों, यदि आपने हमें जो अनुमति दी है वह स्पष्ट रूप से ऐसे संग्रह की अनुमति देती
                है। यदि आप हमें अपना भौगोलिक स्थान एकत्र करने की अनुमति अस्वीकार करते हैं, तो हम इसे एकत्र नहीं
                करेंगे, हालांकि प्लेटफ़ॉर्म पर कुछ सेवाओं के उद्देश्य के लिए इसकी आवश्यकता हो सकती है।
              </li>
            </ul>

            <h2 className="my-4 font-weight-bold pt-2 h4">
              कुकीज़ और अन्य समान डेटा संग्रह तकनीकें
            </h2>

            <p>
              जब आप हमारी सेवाओं पर जाते हैं, तो हम आपकी डिवाइस को एक या अधिक कुकीज़ या अन्य तकनीकों को असाइन कर
              सकते हैं जो हमारी सेवा तक पहुंच को सुविधाजनक बनाने और आपके अनुभव को वैयक्तिकृत करने के लिए
              वैयक्तिकरण की सुविधा प्रदान करती हैं। एक कुकी के उपयोग के माध्यम से, हम स्वचालित रूप से हमारी सेवा
              पर आपकी गतिविधि के बारे में जानकारी एकत्र कर सकते हैं, जैसे कि आपके द्वारा देखे जाने वाले पृष्ठ,
              आपकी विज़िट का समय और तारीख और आपके द्वारा क्लिक किए गए लिंक और यह भी जानकारी एकत्र करते हैं कि आपने
              ऑनलाइन कमाई दुकान के साथ कैसे बातचीत की चाहे आपने उनके पोर्टल से कोई सामान/सेवाएं खरीदी हों। यदि हम
              विज्ञापन करते हैं, तो हम (या तृतीय पक्ष) हमारी सेवा पर एकत्रित कुछ डेटा का उपयोग आपको अन्य साइटों या
              एप्लिकेशन पर विज्ञापन दिखाने के लिए कर सकते हैं।
            </p>

            <p>
              यदि आप अपने डिवाइस पर हमारे प्लेटफॉर्म पर कुकीज़ या अन्य ऐसी तकनीकों के उपयोग से इनकार करते हैं, तो
              हम ऑनलाइन कमाई दुकान पोर्टल्स पर आपकी गतिविधियों का पता लगाने में सक्षम नहीं हो सकते हैं और इसके
              परिणामस्वरूप हम किसी भी क्रेडिट प्वांइट (डब्ल्यूएसपी या डब्ल्यूआरपी, जैसा कि मामला हो सकता है या
              कमीशन) आपके खाते में दे पाने के लिए बाध्य नहीं होंगे।
            </p>

            <h2 className="my-4 font-weight-bold pt-2 h4">
              हम जानकारी का उपयोग कैसे करते हैं
            </h2>

            <p>आपकी जानकारी का उपयोग करने का मुख्य कारण हमारी सेवाओं को वितरित करना और उनमें सुधार करना है। इसके
              अतिरिक्त, हम आपकी जानकारी का उपयोग आपको सुरक्षित रखने में सहायता के लिए और आपको ऐसे विज्ञापन प्रदान
              करने के लिए करते हैं जो आपकी रुचि के हो सकते हैं। इसलिए, हम एकत्रित जानकारी का उपयोग निम्न के लिए कर
              सकते हैं:</p>

            <ul className="mb-4 pb-2">
              <li>हमारी सेवाओं को वितरित और सुधारने के लिए, और हमारे व्यवसाय का प्रबंधन करने के लिए</li>
              <li>आपका खाता मैनेज करने के लिए और आपको ग्राहक सहायता प्रदान करने के लिए ।</li>
              <li>आपके इस्तेमाल और रूचि जो आपके द्वारा हमारे या दूसरों के प्रोडक्ट, सर्विसेज़ या सामग्री पर दिखाया
                गया है, पर अनुसंधान और विश्लेषण करने के लिए। </li>
              <li>सुनिश्चित करने के लिए कि लेनदेन के लिए भुगतान उचित तरीके से किया जाता है;</li>
              <li>हमारे प्लेटफ़ॉर्म के अनन्य लाभों के रूप में प्रासंगिक रिवॉर्ड पॉइंट या कमीशन, जैसा लागू हो, को
                अपने खाते में क्रेडिट करने के लिए;</li>
              <li>सेवाओं या आपके खाते के संबंध में सूचनाओं के लिए ईमेल, टेलीफोन, व्हाट्सएप या अन्य संचार माध्यम से
                आपसे संवाद करने के लिए।</li>
              <li>उन उत्पादों या सेवाओं के बारे में ईमेल, डाक मेल, टेलीफोन और/या मोबाइल उपकरणों द्वारा आपके साथ
                संवाद करने के लिए जो हमारी या अन्य तृतीय पक्षों की ओर से आपके लिए रुचिकर हो सकते हैं;</li>
              <li>जब आप अन्य साइटों पर जाते हैं तो आपको हमारे विज्ञापन प्रदान करने सहित, हमारी सेवा और अन्य साइटों
                पर आपकी रुचि के अनुरूप सामग्री और विज्ञापन विकसित करने , प्रदर्शित करने और ट्रैक करने के लिए;
              </li>
              <li>मोबाइल एप्लिकेशन विश्लेषण;</li>
              <li>धोखाधड़ी या अन्य अवैध या अनधिकृत गतिविधियों को रोकने, पता लगाने और लड़ने के लिए;</li>
              <li>अपनी पात्रता सत्यापित करने के लिए और लकी ड्रॉ या अन्य प्रतियोगिताओं और स्वीपस्टेक के संबंध में
                पुरस्कार वितरित करने के लिए
              </li>
              <li>हमारी शर्तों में किसी भी अधिकार को लागू करने या उसका प्रयोग करने के लिए;</li>
              <li>कानूनी अनुपालन सुनिश्चित करने या कानून प्रवर्तन एजेंसियों की सहायता करने के लिए; तथा</li>
              <li>संग्रह के समय आपके लिए अन्यथा वर्णित कार्यों या सेवाओं को निष्पादित करने के लिए।</li>
            </ul>

            <h2 className="my-4 font-weight-bold pt-2 h4">
              हम जानकारी कैसे साझा करते हैं
            </h2>

            <p>
              चूंकि हम एक ऑनलाइन मार्केटप्लेस संचालित करते हैं, जहां एक ग्राहक और विक्रेता वस्तुओं/सेवाओं का
              व्यापार करने के लिए बातचीत करते हैं, हम आपके द्वारा प्रदान की गई कुछ जानकारी को अन्य सदस्यों और
              हमारे सेवा प्रदाताओं के साथ और कुछ अन्य व्यक्तियों के साथ भी साझा करते हैं जैसा कि नीचे दिया गया है:
            </p>

            <ul className="mb-4 pb-2">
              <li>
                <b>अन्य उपयोगकर्ताओं के साथ:</b> प्लेटफ़ॉर्म का उपयोग करते समय, आपको यह मान लेना चाहिए कि आप
                प्लेटफ़ॉर्म पर जो कुछ भी पोस्ट या सबमिट करते हैं, वह अन्य संरक्षकों (ग्राहकों), भागीदारों या
                कप्तानों या अपंजीकृत उपयोगकर्ताओं (अतिथियों) द्वारा सार्वजनिक रूप से देखने योग्य और पहुंच योग्य
                हो सकता है। हम, जहां लागू हो, सेवाओं को पूरा करने के लिए उपयोगकर्ताओं के बीच कुछ भुगतान/लेन-देन
                की जानकारी साझा कर सकते हैं।
              </li>

              <li>
                <b>हमारे सेवा प्रदाताओं के साथ:</b> हम अपनी सेवाओं को संचालित करने और बेहतर बनाने में मदद करने
                के लिए तीसरे पक्ष का उपयोग करते हैं। ये तृतीय पक्ष डेटा होस्टिंग और रखरखाव, विश्लेषण, ग्राहक
                सेवा, विपणन, विज्ञापन, भुगतान प्रसंस्करण और सुरक्षा संचालन सहित विभिन्न कार्यों में हमारी सहायता
                करते हैं। हम उन भागीदारों के साथ भी जानकारी साझा कर सकते हैं जो हमारी सेवाओं के विज्ञापन और
                वितरण में हमारी सहायता करते हैं। उदाहरण के लिए, हम आपके बारे में हैशेड, गैर-मानव पठनीय रूप में
                विज्ञापन भागीदारों को सीमित जानकारी साझा कर सकते हैं। किसी भी सेवा प्रदाता को नियुक्त करने या
                किसी भागीदार के साथ काम करने से पहले हम एक सख्त जांच प्रक्रिया का पालन करते हैं। हमारे सभी सेवा
                प्रदाताओं और भागीदारों को सख्त गोपनीयता दायित्वों के लिए सहमत होना होता है।
              </li>

              <li>
                <b>कॉर्पोरेट लेनदेन के लिए:</b> यदि हम विलय, बिक्री, अधिग्रहण, विनिवेश, पुनर्गठन, विघटन,
                दिवालियापन या स्वामित्व या नियंत्रण के अन्य परिवर्तन में शामिल हैं, तो हम आपकी जानकारी को
                स्थानांतरित कर सकते हैं।
              </li>

              <li>
                <b>जब कानून द्वारा आवश्यक हो:</b> यदि उचित रूप से आवश्यक हो तो हम आपकी जानकारी का खुलासा कर सकते
                हैं: <br />
                (i) कानूनी प्रक्रिया का पालन करने के लिए, जैसे कि अदालत का आदेश, या वारंट, सरकार / कानून
                प्रवर्तन जांच या अन्य कानूनी आवश्यकताएं; <br />
                (ii) अपराध की रोकथाम या पता लगाने में सहायता करना (प्रत्येक मामले में लागू कानून के अधीन); या
                <br />
                (iii) किसी व्यक्ति की सुरक्षा की रक्षा के लिए।
              </li>

              <li>
                <b>हमारे कानूनी अधिकारों को लागू करने के लिए:</b> हम जानकारी भी साझा कर सकते हैं:
                <br />
                (i) यदि
                प्रकटीकरण किसी वास्तविक या संकटग्रस्त मुकदमे में हमारे दायित्व को कम करेगा; <br />
                (ii) हमारे कानूनी
                अधिकारों और हमारे उपयोगकर्ताओं, व्यापार भागीदारों या अन्य इच्छुक पार्टियों के कानूनी अधिकारों की
                रक्षा के लिए आवश्यक है;
                <br />
                (iii) आपके साथ हमारे समझौतों को लागू करने के लिए; और
                <br />
                (iv) अवैध गतिविधि, संदिग्ध धोखाधड़ी या अन्य गलत कामों की जांच, रोकथाम या अन्य कार्रवाई करने के
                लिए।
              </li>
              <li>
                <b>आपकी सहमति से या आपके अनुरोध पर:</b> हम आपकी जानकारी को तीसरे पक्ष के साथ साझा करने के लिए
                आपकी सहमति मांग सकते हैं। ऐसे किसी भी मामले में, हम यह स्पष्ट करेंगे कि हम जानकारी क्यों साझा
                करना चाहते हैं।
              </li>
            </ul>

            <p>
              हम गैर-व्यक्तिगत जानकारी का उपयोग कर सकतें हैं और उसको साझा कर सकते हैं (अर्थात् ऐसी जानकारी, जो
              स्वयं की पहचान नहीं करती है कि आप कौन हैं जैसे कि डिवाइस की जानकारी, सामान्य जनसांख्यिकी, सामान्य
              व्यवहार डेटा, गैर-पहचान में भौगोलिक स्थान), साथ ही हैशेड में व्यक्तिगत जानकारी , उपरोक्त किसी भी
              परिस्थिति में गैर-मानव पठनीय रूप। हम इस जानकारी को अन्य समूह कंपनियों और तृतीय पक्षों (विशेष रूप से
              विज्ञापनदाताओं) के साथ साझा कर सकते हैं ताकि हमारी सेवाओं और वेबसाइटों या तृतीय पक्षों के
              अनुप्रयोगों पर लक्षित विज्ञापन विकसित और वितरित कर सकें, और आपके द्वारा देखे जाने वाले विज्ञापन पर
              विश्लेषण और रिपोर्ट कर सकें। हम इस जानकारी को अतिरिक्त गैर-व्यक्तिगत जानकारी या अन्य स्रोतों से
              एकत्र किए गए हैशेड, गैर-मानव पठनीय रूप में व्यक्तिगत जानकारी के साथ जोड़ सकते हैं।
            </p>

            <h2 className="my-4 font-weight-bold pt-2 h4">
              डेटा का स्थानांतरण
            </h2>

            <ul className="mb-4 pb-2">
              <li>
                आपकी जानकारी को भारत के बाहर स्थित उपकरणों पर स्थानांतरित और अनुरक्षित किया जा सकता है, जहां
                डेटा सुरक्षा कानून भारत से भिन्न हो सकते हैं। यदि आप भारत से बाहर स्थित हैं, तो आप सेवाओं का
                उपयोग नहीं कर सकते हैं, हालांकि, यदि आप अभी भी ऐसी जानकारी का उपयोग करते हैं, तो हम आपकी जानकारी
                को भारत में संसाधित (प्रोसेस) करने का अधिकार सुरक्षित रखते हैं।
              </li>
              <li>
              KAMAIE यह सुनिश्चित करने के लिए उचित रूप से आवश्यक सभी कदम उठाएगी कि आपके डेटा को सुरक्षित रूप से
                treat किया जाए और इस गोपनीयता नीति के अनुसार और आपके व्यक्तिगत डेटा का कोई हस्तांतरण किसी संगठन
                या देश में तब तक नहीं होगा जब तक कि आपके डेटा और अन्य व्यक्तिगत जानकारी के सुरक्षा सहित पर्याप्त
                नियंत्रण न हों।
              </li>
            </ul>

            <h2 className="my-4 font-weight-bold pt-2 h4">
              ग्राहक का हक
            </h2>

            <p>
              अपनी जानकारी को नियंत्रित करने के लिए, आप निम्न में से किसी का भी उपयोग कर सकते हैं:
            </p>

            <ul className="mb-4 pb-2">
              <li>
                सेवा में एक्सेस/अपडेट टूल: खाता सेटिंग्स जो आपको हमें प्रदान की गई जानकारी तक पहुंचने, सुधारने
                या हटाने में मदद करती हैं और जो सीधे आपके खाते से जुड़ी होती है।
              </li>
              <li>
                डिवाइस अनुमतियाँ: मोबाइल प्लेटफ़ॉर्म में विशिष्ट प्रकार के डिवाइस डेटा और सूचनाओं, जैसे फ़ोन बुक
                और स्थान सेवाओं के साथ-साथ पुश सूचनाओं के लिए अनुमति प्रणालियाँ होती हैं। आप सहमति देने या
                संबंधित जानकारी के संग्रह का विरोध करने या संबंधित सूचनाओं के प्रदर्शन का विरोध करने के लिए अपने
                डिवाइस पर अपनी सेटिंग्स बदल सकते हैं। बेशक, यदि आप ऐसा करते हैं, तो कुछ सेवाएं की पूर्ण
                कार्यक्षमता खो सकती हैं।
              </li>
              <li>
                हटाना: हम आपको आपके खाते को हटाने की क्षमता प्रदान कर सकते हैं, हालांकि, हम भारत के लागू कानूनों
                के अनुपालन के उद्देश्य से कुछ जानकारी अभी भी रख सकते हैं।
              </li>
              <li>
                अनइंस्टॉल करें: आप अपने डिवाइस के लिए मानक अनइंस्टॉल प्रक्रिया का उपयोग करके किसी ऐप को
                अनइंस्टॉल करके सभी जानकारी संग्रह को रोक सकते हैं। अगर आप अपने मोबाइल डिवाइस से ऐप को अनइंस्टॉल
                करते हैं, तो आपके डिवाइस से जुड़े यूनिक आइडेंटिफ़ायर को स्टोर करना जारी रहेगा। यदि आप उसी मोबाइल
                डिवाइस पर एप्लिकेशन को फिर से इंस्टॉल करते हैं, तो हम इस पहचानकर्ता को आपके पिछले लेन-देन और
                गतिविधियों के साथ फिर से जोड़ सकेंगे।
              </li>
              <li>
                शिकायत: आप अपने खाते से संबंधित किसी भी डेटा संबंधी पूछताछ के लिए हमारी ग्राहक सेवा या शिकायत
                निवारण अधिकारी के पास शिकायत दर्ज करा सकते हैं।
              </li>
            </ul>

            <h2 className="my-4 font-weight-bold pt-2 h4">
              हम आपकी जानकारी की सुरक्षा कैसे करते हैं
            </h2>

            <ul className="mb-4 pb-2">
              <li>
                हम आपकी व्यक्तिगत जानकारी तक अनधिकृत पहुंच या परिवर्तन, प्रकटीकरण या विनाश से आपकी रक्षा करने के
                लिए कड़ी मेहनत करते हैं। जैसा कि सभी प्रौद्योगिकी कंपनियों के साथ होता है, हालांकि हम आपकी
                जानकारी को सुरक्षित करने के लिए कदम उठाते हैं, हम वादा नहीं करते हैं, और आपको यह उम्मीद नहीं
                करनी चाहिए कि आपकी व्यक्तिगत जानकारी हमेशा सुरक्षित रहेगी।
              </li>

              <li>
                हम संभावित कमजोरियों और हमलों के लिए नियमित रूप से अपने सिस्टम की निगरानी करते हैं और अपने
                भौतिक, तकनीकी और संगठनात्मक सुरक्षा उपायों को अपडेट करने के लिए नियमित रूप से हमारे सूचना
                संग्रह, भंडारण और प्रसंस्करण प्रथाओं की समीक्षा करते हैं।
              </li>

              <li>
                यदि हमें सुरक्षा के किसी उल्लंघन का संदेह या पता चलता है, तो हम बिना किसी सूचना के आपके सभी या
                सेवाओं के हिस्से के उपयोग को निलंबित कर सकते हैं। यदि आपको लगता है कि आपका खाता या जानकारी अब
                सुरक्षित नहीं है, तो कृपया हमें तुरंत ग्राहक सेवा या शिकायत निवारण अधिकारी को सूचित करें। हम ऐसे
                किसी भी उल्लंघन के लिए उत्तरदायी नहीं होंगे जहां आप हमें तुरंत सूचित करने में विफल रहे हैं।
              </li>

              <li>
                विशेष रूप से लकी ड्रॉ के संबंध में, यदि आप एक प्रतिभागी के रूप में लकी ड्रॉ में कोई पुरस्कार
                जीतते हैं, तो हमारा एकमात्र दायित्व यह होगा कि हम पुरस्कार राशि को आपके खाते में मान्य बैंक खाते
                में भेज दें। यदि आपके खाते में प्रदान किया गया ऐसा कोई बैंक खाता आपका नहीं है या यदि आप धन का
                उपयोग करने में असमर्थ हैं, तो हम इसके लिए उत्तरदायी नहीं होंगे।
              </li>
            </ul>

            <h2 className="my-4 font-weight-bold pt-2 h4">
              सुरक्षा
            </h2>

            <ul className="mb-4 pb-2">
              <li>
                आपके डेटा की सुरक्षा हमारे लिए महत्वपूर्ण है लेकिन याद रखें कि इंटरनेट पर ट्रांसमिशन का कोई भी
                तरीका या इलेक्ट्रॉनिक स्टोरेज का तरीका 100% सुरक्षित नहीं है। जबकि हम आपकी जानकारी की सुरक्षा के
                लिए व्यावसायिक रूप से स्वीकार्य साधनों का उपयोग करने का प्रयास करते हैं, हम किसी भी तरह से इसकी
                पूर्ण सुरक्षा की गारंटी नहीं दे सकते हैं। दुर्भाग्य से, कोई भी इंटरनेट ट्रांसमिशन कभी भी पूरी
                तरह से 100% सुरक्षित नहीं होता है और यहां तक ​​कि हम यह गारंटी नहीं दे सकते कि अनधिकृत पहुंच,
                हैकिंग, डेटा हानि या अन्य उल्लंघन कभी नहीं होंगे।
              </li>
              <li>
                अगर आपको कभी लगता है कि किसी के पास आपके पासवर्ड या खाते तक पहुंच है, तो कृपया यहां दिए गए चरणों
                का पालन करें। हम आपके व्यक्तिगत डेटा की सुरक्षा की गारंटी नहीं दे सकते, जबकि इसे हमारी साइट पर
                प्रेषित किया जा रहा है और कोई भी प्रसारण आपके अपने जोखिम पर है।
              </li>
            </ul>

            <h2 className="my-4 font-weight-bold pt-2 h4">
              नीति परिवर्तन
            </h2>

            <p>
              हम गोपनीयता को गंभीरता से लेते हैं और इसलिए, समय-समय पर हम इस नीति में संशोधन, परिवर्तन, रद्दीकरण
              और/या इसे प्रतिबिंबित करने के लिए अद्यतन कर सकते हैं। यदि हमारे व्यापार मॉडल में कोई परिवर्तन होता
              है तो हम इस नीति में भी संशोधन करते हैं। आपके द्वारा प्लेटफ़ॉर्म के निरंतर उपयोग को इस नीति में किसी
              भी बदलाव की स्वीकृति माना जाएगा।
            </p>

            <p>
              किसी भी बदलाव से खुद को अपडेट रखना और शर्तों के साथ इस नीति की नियमित रूप से समीक्षा करना आपकी
              जिम्मेदारी होगी। यदि आप इस नीति के किसी प्रावधान से असहमत हैं, तो आप प्लेटफ़ॉर्म का उपयोग करना बंद
              कर सकते हैं।
            </p>

            <h2 className="my-4 font-weight-bold pt-2 h4">
              शिकायत निवारण
            </h2>

            <p>
              इस नीति के किसी भी उपयोगकर्ता द्वारा किसी भी उल्लंघन या प्लेटफ़ॉर्म पर इस नीति के किसी भी असंगत
              एपलिकेशन या कानूनों के उल्लंघन की स्थिति में, आप हमें लिखित रूप में अपनी चिंताओं को यहां प्रदान कर
              सकते हैं:
            </p>

            <p>
              ईमेल: grievance@kamaie.com
            </p>

            <p>
              इसके अतिरिक्त, आप नीचे दिए गए पते पर भी हमसे संपर्क कर सकते हैं। जब KAMAIE को किसी चीज़ की सूचना
              मिलती है, तो हम उसकी समीक्षा करते हैं और उसके अनुसार कार्य करते हैं। जब हम रिपोर्ट किए गए व्यक्तियों
              से संपर्क करते हैं तो हम रिपोर्ट दर्ज करने वाले व्यक्ति के बारे में कोई जानकारी शामिल नहीं करते हैं।
              हालांकि, कृपया ध्यान दें कि हमें किसी चीज़ की रिपोर्ट करना इस बात की गारंटी नहीं देता कि उसे हटा
              दिया जाएगा क्योंकि हम कुछ चीज़ों को बनाए रखने के लिए कानूनी रूप से बाध्य हैं।
            </p>

            <h2 className="my-4 font-weight-bold pt-2 h4">
              विविध
            </h2>

            <ul className="mb-4">
              <li>
                बच्चों की गोपनीयता: हमारी सेवाएं 18 वर्ष से कम उम्र के किसी भी व्यक्ति को अनुमति नहीं देती हैं।
                KAMAIE व्यक्तिगत रूप से यह नहीं पहचान सकता है कि कोई व्यक्ति 18 वर्ष का है या नहीं, इसलिए यह
                सुनिश्चित करने के लिए 18 वर्ष से कम उम्र के किसी भी व्यक्ति के माता-पिता या अभिभावक की
                जिम्मेदारी है कि वे हमारी सेवाओं/प्लेटफॉर्म तक पहुंच न बनाएं। यदि हमें ऐसे किसी व्यक्ति (18 वर्ष
                से कम आयु) के बारे में सूचित किया जाता है, तो हम तुरंत अपने प्लेटफॉर्म पर उनकी पहुंच को
                प्रतिबंधित कर देंगे।
              </li>
              <li>
                हमसे संपर्क करें: यदि आपके कोई प्रश्न या टिप्पणी हैं, या हमारे ग्राहक सहायता स्टाफ के साथ अपने
                खाते पर चर्चा करना चाहते हैं, तो कृपया नीचे दिए गए विवरण पर हमसे संपर्क करें:
              </li>
            </ul>

            <p>
              <b>नाम:</b> KAMAIE  <br />
              <b>ईमेल:</b> support@kamaie.com
            </p>
          </div>
        </div>

        <div className="row my-4">
          <div className="col-12 p-0">
            <div className="text-center">
              <small>© कॉपीराइट 2025 । KAMAIE .</small>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      <PrivacyPolicyMetaData />
      <div className="container font-proxima-nova">
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            <small>
              <Link to="/">
                <FormattedMessage id="breadcrumbs.home" defaultMessage="Home" />
              </Link>{" "}
              /{" "}
              <strong>
                <FormattedMessage
                  id="footer.privacy-policy"
                  defaultMessage="Privacy Policy"
                  description="Privacy Policy"
                  values={{ what: "react-intl" }}
                />
              </strong>
            </small>
          </div>
        </div>
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            {language === "hi" ? HindiContent() : EnglishContent()}
            {/* <Iframe
              source={`${process.env.REACT_APP_BASE_URL_APP_PRIVACY_POLICY
                }/${currentlySelectedLanguage()}`}
            ></Iframe> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default PrivacyPolicy;
