import React from "react";
import MultiItemCarousel from "../../../components/MultiItemCarousel";
import { useSelector } from "react-redux";
import viewAll from "../../../assets/home/ic_view_all_arrow.svg";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import rightGreen from '../../../assets/icon/rightISideArrow_2.svg'
import rightArrow from '../../../assets/icon/rightBlackArrow.svg'

export default function   ShoppingCarousal() {
  const ShoppingCarousalData = useSelector(
    (state) => state?.home?.popular_affiliate
  );
  const { wrp } = useSelector(
    (state) => state?.home?.heading_text?.shopping_site
  );
  return (
    <div className=" home-page section-space" >
      <div className="container">
        <div className="row">
          <div className="col-9">
            <h2 className="title">
              <span>Experience shopping </span>
              <span className="font-slim">like never before</span>{" "}
            </h2>
          </div>
          <div className="col-3 text-right">
            <Link to={{ pathname: "/shopping-sites", state: { from: "/" } }}>
              <span className="title-thick d-flex justify-content-end mobile-hide">
                <FormattedMessage
                  id="home.view-all"
                  defaultMessage="View All"
                  description="View All"
                  values={{ what: "react-intl" }}
                />
                <img className="view-all" src={rightArrow} alt="view all" title="view all"/>
              </span>
            </Link>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12">
            <h3 className="title-thick font-slim ">
              Buy through handpicked shopping sites especially for you & Earn 
            </h3>
          </div>
        </div>
        <MultiItemCarousel>
          {ShoppingCarousalData?.map((item, index) => {
            return (
              <div
                key={"shopping" + index}
                className="d-flex flex-column justify-content-center align-items-center p-1"
              >
                <div className="shoping-card-title d-flex justify-content-center mb-2">
                  <span>{item?.name}</span>
                </div>
                <Link to={ item?.slug ? `/shopping-sites/${item?.slug}`: `/shopping-sites/${item?.id}`}>
                  <div
                    className="shopping-carousal-box d-flex flex-column position-relative align-items-start my-0 mx-1 md-md-2 mx-lg-3"
                    key={index}
                  >
                    <img
                      style={{ width: "100%" }}
                      src={item?.carousel_image_url}
                      alt="shping-img"
                      title="shping-img"
                      id="shopping-img"
                      className="p-2"
                    />
                    <div className="shopping-earn-caurosal-badge w-100 d-flex flex-column align-items-between justify-content-end position-absolute"  style={{ bottom: "0px" }}>
                      {/* <p className="shopping-carousal-inner-badge-bg shopping-badge-text px-2 px-md-4 mb-0 mx-auto">
                        EARN {parseFloat(item.wrp_percentage).toFixed(0)}% WRP

                      </p> */}
                      {item?.offer_text !== "N/A" ? (
                        <span className="shoping-badge-content-home mt-auto">
                          <p className="shopping-badge-text shopping-offer-badge-home m-0 px-1 py-1 col-10 pl-1">
                            {item?.offer_text}
                          </p>
                        </span>
                      ) : (
                        <span className="mt-auto d-hidden"></span>
                      )}
                    </div>
                    <div className=" w-100 "></div>
                  </div>
                </Link>
              </div>
            );
          })}
        </MultiItemCarousel>
      </div>
    </div>
  );
}
