export const affiliateCategoryMetaContent = {
    "beauty-wellness-grooming": {
        title: "Buy from Beauty, Wellness & Grooming shopping site only on KAMAIE | KAMAIE App  ",
        description: "Buy beauty, wellness or grooming products from online shopping sites via KAMAIEApp. Shop via shopping sites on the KAMAIEApp & buy beauty, wellness or grooming products online.",
        keywords: "beauty  shopping site, wellness   shopping site, KAMAIE app cosmetics   shopping site, KAMAIE app grooming   shopping site, KAMAIE app beauty   shopping sites, KAMAIE app wellness   shopping sites, KAMAIE app wellness   shopping sites, KAMAIE app beauty shopping site,"
    },
    "education": {
        title: "Buy Learning courses from Ed-Tech online site only on KAMAIE | KAMAIE App  ",
        description: "Buy educational services from online shopping sites via KAMAIEApp. Shop via shopping sites on the KAMAIEApp & buy educational services online.",
        keywords: "educational sites, educational website, education online site, Ed-Tech sites, Ed-Tech site, Ed-tech online"
    },
    "fuel-automobile": {
        title: "Buy from Fuel & Automobile shopping site only on KAMAIE | KAMAIE App  ",
        description: "Buy KAMAIE shopping sites/  shopping site for Fuel & Automobile  s online from the KAMAIE App. Shop online today Fuel & Automobile    shopping site using the KAMAIE App. ",
        keywords: "KAMAIE app fuel   voucher, KAMAIE app oil   voucher, automobile  s, KAMAIE app fuel   shopping site, KAMAIE online oil   shopping site, diesel pump   shopping site, automobile oil online shopping site, automobile oil   shopping site, car oil shopping site, HPCL voucher, BPCL voucher"
    },
    "home-furniture": {
        title: "",
        description: "",
        keywords: ""
    },
    "gym-fitness": {
        title: "Buy from Gym & Fitness essentials only on KAMAIE | KAMAIE App  ",
        description: "Buy gym and fitness products or gym membership from online shopping sites via KAMAIEApp. Shop via shopping sites on the KAMAIEApp, buy gym and fitness products or gym membership online. ",
        keywords: "KAMAIE Gym & Fitness, KAMAIE online gym  s, online gym site, online fitness , online fitness site, online gym site, gym sites"
    },
    "miscellaneous": {
        title: "",
        description: "",
        keywords: ""
    },
    "others": {
        title: "Buy products from your favourite shopping sites only on KAMAIE | KAMAIE App  ",
        description: "Buy products from online shopping sites via KAMAIEApp. Shop via shopping sites on the KAMAIEApp & buy products online.",
        keywords: "KAMAIE, KAMAIE app, shopping, shopping site, buy shopping site online, KAMAIE shopping site, KAMAIE   shopping site, gift cards, KAMAIE shopping sites, KAMAIE e-gift shopping site, online shopping site, shopping sites, e-shopping site, buy gift cards, gift shopping site, KAMAIE app gift cards, KAMAIE app gift shopping site, KAMAIE app shopping site, KAMAIE shopping app, "
    },
    "travel-hotels": {
        title: "Book from Travel & Hotels shopping site only on KAMAIE | KAMAIE App  ",
        description: "Buy flight tickets, train tickets, bus tickets or book your hotel from online shopping sites via KAMAIEApp. Shop via shopping sites on the KAMAIEApp & buy your travel tickets or holiday packages online.",
        keywords: " KAMAIEapp online travel site, KAMAIE app hotel booking site, KAMAIE app online flight booking online site, KAMAIE app bus booking site, KAMAIE app rail booking site, KAMAIE Travel & Hotels online, KAMAIE Travel & Hotels sites"
    },
    "clothing-accessories": {
        title: "Buy Clothing & Accessories from shopping site only on KAMAIE | KAMAIE App  ",
        description: "Buy fashion clothes, new clothes, apparel, clothing from online shopping sites via KAMAIEApp. Shop via shopping sites on the KAMAIEApp & order clothes or apparels online.",
        keywords: "KAMAIE Clothing  shopping site, KAMAIE Clothing & Accessories   shopping site, new fashion   shopping site, KAMAIE Fashion   shopping site, KAMAIE Clothes shopping sites, Clothing accessories   shopping sites, KAMAIE Accessories shopping sites, Accessories shopping site, KAMAIE Clothing shopping site, KAMAIE Fashion shopping site, Apparel shopping site"
    },
    "electronics-mobile-accessories": {
        title: "Buy Electronics & Mobile Accessories from shopping site only on KAMAIE | KAMAIE App",
        description: "Buy mobile, electronics, home appliances, electronic accessories from online shopping sites via KAMAIEApp. Shop via shopping sites on the KAMAIEApp & buy electronic devices online.",
        keywords: " KAMAIE Electronics & Mobile Accessories , KAMAIE Electronics site, KAMAIE Mobile Accessories and Electronics  site, KAMAIE new Electronics  site, Electronics  shopping site, KAMAIE Electronics shopping sites, KAMAIE Mobile Accessories   shopping sites, Gadgets shopping sites, Tech shopping site, KAMAIE Mobile shopping site, KAMAIE Mobile E gift  shopping site, New Gadgets shopping site,"
    },
    "gifting": {
        title: "Buy Gifts from shopping site only on KAMAIE | KAMAIE App  ",
        description: "Buy birthday gifts/presents, wedding gifts/presents, anniversary gifts/presents from online shopping sites via KAMAIEApp. Shop via shopping sites on the KAMAIEApp & buy gifts or presents online.",
        keywords: "KAMAIE Gifting E- shopping site, KAMAIE Gifting shopping site, Gift cards, shopping sites, Gift certificates, KAMAIE Voucher codes, KAMAIE Digital gift cards, Online gift shopping site, Gift card codes, Voucher gifts, KAMAIE Gift card offers, Discounted gift cards, KAMAIE Gift card promotions,"
    },
    "healthcare": {
        title: "Buy Healthcare and medicine from online site only on KAMAIE | KAMAIE App  ",
        description: "Buy medicines, pharmacy products, medical products, healthcare products from online shopping sites via KAMAIEApp. Shop via shopping sites on the KAMAIEApp & buy healthcare products online.",
        keywords: "KAMAIE Healthcare and medicine site, KAMAIE Healthcare site, KAMAIE Medical online site, KAMAIE Medical site, KAMAIE Health sites, Medical care site , Health services online, Wellness online, Nutrition site, KAMAIE fitness online site, Health Voucher, Fitness certificates, Health cards, Online Fitness shopping site"
    },
    "jewellery": {
        title: "Buy Jewellery from shopping site only on KAMAIE | KAMAIE App  ",
        description: "Buy jewellery products from online shopping sites via KAMAIEApp. Shop via shopping sites on the KAMAIEApp & buy gold, diamond, silver, platinum jewellery online.",
        keywords: "online jewellery shopping site, KAMAIE app online jewellery shopping site, KAMAIE app jewellery shopping site, KAMAIE app jewellery   shopping site, KAMAIE jewellery shopping site"
    },
    "movies-events": {
        title: "Buy Movies & Events tickets and pass online only on KAMAIE | KAMAIE App",
        description: "Book movie tickets or event tickets from online shopping sites via KAMAIEApp. Shop via shopping sites on the KAMAIEApp & buy movie tickets or event tickets online.",
        keywords: "KAMAIE online movie site, KAMAIE app movie shopping site, online movie booking site, KAMAIE online event  site, online event booking site, "
    },
    "ott": {
        title: "Buy Clothing & Accessories from shopping site only on KAMAIE | KAMAIE App  ",
        description: "Buy KAMAIE shopping sites/  shopping site for OTT  s online from the KAMAIE App. Shop online today OTT   shopping site using the KAMAIE App. ",
        keywords: "KAMAIE OTT   shopping site,KAMAIE Movies   shopping site, KAMAIE TV Series   shopping site, KAMAIE OTT   shopping sites, KAMAIE Kids' streaming   shopping sites, KAMAIE Movie Streaming   shopping sites, KAMAIE Tv Series shopping site,"
    },
    "travel-accessories": {
        title: "Buy Travel Accessories from shopping site only on KAMAIE | KAMAIE App  ",
        description: "Buy travel accessories, suitcases, luggage, bags from online shopping sites via KAMAIEApp. Shop via shopping sites on the KAMAIEApp & buy travel bags online.",
        keywords: "KAMAIE Travel Accessories shopping site, KAMAIE Travel   shopping site, KAMAIE Travel E-Gift shopping site, Travel bags  shopping sites, KAMAIE Accessories Travel streaming   shopping sites, Travel Bags  shopping sites,KAMAIE Travel Accessories online site"
    },
    "e-commerce": {
        title: "Buy anything from your favourite E-commerce site only on KAMAIE | KAMAIE App",
        description: "Order products from online ecommerce shopping sites via KAMAIEApp. Shop via ecommerce shopping sites on the KAMAIEApp & buy products online.",
        keywords: "KAMAIE e-commerce site, KAMAIE app online shopping site, KAMAIE app e-commerce site, KAMAIE e-commerce, KAMAIE e-commerce websites"
    },
    "food-beverages": {
        title: "Buy Food & Beverages online only on KAMAIE | KAMAIE App  ",
        description: "Order food or beverages from online shopping sites via KAMAIEApp. Shop via shopping sites on the KAMAIEApp & order food or beverage online.",
        keywords: "KAMAIE online food delivery site, KAMAIE app online food ordering site, KAMAIE app food site, KAMAIE online beverage ordering, KAMAIE online food order site"
    },
    "grocery": {
        title: "Buy Grocery online only on KAMAIE | KAMAIE App  ",
        description: "Order daily groceries from online shopping sites via KAMAIEApp. Shop via shopping sites on the KAMAIEApp & order groceries online.",
        keywords: "KAMAIE online grocery delivery site, KAMAIE app online grocery ordering site, KAMAIE app food site, online grocery ordering, KAMAIE grocery online,"
    },
    "home-decor": {
        title: "Buy Home & Decor items from shopping site only on KAMAIE | KAMAIE App  ",
        description: "Order home and decor products from online shopping sites via KAMAIEApp. Shop via shopping sites on the KAMAIEApp & order home and decor products online.",
        keywords: "KAMAIE online home decor shopping site, KAMAIE app online home decor shopping site, KAMAIE Home & Decor online, home decor shopping site, "
    },
    "kids-books-stationery": {
        title: "Buy Kids Clothing, Books & Stationary from online shopping site only on KAMAIE | KAMAIE App  ",
        description: "Buy kids toys, stationery,books from online shopping sites via KAMAIEApp. Shop via shopping sites on the KAMAIEApp & buy kid related products online.",
        keywords: "KAMAIE Kids, Books & Stationary shopping site, KAMAIE Books & Stationary shopping site, KAMAIE Books site, KAMAIE Stationary site, KAMAIE Stationary and Books, Stationary accessories shopping sites, Accessories shopping sites, Kids Accessories shopping site, KAMAIE Kids Clothing shopping site, KAMAIE Kids Fashion shopping site, KAMAIE Apparel shopping site"
    },
    "music": {
        title: "",
        description: "",
        keywords: ""
    },
    "salon-spa": {
        title: "Book Salon & Spa online only on KAMAIE | KAMAIE App  ",
        description: "Book salon and spa services from online shopping sites via KAMAIEApp. Shop via shopping sites on the KAMAIEApp & book salon and spa services online.",
        keywords: "KAMAIE online salon site, KAMAIE app salon site, KAMAIE online spa site, KAMAIE app salon site, KAMAIE salon and spa online site"
    },
}