import React from "react";
import { connect, useSelector } from "react-redux";
import { useState } from "react";
import ic_mobile from "../../../assets/prepaid_cards/ic_mobile.svg";
import ic_user from "../../../assets/prepaid_cards/ic_user.svg";
import ic_flag from '../../../assets/prepaid_cards/ic_flag.png';
import ic_map from '../../../assets/prepaid_cards/map.svg'
import { useHistory } from "react-router-dom";
import prepaidCardClientX from "../../../utils/api/prepaidCardClientX";
import InlineLoader from "../../../components/InlineLoader";
import Lottie from "lottie-react";
import ic_waiting from "../../../assets/prepaid_cards/ic_loader.json"
import ic_address from "../../../assets/prepaid_cards/ic_address.png";
import { Button, Modal } from "react-bootstrap";


const ShippingForm = () => {
  let history = useHistory()
  const { shippingAddress, shippingRedirectionData, selectedCardData } = useSelector((state) => state?.ppi)
  const { profile } = useSelector((state) => state?.global)

  const [btnLoad, setBtnLoad] = useState(false)
  const [shippingResponseData, setShippingResponseData] = useState([])
  const [show, setShow] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const updateShippingAddress = () => {
    handleShow()
    setSubmitLoading(true)

    const authConfig = {
      headers: {
        TempAuthToken: profile?.temp_ppi_token,
      }
    }
    let apiURL, referenceNumber
    if (shippingRedirectionData?.redirectType === "shipping_address_details_page") {
      apiURL = 'instant-physical-card-issue'
      referenceNumber = null
    }
    else {
      apiURL = 'digital-to-physical-card-issue'
      referenceNumber = shippingAddress?.referenceNumber
    }
    const payload = {
      "card_type_id": selectedCardData?.cardTypeID,
      "referenceNumber": referenceNumber,
      "customerName": shippingAddress?.name,
      "addressLine1": shippingAddress?.addressLine1,
      "addressLine2": shippingAddress?.addressLine2,
      "city": shippingAddress?.cityDistrict,
      "state": shippingAddress?.state,
      "pinCode": shippingAddress?.pincode
    }

    setBtnLoad(true)
    prepaidCardClientX.post(`/${apiURL}`, payload, authConfig)
      .then((response) => {
        const data = response.data?.data
        setBtnLoad(false)
        setShippingResponseData(data)
        setSubmitSuccess(true)
        setSubmitLoading(false)
      })
      .catch(error => {
        setBtnLoad(false)
        setSubmitLoading(false)
        handleClose()
      })
  }

  const WaitingResponseModal = () => {
    return (
      <div className="p-3 p-md-5 text-center d-flex flex-column justify-content-center align-items-center">
        <span className="h4 font-weight-bold"></span>
        <div className="p-4" style={{ width: "200px" }}>
          <Lottie animationData={ic_waiting} loop={true} />
        </div>
        <span className="h4 font-weight-bold">Waiting for Response!</span>
        <p>Kindly avoid closing or forcefully terminating the application</p>
      </div>
    )
  }

  const SuccessResponseModal = () => {
    return (
      <div
        className="p-4 p-md-5 text-center d-flex flex-column justify-content-center align-items-center"
        style={{ background: "linear-gradient(180deg, #009BA3 0%, #5BCDBB 100%)" }}
      >
        <div className="p-4 text-white mb-5" style={{ width: "180px", "background": "#57CABA", "height": "180px", "borderRadius": "50%" }}>
          <img src={ic_address} alt="address" width="55%" />
        </div>
        <span className="h4 font-weight-bold text-white mb-3">Your card shipping address has been updated successfully!</span>
        <span className="text-white mb-3">The WINDS Prepaid Card will be deliverd to given address. Signature may be required to receive the parcel. Please be available to receive.</span>
        <Button style={{ background: "white", color: "black", width: "auto" }} className="animated-btn  mx-auto py-3 px-5 my-2"
          onClick={() => {
            history.push('/')
          }}>
          Go back to Home
        </Button>
      </div>
    )
  }

  return (
    <>
      <div className="my-4 mb-5">
        <div className="container">
          <div className="row d-flex flex-column justify-content-center align-items-center">
            <p className="cardkyc-title text-center bold m-1 m-sm-2 m-md-2 pb-4">
              Confirm Shipping Address
            </p>
            <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4 shipping-data-body">
              <div className="p-5">
                <div className="d-flex flex-row justify-content-center ">
                  <div className="p-1 col-1 pr-5 pr-lg-5 pt-2">
                    <img src={ic_mobile} alt="img" />
                  </div>
                  <div className="p-1 col-11">
                    <p className="shoppingform-head-text">Name</p>
                    <p className="shoppingform-body-text ">{shippingAddress?.name}</p>
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-center mt-2">
                  <div className="p-1 col-1 pr-5 pr-lg-5 pt-2">
                    <img src={ic_user} alt="img" />
                  </div>
                  <div className="p-1 col-11">
                    <p className="shoppingform-head-text">Mobile Number</p>
                    <p className="shoppingform-body-text ">+91 {shippingAddress?.mobile}</p>
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-center mt-2">
                  <div className="p-1 col-1 pr-5 pr-lg-5 pt-2">
                    <img src={ic_map} className="map-img" alt="img" />
                  </div>
                  <div className="p-1 col-11">
                    <p className="shoppingform-head-text">Shipping Address</p>
                    <p className="shoppingform-body-text ">
                      {shippingAddress?.addressLine1}, {shippingAddress?.addressLine2}, {shippingAddress?.cityDistrict}, {shippingAddress?.state}, {shippingAddress?.pincode}
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-center mt-2">
                  <div className="p-1 col-1 pr-5 pr-lg-5 pt-2">
                    <img src={ic_flag} alt="img" />
                  </div>
                  <div className="p-1 col-11">
                    <p className="shoppingform-head-text">Landmark</p>
                    <p className="shoppingform-body-text ">
                      {shippingAddress.landmark}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-8 col-sm-5 col-md-2">
              <button className="animated-btn-border shippingdata-edit-btn-style" onClick={() => { history.push("/prepaid-cards/shipping/edit") }}>EDIT/CHANGE</button>
            </div>
            <p className="cardkyc-desc-small col-12 col-sm-8 col-md-4 text-center">
              Note: The WIKAMAIE Prepaid Card will be sent to the address above.
              Signature may be required to receive the parcel. Please be
              available to receive.
            </p>

            <div className="col-12 col-sm-8 col-md-4">
              {
                btnLoad ?
                  <div className="d-flex justify-content-center mt-3">
                    <InlineLoader />
                  </div> :
                  <button className="animated-btn mt-4" onClick={() => updateShippingAddress()}>SAVE AND CONTINUE</button>
              }
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        {submitLoading && WaitingResponseModal()}
        {submitSuccess && SuccessResponseModal()}
      </Modal>
    </>
  );
};
function mapDispatchToProps(dispatch) {
  return {};
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ShippingForm);
