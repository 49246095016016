import HelmetMetaData from "../components/HelmetMetaData";
import { useLocation } from "react-router-dom";

function PartnerMetaData() {
  let location = useLocation();
  let currentAppUrl = process.env.REACT_APP_URL + location.pathname;

  return (
    <HelmetMetaData
      title={`KAMAIE Partner: Join & Boost Your Business with KAMAIE App`}
      description={`Join the KAMAIE App as a partner and enjoy increased earnings, enhanced online visibility, targeted promotion and referral benefits. Become KAMAIE Partner & start boosting your business today.`}
      keywords={` KAMAIE partner, business with KAMAIE app, partner, boost your business, vouchers, gift vouchers, e-vouchers, discount vouchers, online shopping sites, KAMAIE app, brand vouchers`}
      voiceSchema={<script type="application/ld+json">
        {JSON.stringify(
          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            "name": "KAMAIE PARTNER",
            "speakable":
            {
              "@type": "SpeakableSpecification",
              "xpath":
                [
                  "/html/head/title",
                  "/html/head/meta[@name='description']/@content"
                ]
            },
            "url": currentAppUrl
          }
        )}
      </script>
      }
      breadcrumbSchema={<script type="application/ld+json">
        {JSON.stringify(
          {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": process.env.REACT_APP_URL
            }, {
              "@type": "ListItem",
              "position": 2,
              "name": "KAMAIE PARTNER",
              "item": currentAppUrl
            }]
          }
        )}
      </script>}
      faqSchema={
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
                {
                  "@type": "Question",
                  "name": "Who Can have a KAMAIE Dukaan?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "- Any well-established, reputable business in the market renowned for its goods or services.\n- An approachable, easily accessible outlet or a business.\n- A business that will adhere to the terms and conditions laid down by KAMAIE.\n- A business willing to pay the KAMAIE Service Charge applicable to their respective categories on the transactions done through KAMAIE can become a KAMAIE Partner."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What Does WSP Stand For?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "WSP stands for KAMAIE Service Points. For every 1 Rupee given to KAMAIE as a Service charge by the KAMAIE Partner, 1 WSP will be earned by them."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How Many WSPs Will A KAMAIE Partner Have To Generate To Participate In The Monthly Lucky Draw?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Every 5000 WSP accumulated makes a coupon that entitles the Partner to participate in the Monthly Lucky Draw. If a partner pays ₹20,000 as a service charge, he earns 20,000 WSP and thus makes four coupons, all of which will be added to the draw."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How Does Using The KAMAIE App Benefits A KAMAIE Partner?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The platform of KAMAIE is genuinely unique and beneficial for any shopkeeper. KAMAIE is the World's Largest Rewards and Happiness program for Customers and Sellers. The Patrons, who are the Customers, stand to win prizes of CRORES* of rupees every month on their everyday purchases, and this benefit motivates them to shop at Partner's Outlets registered with KAMAIE. \n\nTherefore, it is a massive opportunity for the shopkeepers to not only increase Customer traffic at their respective outlets by joining KAMAIE and earning referral commission, but as a Partner, also to become a part of the World's Largest Rewards program, thereby getting the chance of winning CRORES* of rupees every month. KAMAIE also provides its registered Partners with a digital platform with free listings, which increases their online visibility and thus increases the likelihood of more customers visiting their store. With KAMAIE, they can also put up their customized offers on the KAMAIE platform and increase their sales significantly by attracting Customers in their specific areas."
                  }
                },
                {
                  "@type": "Question",
                  "name": "If A KAMAIE Partner Refers Any Patron In The KAMAIE Ecosystem, Would That Benefit Them?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "If any KAMAIE Partner does so, they will earn 10% of KAMAIE earnings \nwhenever their referred KAMAIE Patron (Customer) shops on the KAMAIE App. This referral earning will apply to all shopping done through the KAMAIE app, including at other KAMAIE Partner Retail outlets, i.e., wherever the Patron has shopped. And the said Partner will get this benefit on each of the Patrons he refers to or introduces."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Can The Same Person Be KAMAIE Patron/Partner/Senior Captain?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes. One person can perform all these roles."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How Much Time Does It Take For The WSP Earned To Be Updated In The KAMAIE Partner App?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Any WSPs earned would be updated immediately."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What Are The Responsibilities Of A KAMAIE Partner?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The primary responsibilities of a KAMAIE Partner would be:\n\n- Agree to pay the Service Charge to KAMAIE on every transaction done through the platform.\n-Ensure quality service and products to all KAMAIE Patrons (The Customers).\n- Encourage them to use more of the KAMAIE App and create exciting offers and promotions. \n- Overall, they should be willing to adhere to the terms & conditions as laid down by KAMAIE."
                  }
                },
                {
                  "@type": "Question",
                  "name": "For How Long The KAMAIE Partner Agreement Be Valid? \nAlso, How Can It Be Renewed?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "KAMAIE sets no validity limit for this agreement. This will be for life, provided the terms and conditions are not breached in any way by the KAMAIE Partner. Please note that KAMAIE reserves the right to terminate the contract immediately."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What Does The Partner Need To Do To Receive His Money If He Wins In The Monthly Lucky Draw?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Once the prize has been won, it will reflect in the wallet option in the Partner's App. They must enter a withdrawal request and share and validate their bank account details. After completing this process, the said amount will be transferred to their respective bank accounts."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How Will A Partner Pay Service Charge?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "When a KAMAIE Patron makes an online payment through the \"Pay Now\" feature on KAMAIE App after shopping through a KAMAIE Partner, then the Service Charge due to KAMAIE, as per the Service Charge category of the Partner, will automatically be transferred to KAMAIE. At the same time, the balance payment shall be transferred to the Partner.\n\nExample: If a Partner is registered in the 5% Service Charge category, then 5% of the payment made by the Patron will be transferred to KAMAIE, and the rest 95% will be shared with the Partner by the Payment Gateway."
                  }
                }
              ]
            }
          )}
        </script>
      }
    ></HelmetMetaData>
  );
}

export default PartnerMetaData;