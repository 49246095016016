import React, { useState } from "react";
import MultiItemCarousel from "../../../components/MultiItemCarousel";
import { useSelector } from "react-redux";
// import viewAll from "../../../assets/home/ic_view_all_arrow.svg";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import ic_home_arrow from "../../../assets/games/ic_arrow_nearby_vouchers copy 3.png";
import LoginDialog from "../../../components/LoginDialog";
import rightGreen from '../../../assets/icon/rightISideArrow_2.svg'
import rightgreenOval from '../../../assets/icon/rightArrowSvg.svg'
import rightArrow from '../../../assets/icon/rightBlackArrow.svg'
export default function FunGameCarousel() {
  const homecarousalData = useSelector((state) => state?.home?.fun_game_zone?.game_data)
  const profile = useSelector((state) => state?.global?.profile)

  const [isLoginDialogOpen, setLoginDialog] = useState(false)

  const openLoginDialog = () => {
    setLoginDialog(true)
  };
  const closeLoginDialog = () => {
    setLoginDialog(false)
  };

  const playFunGame = (url) => {
    if (profile) window.open(url, "_blank", "noopener,noreferrer");
    else openLoginDialog()
  };

  return (
    <div className="container">
      <div className=" home-page section-space row d-flex justify-content-between">
        <div className="title mb-md-4 col-9">
          <h2 className="title">
            <span className="font-slim">Fun </span>
            <span>Games </span>
            <span className="font-slim">Zones- Games for all</span>{" "}
          </h2>
        </div>
        <div className="col-3 text-right">
          <Link to={{ pathname: "/fun-games-zone", state: { from: "/" } }}>
            <span className="title-thick d-flex justify-content-end mobile-hide">
              <FormattedMessage
                id="home.view-all"
                defaultMessage="View All"
                description="View All"
                values={{ what: "react-intl" }}
              />
              <img className="view-all" src={rightArrow} alt="view all" title="view all"  />
            </span>
          </Link>
        </div>
        <div className="col-12 ">
          <div>
            <MultiItemCarousel>
              {homecarousalData?.map((item, index) => {
                return (
                  <div key={"game" + index}>
                    <div
                      className="fun-game-title align-items-end">
                      <span>{item?.name}</span>
                    </div>
                    <div
                      className="fun-game-box "
                      key={index}
                      onClick={() => playFunGame(item?.url)}
                    >
                      <img
                        style={{ width: "100%" }}
                        src={item.image}
                        alt={item.name}
                        title={item.name}
                        id="fun-game-img"
                      />
                      <span className="fun-game-playnow-title d-flex justify-content-center align-items-center text-uppercase m-0 p-2 text-center">
                        Play Now
                        <img src={rightgreenOval} className="fun-game-label ml-2" alt="arrow" title="arrow" />
                      </span>
                    </div>
                  </div>
                );
              })}
            </MultiItemCarousel>
          </div>
        </div>
      </div>
      {isLoginDialogOpen && (
        <LoginDialog
          isLoginDialogOpen={isLoginDialogOpen}
          closeLoginDialog={closeLoginDialog}
        />
      )}
    </div>
  );
}
