import React from "react";
import { useState } from "react";
import { Card, Button, Accordion, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import arrow from "../../../assets/img/about/arrow.png"
import PartnerMetaData from "../../../seo/PartnerMetaData";

function BusinessReadMore(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="site-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter text-center">
          <FormattedMessage id="page.nav-winds-partner" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul>
          <li><FormattedMessage id="partner.section4.read-more-desc1" /></li>
          <li><FormattedMessage id="partner.section4.read-more-desc2" /></li>
          <li><FormattedMessage id="partner.section4.read-more-desc3" /></li>
        </ul>
      </Modal.Body>
    </Modal>
  );
}
const Partner = () => {
  const [style, setStyle] = useState(false);
  const changeStyle = () => {
    style === false ? setStyle(true) : setStyle(false);
  };
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <PartnerMetaData />
      <section className="header-sec">
        <nav className="navbar navbar-expand-lg all">
          <ul className="navbar-nav site mr-auto mt-2 mt-lg-0">
            <li className="nav-item">
              <Link className="nav-link" to="/patrons"><FormattedMessage id="page.nav-winds-patrons" /></Link>
            </li>
            <li className="nav-item site-active">
              <Link className="nav-link" to="/partner"><h1 style={{fontSize:"inherit", marginBottom:"0"}}><FormattedMessage id="page.nav-winds-partner" /></h1></Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/digi-world"><FormattedMessage id="page.nav-winds-digi" /></Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about-us"><FormattedMessage id="page.nav-about-us" /></Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" to="/luckydraw-patrons"><FormattedMessage id="page.nav-winds-luckydraw" /></Link>
            </li> */}
            {/* <li className="nav-item">
              <Link className="nav-link" to="/testimonials"><FormattedMessage id="page.nav-winds-testimonials" /></Link>
            </li> */}
          </ul>
        </nav>
      </section>
      <section className="about-sec-1 site-partner site-page site-top">
        <div className="container font-proxima-nova">
          <div className="row reverse-col">
            <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 d-flex flex-column justify-content-center">
              <img width="100%" src={require('../../../assets/img/site-partnerImage/ic_illustration_Partner.png')} alt="partner" title="partner" className="pr-md-5 pr-0 mt-md-0 mt-3" />
            </div>
            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 d-flex flex-column justify-content-center">
              <h2 className="title-captain"><FormattedMessage id="partner.section1.title" /></h2>
              {/* <p className="desc"><FormattedMessage id="partner.section1.desc" /></p> */}
              <p className="desc">Any approachable, easily accessible outlet or business center selling/offering quality goods or services, willing to pay the KAMAIE Service Charge on their transactions with the KAMAIE Customers and overall, willing to adhere to the terms and conditions laid down by KAMAIE, can be introduced as a KAMAIE Dukaan.</p>
              <span className="mt-4 mb-3 download-patron">Download KAMAIE Dukaan App Now</span>
              <div className="paystore">
                <a href="https://p.winds.to/app" target="blank">
                  <img className="icons" src={require('../../../assets/img/site-patrons/ic_google_pay.png')} alt="play store" title="play store" />
                </a>
                <a href="https://p.winds.to/app" target="blank">
                  <img className="icons" src={require('../../../assets/img/site-patrons/ic_app_store.png')} alt="app store" title="app store" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec-2 site-page">
        <div className="container font-proxima-nova">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <h2 className="title-captain">Benefit of having a KAMAIE Dukaan
              </h2>
              {/* <p className="desc"><FormattedMessage id="partner.section2.desc1" /></p> */}
              <p className="desc">On being associated with KAMAIE, the earnings of the KAMAIE Dukaan would be enhanced as they would get Customers in the form of KAMAIE Dukaan in huge numbers.</p>
              <p className="desc">KAMAIE Dukaan would have increased online visibility of their businesses. </p>
              {/* <p className="desc"><FormattedMessage id="partner.section2.desc3" /></p> */}
              <p className="desc"><FormattedMessage id="partner.section2.desc4" /></p>
              <p className="desc">KAMAIE Dukaan can avail of a referral benefit on introducing other Customers as KAMAIE Dukaan.</p>
              {/* <Accordion defaultActiveKey="0">
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1" onClick={changeStyle}>
                      <h3 className="mb-0 font-weight-bold"><FormattedMessage id="partner.section2.mini-monthly-draw" /></h3>
                      <img src={arrow} width="15" style={{ marginLeft: "5px", style }} alt="arrow" title="arrow" className={`${style ? "down-arrow" : "up-arrow"}`} />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <p className="desc"><FormattedMessage id="partner.section2.mini-monthly-draw-desc" /></p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion> */}
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <img width="100%" src={require('../../../assets/img/site-partnerImage/ic_partner_shop.png')} alt="partner" title="partner" />
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec-3redirect site-page">
        <div className="container font-proxima-nova">
          <div className="row reverse-col">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <img width="100%" src={require('../../../assets/img/site-partnerImage/ic_partner_referral.png')} alt="partner" title="partner" className="px-5 mt-md-0 mt-3" />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <h2 className="title-captain">Benefits of KAMAIE Dukaan Earnings - on KAMAIE Dukaan Referrals</h2>
              <p className="desc">On introducing other Customers as KAMAIE Dukaan using their own referral codes, KAMAIE Dukaan get a huge referral benefit by way of 10% of KAMAIE' earnings on all shopping by those introduced KAMAIE Dukaan through the KAMAIE App, including at other KAMAIE Dukaan outlets.</p>
              {/* <p className="desc"><FormattedMessage id="partner.section3.desc2" /></p> */}
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec-4 site-page">
        <div className="container font-proxima-nova">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <h2 className="title-captain">Benefits for their KAMAIE Dukaan
              </h2>
              <p className="desc" >The KAMAIE Dukaan will get the Online platform to purchase or transact all their products and services at their nearby retail outlets through the KAMAIE App. Besides this, the KAMAIE Dukaan can also add Offers, Banners, Shop Images, shop branding through the KAMAIE App to improve their online reach.</p>
              {/* <p className="sub-title" style={{ "fontWeight": "600", cursor: "pointer" }} onClick={() => setModalShow(true)}>
                <FormattedMessage id="partner.section4.read-more" />
                <img src={arrow} width="15" style={{ marginLeft: "5px" }} alt="arrow" title="arrow" />
              </p> */}
              <BusinessReadMore
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
              <h2 className="download-patron pt-2">Responsibilities to be have a KAMAIE Dukaan</h2>
              <p className="desc">Besides ensuring quality services and products to all KAMAIE Dukaan and creating exciting offers and promotions for all of them, being prompt in the payment of the relevant service charge to KAMAIE on the satisfactory completion and acknowledgment of the transaction with the customer, a KAMAIE Dukaan must encourage them (the Customers) to make more and more use of the KAMAIE App while making purchases.</p>
              {/* <h3 className="download-patron pt-3" style={{ textDecoration: "underline" }}>
                <Link to="/partner-faq">
                  <FormattedMessage id="partner.faq" />
                </Link>
              </h3> */}
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <img width="100%" src={require('../../../assets/img/site-partnerImage/ic_partner_mockup.png')} alt="partner" title="partner" className="pr-md-5 pr-0 mt-md-0 mt-3" />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container py-3">
          <h2 className="title-captain">Frequently Asked Questions</h2>
          <Accordion defaultActiveKey="1">
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="1" >
                  <h3 className="mb-0 h5 font-weight-bold">Who Can have A KAMAIE Dukaan?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p className="desc">- Any well-established, reputable business in the market renowned for its goods or services.
                    - An approachable, easily accessible outlet or a business.
                    - A business that will adhere to the terms and conditions laid down by KAMAIE.
                    - A business willing to pay the KAMAIE Service Charge applicable to their respective categories on the transactions done through KAMAIE can become a KAMAIE Partner.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            {/* <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="2" >
                  <h3 className="mb-0 h5 font-weight-bold">What Does WSP Stand For?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p className="desc">WSP stands for WINDS Service Points. For every 1 Rupee given to WINDS as a Service charge by the WINDS Partner, 1 WSP will be earned by them.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card> */}
            {/* <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="3" >
                  <h3 className="mb-0 h5 font-weight-bold">How Many WSPs Will A WINDS Partner Have To Generate To Participate In The Monthly Lucky Draw?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p className="desc">Every 5000 WSP accumulated makes a coupon that entitles the Partner to participate in the Monthly Lucky Draw. If a partner pays ₹20,000 as a service charge, he earns 20,000 WSP and thus makes four coupons, all of which will be added to the draw.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card> */}
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="4" >
                  <h3 className="mb-0 h5 font-weight-bold">How Does Using The KAMAIE App Benefits A KAMAIE Dukaan?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <p className="desc">The platform of KAMAIE is genuinely unique and beneficial for any shopkeeper. KAMAIE gives a massive opportunity for the shopkeepers to increase Customer traffic at their respective outlets by joining KAMAIE and earning referral commission, as a KAMAIE Dukaan. KAMAIE also provides its registered Partners with a digital platform with free listings, which increases their online visibility and thus increases the likelihood of more customers visiting their store. With KAMAIE, they can also put up their customized offers on the KAMAIE platform and increase their sales significantly by attracting Customers in their specific areas.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="5" >
                  <h3 className="mb-0 h5 font-weight-bold">If A KAMAIE Dukaan Refers Any customer In The KAMAIE Ecosystem, Would That Benefit Them?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  <p className="desc">If any KAMAIE Dukaan does so, they will earn 10% of KAMAIE earnings whenever their referred KAMAIE  (Customer) shops on the KAMAIE App. This referral earning will apply to all shopping done through the KAMAIE app, including at other KAMAIE Dukaan Retail outlets, i.e., wherever the Patron has shopped. And the said KAMAIE Dukaan will get this benefit on each of the customer he refers to or introduces.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="6" >
                  <h3 className="mb-0 h5 font-weight-bold">Can The Same Person Be KAMAIE Customer/Dukaan/KBP?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="6">
                <Card.Body>
                  <p className="desc">Yes. One person can perform all these roles.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            {/* <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="7" >
                  <h3 className="mb-0 h5 font-weight-bold">How Much Time Does It Take For The WSP Earned To Be Updated In The WINDS Partner App?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="7">
                <Card.Body>
                  <p className="desc">Any WSPs earned would be updated immediately.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card> */}
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="8" >
                  <h3 className="mb-0 h5 font-weight-bold">What Are The Responsibilities Of having A KAMAIE Dukaan?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="8">
                <Card.Body>
                  <p className="desc">The primary responsibilities of a KAMAIE Dukaan would be:
                   <li>Agree to pay the Service Charge to KAMAIE on every transaction done through the platform.</li>
                   <li>Ensure quality service and products to all KAMAIE Dukaan Customer.</li>
                   <li>Encourage them to use more of the KAMAIE App and create exciting offers and promotions.</li>
                   <li>Overall, they should be willing to adhere to the terms & conditions as laid down by KAMAIE. </li></p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="9" >
                  <h3 className="mb-0 h5 font-weight-bold">For How Long The KAMAIE Partner Agreement Be Valid? Also, How Can It Be Renewed? </h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="9">
                <Card.Body>
                  <p className="desc">KAMAIE sets no validity limit for this agreement. This will be for life, provided the terms and conditions are not breached in any way by the KAMAIE Dukaan. Please note that KAMAIE reserves the right to terminate the contract immediately.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            {/* <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="10" >
                  <h3 className="mb-0 h5 font-weight-bold">What Does The Partner Need To Do To Receive His Money If He Wins In The Monthly Lucky Draw?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="10">
                <Card.Body>
                  <p className="desc">Once the prize has been won, it will reflect in the wallet option in the Partner's App. They must enter a withdrawal request and share and validate their bank account details. After completing this process, the said amount will be transferred to their respective bank accounts.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card> */}
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="11" >
                  <h3 className="mb-0 h5 font-weight-bold">How Will A Partner Pay Service Charge?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="11">
                <Card.Body>
                  <p className="desc">When a KAMAIE Customer makes an online payment through the "Pay Now" feature on KAMAIE App after shopping through a KAMAIE Dukaan, then the Service Charge due to KAMAIE, as per the Service Charge category of the KAMAIE Dukaan, will automatically be transferred to KAMAIE. At the same time, the balance payment shall be transferred to the KAMAIE Dukaan.</p><p> Example: If a KAMAIE Dukaan is registered in the 3% Service Charge category, then 3% of the payment made by the Customer will be transferred to KAMAIE, and the rest 97% will be shared with the KAMAIE Dukaan by the Payment Gateway.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </section>
    </>
  );
};
export default Partner;
