import React, { PureComponent } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Lottie from "lottie-react";
import InlineLoader from "../../../components/InlineLoader";
import OtpValidationModal from "../../../components/OtpValidationModal";
import ic_upi from "../../../assets/billpay/ic_upi.svg"
import ic_debit_card from "../../../assets/billpay/ic_debit_card.svg"
import ic_netbanking from "../../../assets/billpay/ic_netbanking.svg"
import ic_wallet from "../../../assets/billpay/ic_wallet.svg"
import ic_phonepe from "../../../assets/billpay/ic_phonepe.png"
import ic_gpay from "../../../assets/billpay/ic_gpay.png"
import ic_paytm from "../../../assets/billpay/ic_paytm.png"
import ic_bhim from "../../../assets/billpay/ic_bhim.png"
import ic_more from "../../../assets/billpay/ic_more.png"
import ic_airtel from "../../../assets/billpay/ic_airtel.png"
import ic_jiomoney from "../../../assets/billpay/ic_jiomoney.png"
import prepaidCardClientX from "../../../utils/api/prepaidCardClientX";
import profileClientX from "../../../utils/api/profileClientX";
import { Button, Modal } from "react-bootstrap";
import ic_waiting from "../../../assets/prepaid_cards/ic_loader.json"
import ic_success from "../../../assets/prepaid_cards/ic_success.json"
import ic_failed from "../../../assets/prepaid_cards/ic_failed.json"
import ic_checkbox_active from "../../../assets/prepaid_cards/ic_check_blue.svg";
import ic_checkbox_empty from "../../../assets/prepaid_cards/ic_checkbox_empty.svg";

let interval
class Cart extends PureComponent {


  constructor(props) {
    super(props);
    this.state = {
      cart: [],
      payment_methods: [],
      paymentLoading: false,
      paymentSuccess: false,
      paymentMode: null,
      razorPayMethod: "",
      wallet_balance: null,
      wallet_enabled: 0,
      wallet_info: null,
      gc_wallet_balance: null,
      gc_wallet_enabled: 0,
      gc_wallet_info: null,
      PG: 0,
      proceedPay: null,
      pageLoading: false,
      showModal: false,
      redirectCount: 3,
      currentCartSegment: this.props.match.path.replace("/cart", "").slice(1),
      fulfillresponse: null,
      isOtpValidationModalOpen: false,
    };

    // Initialize timer to null
    this.timer = null;
  }

  componentDidMount() {
    this.setState({ pageLoading: true })
    profileClientX
      .get("v1/profile?billing_email=true")
      .then((response) => {
        this.setState({ pageLoading: false })
      })
      .catch((error) => {
        this.setState({ pageLoading: false })
      });
    this.getCartSummary()
  }

  componentWillUnmount() {
    clearTimeout(interval);
  }

  openOtpValidationModal = () => {
    this.setState({ isOtpValidationModalOpen: true });
  };

  closeOtpValidationModal = () => {
    this.setState({ isOtpValidationModalOpen: false });
  };

  getCartSummary = () => {

    let { apiURL, requestParams } = this.handleCartSummaryAPIPayloads()

    const authConfig = {
      headers: {
        TempAuthToken: this.props?.profile?.temp_ppi_token,
      }
    }

    prepaidCardClientX
      .post(apiURL, requestParams, authConfig)
      .then((response) => {
        if (!response?.data?.success) {
          toast.success(response.data?.message)
        }
        if (response?.data?.data?.page_redirection_segment === "min_kyc_document")
          this.props.history.push("/prepaid-cards/start-kyc")
        if (response?.data?.data?.page_redirection_segment === "full_kyc_document")
          this.props.history.push("/prepaid-cards/start-premium-kyc")
        else if (response?.data?.data?.page_redirection_segment === "card_details_page")
          this.props.history.push("/prepaid-cards/managecard")
        else if (response?.data?.data?.page_redirection_segment === "shipping_address_details_page")
          this.props.history.push("/prepaid-cards/shipping/edit")
        // else if (response?.data?.data?.page_redirection_segment === "payment_summary")
        //   this.props.history.push("/prepaid-cards/purchase/cart")

        this.setState({
          cart: response.data.data.cart_data,
          wallet_balance: response.data.data?.wallet_info?.balance,
          wallet_info: response.data.data?.wallet_info,
          gc_wallet_balance: response.data.data?.gc_wallet_info?.balance,
          gc_wallet_info: response.data.data?.gc_wallet_info
        });

        // eslint-disable-next-line array-callback-return
        response.data.data.cart_data.map(item => {
          if (item.key === "processing_fee") {
            this.setState({ PG: item.value })
          }
          if (item.key === "to_pay") {
            if (item.value === "₹ 0.00")
              this.setState({ proceedPay: null })
            else
              this.setState({ proceedPay: item.value })
          }
        })

        if (response.data.data.payment_methods !== null) {
          this.setState({
            payment_methods: response.data.data.payment_methods
          })
          // eslint-disable-next-line array-callback-return
          response.data.data.payment_methods.map(item => {
            if (item.is_selected) {
              this.setState({ razorPayMethod: item.identifier })
              this.setState({ paymentMode: item.id })
            }
          })
        }
        else {
          this.setState({ payment_methods: [] })
        }

      })
      .catch((error) => {
        // this.props.history.goBack();
      });
  }

  handleClose = () => {
    this.setState({ showModal: false })
  }

  handleShow = () => {
    this.setState({ showModal: true })
  }

  handleWallet = (e) => {
    this.setState({ wallet_enabled: !this.state.wallet_enabled }, () => {
      this.getCartSummary()
    })
  }

  handleGCWallet = (e) => {
    this.setState({ gc_wallet_enabled: !this.state.gc_wallet_enabled }, () => {
      this.getCartSummary()
    })
  }

  handlePayment = (id) => {
    this.setState({ paymentMode: parseInt(id) },
      () => {
        this.getCartSummary()
      })
  }

  handleCartSummaryAPIPayloads = () => {
    let requestParams, apiURL

    switch (this.state.currentCartSegment) {
      case "prepaid-cards/purchase":
        apiURL = 'cart-details'
        requestParams = {
          card_plan_id: this.props.selectedCardData?.cardPlanID,
          card_type_id: this.props.selectedCardData?.cardTypeID,
          wallet_enabled: this.state.wallet_enabled,
          gc_wallet_enabled: this.state.gc_wallet_enabled,
          payment_mode: this.state.paymentMode,
        }
        break;
      case "prepaid-cards/load-balance":
        apiURL = 'card-reload-cart-details'
        requestParams = {
          amount: this.props.loadBalance?.amount,
          wallet_enabled: this.state.wallet_enabled,
          gc_wallet_enabled: this.state.gc_wallet_enabled,
          payment_mode: this.state.paymentMode,
        }
        break;
      default:
        break;
    }
    return { apiURL, requestParams }
  }

  handleInitiateAPIPayloads = () => {
    let initiateApiURL, fulfillApiURL, requestParams

    switch (this.state.currentCartSegment) {
      case "prepaid-cards/purchase":
        initiateApiURL = 'initiate-payment-card-purchase'
        fulfillApiURL = 'fulfill-payment-card-purchase'
        requestParams = {
          card_plan_id: this.props.selectedCardData.cardPlanID,
          card_type_id: this.props.selectedCardData.cardTypeID,
          wallet_enabled: this.state.wallet_enabled,
          payment_mode: this.state.paymentMode,
          gc_wallet_enabled: this.state.gc_wallet_enabled,
        }
        break;
      case "prepaid-cards/load-balance":
        initiateApiURL = 'initiate-payment-card-reload'
        fulfillApiURL = 'fulfill-payment-card-reload'
        requestParams = {
          amount: this.props.loadBalance.amount,
          card_account_number: this.props?.cardDetailResponse?.accountNumber,
          wallet_enabled: this.state.wallet_enabled,
          payment_mode: this.state.paymentMode,
          gc_wallet_enabled: this.state.gc_wallet_enabled,
        }
        break;
      default:
        break;
    }
    return { initiateApiURL, fulfillApiURL, requestParams }
  }

  handlePostPaymentRedirect = () => {
    switch (this.state.currentCartSegment) {
      case "prepaid-cards/purchase":
        if (this.state.fulfillresponse) {
          if (this.state.fulfillresponse?.page_redirection_segment === 'shipping_address_details_page')
            return this.props.history.push('/prepaid-cards/shipping/edit')
          else if (this.state.fulfillresponse?.page_redirection_segment === 'full_kyc_document')
            return this.props.history.push('/prepaid-cards/start-premium-kyc')
          else
            this.props.history.push(`/prepaid-cards/start-kyc`);
        }
        break;
      case "prepaid-cards/load-balance":
        this.props.history.push(`/prepaid-cards/managecard`);
        break;
      default:
        break;
    }
  }

  onInitOtpValidation = () => {
    if (this.state.wallet_enabled || this.state.gc_wallet_enabled)
      this.openOtpValidationModal()
    else
      this.onInitOrder()
  }

  onInitOrder = () => {
    let { initiateApiURL, fulfillApiURL, requestParams } = this.handleInitiateAPIPayloads()

    const authConfig = {
      headers: {
        TempAuthToken: this.props?.profile?.temp_ppi_token,
      }
    }

    let that = this;
    this.setState({ paymentLoading: true, paymentSuccess: false })


    prepaidCardClientX
      .post(initiateApiURL, requestParams, authConfig)
      .then((response) => {
        if (response.data.data.proceed_to_gateway === false) {
          this.handleShow()
          this.setState({ paymentLoading: true, paymentSuccess: false })
          prepaidCardClientX
            .post(`${fulfillApiURL}/${response.data.data.card_order_id}`, {
              payment_gateway_order_id: null,
              razorpay_order_id: null,
            }, authConfig)
            .then((response) => {
              this.setState({ paymentLoading: false, paymentSuccess: true, fulfillresponse: response?.data?.data })
            }).catch(error => {
              this.setState({ paymentLoading: false, paymentSuccess: false, fulfillresponse: null })
            })
        }
        else {
          if (response.data.data.razorpay_key === null || response.data.data.razorpay_key === "") {
            this.setState({ paymentLoading: false, paymentSuccess: false })
            toast.error(
              "Something went wrong. Please try again later",
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
          }
          else {
            new window.Razorpay({
              // key: process.env.REACT_APP_RAZOR_PAY_KEY,
              key: response.data.data.razorpay_key,
              order_id: response.data.data.gateway_order_id,
              "theme": {
                "hide_topbar": true
              },
              "prefill": {
                "name": this.props.profile?.name,
                "email": this.props.profile?.email,
                "contact": this.props.profile?.mobile,
                "method": this.state.razorPayMethod,
              },
              handler: function (pgResponse) {
                that.handleShow()
                prepaidCardClientX
                  .post(`${fulfillApiURL}/${response.data.data.card_order_id}`, {
                    razorpay_order_id: pgResponse.razorpay_order_id,
                    razorpay_payment_id: pgResponse.razorpay_payment_id,
                    razorpay_signature: pgResponse.razorpay_signature,
                    payment_failed: 0
                  }, authConfig)
                  .then((response) => {
                    that.setState({ paymentLoading: false, paymentSuccess: true, fulfillresponse: response?.data?.data })
                  })
                  .catch((error) => {
                    that.setState({ paymentLoading: false, paymentSuccess: false, fulfillresponse: null })
                  });
              },
              "modal": {
                "ondismiss": function () {
                  that.handleShow()
                  that.setState({ paymentLoading: false, paymentSuccess: false, fulfillresponse: null })
                  prepaidCardClientX
                    .post(`${fulfillApiURL}/${response.data.data.card_order_id}`, {
                      razorpay_order_id: response.data.data.payment_gateway_order_id,
                      payment_failed: 1
                    }, authConfig)
                    .then((cancelresponse) => {
                      that.setState({ paymentLoading: false, paymentSuccess: false, fulfillresponse: null })

                    }).catch(error => {
                      that.setState({ paymentLoading: false, paymentSuccess: false, fulfillresponse: null })
                    })
                }
              }
            }).open();
          }
        }
      })
      .catch((error) => {
        this.setState({ paymentLoading: false, paymentSuccess: false })
      });
  };

  render() {
    return (
      this.state.pageLoading ?
        <this.Shimmer /> :
        <>
          <div className="container">
            <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
              <div className="col-12">
                {/* <div className="row align-items-center">
                  <div className="col-12">
                    <small>
                      <Link to="/">
                        <FormattedMessage
                          id="breadcrumbs.home"
                          defaultMessage="Home"
                        />
                      </Link>
                    </small>{" "}
                    /{" "}
                    <small>
                      {this.state.currentCartSegment === 'prepaid-cards/purchase' ?
                        (
                          <Link to="/prepaid-cards/plans">
                            Card Plans
                          </Link>
                        ) :
                        (
                          <Link to="/prepaid-cards/managecard">
                            Manage Cards
                          </Link>
                        )
                      }
                    </small>{" "}
                    /{" "}
                    <small>
                      <strong>
                        Cart
                      </strong>
                    </small>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="row mb-5">
                  <div className="col-12 col-md-6">
                    <this.Controls />

                  </div>
                  <div className="col-12 col-md-6">
                    {
                      // this.state.wallet_info?.can_show_wallet &&
                      <>
                        <div className={`card-body payment-card mb-2 ${this.state?.wallet_info?.can_show_wallet ? "cursor-pointer" : "wallet-disable"}`} onClick={(e) => this.handleWallet(e)}>
                          <div className="row align-items-center">
                            <div className="col-8 d-flex">
                              <img src={this.state.wallet_enabled ? ic_checkbox_active : ic_checkbox_empty} alt="selection-img" />
                              <div className="payment-card-top d-flex flex-row align-items-center m-0">
                                <h3 className="m-0 ml-4">
                                  <FormattedMessage
                                    id="wallet.wallet-balance"
                                    defaultMessage="Wallet Balance"
                                  />
                                </h3>
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="payment-card-top d-flex flex-row justify-content-end align-items-center">
                                <h3 className="m-0">₹ {this.state.wallet_balance}</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <p style={{ color: "red" }}>{this.state?.wallet_info?.min_balance_text}</p>
                      </>
                    }
                    {
                      this.state.gc_wallet_info?.can_show_wallet &&
                      <>
                        <div className={`card-body payment-card mt-3 mb-md-5 mb-4 cursor-pointer ${this.state?.gc_wallet_info?.can_show_wallet ? "" : "wallet-disable"}`}>
                          <div className="row align-items-center" onClick={() => this.handleGCWallet()}>
                            <div className="col-9 d-flex">
                              <img src={this.state.gc_wallet_enabled ? ic_checkbox_active : ic_checkbox_empty} alt="selection-img" />
                              <div className="payment-card-top d-flex flex-row align-items-center m-0">
                                <h3 className="m-0 ml-4">
                                  <FormattedMessage
                                    id="wallet.gc_wallet-balance"
                                    defaultMessage="WINDS E-Gift Voucher"
                                  />
                                </h3>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="payment-card-top d-flex flex-row justify-content-end align-items-center m-0">
                                <h3 className="m-0">₹ {this.state.gc_wallet_balance}</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <p style={{ color: "red" }}>{this.state?.gc_wallet_info?.min_balance_text}</p> */}
                      </>
                    }
                    {this.state.payment_methods && this.state.payment_methods.length > 0 ?
                      <this.Payments /> :
                      <></>
                    }
                    {this.state.paymentLoading ?
                      <div className="mb-md-5 mb-3">
                        <InlineLoader />
                      </div> :
                      !this.state.paymentLoading && this.state.proceedPay ? (
                        <button
                          className="animated-btn mt-3 mt-md-2 mb-3 mb-md-5"
                          onClick={this.onInitOtpValidation}
                        >
                          PAY
                          {" "}{this.state.proceedPay}
                        </button>
                      ) : !this.state.paymentLoading && this.state.gc_wallet_enabled ?
                        (
                          <button
                            className="animated-btn mt-3 mt-md-2 mb-3 mb-md-5"
                            onClick={this.onInitOtpValidation}
                          >
                            <FormattedMessage id="pay-via-voucher"
                              defaultMessage="PAY VIA VOUCHER"
                            />
                          </button>
                        ) : !this.state.paymentLoading && this.state.wallet_enabled ?
                          (
                            <button
                              className="animated-btn mt-3 mt-md-2 mb-3 mb-md-5"
                              onClick={this.onInitOtpValidation}
                            >
                              <FormattedMessage id="pay-via-wallet"
                                defaultMessage="PAY VIA WALLET"
                              />
                            </button>
                          ) :
                          (
                            <button
                              className="animated-btn-disabled mt-3 mt-md-2 mb-3 mb-md-5"
                              disabled
                            >
                              <FormattedMessage id="billpay.proceed-to-pay"
                                defaultMessage="PROCEED TO PAY"
                              />
                            </button>
                          )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={this.state.showModal}
            onHide={this.handleClose}
            backdrop="static"
            keyboard={false}
            centered
            size="lg"
          >
            {
              this.state.paymentLoading ? this.WaitingResponseModal() :
                this.state.paymentSuccess ? this.SuccessResponseModal() :
                  this.RetryResponseModal()
            }
          </Modal>
          {this.state.isOtpValidationModalOpen && (
            <OtpValidationModal
              isOtpValidationModalOpen={this.state.isOtpValidationModalOpen}
              closeOtpValidationModal={this.closeOtpValidationModal}
              onVerifyOTP={(otpStatus) => {
                otpStatus && this.onInitOrder()
              }}
            />
          )}
        </>
    );
  }

  Shimmer = (props) => {
    return (
      <div className="container py-3 py-md-5">
        <div className="row">
          <div className="col-md-7">
            <div className="box shine box-voucher-detail-logo mb-2"></div>
            <div className="box shine w-100 mb-2" style={{ height: "60px" }}></div>
            <div className="box shine box-voucher-detail-logo"></div>
          </div>
          <div className="col-md-5 pt-4 pt-md-0">
            <div className="box shine w-100" style={{ height: "40px" }}></div>
            <div className="box shine w-100" style={{ height: "300px" }}></div>
            <div className="box shine w-100" style={{ height: "38px" }}></div>
            <div className="box shine w-100" style={{ height: "70px" }}></div>
            <div className="box shine w-100" style={{ height: "70px" }}></div>
            <div className="box shine w-100" style={{ height: "70px" }}></div>
            <div className="box shine w-100" style={{ height: "70px" }}></div>
          </div>
        </div>
      </div>
    );
  };

  WaitingResponseModal = () => {
    return (
      <div className="p-3 p-md-5 text-center d-flex flex-column justify-content-center align-items-center">
        <span className="h4">Payment for - KAMAIE Prepaid Card</span>
        <span className="h4 font-weight-bold">{this.state.proceedPay}</span>
        <div className="p-4" style={{ width: "200px" }}>
          <Lottie animationData={ic_waiting} loop={true} />
        </div>
        <span className="h4 font-weight-bold">Waiting for Response!</span>
        <p>Kindly avoid closing or forcefully terminating the application</p>
      </div>
    )
  }

  SuccessResponseModal = () => {
    // let count = this.state.redirectCount
    // interval = setInterval(() => {
    //   this.setState({ redirectCount: count - 1 })
    //   if (this.state.redirectCount === 0){
    //     clearInterval(interval)
    //     this.handlePostPaymentRedirect()
    //   }
    // }, 1000);

    interval = setTimeout(() => {
      clearInterval(interval)
      this.handlePostPaymentRedirect()
    }, 3000);


    return (
      <div
        className="p-3 p-md-5 text-center d-flex flex-column justify-content-center align-items-center"
        style={{ background: "linear-gradient(180deg, #009BA3 0%, #5BCDBB 100%)" }}
      >
        <span className="h4 text-white">Payment for - KAMAIE Prepaid Card</span>
        <span className="h4 font-weight-bold text-white">{this.state.proceedPay}</span>
        <div className="p-4 text-white" style={{ width: "200px" }}>
          <Lottie animationData={ic_success} loop={false} />
        </div>
        <span className="h4 font-weight-bold text-white">Your Payment has been Successful!</span>
        <Button style={{ background: "white", color: "black" }} className="animated-btn w-50 mx-auto py-3 my-2"
          onClick={() => {
            this.handleClose()
            this.handlePostPaymentRedirect()
          }}>
          {this.state.currentCartSegment === 'prepaid-cards/purchase' ? 'Complete KYC' : 'Go Back'}
        </Button>
        {/* <Link to="/">
          <span style={{ textDecoration: "underline" }} className="text-white text-decoration-underline cursor-pointer">Go back to Home {">"}</span>
        </Link> */}
        {/* <p className="text-white mb-0"> Redirecting in {this.state.redirectCount} sec...</p> */}
      </div>
    )
  }

  RetryResponseModal = () => {
    return (
      <div
        className="p-3 p-md-5 text-center d-flex flex-column justify-content-center align-items-center"
      >
        <span className="h4">Payment for - WINDS Prepaid Card</span>
        <span className="h4 font-weight-bold">{this.state.proceedPay}</span>
        <div className="p-4" style={{ width: "200px" }}>
          <Lottie animationData={ic_failed} loop={false} />
        </div>
        <span className="h4 font-weight-bold mb-4">Your payment has failed!</span>
        <Button className="animated-btn w-50 mx-auto py-3 my-2"
          onClick={() => {
            this.handleClose()
          }}>
          Retry Again
        </Button>
        <Link to="/">
          <span style={{ textDecoration: "underline" }} className="text-decoration-underline cursor-pointer">Go back to Home {">"}</span>
        </Link>
      </div>
    )
  }

  Controls = (props) => {
    const { cart } = this.state;
    return (
      <>
        <div
          className="card mb-3 mb-md-4"
          style={{
            backgroundColor: "#F8F9FA",
            borderRadius: "8px",
            border: "none",
          }}
        >
          <div className="card-body font-weight-bold h5 py-3 mb-0" style={{ borderRadius: "8px 8px 0 0" }}>
            Payment Details
          </div>
          <div
            className="card-footer pt-4 pb-4"
            style={{
              border: "none",
              borderRadius: "0 0 8px 8px",
              borderTop: "solid 1px #E8E8E8",
            }}
          >

            {cart.map((item, index) => {
              return (
                <div className="row mb-2" key={index}>
                  <div className="col-6">
                    <p className="mb-0">{item.status_text_translated}</p>
                  </div>
                  <div className="col-6 text-right">
                    <p className="mb-0">
                      <strong>{item.value}</strong>
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  Payments = (props) => {
    return (
      <div
        className="card mt-3 mt-md-0 mb-3 mb-md-2"
        style={{
          backgroundColor: "#FFF",
          borderRadius: "8px",
          border: "none",
        }}
      >

        <h3 className="choose">
          <FormattedMessage id="payment-options" defaultMessage="Payment Options" />
        </h3>
        {/* eslint-disable-next-line array-callback-return */}
        {this.state.payment_methods.map((item, index) => {
          if (item.identifier === "upi")
            return (
              <div id={"b" + item.id} key={item?.id} className={"card-body payment-card mb-2 cursor-pointer " + (item.is_selected ? 'select-border' : '')} onClick={(e) => this.handlePayment(item.id)}>
                <div className="row align-items-center">
                  <div className="col-2">
                    <img src={ic_upi} alt="upi" />
                  </div>
                  <div className="col-8 pl-0">
                    <div className="payment-card-top d-flex flex-row align-items-center">
                      <h3 className="m-0">UPI</h3>
                      <div className="line"></div>
                      <p className="m-0">{item.display_string}</p>
                    </div>
                    <div className="payment-card-bottom d-flex flex-row align-items-center">
                      <img src={ic_phonepe} alt="phonepe" />
                      <img src={ic_gpay} alt="gpay" />
                      <img src={ic_paytm} alt="paytm" />
                      <img src={ic_bhim} alt="bhim" />
                      <img src={ic_more} alt="more" />
                    </div>
                  </div>
                  <div className="col-2">
                    <img src={item.is_selected ? ic_checkbox_active : ic_checkbox_empty} alt="selection-img" className="cart-selected-tic" />
                  </div>
                </div>
              </div>
            )

          if (item.identifier === "card")
            return (
              <div id={"b" + item.id} key={item?.id} className={"card-body payment-card mb-2 cursor-pointer " + (item.is_selected ? 'select-border' : '')} onClick={(e) => this.handlePayment(item.id)}>
                <div className="row align-items-center">
                  <div className="col-2">
                    <img src={ic_debit_card} alt="upi" />
                  </div>
                  <div className="col-8 pl-0">
                    <div className="payment-card-top d-flex flex-row align-items-center">
                      <h3 className="m-0">Cards</h3>
                      <div className="line"></div>
                      <p className="m-0">{item.display_string}</p>
                    </div>
                    <div className="payment-card-bottom d-flex flex-row align-items-center">
                      <p className="m-0" >Master Card, Visa, Rupay and more</p>
                    </div>
                  </div>
                  <div className="col-2">
                    <img src={item.is_selected ? ic_checkbox_active : ic_checkbox_empty} alt="selection-img" className="cart-selected-tic" />
                  </div>
                </div>
              </div>
            )

          if (item.identifier === "netbanking")
            return (
              <div id={"b" + item.id} key={item?.id} className={"card-body payment-card mb-2 cursor-pointer " + (item.is_selected ? 'select-border' : '')} onClick={(e) => this.handlePayment(item.id)}>
                <div className="row align-items-center">
                  <div className="col-2">
                    <img src={ic_netbanking} alt="upi" />
                  </div>
                  <div className="col-8 pl-0">
                    <div className="payment-card-top d-flex flex-row align-items-center">
                      <h3 className="m-0">Netbanking</h3>
                      <div className="line"></div>
                      <p className="m-0">{item.display_string}</p>
                    </div>
                    <div className="payment-card-bottom d-flex flex-row align-items-center">
                      <p className="m-0" >Pay with all indian banks</p>
                    </div>
                  </div>
                  <div className="col-2">
                    <img src={item.is_selected ? ic_checkbox_active : ic_checkbox_empty} alt="selection-img" className="cart-selected-tic" />
                  </div>
                </div>
              </div>
            )

          if (item.identifier === "wallet")
            return (
              <div id={"b" + item.id} key={item?.id} className={"card-body payment-card mb-2 cursor-pointer " + (item.is_selected ? 'select-border' : '')} onClick={(e) => this.handlePayment(item.id)}>
                <div className="row align-items-center">
                  <div className="col-2">
                    <img src={ic_wallet} alt="upi" />
                  </div>
                  <div className="col-8 pl-0">
                    <div className="payment-card-top d-flex flex-row align-items-center">
                      <h3 className="m-0">Wallets</h3>
                      <div className="line"></div>
                      <p className="m-0">{item.display_string}</p>
                    </div>
                    <div className="payment-card-bottom d-flex flex-row align-items-center">
                      <img src={ic_phonepe} alt="phonepe" />
                      <img src={ic_paytm} alt="paytm" />
                      <img src={ic_airtel} alt="airtel" />
                      <img src={ic_jiomoney} alt="jio" />
                      <img src={ic_more} alt="more" />
                    </div>
                  </div>
                  <div className="col-2">
                    <img src={item.is_selected ? ic_checkbox_active : ic_checkbox_empty} alt="selection-img" className="cart-selected-tic" />
                  </div>
                </div>
              </div>
            )
        })}
      </div>
    );
  };

}

function mapStateToProps(state) {
  return {
    profile: state.global.profile,
    selectedCardData: state.ppi?.selectedCardData,
    loadBalance: state.ppi?.loadBalance,
    cardDetailResponse: state.ppi?.cardDetails?.cardDetailResponse
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Cart)));