import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import DigiWorldMetaData from "../../../seo/DigiWorldMetaData";
import { Accordion, Card } from "react-bootstrap";


const Captain = () => {
  return (
    <>
      <DigiWorldMetaData />
      <section className="header-sec">
        <nav className="navbar navbar-expand-lg all">
          <ul className="navbar-nav site mr-auto mt-2 mt-lg-0">
            <li className="nav-item">
              <Link className="nav-link" to="/patrons"><FormattedMessage id="page.nav-winds-patrons" /></Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/partner"><FormattedMessage id="page.nav-winds-partner" /></Link>
            </li>
            <li className="nav-item site-active">
              <Link className="nav-link" to="/digi-world"><h1 style={{fontSize:"inherit", marginBottom:"0"}}><FormattedMessage id="page.nav-winds-digi" /></h1></Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about-us"><FormattedMessage id="page.nav-about-us" /></Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" to="/luckydraw-patrons"><FormattedMessage id="page.nav-winds-luckydraw" /></Link>
            </li> */}
            {/* <li className="nav-item">
              <Link className="nav-link" to="/testimonials"><FormattedMessage id="page.nav-winds-testimonials" /></Link>
            </li> */}
          </ul>
        </nav>
      </section>
      <section className="about-sec-1 patrons bg site-page site-top">
        <div className="container font-proxima-nova">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 d-flex flex-column justify-content-center">
              <img className="w-25 mb-4 mb-md-3" src={require('../../../assets/img/site-captain/kamaie_kro_new.png')} alt="captain" title="captain" />
              <h2 className="title-captain"><FormattedMessage id="captain.section1.title" /></h2>
              <p className="desc"><FormattedMessage id="captain.section1.desc" /></p>
              <p className="desc">An exciting new platform that will make you genuinely independent by enhancing your prospects for starting a new business or evolving as an existing business owner, with increased earnings and rewards, without having to do anything extra. </p>
              <span className="mt-4 mb-3 download-patron"><FormattedMessage id="captain.section1.subtitle" /></span>
              <div className="paystore">
                <a href="https://c.winds.to/app" target="blank">
                  <img src={require('../../../assets/img/site-patrons/ic_google_pay.png')} alt="icon" title="icon" />
                </a>
                <a href="https://c.winds.to/app" target="blank">
                  <img src={require('../../../assets/img/site-patrons/ic_app_store.png')} alt="captain" title="captain" />
                </a>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 d-flex flex-column justify-content-center">
              <img className="px-md-5 px-0" width="100%" src={require('../../../assets/img/site-captain/ic_winds_captain.png')} alt="captain" title="captain" />
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec-2captain site-page">
        <div className="container font-proxima-nova">
          <div className="row reverse-col">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <img width="100%" src={require('../../../assets/img/site-captain/ic_criteria.png')} alt="captain" title="captain" className="pr-md-5 pr-0 mt-md-0 mt-3" />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              {/* <h2 className="title-captain"><FormattedMessage id="captain.section2.title" /></h2> */}
              <h2 className="title-captain"> To become a KBP with KAMAIE KARO, all you need is a passion to grow and a drive to excel </h2>
             <p className="desc">Count this as a real asset towards furthering your business interests and realizing your true growth potential. As a franchisee, you can enjoy all its benefits throughout your life. Qualification or business experience is optional. Also, you don't need to possess physical inventory or infrastructure. </p>
              <p className="desc">You get a virtual shop providing access to 100+ Online e-Commerce Portals and offline Outlets in your neighbourhood where your patrons can pay by using his / her Kamaie Pre-Paid card. They also get to facilitate various utility bill payments and 200+ prominent brand vouchers to sell. </p>
              
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec-3captain site-page">
        <div className="container font-proxima-nova">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 d-flex flex-column justify-content-center ">
              <h2 className="title-captain"><FormattedMessage id="captain.section3.title" /></h2>
              <p className="desc">As a KBP Partner, you will earn from Kamaie’s earnings on all transactions between your KBPs and the patrons as referred to and added by you. </p>
              {/* <p className="desc"><FormattedMessage id="captain.section3.desc1" /></p> */}
              <p className="desc"><FormattedMessage id="captain.section3.desc2" /></p>
              <p className="desc"><FormattedMessage id="captain.section3.desc3" /></p>
              <p className="desc"><FormattedMessage id="captain.section3.desc4" /> </p>
              <p className="desc">Sell & Earn commissions from Products & Services of Flipkart, Shopsy, Ajio, Myntra & many other Best Shopping Websites 
              </p>
            </div>
            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 d-flex flex-column justify-content-center align-items-center">
              <img src={require('../../../assets/img/site-captain/ic_responsibilities_mockup.png')} alt="captain" title="captain" className="px-0 px-md-5 mt-md-0 mt-3 w-100" />
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec-4 site-page">
        <div className="container font-proxima-nova">
          <div className="row reverse-col">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <img width="100%" src={require('../../../assets/img/site-captain/kyo_kamaie_1.png')} alt="captain" title="captain" className="pr-0 mt-md-0" />
              {/* <span className="download-patron text-center"><FormattedMessage id="captain.section4.image-title" /></span> */}
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <h2 className="title-captain"><FormattedMessage id="captain.section4.title" /></h2>
              {/* <p className="desc"><FormattedMessage id="captain.section4.desc1" /></p> */}
              <ul className="bullet">
                <li>New categories like Generic Medicines, Life / Vehicle / Term Insurances, Loans and Credit cards etc. to earn from.  </li>
                <li>Kamaie Voucher payouts are the highest, which can go up to 25%.</li>
                <li>Sell & Earn commissions from Brand’s vouchers like Big Basket, Shoppers Stop, and much more with approx. 200+ Brands.</li>
                <li>Get your KBPs & customers to book flights, Bus Tickets, Hotels, etc. through Kamaie App and earn huge commissions. </li>
                <li>Kamaie has 5 million + products to earn commissions from. </li>
                <li>Kamaie Pre-Paid card will ease your online and offline shopping. </li>
                <li>Kamaie Pre-Paid card will ease your online and offline shopping. </li>
                <li>Customers can pay utility bill payments. </li>
                <li>Chance to start your own business. </li>
                <li>Maximum income from lowest investment. </li>
                <li>No investment in physical inventory or infrastructure or maintenance.
                </li>
                <li>No logistics cost to spend or staff to recruit. </li>
                <li>Business done from anywhere without heavy software costs. 
                </li>
                <li>Waiver of payment gateway charges.</li>
                <li>Great chance to increase revenue from your existing customer base.  </li>
                <li>Added Earnings, Huge Rewards, and Enhanced Happiness by way of a string of benefits.  </li>
                <li>Kamaie digital coins will be credited in your wallet as a KBP enrollment.
                </li>
              </ul>
              {/* <p className="desc"><FormattedMessage id="captain.section4.desc2" /></p>
              <ol className="bullet">
                <li><FormattedMessage id="captain.section4.desc2a" /></li>
                <li><FormattedMessage id="captain.section4.desc2b" /></li>
              </ol> */}
              {/* <p className="desc"><FormattedMessage id="captain.section4.desc3" /></p> */}
              {/* <ol className="bullet"> */}
                {/* <li><FormattedMessage id="captain.section4.desc3a" /></li> */}
                {/* <li><FormattedMessage id="captain.section4.desc3b" /></li> */}
              {/* </ol> */}
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec-4 " style={{ background: "#FAF1E8" }}>
        <div className="container font-proxima-nova">
          <div className="row reverse-col">

            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <h2 className="title-captain">Kamaie Karo Uniques
              </h2>
              {/* <p className="desc"><FormattedMessage id="captain.section4.desc1" /></p> */}
              <ul className="">
                <li className="mb-2">Kamaie Karo engine will help you create scenario for your income growth.</li>
                <li className="mb-2">Direct partnered brands will give you product training to improve your sell. 
                </li>
                <li className="mb-2">Insurance, Loan, FD, Vehicle Insurance, General medicines, Sporting Goods Brands & many more are special categories to grow your customer base. 
                </li>
              <li className="mb-2">Best commission structure ranging from 0.2% to 20%.
              </li>
              <li className="mb-2">Business application with transparent dashboard on your fingertips.
              </li>
              <li className="mb-2">Zero payment gateway charges for you.</li>
              </ul>

            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <img width="100%" src={require('../../../assets/img/site-captain/kamaieKaroWorks_2.png')} alt="captain" title="captain" className="pr-0 mt-md-0" />
              
            </div>
          </div>
        </div>
      </section>
      <section className="site-page" >
        <div className="container font-proxima-nova">
          <div className="row">
            <div className="col-12 d-flex flex-column justify-content-center">
              {/* <h2 className="title-captain"><FormattedMessage id="captain.section5.title" /></h2> */}
              <h2 className="title-captain">How Kamaie Karo works?
              </h2>
              <div className="d-flex align-items-start">
                <img className="mr-2" src={require('../../../assets/img/site-captain/ic_tick_mark.png')} width="30px" alt="tick" title="tick" />
                <p className="desc">You need to refer and add Customers (Patrons) to the Kamaie platform and motivate them to do their daily shopping / utility bills etc. by using the Kamaie App. </p>
              </div>
              <div className="d-flex align-items-start">
                <img className="mr-2" src={require('../../../assets/img/site-captain/ic_tick_mark.png')} width="30px" alt="tick" title="tick" />
                <p className="desc">You may also refer/add multiple Franchisees* under you who identify/appoint as a Kamaie Business Partners (KBP), refer/tag Kamaie customers, and maximize their earnings on the transactions between those referred Business Partners and Customers. </p>
              </div>
              <div className="d-flex align-items-start">
                <img className="mr-2" src={require('../../../assets/img/site-captain/ic_tick_mark.png')} width="30px" alt="tick" title="tick" />
                <p className="desc">As a franchise owner, you will get Kamaie’s earnings on all transactions between your KBP and the Customers as referred to and added by you. 
                </p>
              </div>
              <div className="d-flex align-items-start">
                <img className="mr-2" src={require('../../../assets/img/site-captain/ic_tick_mark.png')} width="30px" alt="tick" title="tick" />
                <p className="desc">You can refer to KBP as per your purchased plan and get benefited from their Earnings.  
                </p>
              </div>
              {/* <div className="d-flex align-items-start">
                <img className="mr-2" src={require('../../../assets/img/site-captain/ic_tick_mark.png')} width="30px" alt="tick" title="tick" />
                <p className="desc"><FormattedMessage id="captain.section5.desc2" /> </p>
              </div> */}
            </div>
          </div>
          {/* <div className="row mt-5">
            <div className="col-12 d-flex flex-column justify-content-center">
              <h2 className="title-captain"><FormattedMessage id="captain.section6.title" /></h2>
              <p className="desc"><FormattedMessage id="captain.section6.desc1" /></p>
              <p className="desc"><FormattedMessage id="captain.section6.desc2" /></p>
              <p className="desc"><FormattedMessage id="captain.section6.desc3" /></p> */}
              {/* <h3 className="download-patron pt-3" style={{ textDecoration: "underline" }}>
                <Link to="/digi-world-faq">
                  <FormattedMessage id="captain.faq" />
                </Link>
              </h3> */}
            {/* </div>
          </div> */}
        </div>
      </section>

      {/* <section>
        <div className="container py-5">
          <h2 className="title-captain">Frequently Asked Questions</h2>
          <Accordion defaultActiveKey="1">
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg" >
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="1" >
                  <h3 className="mb-0 h5 font-weight-bold">How Much Has To Be Paid To Become A WINDS Digi World Franchisee?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p className="desc">You will have to pay a one-time fee of ₹15,000 (Inclusive of GST) to become a WINDS Digi World Franchisee.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="2" >
                  <h3 className="mb-0 h5 font-weight-bold">What Will Be The Primary Responsibilities Of The Digi World Franchisee?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p className="desc">The primary responsibility of a Franchisee is to appoint multiple franchisees under them so that they get more opportunities to earn through the Referral Commission on appointment besides the Overriding Commission (ORC) through businesses of those appointed Franchisees. They must identify and establish more WINDS Partners and ensure that the appointed Partners increasingly transact through WINDS so that the Franchisee can earn Commission from every transaction. They must refer and add on/tag WINDS Patrons and ensure they shop as much as possible through WINDS Partners. Also, they must ensure that the Franchisee referred by them add on/tag more Digi World Franchisees, WINDS Partners and WINDS Patrons.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="3" >
                  <h3 className="mb-0 h5 font-weight-bold">Is There Validity To Purchasing This DIGI WORLD FRANCHISE?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p className="desc">No, there is no validity. Just pay the one-time fees & become the owner of the WINDS DIGI World Franchise.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="4" >
                  <h3 className="mb-0 h5 font-weight-bold">If A Franchisee Introduces Another Franchisee, How Much Referral Commission Will They Get?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <p className="desc">In such a situation, the Franchisee will get 15% of the Franchisee fee amount, which is 15% of ₹ 15,000/ i.e. ₹ 2250/- for every Franchisee referred.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="5" >
                  <h3 className="mb-0 h5 font-weight-bold">Suppose the WINDS Patron added by the Franchisee purchases the introduced WINDS Partner through the same Franchisee. What percentage of the WINDS' income on the purchase will be earned as a commission by the Franchisee?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  <p className="desc">Suppose the same Franchisee has introduced both the transacting Partner and the Patron. In that case, the Franchisee will get 20% of WINDS' earnings from the total business done between them (the Partner and the Patron).</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="6" >
                  <h3 className="mb-0 h5 font-weight-bold">Is The Deposit Amount Of Owning The DIGI WORLD FRANCHISE Refundable?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="6">
                <Card.Body>
                  <p className="desc">Unfortunately, the one-time fee isn't refundable. </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="7" >
                  <h3 className="mb-0 h5 font-weight-bold">What Documents Are Required For Owning A WINDS DIGI WORLD FRANCHISE?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="7">
                <Card.Body>
                  <p className="desc">Only bank details & a PAN card are required to own a WINDS DIGI WORLD Franchise.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="8" >
                  <h3 className="mb-0 h5 font-weight-bold">How Much Overriding Commission Will Franchisees Get Based On The Business Done Through Their Recruited Franchisees?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="8">
                <Card.Body>
                  <p className="desc">A Franchisee will earn 25% of the earnings (excluding referral commission) of every Franchisee directly recruited by them as Overriding Commission (ORC) if that referred Franchisee has on-boarded 20 Partners and 100 Patrons or more. For Franchisees who have on-boarded fewer than 20 Partners and fewer than 100 Patrons, the ORC earned would be 18.75% of the recruited franchisees’ earnings.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="9" >
                  <h3 className="mb-0 h5 font-weight-bold">Will I Get Additional Benefits From Owning The DIGI WORLD FRANCHISE Compared To A Regular WINDS App User?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="9">
                <Card.Body>
                  <p className="desc">Yes, of course. After owning the WINDS DIGI World Franchise, you will instantly receive 500 WINDS Coins on your WINDS Patron app, and also a lifetime benefit of 1.5 times of WINDS Coins than the usual on all WINDS transactions done by you on our Patron app (exclusive of all drives and offers).</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="10" >
                  <h3 className="mb-0 h5 font-weight-bold">Can I Own More Than One DIGI WORLD FRANCHISE As An Individual?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="10">
                <Card.Body>
                  <p className="desc">Yes, you can.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="11" >
                  <h3 className="mb-0 h5 font-weight-bold">Do I Need Educational Qualifications or Any Business Experience To Become A Franchisee?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="11">
                <Card.Body>
                  <p className="desc">There is no requirement for any educational qualification or prior business experience for anybody to become a Franchisee.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="12" >
                  <h3 className="mb-0 h5 font-weight-bold">Can Two Individuals Be A Joint Owner Of A DIGI WORLD FRANCHISE?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="12">
                <Card.Body>
                  <p className="desc">On record, only an individual can own the WINDS DIGI World Franchise.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="13" >
                  <h3 className="mb-0 h5 font-weight-bold">Do We Require A GST Number To Own This Franchise?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="13">
                <Card.Body>
                  <p className="desc">GST Number is not mandatory to own the Winds DIGI Owner Franchise.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="15" >
                  <h3 className="mb-0 h5 font-weight-bold">Does the DIGI WORLD FRANCHISE Subscription Have A Validity?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="15">
                <Card.Body>
                  <p className="desc">No, after a one-time Franchise fee, you can enjoy all the benefits after subscribing to the WINDS DIGI World Franchise for a lifetime.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="16" >
                  <h3 className="mb-0 h5 font-weight-bold">How Will I Receive My Earnings Of Being A DIGI WORLD FRANCHISE Owner?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="16">
                <Card.Body>
                  <p className="desc">Every commission you earn by introducing accumulates in your WINDS DIGI Franchise App. Once you collect Rs. 501, you can transfer the money to your Bank Account.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          

          </div>
      </section> */}
    
    </>
  );
};
export default Captain;
