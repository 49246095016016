import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ic_kyc from "../../../../assets/prepaid_cards/ic_kyc.png";
import { useHistory } from "react-router-dom";
import prepaidCardClientX from "../../../../utils/api/prepaidCardClientX";
import ConsentAgreement from "../../components/ConsentAgreement";
import ic_checkbox_empty from "../../../../assets/prepaid_cards/ic_checkbox_empty.svg";
import ic_check_blue from "../../../../assets/prepaid_cards/ic_check_blue.svg";
import { setSelectedDocumentData } from "../../actions";

const StartKYC = () => {
  let history = useHistory()
  const { selectedCardData } = useSelector((state) => state?.ppi)
  const { profile } = useSelector((state) => state?.global)

  const [documentTypes, setDocumentTypes] = useState(null)
  const [consentCheck, setConSentCheck] = useState(false);
  const [termsCheck, setTermsCheck] = useState(false);
  const [pepCheck, setPEPCheck] = useState(false);
  const [show, setShow] = useState(false);
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getDoucumentTypes()
    setSelectedDocumentData(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDoucumentTypes = () => {
    const authConfig = {
      headers: {
        TempAuthToken: profile?.temp_ppi_token,
      }
    }
    prepaidCardClientX.get(`/get-card-document-types/${selectedCardData?.cardPlanID}`, authConfig)
      .then((response) => {
        let data = response.data;
        setDocumentTypes(data)
      });
  }

  const proceedKYC = () => {
    if (selectedCardData?.cardPlanID === 2)
      history.push("/prepaid-cards/full-kyc/initiate")
    else
      history.push("/prepaid-cards/small-kyc/initiate")
  }

  return (
    <div className="py-5" key="card">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p className="cardkyc-title text-center bold mb-4">
              {selectedCardData?.cardPlan} CARD KYC
            </p>
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-10 col-sm-6 col-md-5 col-lg-4 ">
                <img src={ic_kyc} className="img-fluid" alt="img" />
              </div>
              <div className="col-12 justify-content-center m-3">
                <p className="col-11 col-sm-9 col-md-6 col-lg-5 title cardkyc-subtitle m-auto">
                  We only accept one of the following documents for the KYC process.
                </p>
              </div>
              <div className="col-12 d-flex justify-content-center">
                <p className="cardkyc-doc-desc">
                  {documentTypes?.data && documentTypes?.data.map(item => {
                    return (
                      <span id={item.document_id} key={item.document_id} className="cardkyc-span-col border-right px-3">{item.display_document_type}</span>
                    )
                  })
                  }
                </p>
              </div>
              <div className="col-12 justify-content-center">
                <p className="col-11 col-sm-9 col-md-6 col-lg-5 title cardkyc-desc m-auto">
                  Ensure that one of the above document information with you before
                  proceeding further.
                </p>
              </div>
              <hr className="w-50 mx-auto" />
              <div className="col-12 col-md-12 title cardkyc-desc mt-5 m-auto d-flex flex-column declaration-link">

                {documentTypes?.consent_log_data?.programTnC && <div className="cardkyc-concent " >
                  <img src={termsCheck === false ? ic_checkbox_empty : ic_check_blue} alt="img" className="cursor-pointer"  onClick={() => setTermsCheck(!termsCheck)} />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: documentTypes?.consent_log_data?.programTnC,
                    }}
                  ></span>
                </div>}
                {documentTypes?.consent_log_data?.pepDeclaration && <div className="cardkyc-concent " >
                  <img src={pepCheck === false ? ic_checkbox_empty : ic_check_blue} alt="img" className="cursor-pointer" onClick={() => setPEPCheck(!pepCheck)} />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: documentTypes?.consent_log_data?.pepDeclaration,
                    }}
                  ></span>

                </div>}
                {documentTypes?.consent_log_data?.kycConsent && <div className="cardkyc-concent " >
                  <img src={consentCheck === false ? ic_checkbox_empty : ic_check_blue} alt="img" className="cursor-pointer" onClick={() => {
                    setConSentCheck(!consentCheck)
                    !consentCheck && handleShow()
                  }} />
                  <div
                    dangerouslySetInnerHTML={{ __html: documentTypes?.consent_log_data?.kycConsent }}
                    onClick={(e) => {
                      if (e.target.tagName === 'A') {
                        e.preventDefault();
                        handleShow();
                      }
                    }}
                  />
                </div>}
              </div>
              <button
                className="animated-btn col-8 col-md-4 mt-4"
                id={(documentTypes?.data?.length === 0 || !consentCheck || !termsCheck || !pepCheck) && "animated-btn-disable"}
                onClick={() => proceedKYC()}
                disabled={documentTypes?.data?.length === 0 || !consentCheck || !termsCheck || !pepCheck}>
                PROCEED TO KYC
              </button>
            </div>
          </div>
        </div>
      </div >
      <ConsentAgreement show={show} handleClose={handleClose} consentText={documentTypes?.consent_log_data?.KycTypeConsent} />
    </div>
  );
};
export default StartKYC;
