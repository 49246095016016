import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import billImage from "../../../assets/refund-request-image.svg";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import customerPartnerClientX from "../../../utils/api/customerPartnerClientX";
import classNames from "classnames";
import { currentlySelectedLanguage } from "../../../utils/helper";

const RefundRequest = (props) => {
  const { params } = props.match;
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [inputs, setInputs] = useState({
    isTermsConditionAccepted: {
      value: false,
      isValid: false,
      error: "",
      isPristine: false,
    },
    refundType: {
      value: "fullRefund",
      isValid: true,
      error: "",
      isPristine: false,
    },
    amount: {
      value: params.amount,
      isValid: true,
      error: "",
      isPristine: true,
    },
    comment: {
      value: "",
      isValid: false,
      error: "",
      isPristine: false,
    }
  });


  const { amount, comment, refundType } = inputs
  const handleInputChange = (event) => {
    event.persist();
    const { name, value, checked } = event.target;
    if (name === 'refundType') {
      if (value === 'partialRefund') {

        setInputs((inputs) => ({
          ...inputs,
          amount: {
            value: '',
            isValid: false,
            error: 'Amount is required',
            isPristine: true,
          },
        }));
      } else {

        setInputs((inputs) => ({
          ...inputs,
          amount: {
            value: params.amount,
            isValid: true,
            error: '',
            isPristine: true,
          },
        }));
      }
    }
    if (name === 'isTermsConditionAccepted') {
      validateField(name, checked);
    } else {
      validateField(name, value);
    }
  };

  const validateField = (fieldName, value) => {
    const tempInputs = { ...inputs };
    switch (fieldName) {
      case "isTermsConditionAccepted":
        tempInputs.isTermsConditionAccepted.isValid = value;
        tempInputs.isTermsConditionAccepted.error = tempInputs.isTermsConditionAccepted.isValid
          ? ""
          : "isTermsConditionAccepted is required";
        break;
      case "refundType":
        tempInputs.refundType.isValid = value.length > 2;
        tempInputs.refundType.error = tempInputs.refundType.isValid
          ? ""
          : "refundType is required";
        break;

      case "comment":
        tempInputs.comment.isValid = value.length > 1;
        tempInputs.comment.error = tempInputs.comment.isValid
          ? ""
          : "Comment is required";
        break;
      case "amount":
        tempInputs.amount.isValid = value.length > 1;
        tempInputs.amount.error = tempInputs.amount.isValid
          ? ""
          : "Amount is required";
        break;
      default:
        break;
    }
    setInputs((inputs) => ({
      ...inputs,
      [fieldName]: {
        value: value,
        isValid: tempInputs[fieldName].isValid,
        error: tempInputs[fieldName].error,
        isPristine: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    setConfirmationDialogOpen(true);
  };
  const onConfirmSubmission = () => {
    const form = {
      "refund_bill_amount": amount.value,
      "full_refund": refundType.value === 'partialRefund' ? 0 : 1,
      "comment": comment.value
    }


    customerPartnerClientX
      .post(`/v1/bills/refund-initiate/${params.billId}`, form)
      .then((response) => {
        props.history.push('/orders');
      })
      .catch((error) => {
      });

    setConfirmationDialogOpen(false)
  }
  const onDismissSumission = () => {
    setConfirmationDialogOpen(false)
  }
  return (
    <div className="container-fluid font-proxima-nova">
      <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-12">
              <small>
                <Link to="/">
                  <FormattedMessage
                    id="breadcrumbs.home"
                    defaultMessage="Home"
                  />
                </Link>{" "}
                /{" "}
                <Link to="/orders">
                  <FormattedMessage
                    id="navbar.orders"
                    defaultMessage="Orders"
                  />
                </Link>{" "}
                /{" "}
                <strong>
                  <FormattedMessage
                    id="order.refund-request-form"
                    defaultMessage="Refund Request Form"
                  />
                </strong>
              </small>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3 mb-5 mt-md-4 mt-md-4">
        <div className="col-12 col-md-7">
          <div className="refund-form-container">
            <h5 className="font-proxima-nova-bold"><FormattedMessage
              id="order.refund-settlement-disclaimer"
              defaultMessage="Refund settlement will be happening directly with
                      Partner and not through KAMAIE."
            />
            </h5>

            <div className="custom-control custom-checkbox border-bottom pb-3 col-md-8">
              <input
                type="checkbox"
                className="custom-control-input"
                id="paynowRefundDisclaimer"
                name="isTermsConditionAccepted"
                onChange={handleInputChange}
              />
              <label
                className="custom-control-label"
                htmlFor="paynowRefundDisclaimer"
              >
                <FormattedMessage
                  id="general.agree-to"
                  defaultMessage="I agree to all the"
                />
                  <a className="ml-2 terms-btn winds-link-color" href={`https://winds-customer-static.web.app/refund-tnc/${currentlySelectedLanguage()}`} target="_blank" rel="noreferrer">
                    <FormattedMessage
                      id="footer.terms-and-conditions"
                      defaultMessage="Terms and conditions"
                    />
                  </a>
              </label>
            </div>

            <div className="tax mt-3 font-proxima-nova">
              <p>
                <FormattedMessage
                  id="payNow.amount-instruction"
                  defaultMessage="Please enter the bill amount excluding GST amount."
                />
              </p>
              <div className="form-check form-check-inline mr-5">
                <input
                  className="form-check-input"
                  type="radio"
                  name="refundType"
                  id="inlineRadio1"
                  value="partialRefund"
                  onChange={handleInputChange}
                />
                <label
                  className="form-check-label ml-2"
                  htmlFor="inlineRadio1"
                >
                  <FormattedMessage
                    id="refund.partial-refund"
                    defaultMessage="Partial Refund"
                  />
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="refundType"
                  id="inlineRadio2"
                  value="fullRefund"
                  onChange={handleInputChange}
                  defaultChecked
                />
                <label
                  className="form-check-label ml-2"
                  htmlFor="inlineRadio2"
                >
                  <FormattedMessage
                    id="refund.full-refund"
                    defaultMessage="Full Refund"
                  />
                </label>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6">
                <p className="winds-grey-light">
                  <FormattedMessage
                    id="refund.partner-purchase"
                    defaultMessage="Partner Purchase"
                  />: #{params.orderNumber}
                </p>
              </div>
              <div className="col-6">
                <p className="winds-grey-light">
                  {params.createdAt}
                </p>
              </div>
            </div>
            <div>
              <label>
                <FormattedMessage
                  id="wallet.amount"
                  defaultMessage="Amount"
                />
              </label>
              <input
                type="text"
                name="amount"
                onChange={handleInputChange}
                value={amount.value}
                disabled={refundType.value === 'fullRefund'}
                className={classNames("form-control pay-now-input border-bottom", {
                  "is-valid": inputs.amount.isValid,
                  "is-invalid":
                    inputs.amount.isPristine &&
                    !inputs.amount.isValid,
                })}
              />
            </div>

            <div className="mt-4">
              <label>
                <FormattedMessage
                  id="pay-now.comment"
                  defaultMessage="Comment"
                />
                <sup className="text-danger"> *</sup>
              </label>
              <textarea
                id="refund-reason"
                name="comment"
                rows="3"
                cols="50"
                placeholder="Write your reason"
                onChange={handleInputChange}
                value={comment.value}
                className={classNames("form-control refund-reason-textarea", {
                  "is-valid": inputs.comment.isValid,
                  "is-invalid":
                    inputs.comment.isPristine &&
                    !inputs.comment.isValid,
                })}
              >
                <FormattedMessage
                  id="order.write-comment"
                  defaultMessage="Write your comment."
                />

              </textarea>

            </div>
            <button
              className="btn btn-primary home-btn refund-btn"
              onClick={handleSubmit}
              disabled={
                !inputs.isTermsConditionAccepted.isValid ||
                !inputs.refundType.isValid ||
                !inputs.comment.isValid ||
                !inputs.amount.isValid
              }
            >
              <FormattedMessage
                id="refund.submit-request"
                defaultMessage="Submit Request"
              />
            </button>
          </div>
        </div>
        <div className="col-12 col-md-5 mt-5">
          <img src={billImage} alt="finding bill illustration." title="finding bill illustration." className="w-100" />
        </div>
      </div>
      {isConfirmationDialogOpen && (
        <ConfirmationDialog
          isConfirmationDialogOpen={true}
          hideHeader={true}
          body={
            <>
              <FormattedMessage
                id="refund.are-you-sure"
                defaultMessage="Are you sure, you want to submit"
              />
            </>
          }
          buttonTxt1={
            <>
              <FormattedMessage
                id="general.confirm"
                defaultMessage="Confirm"
              />
            </>
          }
          buttonTxt2={
            <>
              <FormattedMessage id="profile.cancel" defaultMessage="Cancel" />
            </>
          }
          onSuccess={onConfirmSubmission}
          onDismiss={onDismissSumission}
        />
      )}
    </div>

  )
}

export default RefundRequest;