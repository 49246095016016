import React, { Component } from "react";
import { Link } from "react-router-dom";
import walletClientX from "../../../utils/api/walletClientX";
import lodash from "lodash";
import ContentLoader from "react-content-loader";
import { FormattedMessage } from "react-intl";
import filterIcon from "../../../assets/filter-icon.svg";
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import InlineLoader from "../../../components/InlineLoader";
import debitIcon from "../../../assets/ic_debit.svg";
import creditIcon from "../../../assets/ic_credit.svg";
import pendingIcon from "../../../assets/ic_pending.svg";
// import failedIcon from "../../../assets/ic_failed.svg";
import EmptyState from "../../../components/EmptyState";
import aboutBlue from "../../../assets/about-blue.svg";
import voucher from "../../../assets/ic_voucher_round.svg"
import billpay from "../../../assets/ic_billpay_round.svg"
import WithdrawMoney from "../../../assets/Withdraw-Money.png"
import EmptyImage from "../../../assets/empty-state-screen.svg";
import { FirebaseContext } from "../../../utils/firebase";
import { currentlySelectedLanguage, getIsKamaie, getIsMob } from "../../../utils/helper";
let walletInfoMessage

export default class Wallet extends Component {
  static contextType = FirebaseContext;
  radioOptions = [
    {
      value: 0,
      viewValue: "Credit",
    },
    {
      value: 1,
      viewValue: "Debit",
    },
  ];
  constructor(props) {
    super(props);
    this.state = {
      balance: null,
      min_withdrawal: null,
      isFetchingWalletBalance: false,
      transactions: [],
      isFetchingTransaction: false,
      filterValue: "",
      isFilterModalOpen: false,
      page: 1,
      isLastPage: false,
      filteredData: [],
      show: false,
      isMob:0,
      is_kamaie:0
    };
    this.loadRef = React.createRef();
  }

  handleClose = () => this.setState({show:false})
  handleShow = () => this.setState({show:true})

  onFilterClick = () => {
    this.setState((prevState) => ({
      isFilterModalOpen: !prevState.isFilterModalOpen,
    }));
  };
  componentDidMount() {
    const queryParameters = new URLSearchParams(window.location.search);
    this.setState({ isMob: getIsMob("isMob") });
    this.setState({is_kamaie:getIsKamaie("is_kamaie")}) 
    let defaultWalletMessage = "This wallet reflects your Lucky Draw Winnings and Referral Earnings. Once you WIN BIG, you can transfer the balance to your bank account directly. The Wallet balance can also be used for voucher purchases or bill payments."
    setTimeout(() => {
    walletInfoMessage = this.context.wallet_withdraw_message[currentlySelectedLanguage()] ? 
      this.context.wallet_withdraw_message[currentlySelectedLanguage()] : 
      defaultWalletMessage;
    }, 1000);
    this.setState(
      {
        isFetchingWalletBalance: true,
      },
      () => {
        walletClientX.get("v1/wallet").then((response) => {
          this.setState({
            balance: response.data.data.balance,
            min_withdrawal: response.data.data.min_withdrawal,
            isFetchingWalletBalance: false,
          });
        });
      }
    );

    this.setState(
      {
        isFetchingTransaction: true,
      },
      () => {
        this.fetchTransaction(this.state.page, null);
      }
    );

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0 && !this.state.isLastPage) {
          this.setState(
            (prevState) => ({ ...prevState, page: prevState.page + 1 }),
            () => {
              this.fetchTransaction(this.state.page, null);
            }
          );
        }
      });
    });
    this.observer.observe(this.loadRef.current);
  }

  getFilteredTransaction = (data, filterValue) => {
    return data.filter((item) => Number(item.type) === filterValue);
  };

  fetchTransaction = (pageNo, filter) => {
    walletClientX
      .get(`v1/transactions`, {
        params: {
          type: this.state.filterValue,
          page: pageNo,
        },
      })
      .then((response) => {
        let isLast;
        isLast = response.data.links.next === null;
        this.setState((prevState) => ({
          ...prevState,
          transactions: filter
            ? prevState.filteredData.concat(response.data.data)
            : prevState.transactions.concat(response.data.data),
          isFetchingTransaction: false,
          isLastPage: isLast,
        }));
      });
  };

  render() {
    return (
      <React.Fragment>
        <div style={{ backgroundColor: "#F2F6FA" }}>
        <div className="container font-proxima-nova">
          <div className="row pt-3 mb-3">
            <div className="col-12">
              <div className="row">
                {
                  (this.state.isMob!= 1 && this.state.is_kamaie!= 1)&&(
                    <div className="col-12">
                    <small>
                      <Link to="/">
                        <FormattedMessage
                          id="breadcrumbs.home"
                          defaultMessage="Home"
                        />
                      </Link>
                    </small>{" "}
                    /{" "}
                    <small>
                      <strong>
                        <FormattedMessage
                          id="wallet.wallet"
                          defaultMessage="Wallet"
                        />
                      </strong>
                    </small>
                  </div>
                  )
                }
               
              </div>
            </div>
          </div>

          <this.WalletBalance />
        </div>
        </div>
        <this.WalletTransactions />
      </React.Fragment>
    );
  }

  WalletBalance = (props) => {
    return (
      <div className="row mt-3 mb-3 wallet">
        <div className="col-12">
          <div className="row mt-md-4 mb-4">
            <div className="col-12 col-md-5">
              <p className="mb-0">
                <FormattedMessage
                  id="wallet.wallet-balance"
                  defaultMessage="Wallet Balance"
                />
              </p>
              {this.state.isFetchingWalletBalance ? (
                <ContentLoader
                  speed={2}
                  height={20}
                  primaryColor="#F6F7F8"
                  secondaryColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="3" ry="3" width="150" height="20" />
                </ContentLoader>
              ) : (<h3><strong>₹ {this.state.balance}</strong></h3>


              )}
              <span className="d-inline"> <img src={aboutBlue} alt="about" title="about" /></span><p className="withdrawText mb-0 d-inline">The minimum amount that can be withdrawn is Rs. {this.state.min_withdrawal}</p>
            </div>
            <div className="col-12 col-md-5 text-center">
                <div className="wallet-card my-2 my-md-1">
                <p className="mb-1"><FormattedMessage id="wallet.use" defaultMessage="Now you can use your wallet balance to" /></p>
                <div className="d-flex flex-row justify-content-center">
                  <Link to="/vouchers" style={{ width: "inherit" }}>
                    <p className="m-0 mr-2"><img className="mr-1" src={voucher} alt="voucher" title="voucher" /><FormattedMessage id="wallet.buy-vouchers" defaultMessage="Buy Vouchers" /></p>
                  </Link>
                  <Link to="/bill-pay" style={{ width: "inherit" }}>
                    <p className="m-0 ml-2"><img className="mr-1" src={billpay} alt="billpay" title="billpay" /><FormattedMessage id="wallet.bill-utility" defaultMessage="Bill/Utility payments" /></p>
                  </Link>
                </div>
                </div>
            </div>
            <div className="col-md-2 col-12">
              {this.isBalGreaterThanMinBal(this.state) ? (
                // <Link to="/wallet/withdraw-money" style={{ width: "inherit" }}>
                <>
                  <button
                    className="btn btn-primary claim-btn float-right w-100"
                    // onClick={this.onWithdrawBtn}
                    onClick={this.handleShow}
                  >
                    <FormattedMessage
                      id="wallet.withdraw-money"
                      defaultMessage="WITHDRAW MONEY"
                    />
                  </button>
                  <Modal show={this.state.show} onHide={this.handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title style={{textAlign:"center", width:"100%"}}>Withdraw Money</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{textAlign:"center", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                    <img className="w-50 mb-3" src={WithdrawMoney} alt="withdraw" />
                    <p style={{color:"#1C1C1C", fontWeight:"600"}}>{walletInfoMessage}</p>
                    <Link to="/" style={{ width: "100%" }}>
                    <Button variant="primary w-100 text-uppercase font-weight-bold py-2 mb-3">
                      CANCEL WITHDRAWAL
                    </Button>
                    </Link>
                    <Link to="/wallet/withdraw-money" style={{ width: "100%" }}>
                      <Button variant="outline-primary w-100 text-uppercase font-weight-bold py-2 mb-2">Continue with withdrawal</Button>
                    </Link>
                  </Modal.Body>
                </Modal>
                </>
              ) : (
                <button
                  className="btn btn-primary w-100 mt-2"
                  disabled
                >
                  <FormattedMessage
                    id="wallet.withdraw-money"
                    defaultMessage="WITHDRAW MONEY"
                  />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  onChange = (item) => {
    if (this.loadRef.current) {
      this.observer.observe(this.loadRef.current);
    }

    const { value } = item;
    if (value !== this.state.filterValue) {
      this.setState(
        {
          filteredData: [],
        },
        () => {
          this.setState(
            {
              filterValue: value,
              filteredData: [],
              page: 1,
              isLastPage: false,
            },
            () => {
              this.fetchTransaction(this.state.page, item.viewValue);
            }
          );
        }
      );
    }
  };

  isBalGreaterThanMinBal(state) {

    if (state.balance != null && state.min_withdrawal != null) {
      if (parseInt(state.balance.replace(",", "")) >= parseInt(state.min_withdrawal)) {
        return true;
      }
    }
    return false;
  }
  componentWillUnmount() {
    //    window.removeEventListener('scroll', this.onScroll, false);
    this.observer.disconnect();
  }

  reset = () => {
    if (this.loadRef.current) {
      this.observer.observe(this.loadRef.current);
    }

    this.setState(
      {
        filterValue: "",
        page: 1,
        transactions: [],
        filteredData: [],
        isLastPage: false,
        isFetchingTransaction: true,
      },
      () => {
        this.fetchTransaction(this.state.page, null);
      }
    );
  };

  FiltersBlock = (props) => (
    <div className="col-3 border-right d-none d-md-block">
      <div className="row border-bottom align-items-center status">
        <div className="col-md-6 pl-0">
          <h5 className="font-proxima-nova-bold">
            {" "}
            <FormattedMessage id="general.filters" defaultMessage="Filters" />
          </h5>
        </div>
        <div className="col-md-6 pr-0 pb-2">
          <button
            className="btn btn-outline float-right winds-link-color"
            onClick={this.reset}
          >
            <span>
              {" "}
              <FormattedMessage id="general.reset" defaultMessage="Reset" />
            </span>
          </button>
        </div>
      </div>
      <div className="row align-items-center mt-4 pb-5 status">
        {/* <this.Filters /> */}
        {this.Filters()}
      </div>
    </div>
  );

  MobileFilters = (props) => {
    return (
      <div className="col-md-12 pr-0 pb-4">
        {this.radioOptions.map((item, index) => {
          return (
            <div className="row mt-3" key={index}>
              <div className="col-9 winds-grey-color">{item.viewValue}</div>
              <div className="col-3 text-center">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    id={item.value}
                    name="filter"
                    onChange={() => this.onChange(item)}
                    checked={this.state.filterValue === item.value}
                    value={item.viewValue}
                  />
                  <label
                    htmlFor={item.value}
                    className="custom-control-label"
                  ></label>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  Filters = (props) => {
    return (
      <div className="col-md-12 pr-0 pl-0">
        {this.radioOptions.map((item, index) => {
          return (
            <div className="row mt-3" key={index}>
              <div className="col-md-9 winds-grey-color">
                {item.viewValue === "Credit" ? (
                  <FormattedMessage
                    id="wallet.credit"
                    defaultMessage={item.viewValue}
                  />
                ) : (
                  <FormattedMessage
                    id="wallet.debit"
                    defaultMessage={item.viewValue}
                  />
                )}
              </div>
              <div className="col-md-3 text-center">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    id={item.viewValue}
                    name="wallet-filter"
                    onChange={() => this.onChange(item)}
                    checked={this.state.filterValue === item.value}
                    value={item.value}
                  />
                  <label
                    htmlFor={item.viewValue}
                    className="custom-control-label"
                  ></label>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  WalletTransactions = (props) => {
    return (
      <div className="container mb-3 mt-md-4 mb-md-4 font-proxima-nova">
        {this.state.isFetchingTransaction ? (
          <React.Fragment>
            {lodash.range(4).map((index) => {
              return (
                <div className="row" key={index}>
                  <div className="col-12">
                    <div
                      className="box shine mb-3 mb-md-4"
                      style={{ height: "100px" }}
                    ></div>
                  </div>
                </div>
              );
            })}
          </React.Fragment>
        ) : 0 === this.state.transactions.length ? (
          <div className="row">
            {/* <this.FiltersBlock /> */}
            {this.FiltersBlock()}
            <div className="col-12 col-md-9 main-content text-center">
              <div className="empty-state">
                <EmptyState
                  title={
                    <FormattedMessage
                      id="general.transactions"
                      defaultMessage="Anu Cash Reward won by you and your referral earnings shows up in the wallet. You can request for bank transfer from this page, Keep wInnIng WIth KAMAIE!"
                    />
                  }
                  imageWidth={25}
                  image={EmptyImage}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12 text-right d-block d-md-none">
                  <button
                    className="btn text-primary"
                    onClick={this.onFilterClick}
                  >
                    <img src={filterIcon} alt="filter" title="filter" className="filter-icon mr-2" />
                    <FormattedMessage
                      id="general.filters"
                      defaultMessage="Filters"
                    />
                  </button>

                  <React.Fragment>
                    <Modal
                      show={this.state.isFilterModalOpen}
                      onHide={this.onFilterClick}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          <FormattedMessage
                            id="general.filters"
                            defaultMessage="Filters"
                          />
                        </Modal.Title>
                      </Modal.Header>

                      <Modal.Body>
                        {/* <this.MobileFilters /> */}
                        {this.MobileFilters()}
                      </Modal.Body>
                      <Modal.Footer>
                        <div className="row w-100">
                          <div className="col-6">
                            <button
                              className="w-100 btn btn-outline-primary"
                              onClick={this.onFilterClick}
                            >
                              <FormattedMessage
                                id="general.apply"
                                defaultMessage="Apply"
                              />
                            </button>
                          </div>
                          <div className="col-6">
                            <button
                              className="btn w-100 btn-outline-primary"
                              onClick={() => {
                                this.onFilterClick();
                                this.reset();
                              }}
                            >
                              <FormattedMessage
                                id="general.reset"
                                defaultMessage="Reset"
                              />
                            </button>
                          </div>
                        </div>
                      </Modal.Footer>
                    </Modal>
                  </React.Fragment>
                </div>
              </div>
              <div className="row">
                {/* <this.FiltersBlock /> */}
                {this.FiltersBlock()}
                <div className="col-12 col-md-9 main-content">
                  <div className="row">
                    <div className="col-12 col-md-6 d-none d-lg-block">
                      <h5 className="mb-0">
                        <strong>
                          <FormattedMessage
                            id="wallet.description"
                            defaultMessage="Description"
                          />
                        </strong>
                      </h5>
                    </div>
                    <div className="col-12 col-md-4 d-none d-lg-block">
                      <h5 className="mb-0">
                        <strong>
                          <FormattedMessage
                            id="wallet.date"
                            defaultMessage="Date &amp; Time"
                          />
                        </strong>
                      </h5>
                    </div>
                    <div className="col-12 col-md-2 d-none d-lg-block ">
                      <h5 className="mb-0">
                        <strong>
                          <FormattedMessage
                            id="general.amount"
                            defaultMessage="Amount"
                          />
                        </strong>
                      </h5>
                    </div>
                  </div>
                  {this.state.transactions.map((item, index) => {
                    return (
                      <div
                        className="card mt-3"
                        style={{
                          boxShadow: "0 4px 14px 0 rgba(0,0,0,0.08)",
                          borderRadius: "8px",
                          border: "none",
                        }}
                        key={index}
                      >
                        <div className="card-body">
                          <div className="row justify-content-around">
                          <div className="col-0-1qtr ">
                          <img
                                alt="credit-debot"
                                title="credit-debot"
                                src={
                                  item.type_text === "Credit"
                                    ? creditIcon
                                    : item.withdrawal_request && item.withdrawal_request.status === 0
                                      ? pendingIcon
                                      : item.withdrawal_request && item.withdrawal_request.status === 1
                                        ? debitIcon
                                        : debitIcon
                                }
                              />
                            </div>

                            <div className="col-11 col-lg-5">
                              
                              <h6 className="mb-0 d-inline-block ml-0">
                                <strong>{item.description}</strong>
                              </h6>
                              <div className="text-secondary ">
                                <span className="text-dark">
                                  {item.status_text}
                                </span>{" "}
                                <br />
                                {item.withdrawal_request ? (
                                  <React.Fragment>
                                    <FormattedMessage
                                      id="wallet.name-account"
                                      defaultMessage="Account Name"
                                    />{" "}
                                    :{" "}
                                    {
                                      item.withdrawal_request.bank_account
                                        .account_name
                                    }{" "}
                                    <br />
                                    <FormattedMessage
                                      id="wallet.number-account"
                                      defaultMessage="Account No"
                                    />
                                    :{" "}
                                    {
                                      item.withdrawal_request.bank_account
                                        .account_number
                                    }{" "}
                                    <br />
                                    <FormattedMessage
                                      id="wallet.ifsc"
                                      defaultMessage="IFSC"
                                    />{" "}
                                    :{" "}
                                    {item.withdrawal_request.bank_account.ifsc}{" "}
                                    <br />
                                    <FormattedMessage
                                      id="wallet.branch-account"
                                      defaultMessage="Branch"
                                    />
                                    :{" "}
                                    {
                                      item.withdrawal_request.bank_account
                                        .branch
                                    }{" "}
                                    <br />
                                    <FormattedMessage
                                      id="wallet.pan"
                                      defaultMessage="PAN"
                                    />
                                    : {item.withdrawal_request.bank_account.pan}{" "}
                                    <br />
                                    {item.withdrawal_request.bank_account
                                      .payment_utr && (
                                        <React.Fragment>
                                          <FormattedMessage
                                            id="wallet.utr"
                                            defaultMessage="UTR No."
                                          />
                                          :{" "}
                                          {
                                            item.withdrawal_request.bank_account
                                              .payment_utr
                                          }
                                        </React.Fragment>
                                      )}
                                    <br />
                                    {item.withdrawal_request.error_message && (
                                      <React.Fragment>
                                        <FormattedMessage
                                          id="wallet.failure-reason"
                                          defaultMessage="Reason"
                                        />
                                        :{" "}
                                        {item.withdrawal_request.error_message}
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <span className="d-block">
                                      <FormattedMessage
                                        id="wallet.tx-ref"
                                        defaultMessage="Tx Ref"
                                      />
                                      : {item.transaction_reference}
                                    </span>
                                    {item.has_tds && item.tds_details && (
                                      <React.Fragment>
                                        <span className="d-block">
                                          <FormattedMessage
                                            id="wallet.tds-deducted"
                                            defaultMessage="TDS Deducted"
                                          />
                                          :{" "}
                                          {item.tds_details.final_tds_deducted}{" "}
                                        </span>
                                        <span className="d-block">
                                          <FormattedMessage
                                            id="wallet.tds-rate"
                                            defaultMessage="TDS Rate"
                                          />
                                          : {item.tds_details.final_tds_rate}
                                        </span>
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-lg-4">
                              <p className="mb-0 withdrawal-details">
                                {1 === parseInt(item.type) ? (
                                  <FormattedMessage
                                    id="wallet.debited-at"
                                    defaultMessage="Debited at"
                                  />
                                ) : (
                                  <FormattedMessage
                                    id="wallet.credited-at"
                                    defaultMessage="Credited at"
                                  />
                                )}{" "}
                                {item.date} {item.time}
                              </p>
                            </div>
                            <div className="col-12 col-lg-2">
                              <p className="mb-0 withdrawal-details">
                                <strong>₹ {item.amount}</strong>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
        <p style={{ textAlign: "center" }} id="load" ref={this.loadRef}></p>
        {!this.state.isLastPage && this.state.transactions.length > 0 && (
          <div style={{ textAlign: "center" }}>
            <InlineLoader />
          </div>
        )}
      </div>
    );
  };
}
