const INITIAL_STATE = {
  banner: [],
  heading_text: null,
  affiliate: [],
  popular_affiliate:[],
  partner: [],
  voucher: [],
  billpay: [],
  offers: [],
  tags: [],
  deals: [],
  isLoading: true,
  active_coupons: null,
  total_points: null,
  pending_points: null,
  coupon_progression_percent: null,
  total_points_per_coupon: null,
  customer_order_commission: [],
  ecommerce: null,
  insurance: null,
  service_categories: null,
  rewards: 0,
  fun_game_zone: null,
  completeProfileCount: 0,
  winds_kart:null,
  monetized_affiliate:[],
  deal_of_the_day:[],
  ppi_card_data: null,
};

function homeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_HOME":
      return {
        ...state,
        banner: action.banner,
        heading_text: action.heading_text,
        affiliate: action.affiliate,
        popular_affiliate:action.popular_affiliate,
        partner: action.partner,
        voucher: action.voucher,
        billpay: action.billpay,
        offers: action.offers,
        tags: action.tags,
        deals: action.deals,
        ecommerce: action.ecommerce,
        customer_order_commission: action.customer_order_commission,
        insurance: action.insurance,
        service_categories: action.service_categories,
        rewards: action.rewards,
        fun_game_zone: action.fun_game_zone,
        winds_kart:action.winds_kart,
        monetized_affiliate:action.monetized_affiliate,
        deal_of_the_day:action.deal_of_the_day,
        featured_products:action.featured_products,
        ppi_card_data: action.ppi_card_data
      };
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case "SET_COUNTER_DATA":
      return {
        ...state,
        active_coupons: action.active_coupons,
        total_points: action.total_points,
        pending_points: action.pending_points,
        coupon_progression_percent: action.coupon_progression_percent,
        total_points_per_coupon: action.total_points_per_coupon,
      };
    case "SET_COMPLETE_PROFILE_COUNT":
      return {
        ...state,
        completeProfileCount: action.count
      }
    case "SET_MONETIZED_AFFILIATE":
      return {
        ...state,
        monetized_affiliate: action.monetized_affiliate,
      };
    case "SET_HOME_AFTER_TOGGLE_BOOKMARK":
      switch (action.listTypeToUpdate) {
        case "voucher":
          return {
            ...state,
            voucher: state.voucher.map((v) =>
              v.id === action.id ? { ...v, bookmarked: !v.bookmarked } : v
            ),
          };
        case "shoppingSites":
          return {
            ...state,
            affiliate: state.affiliate.map((a) =>
              a.id === action.id ? { ...a, bookmarked: !a.bookmarked } : a
            ),
          };
        case "offers":
          return {
            ...state,
            offers: state.offers.map((o) =>
              o.id === action.id ? { ...o, bookmarked: !o.bookmarked } : o
            ),
          };
        default:
          break;
      }
      return {
        ...state,
      };
    default:
      return state;
  }
  
}


export default homeReducer;
