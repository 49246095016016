import React from "react";
import { Card, Button, Accordion } from "react-bootstrap";
import arrow from "../../../assets/img/about/arrow.png"
import spinWheel from "../../../assets/img/site-patrons/ic_spin_wheel.svg"
import monthlyDraw from "../../../assets/img/site-patrons/ic_reward.svg"
// import megaMonthly from "../../../assets/img/site-patrons/ic_mega_monthly_draw.svg"
import vouchers from "../../../assets/img/site-patrons/ic_purchase_vouchers.svg"
import payBills from "../../../assets/img/site-patrons/ic_pay_bills.svg"
import getoffers from "../../../assets/img/site-patrons/ic_best_offer_near_you.svg"
import earn from "../../../assets/img/site-patrons/ic_earn.svg"
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import PatronsMetaData from "../../../seo/PatronsMetaData";

const Patrons = () => {
  return (
    <>
      <PatronsMetaData />
      <section className="header-sec">
        <nav className="navbar navbar-expand-lg all">
          <ul className="navbar-nav site mr-auto mt-2 mt-lg-0">
            <li className="nav-item site-active">
              <Link className="nav-link" to="/patrons"><h1 style={{fontSize:"inherit", marginBottom:"0"}}><FormattedMessage id="page.nav-winds-patrons" /></h1></Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/partner"><FormattedMessage id="page.nav-winds-partner" /></Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/digi-world"><FormattedMessage id="page.nav-winds-digi" /></Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about-us"><FormattedMessage id="page.nav-about-us" /></Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" to="/luckydraw-patrons"><FormattedMessage id="page.nav-winds-luckydraw" /></Link>
            </li> */}
            {/* <li className="nav-item">
              <Link className="nav-link" to="/testimonials"><FormattedMessage id="page.nav-winds-testimonials" /></Link>
            </li> */}
          </ul>
        </nav>
      </section>
      <section className="about-sec-1 patrons site-page site-top">
        <div className="container font-proxima-nova">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <p className="above-title"><FormattedMessage id="patrons.section1.abouve-title" /> </p>
              <h2 className="title-captain"><FormattedMessage id="patrons.section1.title" /> </h2>
              <p className="desc"><FormattedMessage id="patrons.section1.desc" /></p>
              <span className="mt-4 mb-3 download-patron"><FormattedMessage id="patrons.section1.subtitle" /></span>
              <div className="paystore">
                <a href="https://winds.to/app" target="_blank" rel="noreferrer">
                  <img src={require('../../../assets/img/site-patrons/ic_google_pay.png')} alt="play store" title="play store" />
                </a>
                <a href="https://winds.to/app" target="_blank" rel="noreferrer">
                  <img src={require('../../../assets/img/site-patrons/ic_app_store.png')} alt="app store" title="app store" />
                </a>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <img className="ab-image mt-5 mt-md-0" src={require('../../../assets/img/site-patrons/ic_ptron_girl.png')} alt="Patrons" title="Patrons" />
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec-2 bg site-page">
        <div className="container font-proxima-nova">
          <div className="row reverse-col">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center align-items-center">
              <img width="100%" src={require('../../../assets/img/site-patrons/ic_benifitKamaie.png')} alt="Patrons" title="Patrons" className="pr-md-5 pr-0 mt-md-0 mt-3 w-75" />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <h2 className="title-captain"><FormattedMessage id="patrons.section2.title" /></h2>
              <p className="desc"><FormattedMessage id="patrons.section2.desc" /></p>
              <p className="desc">Whether paying your monthly utility bills online (electricity bills, water bills, gas bills, broadband bills), buying brand vouchers/e-gift cards of your favourite brands (Swiggy, Myntra, Flipkart, Amazon, Zomato, etc.), making payments at your nearby offline stores or shopping at your go-to online shopping sites (Nykaa, Ajio, Myntra, Boat, Samsung etc.), we have got you covered!</p>
              <Accordion defaultActiveKey="0">
                <Card>
                  <Card.Header>
                    {/* <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      <img className="mission-img" style={{ width: "25px", marginRight: "13px" }} src={spinWheel} alt="spinwheel" title="spinwheel" />
                      <h3 className="mb-0 font-weight-bold"><FormattedMessage id="patrons.section2.spin-wheel" /></h3>
                      <img src={arrow} width="15" style={{ marginLeft: "5px" }} alt="arrow" title="arrow" />
                    </Accordion.Toggle> */}
                  </Card.Header>
                  {/* <Accordion.Collapse eventKey="0">
                    <Card.Body> */}
                      {/* <p className="desc"><FormattedMessage id="patrons.section2.spin-wheel-desc" /></p> */}
                    {/* </Card.Body>
                  </Accordion.Collapse> */}
                </Card>
                {/* <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1"  >
                      <img className="mission-img" src={monthlyDraw} alt="monthlydraw" title="monthlydraw" />
                      <h3 className="mb-0 font-weight-bold"><FormattedMessage id="patrons.section2.mini-monthly-draw" /></h3>
                      <img src={arrow} width="15" style={{ marginLeft: "5px" }} alt="arrow" title="arrow" />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <p className="desc"><FormattedMessage id="patrons.section2.mini-monthly-draw-desc" /></p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card> */}
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec-3patrons site-page">
        <div className="container font-proxima-nova">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 d-flex flex-column justify-content-center ">
              <h2 className="title-captain"><FormattedMessage id="patrons.section3.title" /></h2>
              <p className="desc"><FormattedMessage id="patrons.section3.desc1" /></p>
              {/* <p className="desc"><FormattedMessage id="patrons.section3.desc2" /></p> */}
            </div>
            <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 d-flex flex-column justify-content-center">
              <img width="100%" src={require('../../../assets/img/site-patrons/ic_referral_program.png')} alt="Patrons" title="Patrons" />
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec-4 site-page">
        <div className="container font-proxima-nova">
          <div className="row reverse-col">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <img width="100%" src={require('../../../assets/img/site-patrons/ic_app_feature.png')} alt="Patrons" title="Patrons" className="pr-md-5 pr-0 mt-md-0 mt-3" />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <h2 className="title-captain"><FormattedMessage id="patrons.section4.title" /></h2>
              <Accordion defaultActiveKey="0">
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      <img className="mission-img" src={vouchers} alt="vouchers" title="vouchers" />
                      <h3 className="mb-0 font-weight-bold"><FormattedMessage id="patrons.section4.purchase-vouchers" /></h3>
                      <img src={arrow} width="15" style={{ marginLeft: "5px" }} alt="arrow" title="arrow" />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <p className="desc"><FormattedMessage id="patrons.section4.purchase-vouchers-desc1" /></p>
                      <p className="desc"><FormattedMessage id="patrons.section4.purchase-vouchers-desc2" /></p>
                      <p className="desc"><FormattedMessage id="patrons.section4.purchase-vouchers-desc3" /></p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                      <img className="mission-img" src={payBills} alt="paybills" title="paybills" />
                      <h3 className="mb-0 font-weight-bold"><FormattedMessage id="patrons.section4.pay-all-bills" /></h3>
                      <img src={arrow} width="15" style={{ marginLeft: "5px" }} alt="arrow" title="arrow" />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <p className="desc"><FormattedMessage id="patrons.section4.pay-all-bills-desc1" /></p>
                      {/* <p className="desc"><FormattedMessage id="patrons.section4.pay-all-bills-desc2" /></p> */}
                      <p className="desc"><FormattedMessage id="patrons.section4.pay-all-bills-desc3" /></p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                      <img className="mission-img" src={getoffers} alt="getoffers" title="getoffers" />
                      <h3 className="mb-0 font-weight-bold"><FormattedMessage id="patrons.section4.get-the-best-offers" /></h3>
                      <img src={arrow} width="15" style={{ marginLeft: "5px" }} alt="arrow" />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <p className="desc"><FormattedMessage id="patrons.section4.get-the-best-offers-desc1" /></p>
                      <p className="desc"><FormattedMessage id="patrons.section4.get-the-best-offers-desc2" /></p>
                      {/* <p className="desc"><FormattedMessage id="patrons.section4.get-the-best-offers-desc3" /></p> */}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                      <img className="mission-img" src={earn} alt="earn" title="earn" />
                      <h3 className="mb-0 font-weight-bold"><FormattedMessage id="patrons.section4.earn-when-friends-shop" /></h3>
                      <img src={arrow} width="15" style={{ marginLeft: "5px" }} alt="arrow" title="arrow" />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      <p className="desc"><FormattedMessage id="patrons.section4.earn-when-friends-shop-desc" /></p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container py-5">
          <h2 className="title-captain">Frequently Asked Questions</h2>
          <Accordion defaultActiveKey="1">
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="1" >
                  <h3 className="mb-0 h5 font-weight-bold">What is KAMAIE?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p className="desc">The KAMAIE App is a one of its kind ‘UNIVERSAL SUPER APP’ which brings in the convenience of discovering Online E-commerce Platforms, Neighborhood Retail Stores, E Gift Vouchers of the choicest Brands and Utility Bill Payment options, Flight booking, Hotel booking, transaction with Rupay Pre-Paid Card. On regular day-to-day shopping or any transaction through this app, whether for a product or service, customer gets KAMAIE Coins on every purchase.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="2" >
                  <h3 className="mb-0 h5 font-weight-bold">Who Are Called KAMAIE Customer?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p className="desc">Anyone individual shopping or using any service through the KAMAIE ecosystem is termed a KAMAIE Customer .</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="3" >
                  <h3 className="mb-0 h5 font-weight-bold">Is using a referral code to download the KAMAIE App mandatory?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p className="desc">No, having a referral code to download the app is not mandatory. You can always download the app from the respective Play Stores.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="4" >
                  <h3 className="mb-0 h5 font-weight-bold">Do You Have A Referral Programme? </h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <p className="desc">Yes, we do have a referral programme. Whenever an individual refers the KAMAIE Customer App, the referer & referee both would earn 50 KAMAIE Coins. Additionally, the referer would be entitled to continuous earnings. This means if Customer B signs up using Customer A's referral code, Customer A will earn 10% of KAMAIE' Earnings on all transactions done by Customer B through the KAMAIE App (For instance, if Customer B buys a laptop worth Rs. 1 lakh through the KAMAIE Customer, and if the KAMAIE' earning on the transaction is 5%, i.e. Rs. 5,000/-, then Customer A will get 10% of KAMAIE' earning, i.e. Rs. 500/-).</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="5" >
                  <h3 className="mb-0 h5 font-weight-bold">What Is KAMAIE Coins & How Can We Redeem It In Cash?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  <p className="desc">Customers can earn KAMAIE Coins by making purchases at Nearby KAMAIE Dukan (registered KAMAIE Customer), shopping at Online Sites, buying Brand Vouchers, or making utility bill payments, Flight booking, Hotel booking, transaction via Rupay Pre-Paid cards through the KAMAIE App.</p><p> KAMAIE Coins are hugely beneficial, you can redeem KAMAIE Coins in your next voucher purchase.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="6" >
                  <h3 className="mb-0 h5 font-weight-bold">How Can I Keep Track of Total KAMAIE Coins Earned? </h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="6">
                <Card.Body>
                  <p className="desc">Open The KAMAIE App {">"} Click On The KAMAIE Coins Section {">"} Total Number of KAMAIE Coins Would Be Displayed There.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="7" >
                  <h3 className="mb-0 h5 font-weight-bold">Can I Transfer Money via KAMAIE App?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="7">
                <Card.Body>
                  <p className="desc">Unfortunately, you can't transfer money via the KAMAIE app. However, you can always buy KAMAIE E-Gift Voucher for your loved ones & gift them.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="8" >
                  <h3 className="mb-0 h5 font-weight-bold">Why Should I Switch To KAMAIE?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="8">
                <Card.Body>
                  <p className="desc">KAMAIE is a unique Shopping App which will reward you for your daily shopping transactions like never before. On your regular transactions, you collect KAMAIE Coins to every transaction via KAMAIE.</p><p>In addition, you are entitled to referral benefits as a percentage of KAMAIE earnings. And that too, for just your routine shopping that you do every month. Where else can you get such enormous benefits?</p> </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="12" >
                  <h3 className="mb-0 h5 font-weight-bold">Are My Personal Details Safe On The App?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="12">
                <Card.Body>
                  <p className="desc">It is entirely safe to update your details on KAMAIE App. Such information won't be shared with the Service Providers. KAMAIE understands and respects the privacy of its customers.</p> </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="13" >
                  <h3 className="mb-0 h5 font-weight-bold">How Much Time Does It Take For The KAMAIE Coins To Get Reflected After A Purchase?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="13">
                <Card.Body>
                  <p className="desc">It takes less than a minute for the recently earned KAMAIE Coins to get reflected. You can always check them under the KAMAIE Coins section available on the app's homepage.</p> </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="7" >
                  <h3 className="mb-0 h5 font-weight-bold">How Can I Get In Touch With Customer Support For Any Queries/Complaints?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="7">
                <Card.Body>
                  <p className="desc">You can reach out to us by sending an email to: support@Kamaie.com</p> </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </section>
    </>
  );
};
export default Patrons;
