import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Accordion, Card } from "react-bootstrap";
import Iframe from "../../../components/iFrame";
import { currentlySelectedLanguage } from "../../../utils/helper";
const TermsConditions = (props) => {
  return (
    <div className="container font-proxima-nova">
      <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
        <div className="col-12">
          <small>
            <Link to="/">
              <FormattedMessage id="breadcrumbs.home" defaultMessage="Home" />
            </Link>{" "}
            /{" "}
            <strong>
              <FormattedMessage
                id="footer.terms-and-conditions"
                defaultMessage="Terms & Conditions"
                description="Terms & Conditions"
                values={{ what: "react-intl" }}
              />
            </strong>
          </small>
        </div>
      </div>
      <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
        <div className="col-12">
          <div class="row mt-4 mb-5">
            <div class="col-md-12">
              <Accordion defaultActiveKey="1">
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Card.Header} variant="link" eventKey="1" style={{ background: "transparent", border: "none", cursor: "pointer" }}>
                      <h4 className="title">
                        T&C For Customers
                      </h4>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Iframe source={`${process.env.REACT_APP_BASE_URL_PATRONS}/terms-n-conditions/${currentlySelectedLanguage()}`}
                    ></Iframe>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Card.Header} variant="link" eventKey="2" style={{ background: "transparent", border: "none", cursor: "pointer" }}>
                      <h4 className="title">
                        T&C For KAMAIE Dukaan
                      </h4>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Iframe source={`${process.env.REACT_APP_BASE_URL_PARTNERS}/agreement/${currentlySelectedLanguage()}`}
                    ></Iframe>
                  </Accordion.Collapse>
                </Card>
                {/* <Card>
                  <Card.Header >
                    <Accordion.Toggle as={Card.Header} variant="link" eventKey="0" style={{ background: "transparent", border: "none", cursor: "pointer" }}>
                      <h4 className="title">
                        T&C For Captains
                      </h4>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Iframe source={`${process.env.REACT_APP_BASE_URL_CAPTAIN}/captain/tnc/${currentlySelectedLanguage()}`}
                    ></Iframe>
                  </Accordion.Collapse>
                </Card> */}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TermsConditions;
