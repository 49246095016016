import React from "react";
import kiran_panday from '../../assets/home/winners/Kiran_Pandey.jpg'


function WinnersPatrons() {
  return (
    <>
      <div className="winners-page testimonialsWrapper">
        <div className="container">
          <div className="hero">
            <div className="container text-center">
              <span>Testimonials</span>
              <hr />
              <span className="comm-head-fon">What Our Cashback Winners Are Saying About The KAMAIE App</span>
              <hr />
            </div>
          </div>
          <h2 style={{ color: "#ffbc01", fontWeight: "bold" }} className="m-0 winner-zone-sub-text">25 LAKH WINNERS</h2>
          <div className="testimonials row">
          <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3 pt-0">
                <img className="w-100" src={kiran_panday} alt="winner-img" style={{ borderRadius: "10px" }} />
                </div>
                <h3 className="mb-2 h5">Mrs Kiran Pandey (Varanasi, UP)</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 25 Lakhs</p>
                <small className="text-muted">Winner for the month of December, 2023</small>
              </div>
            </div>
          <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/rdh2sYSn5Ag?si=9j5iQLh2CRXEXHHw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mr Devraj M (Yadgir, Karnataka)</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 25 Lakhs</p>
                <small className="text-muted">Winner for the month of November, 2023</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/h4zEUN95O2A?si=UnjHNk6SSFfl65dM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mr Pradeep Kumar Yadav (Chhattisgarh)</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 25 Lakhs</p>
                <small className="text-muted">Winner for the month of October, 2023</small>
              </div>
            </div>
          <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/h4Va0lRjpdc?si=U-7L4uMqTZXEuXHG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mrs Shivali Arora(Lucknow)</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 25 Lakhs</p>
                <small className="text-muted">Winner for the month of September, 2023</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/j_u9jh5TuLo?si=ef3hWj5R2cvwxzHj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mr.Nobahar(New Delhi)</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 25 Lakhs</p>
                <small className="text-muted">Winner for the month of August, 2023</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/qeT9PDKd3y8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Sandeep Sood (New Delhi)</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 25 Lakhs</p>
                <small className="text-muted">Winner for the month of July, 2023</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/o5nl4yL3KWw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Deepak Gupta (Delhi, NCR)</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 25 Lakhs</p>
                <small className="text-muted">Winner for the month of June, 2023</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/2hlDcLn6FU4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mr. J. Sriramulu,Telangana</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 25 Lakhs</p>
                <small className="text-muted">Winner for the month of May, 2023</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div class="testimonial">
                <div class="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/uP438YwmqNo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 class="mb-2 h5">Mr. Manoranjan Padhan, Sambalpur (Odisha)</h3>
                <p class="text-primary mb-1">Amount Won : ₹ 25 Lakhs</p>
                <small class="text-muted">Winner for the month of April, 2023</small>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
              <div class="testimonial">
                <div class="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/W7Iat6Cx2Hg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 class="mb-2 h5">Mr. Guruprasad, Bengaluru (Karnataka)</h3>
                <p class="text-primary mb-1">Amount Won : ₹ 25 Lakhs</p>
                <small class="text-muted">Winner for the month of March, 2023</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div class="testimonial">
                <div class="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/pXInRrlqH_M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 class="mb-2 h5">Mrs. Rajitha, Mumbai (Maharshtra)</h3>
                <p class="text-primary mb-1">Amount Won : ₹ 25 Lakhs</p>
                <small class="text-muted">Winner for the month of February, 2023</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div class="testimonial">
                <div class="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/Ccyxgou_byo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 class="mb-2 h5">Mr. Bipul Kumar Singh, Patna (Bihar)</h3>
                <p class="text-primary mb-1">Amount Won : ₹ 25 Lakhs</p>
                <small class="text-muted">Winner for the month of January, 2023</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/qPnwnGQVR4Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mr. Shiva Makin, Lucknow (Uttar Pradesh)</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 25 Lakhs</p>
                <small className="text-muted">Winner for the month of December, 2022</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/zdoTnSEvPgg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mr. Manoj Purohit, Jaisalmer (Rajasthan)</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 25 Lakhs</p>
                <small className="text-muted">Winner for the month of November, 2022</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/QeTjEcdoNM4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mr. Ashok Meena, Kota (Rajasthan)</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 25 Lakhs</p>
                <small className="text-muted">Winner for the month of October, 2022</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/gntJONLXEvk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mr. J. Manzar, Delhi</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 25 Lakhs</p>
                <small className="text-muted">Winner for the month of September, 2022</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe src="https://www.youtube.com/embed/wLLMQM--Eqs" title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mr. G Sathya Reddy, Telangana</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 25 Lakhs</p>
                <small className="text-muted">Winner for the month of August, 2022</small>
              </div>
            </div>
          </div>

          <h2 style={{ color: "#ffbc01", fontWeight: "bold" }} className="m-0">5 LAKH WINNERS</h2>
          <div className="testimonials row">


            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/6YtHPuRNQx4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mr. Jitendra Kumar(Lucknow)</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 5 Lakhs</p>
                <small className="text-muted">Winner for the month of July, 2023</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/mQP4RM5jkdo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mrs. B.Annamma</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 5 Lakhs</p>
                <small className="text-muted">Winner for the month of June, 2023</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/sllhDL75ctY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mr Mukka Naveen</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 5 Lakhs</p>
                <small className="text-muted">Winner for the month of April, 2023</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/Uba7M-hqr2Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mrs. Priyanka Swain, khurda (Odisha)</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 5 Lakhs</p>
                <small className="text-muted">Winner for the month of April, 2023</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/bZKRfX4Q99M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mr. Deepak Kumar, Rajasthan</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 5 Lakhs</p>
                <small className="text-muted">Winner for the month of January, 2023</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/RFDGiXrQ_xg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mr. Prem narayan singh, Gorakhpur</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 5 Lakhs</p>
                <small className="text-muted">Winner for the month of January, 2023</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/SdiWAzVNthw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mr. B.Amruthaiah, Telangana</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 5 Lakhs</p>
                <small className="text-muted">Winner for the month of January, 2023</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/qBa_Uex1uSY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mr. Rantu Hazarika, Assam</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 5 Lakhs</p>
                <small className="text-muted">Winner for the month of December, 2022</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/g03gXUS-Ln8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mr. Amit Kumar, Lucknow</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 5 Lakhs</p>
                <small className="text-muted">Winner for the month of December, 2022</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/jR3B4cLqCH0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mr. Manish Soni ,  Rajasthan</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 5 Lakhs</p>
                <small className="text-muted">Winner for the month of December, 2022</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/X389zPNR4MI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mr. Sawan Kumar, Varanasi</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 5 Lakhs</p>
                <small className="text-muted">Winner for the month of November, 2022</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/87RVzCTqSUM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mrs. Laxmi Kumari, Hyderabad (Telangana)</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 5 Lakhs</p>
                <small className="text-muted">Winner for the month of November, 2022</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/wMHxxTRetOo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mrs. Zeba Usmani, Rajasthan</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 5 Lakhs</p>
                <small className="text-muted">Winner for the month of October, 2022</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/Ew3VT-FLf9I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mr. Rajkumar Dua, Haryana</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 5 Lakhs</p>
                <small className="text-muted">Winner for the month of September, 2022</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/MHDzRxLCZJ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mr. Anand Kumar, Varanasi (UP) </h3>
                <p className="text-primary mb-1">Amount Won : ₹ 5 Lakhs</p>
                <small className="text-muted">Winner for the month of August, 2022</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/FpjiWwikLDM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mr. Neeraj Srivastava, Lucknow</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 5 Lakhs</p>
                <small className="text-muted">Winner for the month of July, 2022</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/m_0w8aWRsXs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mr. Tarique Usmani, Delhi</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 5 Lakhs</p>
                <small className="text-muted">Winner for the month of July, 2022</small>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/nFoSUMfV-CE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Mr. Indrasena Reddy, Telangana</h3>
                <p className="text-primary mb-1">Amount Won : ₹ 5 Lakhs</p>
                <small className="text-muted">Winner for the month of June, 2022</small>
              </div>
            </div>

          </div>

        </div>
      </div>
      <div className="" >
        <div className="container">
          <h2 style={{ color: "#ffbc01", fontWeight: "bold" }} className="m-0">Product Videos / Testimonials</h2>
          <div className="testimonials row pt-4 pb-5">
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/GJHSTqFG9Z0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">What is WINDS Coins</h3>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/qlRw7TuuYrI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Referral Program</h3>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/byIwq5P3MgU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Referral Program</h3>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/QbR9ep_3kjY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">KAMAIE E-Gift Voucher</h3>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/Jb5f9EAe-0I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Claim your #RightToRewards today</h3>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/FoZB5YmDkT8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Myntra Vouchers via KAMAIE</h3>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/FocB7Pb-mz8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Flipkart Vouchers via KAMAIE</h3>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/FWP8QKQoDuI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Amazon Vouchers via KAMAIE</h3>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/_49jyPU4COo?si=cTPyCjz6qtoMVbFs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Swiggy Vouchers via KAMAIE</h3>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial">
                <div className="embed mb-3">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/xefwPaG-Bwg?si=IM4Et4oP9cDseDMJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 className="mb-2 h5">Zomato Vouchers via KAMAIE</h3>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default WinnersPatrons;