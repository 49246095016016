import React, { Component } from 'react';
import '../styles/App.scss';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavBar from '../components/Navbar';
import Home from './home/pages/Home';
import DownloadApp from './download_app/download';
import ShoppingSitesList from './shopping_sites/pages/ShoppingSitesList';
import ShoppingSiteDetail from './shopping_sites/pages/ShoppingSiteDetail';
import CategoryList from './partners/pages/CategoryList';
import NearByPartner from './partners/pages/NearByPartners';
import NearbyPartnerDetail from './partners/pages/PartnerDetail';
import VouchersList from './vouchers/pages/VouchersList';
import VoucherDetail from './vouchers/pages/VoucherDetail';
import BillPayForm from './bill_pay/pages/BillPayForm';
import OffersList from './partners/pages/OffersList';
import Footer from '../components/Footer';
import OfferDetail from './partners/pages/OfferDetail';
import SearchResults from './search/pages/SearchResults';
import Profile from './profile/pages/Profile';
import ProfileDetails from './profile/pages/ProfileDetails';
import Orders from './orders/pages/Orders';
import BookMarks from './bookmarks/pages/Bookmarks';
// import Winners from './winners/pages/Winners';
import Wallet from './wallet/pages/Wallet';
import Coupon from './coupons/pages/Coupon';
import Notifications from './notifications/pages/Notifications';
import Referral from './referral/pages/Referral';
import Rewards from './rewards/pages/Rewards';
import VoucherCart from './vouchers/pages/Cart';
import VoucherOrder from './vouchers/pages/OrderDetail';
import BillPay from './bill_pay/pages/BillPay';
import BillPayOrder from './bill_pay/pages/OrderDetail';
import BillPayCart from './bill_pay/pages/Cart'
import PartnerBillUpload from './partners/pages/BillUpload';
import SSBillUpload from './shopping_sites/pages/BillUpload';
import Address from './profile/pages/Address';
import ChangePassword from './profile/pages/changePassword';
import DeleteAccount from './profile/pages/DeleteAccount';
import Devices from './profile/pages/Devices';
import Ratings from './profile/pages/Ratings';
import WrpDetails from './wrp_details/pages/WrpDetails';
import WRPSummary from './coupons/pages/WRPSummary';
import ScrollToTop from '../components/ScrollToTop';
import NotFound from '../components/NotFound';
import ShoppingSiteOrder from './shopping_sites/pages/OrderDetail';
import PartnerOrder from './partners/pages/OrderDetail';
import RewardOfferDetail from '../modules/rewards/pages/offer-details';
import RewardVoucherDetails from '../modules/rewards/pages/voucher-details';
import CleverTap from '../utils/cleverTap';
import PayNow from './partners/pages/PayNow';
import RefundRequest from './partners/pages/RefundRequest';
import { ToastContainer } from "react-toastify";
import PhysicalPrize from './rewards/pages/physical-prize';
import WithdrawMoney from './wallet/pages/WithdrawMoney';
import VerificationInfo from './rewards/pages/VerificationInfo';
import RewardsInfo from './rewards/pages/RewardsInfo';
import AboutUs from './about/pages/About';
import SitePartner from './site-partner/pages/Partner'
import SitePatrons from './site-patrons/pages/Patrons'
import SiteCaptain from './site-captain/pages/Captain'
import TermsConditions from './terms_conditions/pages/TermsConditions';
import Faq from './faq/pages/Faq';
import PrivacyPolicy from './privacy_policy/pages/privacyPolicy';
import Credits from './credits/pages/Credits';
import Deals from './deals/pages/deals';
import HelmetMetaData from "../components/HelmetMetaData";
import RedeemTermsCondition from './coupons/pages/RedeemTermsCondition';
import Earnings from './earnings/pages';
import EarningsHistory from './earnings/pages/earningsHistory';
import EarningsHistoryDetails from './earnings/pages/earningsHistoryDetails';
import referralList from './earnings/pages/referralList';
import WinnersPatrons from './winners-list/Patrons';
import WinnersPartners from './winners-list/partners';
import Testimonials from './testimonials/testimonials';
import LuckydrawPatrons from './lucky_draw/patrons';
import LuckydrawPartners from './lucky_draw/partners';
import Contact from './contact/contact';
import PartnerCart from './partners/pages/Cart';
// import VerifyOTP from './wallet/components/VerifyOTP';
// import BillSummary from './partners/components/BillSummary';
import Insurance from './insurance/insurance';
import PartnerFAQ from './faq/pages/PartnerFAQ';
import DigiWorldFAQ from './faq/pages/DigiWorld';
import DigiWorldApp from './landing_pages/DigiWorldApp';
import PartnerApp from './landing_pages/PartnerApp';
import PatronApp from './landing_pages/PatronApp';
import WindsTimesEN from './magazine/winds-times/WindsTimesEN';
import WindsTimesHI from './magazine/winds-times/WindsTimesHI';
import RiseComplaint from './bill_pay/pages/RiseComplaint';
import TravelResults from './travel/pages/TravelResults';
import Games from './games/pages/Games';
import ViewTicket from './travel/pages/ViewTicket';
import RedeemWindsVoucher from './vouchers/pages/RedeemWindsVoucher';
import Travel from './travel/pages/travel';
import PreferenceDetails from './profile/pages/PreferenceDetails';
import FlightOrderDetail from './travel/pages/FlightOrderDetail';
import HotelOrderDetail from './travel/pages/HotelOrderDetail';
import DrawWinners from './winners/DrawWinners';
import FollowUs from './profile/pages/FollowUs';
import LoginPage from './login/pages/LoginPage';
import SignupPage from './login/pages/SignupPage';
import ForgotPassword from './login/pages/ForgotPassword';
import CaptainAccountDeletion from './account-deletion/Captain';
import PartnerAccountDeletion from './account-deletion/partner';
import PatronAccountDeletion from './account-deletion/patron';
import PayVerification from './phonepe_pay/Index';
import CartSummary from './cart/CartSummary';
import WindsMerchandiseOrderDetail from './winds_merchandise/pages/OrderDetail'
import PpiBenefits from './faq/pages/PpiBenefits';
import WindsKartOrderDetail from './winds_kart/pages/OrderDetails'
import HotelBooking from './Agoda_hotel/Hotel-booking';
import DealsCart from '../components/DealsCart';
import Featured_products from '../components/FeaturedProducts';
import CardsPlans from  './prepaid_cards/pages/CardsPlans';
import StartKYC from './prepaid_cards/pages/small_KYC/StartKYC';
import InitiateSmallKYC from './prepaid_cards/pages/small_KYC/InitiateKYC';
import ValidateSmallKYC from './prepaid_cards/pages/small_KYC/ValidateKYC';
import DocumentEdit from './prepaid_cards/pages/small_KYC/DocumentEdit';
import DocumentView from './prepaid_cards/pages/small_KYC/DocumentView';
import InitiateFullKYC from './prepaid_cards/pages/full_KYC/InitiateKYC';
import VerifyFullKYC from './prepaid_cards/pages/full_KYC/VerifyKYC';
import ManageCard from './prepaid_cards/pages/manage_card/ManageCard';
import PpiFAQ from './prepaid_cards/pages/manage_card/FAQ';
import CardLimits from './prepaid_cards/pages/manage_card/CardLimits'
import PrepaidCardShippingFormEdit from './prepaid_cards/pages/ShippingFormEdit';
import PrepaidCardShippingFormView from './prepaid_cards/pages/ShippingFormView';
import Statements from './prepaid_cards/pages/manage_card/Statements';
import Cart from './prepaid_cards/pages/Cart';
import StartPremiumKYC from './prepaid_cards/pages/full_KYC/StartPremiumKYC';
class App extends Component {

  constructor() {
    super();
    CleverTap.initialize(process.env.REACT_APP_CLEVERTAP_ACCOUNT_ID);
  }

  render() {
    return (
      <Router>
        <ScrollToTop>
          {(window.location.pathname === "/en/winds-times" ||
            window.location.pathname === "/hi/winds-times" ||
            window.location.pathname === "/winners-patrons" ||
            window.location.pathname === "/winners-partners" ||
            window.location.pathname === "/digi-world-app" ||
            window.location.pathname === "/partner-app" ||
            window.location.pathname === "/patron-app" ||
            window.location.pathname === "/captain-account-deletion" ||
            window.location.pathname === "/pay-verification" ||
            window.location.pathname === "/ppi-benefits")?
            null :
            <NavBar />
          }

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <HelmetMetaData></HelmetMetaData>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/download-app" component={DownloadApp} />
            <Route exact path="/shopping-sites" component={ShoppingSitesList} />
            <Route exact path="/shopping-sites/category/:id" component={ShoppingSitesList} />
            <Route exact path="/shopping-sites/orders/:id" component={ShoppingSiteOrder} />
            <Route exact path="/shopping-sites/:id" component={ShoppingSiteDetail} />
            <Route exact path="/shopping-sites/:id/:dealId" component={ShoppingSiteDetail} />
            <Route exact path="/nearby-partners/categories" component={CategoryList} />
            <Route exact path="/partner-profile/:id" component={NearbyPartnerDetail} />
            <Route exact path="/nearby-partners" component={NearByPartner} />
            <Route exact path="/nearby-partners/orders/:id" component={PartnerOrder} />
            <Route exact path="/upload-bill/nearby-partner/:id" component={PartnerBillUpload} />
            <Route exact path="/upload-bill/nearby-partner" component={PartnerBillUpload} />
            <Route exact path="/upload-bill/shopping-site/:id" component={SSBillUpload} />
            <Route exact path="/pay-now/nearby-partner/cart" component={PartnerCart} />
            <Route exact path="/pay-now/nearby-partner/:id" component={PayNow} />
            <Route exact path="/pay-now/refund-request/:billId/:amount/:orderNumber/:createdAt" component={RefundRequest} />
            <Route exact path="/upload-bill/shopping-site" component={SSBillUpload} />
            <Route exact path="/vouchers" component={VouchersList} />
            <Route exact path="/vouchers/category/:id" component={VouchersList} />
            <Route exact path="/voucher/redeem" component={RedeemWindsVoucher} />
            <Route exact path="/vouchers/cart" component={CartSummary} />
            <Route exact path="/vouchers/orders/:id" component={VoucherOrder} />
            <Route exact path="/voucher/:id" component={VoucherDetail} />
            <Route exact path="/bill-pay" component={BillPay} />
            <Route exact path="/bill-pay/orders/:id" component={BillPayOrder} />
            <Route exact path="/bill-pay/orders/:id/rise-complaint" component={RiseComplaint} />
            <Route exact path="/bill-pay/cart" component={CartSummary} />
            <Route exact path="/bill-pay/:id" component={BillPayForm} />
            <Route exact path="/bill-pay/:id/:provider" component={BillPayForm} />
            <Route exact path="/nearby-offers" component={OffersList} />
            <Route exact path="/offer/:id" component={OfferDetail} />
            {/* <Route exact path="/search" component={SearchResults} /> */}
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/profile/details" component={ProfileDetails} />
            <Route exact path="/preferencedetails" component={PreferenceDetails} />
            <Route exact path="/addresses" component={Address} />
            <Route exact path="/change-password" component={ChangePassword} />
            <Route exact path="/delete-account" component={DeleteAccount} />
            <Route exact path="/devices" component={Devices} />
            <Route exact path="/ratings" component={Ratings} />
            <Route exact path="/verification-info/:id" component={VerificationInfo} />
            <Route exact path="/orders" component={Orders} />
            <Route exact path="/bookmarks" component={BookMarks} />
            <Route exact path="/draw-winners" component={DrawWinners} />
            <Route exact path="/wallet" component={Wallet} />
            <Route exact path="/wallet/withdraw-money" component={WithdrawMoney} />
            <Route exact path="/winds-coins" component={Coupon} />
            <Route exact path="/wrp-summary" component={WRPSummary} />
            <Route exact path="/winds-coins-details" component={WrpDetails} />
            <Route exact path="/credits" component={Credits} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/faq" component={Faq} />
            <Route exact path="/terms-condition" component={TermsConditions} />
            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/patrons" component={SitePatrons} />
            <Route exact path="/digi-world" component={SiteCaptain} />
            <Route exact path="/partner" component={SitePartner} />
            <Route exact path="/deals" component={Deals} />
            <Route exact path="/rewards-info" component={RewardsInfo} />
            <Route exact path="/notifications" component={Notifications} />
            <Route exact path="/refer-and-earn" component={Referral} />
            <Route exact path="/rewards" component={Rewards} />
            <Route exact path="/rewards/offers/:id" component={RewardOfferDetail} />
            <Route exact path="/rewards/vouchers/:id" component={RewardVoucherDetails} />
            <Route exact path="/rewards/physical-prizes/:id" component={PhysicalPrize} />
            <Route exact path="/redeem-terms-condition" component={RedeemTermsCondition} />
            <Route exact path="/earnings" component={Earnings} />
            <Route exact path="/earnings/customer-commission" component={EarningsHistory} />
            <Route exact path="/customer-commission-details" component={EarningsHistoryDetails} />
            <Route exact path="/referral-list" component={referralList} />
            <Route exact path="/testimonials" component={Testimonials} />
            <Route exact path="/winners-patrons" component={WinnersPatrons} />
            <Route exact path="/winners-partners" component={WinnersPartners} />
            {/* <Route exact path="/luckydraw-patrons" component={LuckydrawPatrons} />
            <Route exact path="/luckydraw-partners" component={LuckydrawPartners} /> */}
            <Route exact path="/contact-us" component={Contact} />
            <Route exact path="/insurance" component={Insurance} />
            <Route exact path="/partner-faq" component={PartnerFAQ} />
            <Route exact path="/digi-world-faq" component={DigiWorldFAQ} />
            <Route exact path="/digi-world-app" component={DigiWorldApp} />
            <Route exact path="/partner-app" component={PartnerApp} />
            <Route exact path="/patron-app" component={PatronApp} />
            <Route exact path="/en/winds-times" component={WindsTimesEN} />
            <Route exact path="/hi/winds-times" component={WindsTimesHI} />
            <Route exact path="/travel" component={Travel} />
            <Route exact path="/flights" component={Travel} />
            <Route exact path="/hotels" component={Travel} />
            <Route exact path="/flights/orders/:id" component={FlightOrderDetail} />
            <Route exact path="/hotels/orders/:id" component={HotelOrderDetail} />
            <Route exact path="/search-results" component={TravelResults} />
            <Route exact path="/booking/view-ticket" component={ViewTicket} />
            <Route exact path="/fun-games-zone" component={Games} />
            <Route exact path="/follow-us" component={FollowUs} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/signup" component={SignupPage} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/captain-account-deletion" component={CaptainAccountDeletion} />
            <Route exact path="/patron-account-deletion" component={PatronAccountDeletion} />
            <Route exact path="/partner-account-deletion" component={PartnerAccountDeletion} />
            <Route exact path="/pay-verification" component={PayVerification} />
            <Route exact path="/winds-merchandise/orders/:id" component={WindsMerchandiseOrderDetail} />
            <Route exact path="/ppi-benefits" component={PpiBenefits}/>
            <Route exact path="/winds-kart/orders/:id" component={WindsKartOrderDetail} />
            <Route exact path="/hotel-booking" component={HotelBooking}/>
            <Route exact path="/deals-cart/:id" component={DealsCart}/>
            <Route exact path="/Featured_products" component={Featured_products}/>
            <Route exact path="/prepaid-cards/plans" component={CardsPlans} />
            <Route exact path="/prepaid-cards/purchase/cart" component={Cart} />
            <Route exact path="/prepaid-cards/start-kyc" component={StartKYC} />
            <Route exact path="/prepaid-cards/start-premium-kyc" component={StartPremiumKYC} />
            <Route exact path="/prepaid-cards/small-kyc/initiate" component={InitiateSmallKYC} />
            <Route exact path="/prepaid-cards/small-kyc/validate" component={ValidateSmallKYC} />
            <Route exact path="/prepaid-cards/small-kyc/document/edit" component={DocumentEdit} />
            <Route exact path="/prepaid-cards/small-kyc/document/view" component={DocumentView} />
            <Route exact path="/prepaid-cards/full-kyc/initiate" component={InitiateFullKYC} />
            <Route exact path="/prepaid-cards/full-kyc/verify" component={VerifyFullKYC} />
            <Route exact path="/prepaid-cards/managecard" component={ManageCard} />
            <Route exact path="/prepaid-cards/managecard/faq" component={PpiFAQ} />
            <Route exact path="/prepaid-cards/managecard/cardlimits" component={CardLimits} />
            <Route exact path="/prepaid-cards/managecard/statement" component={Statements} />
            <Route exact path="/prepaid-cards/shipping/edit" component={PrepaidCardShippingFormEdit} />
            <Route exact path="/prepaid-cards/shipping/view" component={PrepaidCardShippingFormView} />
            <Route exact path="/prepaid-cards/load-balance/cart" component={Cart} />
            <Route component={NotFound} />
          </Switch>

          {(window.location.pathname === "/en/winds-times" ||
            window.location.pathname === "/hi/winds-times" ||
            window.location.pathname === "/winners-patrons" ||
            window.location.pathname === "/winners-partners" ||
            window.location.pathname === "/digi-world-app" ||
            window.location.pathname === "/partner-app" ||
            window.location.pathname === "/patron-app" || 
            window.location.pathname === "/captain-account-deletion" ||
            window.location.pathname === "/ppi-benefits" || 
            window.location.pathname === "/pay-verification") ?
            null :
            <Footer />
          }
        </ScrollToTop>
      </Router>
    );
  }


}
export default App;
