import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { connect, useSelector } from "react-redux";
const SponsoredSlider = ({ title, data}) => {
  const profile = useSelector((state) => state.global.profile);
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  const sponsoredDirection = (link) => {
    
    const subIdValue = profile?.id || "defaultUserId"; 
    
    function generateRandomString(length = 10) {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const array = new Uint32Array(length);  
      window.crypto.getRandomValues(array);  
      return Array.from(array, num => chars[num % chars.length]).join('');
  }

    const clickIdValue = `web${generateRandomString(10)}`; 
  
    try {
      const url = new URL(link.rurl);
      const params = new URLSearchParams(url.search);
      params.set('subId', subIdValue);
      params.set('cid', clickIdValue);
  
      url.search = params.toString();
      const modifiedUrl = url.toString();
      window.open(modifiedUrl, '_blank');
      return modifiedUrl;
    } catch (error) {
      console.error("Invalid URL:", link.rurl, error);
    }
  };
  
  return (
    <>
        <div className="container service-categorie">
          <div className="row ">
            <div className="col-12 ">
              <div className="d-flex justify-content-end">
                <h6 className="pt-2 text-success text-end small">{title}</h6>
              </div>
              <Slider {...settings}>
                {data.map((link, index) => (
                  <div key={index} className="d-flex align-items-center justify-content-center" onClick={()=>sponsoredDirection(link)}>
                    <div>
                    <div className="d-flex align-items-center justify-content-center">
                        {link.iurl && (
                          <img src={link.iurl}  alt={link.brand}  width="75" style={{ border: "1px solid rgb(214,213,193)",backgroundColor: "rgb(239,237,224)",borderRadius: "50%",padding: "5px",}}/>
                        )}
                      </div>
                      <div>
                        <p className="text-center">{link.brand}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    profile: state.global.profile,
  };
}
function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SponsoredSlider);
