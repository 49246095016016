import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ic_user from "../../../../assets/prepaid_cards/ic_user.svg";
import ic_mobile from "../../../../assets/prepaid_cards/ic_mobile.svg";
import { useHistory } from "react-router-dom";
import { setKYCUniqueID, setUserName, updateUserData } from "../../actions";
import prepaidCardClientX from "../../../../utils/api/prepaidCardClientX";
import { toast } from "react-toastify";
import InlineLoader from "../../../../components/InlineLoader";

const InitiateSmallKYC = () => {
  let history = useHistory();
  let dispatch = useDispatch();

  const { profile } = (useSelector((state) => state?.global));
  const { selectedCardData } = (useSelector((state) => state?.ppi));

  const [userNameLocal, setUserNameLocal] = useState();
  const [btnLoad, setBtnLoad] = useState(false)
  const [kycData, setKycdata] = useState({
    mobileNumber: profile?.mobile,
    customerName: "",
  });

  const SetUserName = () => {
    const authConfig = {
      headers: {
        TempAuthToken: profile?.temp_ppi_token,
      }
    }

    setKycdata({ ...kycData, customerName: userNameLocal })
    dispatch(updateUserData());
    setBtnLoad(true)
    prepaidCardClientX.post(`/generate-min-kyc`, {
      "customerName": userNameLocal,
      "mobileNumber": parseInt(profile?.mobile),
      // "email": profile?.email,
      // "kyc_consent": 1,
      // "tnc_consent": 1,
      // "pep_consent": 1
    }, authConfig)
      .then((response) => {
        let responseData = response.data.data;
        dispatch(setUserName(userNameLocal));
        dispatch(setKYCUniqueID(responseData.minKycUniqueId))
        toast.success(responseData?.responseMessage)
        setBtnLoad(false)

        if (responseData?.page_redirection_segment === "card_details_page")
          history.push("/prepaid-cards/managecard")
        else
          history.push("/prepaid-cards/small-kyc/validate");

      }).catch(error => {
        setBtnLoad(false)
      })
  };

  return (
    <>
      <div className="my-5">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-sm-8 col-md-5">
              <p className="cardkyc-title text-center bold mb-4">
                {selectedCardData?.cardPlan} CARD KYC
              </p>
              <p className="cardkyc-subtitle text-center">
                Provide your personal details
              </p>
              <div className="d-flex flex-row justify-content-center align-items-center mt-2">
                <div className="p-1 col-1 pr-5 pr-lg-5">
                  <img src={ic_mobile} alt="img"></img>
                </div>
                <div className="p-1 col-10 col-md-8 my-2">
                  <p className="cardedit-head-text">Mobile Number</p>
                  <p className="cardedit-body-text ">+91 {profile?.mobile}</p>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-center align-items-center mt-3 mb-md-5 mb-4">
                <div className=" p-1 col-1 pr-5 pr-lg-5">
                  <img src={ic_user} alt="alt"></img>
                </div>
                <div className="p-1 col-10 col-md-8 mt-2">
                  <p className="cardedit-head-text">Full Name</p>
                  <div class="form__group field w-100 cardedit-body-text">
                    <input
                      type="input"
                      className="form__field cardedit-body-text"
                      placeholder="Enter your name as per the document"
                      value={userNameLocal}
                      onChange={(e) => {
                        setUserNameLocal(e.target.value);
                      }}
                      required
                    />
                  </div>
                </div>
              </div>
              {
                btnLoad ?
                  <div className="d-flex justify-content-center my-4">
                    <InlineLoader />
                  </div>
                  :
                  <button
                    className="animated-btn"
                    id={userNameLocal ? "" : "animated-btn-disable"}
                    onClick={SetUserName}
                  >
                    CONTINUE
                  </button>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InitiateSmallKYC;
