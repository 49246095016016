import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import "./HotelBooking.css";
import windsLogo from "./image/windsLogo.png";
import webAgodaImg from "./image/webAgodaImg.png";
import mobileAgodaImg from "./image/mobileAgodaImg.png";
import { setUserAccessToken } from "../../utils/authorization";
import profileClientX from "../../utils/api/profileClientX";
import { resetProfile, setProfile } from "../../actions";
import { decryptByAesAndBase64WST, readCookie } from "../../utils/helper";
// import { readCookie } from "../../utils/helper";
import {userAccessToken }from "../../utils/userAccessToken"
// export const accessTokenName = process.env.REACT_APP_ACCESS_TOKEN_NAME;
let secret_key = process.env.REACT_APP_OTP_SECRET_KEY;
let secret_iv = process.env.REACT_APP_OTP_SECRET_IV;
export const accessTokenName = process.env.REACT_APP_ACCESS_TOKEN_NAME;

const HotelBooking = ({ profile }) => {
  // const dispatch = useDispatch();
  // //const wst=readCookie('wst')
  // const queryParameters = new URLSearchParams(window.location.search);
  // const parameterData = queryParameters.get(accessTokenName);

  //   const accessToken = decryptByAesAndBase64WST(
  //     parameterData,
  //     secret_key,
  //     secret_iv
  //   );

  //    const getProfileData = () => {
  //      profileClientX
  //     .get("v1/profile")
  //     .then((response) => {
  //       console.log(response,"response")
  //       const responseData = response.data?.data;
  //       dispatch(setProfile(responseData));
  //       profile = response.data?.data;
  //       setUserAccessToken(accessToken);
  //       // history.push("/");
  //       // onLoginEvent(responseData?.mobile)
  //     })
  //     .catch((error) => {
  //       dispatch(resetProfile());
  //       // dispatch(resetWrp());
  //     });

  // };

  useEffect(() => {
    // userAccessToken(dispatch);
    const script1 = document.createElement("script");
    script1.src = "//cdn0.agoda.net/images/sherpa/js/sherpa_init1_08.min.js";
    script1.async = true;

    script1.onload = () => {
      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.innerHTML = `
            var stg = new Object();
            stg.crt="23926669751";
            stg.version="1.04";
            stg.id=stg.name="adgshp-1000689165";
            stg.width="1288px";
            stg.height="300px";
            stg.ReferenceKey="RZREl5sBvP0tpqIJfOmDWg==";
            stg.Layout="SquareCalendar";
            stg.Language="en-us";
            stg.Cid="1927360";
            stg.DestinationName="";
            stg.OverideConf=false; 
            new AgdSherpa(stg).initialize();`;
      document.body.appendChild(script2);
    };

    document.body.appendChild(script1);

      // if (accessToken) {
      //   setUserAccessToken(accessToken);
      //   getProfileData();
      // }
    return () => {
      document.body.removeChild(script1);
    };
  }, []);

  return (
    <>
      <div className="agoda-main">
        <div className="agoda-search-bar">
          <div id="adgshp-1000689165"></div>
        </div>
      </div>
      {profile && (
        <div className="container-fluid">
          <div className="row position-relative">
            <img
              src={webAgodaImg} alt="webImage"
              className="w-100 img-fluids d-none d-sm-block"
            />
            <img
              src={mobileAgodaImg}
              alt="mobile-img"
              className=" w-100 d-block d-sm-none img-fluids"
            />
            <div className="overlay-content position-absolutes w-100 h-100 d-flex flex-column justify-content-center align-items-center">
              <div className="row w-100  d-flex flex-end">
                <div className="col-2 col-xs-3 col-sm-2 col-md-2 col-lg-1 col-xl-2 d-flex justify-content-center align-items-center">
                  <img
                    src={windsLogo}
                    alt="logo"
                    className="overlay-logo  img-fluid"
                  />
                </div>
                <div className="col-10 col-xs-9 col-sm-7 col-md-7 col-lg-8 col-xl-7 d-flex justify-content-start align-items-center">
                  <h5 className="text-white  overlay-text ">
                    Book your hotel from Agoda via KAMAIE App and upload your bill
                    here to claim your KAMAIE Coins and Rewards.
                  </h5>
                </div>
                <div className="col-0 col-xs-0 col-sm-3 col-md-3 col-lg-3 col-xl-3 d-flex justify-content-center align-items-center">
                  <a
                    href="https://windsapp.com/upload-bill/shopping-site/352?isMob=1"
                    target="_blank" rel="noreferrer" 
                  >
                    <button
                      type="button"
                      className="btn btn-primary btn-sm overlay-button "
                    >
                      Upload Bill
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <div className="agoda-content-head ">
              <h2 className="font-weight-bold">
                Hotel Booking from KAMAIE App powered by Agoda
              </h2>
            </div>
            <div>
              <p>
                Agoda is a digital travel platform that brings high-value and
                rewarding travel experiences to people all over the world
                through the Agoda app and website. Our mission is to empower
                everyone to be a traveler by offering affordable deals on
                hotels, flights, activities, and more, with a hassle-free
                booking experience from start to finish. Since 2005, we’ve made
                searching and booking travel as easy as it should be. Agoda is a
                travel-tech company with millions of registered customers, 24/7
                customer service, supported by a dedicated and diverse team of
                more than 6,900 people in 25 markets.
              </p>
            </div>
            <div>
              <h2 className="font-weight-bold pt-4">How it works</h2>
              
                <ul>
                  <li>
                    Enter the City name for Hotel search & select the CheckIn
                    and CheckOut date and click on Search.
                  </li>
                  <li>
                    You will be redirected to Agoda website for Booking the
                    Hotel.
                  </li>
                  <li>
                    Complete the Booking you want in the same session on the
                    Agoda website; (Please do not close or refresh the page else
                    the Booking it will not be tracked)
                  </li>
                  <li>
                    After booking is completed, Click on 'Upload Bill' in KAMAIE
                    Agoda page{" "}
                  </li>
                  <li>
                    Enter the Booking details along with that attaching the
                    Bill/Invoice to track your purchase and Reward you for the
                    booking.
                  </li>
                  <li>
                    If for any reason you leave the website before completing
                    the Booking, you must restart from step 1.
                  </li>
                </ul>
              
            </div>
            <div>
              <h2 className="font-weight-bold pt-4">Terms of use</h2>
              <p>
                <span className="text-decoration-underline text-primary">
                  <a href="https://www.agoda.com/en-in/info/termsofuse.html">
                    https://www.agoda.com/en-in/info/termsofuse.html
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    profile: state.global.profile,
  };
}
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(HotelBooking);
