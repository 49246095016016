import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import billpayRight from "../../../assets/billpay-right.png"
import bpAssuredHorizontal from "../../../assets/billpay/ic_bp_logo_horizontal.svg"
import bharatConnect from "../../../assets/billpay/bharatPayIcon.svg"
import billPayClientX from "../../../utils/api/billPayClientX";
import { billPayServices, billPayServicesLoading } from "../actions/order";
import lodash from "lodash";
import BillpayMetaData from "../../../seo/BillpayMetaData";

class BillPay extends Component {

  state = {}
  componentDidMount() {
    this.props.onInitBillpayServices()
  }

  Shimmer = (props) => {
    return (
      <React.Fragment>
        <div className="shine slider-shimmer"></div>
        {lodash.range(4).map((index) => {
          return (
            <div className="container container-fluid--home-section" key={index} >
              <div className="row row--home-section-title">
                <div className="col-md-12">
                  <div className="heading shine"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-4 col-md-2">
                  <div className="box shine" style={{ borderRadius: "50%" }}></div>
                </div>
                <div className="col-4 col-md-2">
                  <div className="box shine" style={{ borderRadius: "50%" }}></div>
                </div>
                <div className="col-4 col-md-2">
                  <div className="box shine" style={{ borderRadius: "50%" }}></div>
                </div>
                <div className="col-4 col-md-2">
                  <div className="box shine" style={{ borderRadius: "50%" }}></div>
                </div>
                <div className="col-4 col-md-2">
                  <div className="box shine" style={{ borderRadius: "50%" }}></div>
                </div>
                <div className="col-4 col-md-2">
                  <div className="box shine" style={{ borderRadius: "50%" }}></div>
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  billPayServices = () => {
    return (
      <section>
        <div className="billpay-banner">
          <div className="container">
            <div className="row d-flex justify-content-end" >
              <img src={bharatConnect} className="bp-assured-horizontal" alt="Assured" title="Assured" style={{ paddingRight: "15px" }} />
            </div>
            <div className="row align-items-center">
              <div className="col-md-6 col-7 pr-0 pr-md-3 head-margin">
                <h1 className="font-proxima-nova-bold">
                  <FormattedMessage
                    id="home.recharge-pay-bills"
                    defaultMessage="Recharge & Pay Bills"
                    description="Recharge & Pay Bills"
                    values={{ what: "react-intl" }}
                  />
                </h1>
                <p className="h2">
                Earn KAMAIE Coins & Cashback via Spin Wheel On Every Transaction!
                </p>
                <small>*T&C Apply</small>
              </div>
              <div className="col-6 d-flex justify-content-center">
                <img className="image" src={billpayRight} alt="billpay" title="billpay" />
              </div>
            </div>
          </div>
        </div>
        <div className="container container-fluid--home-section billslick pt-md-0" style={{ backgroundColor: "#FFF" }}>
          {this.props?.billPayServices?.data?.length > 0 && this.props?.billPayServices?.data?.map((item, index) => {
            return (
              <div className="mb-md-5 mb-3" key={index}>
                <div className="row">
                  <div className="col-12">
                    <h2 className="billpay-service-title">{item.category}</h2>
                  </div>
                </div>
                <div className="row pb-3">
                  {item?.services?.map((item, index) => {
                    return (
                      <div className="billpay-page col-4 col-sm-4 col-md-3 col-lg-2 mb-3" key={"service-" + index}>
                        <Link to={item.slug ? `/bill-pay/${item?.slug}` : `/bill-pay/${item?.id}`}>
                          <div style={{ textAlign: "center" }}>
                            <div className="recharge-img-box">
                              <img src={item.app_image_url} className="bill-pay-service-icon-img mb-1 mb-md-2" alt={item.name} title={item.name} />
                            </div>
                            <p className="bill-pay-service-icon-label mb-0" style={{ textAlign: "center", color: "#666" }}> {item.name}</p>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </section>
    )
  }

  render() {
    return (
      this.props?.billPayServicesLoading ?
        <this.Shimmer /> :
        <>
          <BillpayMetaData />
          <this.billPayServices />
        </>
    );
  }
}

function mapStateToProps(state) {
  return {
    billPayServices: state.order.billPayServices,
    billPayServicesLoading: state.order.billPayServicesLoading,

  };
}

function mapDispatchToProps(dispatch) {
  return {
    onInitBillpayServices: () => {
      dispatch(billPayServicesLoading(true));
      billPayClientX.get("v3/services")
        .then((response) => {
          const data = response.data;
          dispatch(billPayServices(data));
          dispatch(billPayServicesLoading(false));
        })
        .catch((error) => {

        })
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BillPay);