import { combineReducers } from "redux";
import searchReducer from "../modules/search/reducers";
import locationReducer from "../reducers/location";
import homeReducer from "../modules/home/reducers";
import shoppingSitesReducer from "../modules/shopping_sites/reducers";
import voucherReducers from "../modules/vouchers/reducers";
import partnerReducer from "../modules/partners/reducers/partner";
import offerReducer from "../modules/partners/reducers/offer";
import orderReducer from "../modules/bill_pay/reducers/order";
import earningsReducer from "../modules/earnings/reducer/index";
import GlobalReducer from "./global";
import wrpReducer from '../modules/home/reducers/wrpReducer.js';
import travelReducer from "../modules/travel/reducers";
import ppiReducer from '../modules/prepaid_cards/reducers/index'

const rootReducer = combineReducers({
  home: homeReducer,
  location: locationReducer,
  search: searchReducer,
  shoppingSite: shoppingSitesReducer,
  voucher: voucherReducers,
  partner: partnerReducer,
  offer: offerReducer,
  order: orderReducer,
  global: GlobalReducer,
  earnings: earningsReducer,
  wrp: wrpReducer,
  travel: travelReducer,
  ppi: ppiReducer,
});

export default rootReducer;
